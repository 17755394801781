import { useContext, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { CreateRoleComponent } from "../../components/Roles/CreateRoleComponent";
import { TopHeader } from "../../components/TopHeader";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

export const CreateRolScreen = () => {
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Usuarios");
   const navigate = useNavigate();

   useEffect(() => {
      if (access) {
         navigate("/inicio");
      }
      // eslint-disable-next-line
   }, []);

   return (
      <>
         <Box sx={{ padding: 1, display: "flex", flexDirection: "column", backgroundColor: "#F5F5F5" }}>
            <TopHeader head="Crear rol" content={<></>} />
            <Container maxWidth="xl">
               <CreateRoleComponent />
            </Container>
         </Box>
      </>
   );
};
