import {
   Box,
   Button,
   Dialog,
   DialogTitle,
   Divider,
   Typography,
   MenuItem,
   Container,
   Stack,
   ListItemText,
   IconButton,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { InputSelectField } from "../../Inputs/InputSelectField";
import { InputTextField } from "../../Inputs/InputTextField";
import { useEffect, useState, useContext } from "react";
import { createMasterAccess, getAllServices } from "../../../lib/usersBEClient";
import { MasterAccessContext } from "../../../context/masterAccess/masterAccessContext";
import { SnackBarContext } from "../../../context/snackBarContext";
import { useNavigate } from "react-router-dom";
import { createRolSchema } from "../../../lib/Validations/inputSchemas";
import { InputRadioButton } from "../../Inputs/InputRadioButton";
import { LoadingButton } from "../../LoadingButton";
import { getDateAndTimeFromISODate } from "../../../const/globalConst";
import { Close } from "@mui/icons-material";

interface ICreateAccessModalProps {
   open: boolean;
   setOpen: Function;
}

export const CreateAccessModal = (props: ICreateAccessModalProps) => {
   const { resourcesSelected } = useContext(MasterAccessContext);
   const [services, setServices] = useState([]);
   const [resources, setResources] = useState([]);
   const [lastServiceSelected, setLastServiceSelected] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const navigate = useNavigate();

   const fetchInfo = async () => {
      const servicesResponse = await getAllServices();
      const serviceData = servicesResponse.data;
      setServices(serviceData);
   };

   const handleClose = () => {
      props.setOpen(false);
      setResources([]);
      setLastServiceSelected("");
   };

   async function handleMenuItemClick(serviceSelectedName, serviceSelected) {
      if (serviceSelected === lastServiceSelected) {
         return;
      }
      setLastServiceSelected(serviceSelected);
      const selectedService = servicesObtained.find((service) => service.id === serviceSelectedName);
      setResources(selectedService.resources.filter((resource) => resource.type === "intern"));
   }

   const servicesObtained = services
      .map((s) => ({
         id: s._id,
         name: s.service,
         resources: s.resources,
      }))
      .filter(
         (service) =>
            service.resources &&
            service.resources.length > 0 &&
            service.resources.some((resource) => resource.type === "intern")
      );

   useEffect(() => {
      fetchInfo();
   }, []);

   async function handleSubmit(values) {
      if (resourcesSelected.length === 0) {
         return showSnackBar("Selecciona al menos un recurso", true);
      }
      const info = {
         name: values.name,
         description: values.description,
         resources: resourcesSelected,
      };
      setIsLoading(true);
      try {
         await createMasterAccess(info.name, info.description, info.resources);
         showSnackBar("Acceso creado con éxito", false);
         setIsLoading(false);

         setTimeout(() => {
            navigate(-1);
         }, 2000);
      } catch (error) {
         console.log(error);
         setIsLoading(false);
         return;
      }
   }

   return (
      <Container maxWidth="xl">
         <Dialog
            open={props.open}
            onClose={handleClose}
            maxWidth="md"
            PaperProps={{ style: { width: "80%", maxHeight: "90vh" } }}
         >
            <Box bgcolor={"#162c44"} px={1}>
               <DialogTitle display={"flex"} justifyContent={"space-between"}>
                  <Stack>
                     <Typography fontSize={20} color={"white"} fontWeight={600}>
                        Creación de acceso
                     </Typography>
                     <Typography color={"white"} fontWeight={600}>
                        {getDateAndTimeFromISODate(new Date())}
                     </Typography>
                  </Stack>
                  <IconButton onClick={handleClose}>
                     <Close sx={{ color: "white" }} />
                  </IconButton>
               </DialogTitle>
            </Box>
            <Formik
               initialValues={{
                  name: "",
                  description: "",
               }}
               onSubmit={(values) => {
                  handleSubmit(values);
               }}
               validationSchema={createRolSchema}
            >
               {(formProps: FormikProps<any>) => (
                  <Box
                     overflow={"auto"}
                     sx={{
                        "::-webkit-scrollbar": {
                           display: "none",
                        },
                     }}
                  >
                     <Form>
                        <Box flexGrow={1}>
                           <Box m={2}>
                              <Box display="flex" alignItems="center" px={5} py={1}>
                                 <Typography fontSize={18} fontWeight={500} style={{ flex: "0 0 auto" }}>
                                    Detalles del acceso
                                 </Typography>
                                 <Divider sx={{ my: 1, flexGrow: 1, ml: 2 }} />
                              </Box>

                              <Box sx={{ flexDirection: "row", alignItems: "center" }}>
                                 <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    px={5}
                                    py={1}
                                    columnGap={"30px"}
                                 >
                                    <InputTextField
                                       variant="outlined"
                                       placeholder=""
                                       multiline
                                       size="small"
                                       name="name"
                                       id="name"
                                       type="text"
                                       label="Nombre del acceso"
                                       value={formProps.values.name}
                                       onChange={formProps.handleChange}
                                       sx={{
                                          width: "60%",
                                       }}
                                    />

                                    <InputTextField
                                       variant="outlined"
                                       placeholder=""
                                       multiline
                                       size="small"
                                       name="description"
                                       id="description"
                                       type="text"
                                       label="Descripción"
                                       value={formProps.values.description}
                                       onChange={formProps.handleChange}
                                       sx={{
                                          width: "100%",
                                          height: "100%",
                                       }}
                                    />
                                 </Box>
                              </Box>
                           </Box>

                           <Box m={2}>
                              <Box display="flex" alignItems="center" px={5} py={1}>
                                 <Typography fontSize={18} fontWeight={500} style={{ flex: "0 0 auto" }}>
                                    Accesos del usuario
                                 </Typography>
                                 <Divider sx={{ my: 1, flexGrow: 1, ml: 2 }} />
                              </Box>
                              <Box
                                 display={"flex"}
                                 flex={1}
                                 sx={{
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    px: 5,
                                 }}
                              >
                                 <Box display={"flex"} columnGap={2} alignItems={"center"} mb={2}>
                                    <Box>
                                       <Typography fontWeight={500}>Módulo al que tendrá acceso:</Typography>
                                    </Box>

                                    <Box mx={1} flex={1} width={"400px"}>
                                       <InputSelectField
                                          label="Seleccionar módulo"
                                          labelId="module"
                                          id="module"
                                          fullWidth
                                          name="module"
                                          variant={"outlined"}
                                          size={"small"}
                                       >
                                          {servicesObtained.map((option, index) => {
                                             return (
                                                <MenuItem
                                                   key={`option-${index}`}
                                                   value={option.id}
                                                   onClick={() => handleMenuItemClick(option.id, option.name)}
                                                >
                                                   {option.name}
                                                </MenuItem>
                                             );
                                          })}
                                       </InputSelectField>
                                    </Box>
                                 </Box>
                              </Box>
                              <Box justifyContent={"center"} sx={{ px: 5 }}>
                                 {resources.map((resource, index) => {
                                    return (
                                       <Box
                                          key={resource._id}
                                          sx={{
                                             display: "flex",
                                             alignItems: "center",
                                             justifyContent: "center",
                                             py: 1,
                                             px: 2,
                                             my: 2,
                                             border: "1px solid #B8C5D0",
                                             borderRadius: "10px",
                                             boxShadow: 1,
                                          }}
                                       >
                                          <ListItemText primary={resource.name} />
                                          <InputRadioButton
                                             name={`accessControl.${index}`}
                                             id={`accessControl.${index}`}
                                             row={true}
                                             access={true}
                                             resource={resource._id}
                                          />
                                       </Box>
                                    );
                                 })}
                              </Box>
                           </Box>
                        </Box>

                        <Box sx={{ display: "flex", rowGap: 2, flexDirection: "column", mx: 5, my: 3 }}>
                           <Stack direction="row" spacing={1} justifyContent="space-between">
                              <Button
                                 type="button"
                                 variant="outlined"
                                 sx={{ mr: 4, width: "100px" }}
                                 onClick={() => {
                                    props.setOpen(false);
                                    setResources([]);
                                    setLastServiceSelected("");
                                 }}
                              >
                                 Cancelar
                              </Button>
                              <LoadingButton label={"Guardar"} isLoading={isLoading} />
                           </Stack>
                        </Box>
                     </Form>
                  </Box>
               )}
            </Formik>
         </Dialog>
      </Container>
   );
};
