import React, { useContext } from "react";
import { Typography, StepButton, Step, Stepper, Box, CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { updateComplaintStateTracking, updateComplaintStatus } from "../../lib/lecosyBackendClient";
import { ConfirmationModal } from "../ConfirmationModal";
import { UploadFile } from "./UploadFile";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";
import { IncompetenceStep } from "./Sections/IncompetenceStep";
import { WitnessStep } from "./Sections/WitnessesStep";
import { OffenderStep } from "./Sections/OffenderStep";
import { ComplainantStep } from "./Sections/ComplainantStep";
import { ResolutionStep } from "./Sections/ResolutionStep";
import { NotificationStep } from "./Sections/NotificationStep";
import { ClosureStep } from "./Sections/ClousureStep";
import { SnackBarContext } from "../../context/snackBarContext";
import { IDocumentationDetails } from "../../types/BaseTypes";
import { UseGetCompanySubaname } from "../../hooks/getCompaniesName";

const steps = ["Incompetencia", "Testigos", "Denunciado", "Denunciante"];
const stepsResolution = ["Resolución", "Notificación de resolución", "Cierre de expediente"];

export const ComplaintProccessStepper = () => {
   const {
      complaint,
      complaintState,
      setComplaintState,
      isLoading,
      subSteps,
      setSelectedData,
      openCompleteDialog,
      setOpenCompleteDialog,
      openCompleteAllSteps,
      setOpenCompleteAllSteps,
      getComplaint,
      documentation,
      filteredWitnesses,
   } = useContext(ComplaintStepperContext);
   const { showSnackBar } = useContext(SnackBarContext);

   const handleComplete = async () => {
      const documents = handleGetDocumentList();
      let signatures = true;
      documents.forEach((doc) => {
         if (
            !doc.signature.every((signature) => {
               return signature.signed;
            })
         ) {
            showSnackBar("Faltan miembros del órgano interno por firmar", true);
            signatures = false;
         }
      });
      if (!signatures) return;
      if (complaintState.stateTracking === 0) {
         const necesaryDocuments: boolean[] = [];

         if (complaintState.processState + 1 === 3 && complaint.offenderDetails.length > 0) {
            for (let i = 0; i < complaint.offenderDetails.length; i++) {
               if (
                  complaint.documentation.some((doc) => doc.name === `replica-${complaint.reportNumber}-${i + 1}.pdf`)
               ) {
                  const filteredW = complaint.witnesses.filter(
                     (wit) => wit.createdBy === complaint.offenderDetails[i]._id
                  );
                  for (let j = 0; j < filteredW.length; j++) {
                     necesaryDocuments.push(
                        complaint.documentation.some(
                           (doc) =>
                              doc.name === `entrevista-testigo-${complaint.reportNumber}-${i + 1}-${j + 1}.pdf` ||
                              doc.name ===
                                 `constancia-incomparecencia-testigo-${complaint.reportNumber}-${i + 1}-${j + 1}.pdf`
                        )
                     );
                  }
               }
               necesaryDocuments.push(
                  complaint.documentation.some(
                     (doc) =>
                        doc.name === `entrevista-denunciado-${complaint.reportNumber}-${i + 1}.pdf` ||
                        doc.name === `constancia-incomparecencia-denunciado-${complaint.reportNumber}-${i + 1}.pdf`
                  )
               );
            }
         } else {
            if (complaintState.processState + 1 === 2 && complaint.witnesses.length > 0) {
               for (let i = 0; i < complaint.witnesses.length; i++) {
                  necesaryDocuments.push(
                     complaint.documentation.some(
                        (doc) =>
                           doc.name === `entrevista-testigo-${complaint.reportNumber}-${i + 1}.pdf` ||
                           doc.name === `constancia-incomparecencia-testigo-${complaint.reportNumber}-${i + 1}.pdf`
                     )
                  );
               }
            } else {
               if (complaintState.processState + 1 === 2) {
                  necesaryDocuments.push(
                     complaint.documentation.some(
                        (doc) => doc.name === `constancia-inexistencia-testigo-${complaint.reportNumber}.pdf`
                     )
                  );
               }
            }
            if (complaintState.processState + 1 === 3) {
               necesaryDocuments.push(
                  complaint.documentation.some(
                     (doc) => doc.name === `constancia-inexistencia-denunciado-${complaint.reportNumber}.pdf`
                  )
               );
            }
         }

         if (necesaryDocuments.some((doc) => doc === false)) {
            showSnackBar(`Faltan archivos por enviar.`, true);
            setOpenCompleteDialog(false);
            setOpenCompleteAllSteps(false);
            return;
         }
      } else {
         let necesaryDocuments;
         if (complaintState.processState + 1 === 1) {
            necesaryDocuments = complaint.documentation.find(
               (doc) => doc.name === `resolucion-${complaint.type || "denuncia"}-${complaint.reportNumber}.pdf`
            );
         } else if (complaintState.processState + 1 === 2) {
            necesaryDocuments = complaint.documentation.find(
               (doc) =>
                  doc.name === `notificacion-resolucion-${complaint.type || "denuncia"}-${complaint.reportNumber}.pdf`
            );
         }
         if (!necesaryDocuments) {
            showSnackBar(`Faltan archivos por enviar.`, true);
            setOpenCompleteDialog(false);
            setOpenCompleteAllSteps(false);
            return;
         }
      }

      await updateComplaintStateTracking(
         complaint.reportNumber,
         complaintState.stateTracking,
         complaintState.processState + 1
      );
      if (complaintState.stateTracking === 1 && complaintState.processState + 1 === 2) {
         const date = new Date();
         const getCompanySubName = await UseGetCompanySubaname(complaint);
         await updateComplaintStatus(
            complaint.reportNumber,
            "resuelto",
            date.getDay(),
            date.getMonth(),
            date.getFullYear(),
            `${date.getHours()}:${date.getMinutes()}`,
            `www.denuncias.lecosy.com.mx/${getCompanySubName}`,
            complaint.complainerDetails ? complaint.complainerDetails.email : ""
         );
      }
      setSelectedData({
         index: 0,
         e: {
            name: "",
            lastName: "",
            gender: "",
            charge: "",
            observation: "",
            _id: "",
         },
      });
      setComplaintState({
         ...complaintState,
         processState: complaintState.processState + 1,
         stateTracking: complaintState.stateTracking,
      });
      setOpenCompleteDialog(false);
      setOpenCompleteAllSteps(false);
      getComplaint(complaint.reportNumber);
   };

   const handleCompleteSection = async () => {
      const documents = handleGetDocumentList();
      documents.forEach((doc) => {
         if (
            !doc.signature.every((signature) => {
               return signature.signed;
            })
         ) {
            showSnackBar("Faltan miembros del órgano interno por firmar", true);
            return false;
         }
      });
      if (complaintState.stateTracking + 1 === 1) {
         const necesaryDocuments = complaint.documentation.find(
            (doc) =>
               doc.name === `constancia-anonimo-${complaint.reportNumber}.pdf` ||
               doc.name === `entrevista-denunciante-${complaint.reportNumber}.pdf` ||
               doc.name === `constancia-incomparecencia-denunciante-${complaint.reportNumber}.pdf`
         );
         if (!necesaryDocuments) {
            showSnackBar(`Faltan archivos por enviar.`, true);
            setOpenCompleteDialog(false);
            setOpenCompleteAllSteps(false);
            return;
         } else {
            const date = new Date();
            const getCompanySubName = await UseGetCompanySubaname(complaint);
            await updateComplaintStatus(
               complaint.reportNumber,
               "en valoración",
               date.getDay(),
               date.getMonth(),
               date.getFullYear(),
               `${date.getHours()}:${date.getMinutes()}`,
               `www.denuncias.lecosy.com.mx/${getCompanySubName}`,
               complaint.complainerDetails ? complaint.complainerDetails.email : ""
            );
            setSelectedData({
               index: 0,
               e: {
                  name: "",
                  lastName: "",
                  gender: "",
                  charge: "",
                  observation: "",
                  _id: "",
               },
            });
         }
         await updateComplaintStateTracking(complaint.reportNumber, complaintState.stateTracking + 1, 0);
         getComplaint(complaint.reportNumber);
      }
      if (complaintState.stateTracking + 1 === 2) {
         const necesaryDocuments = complaint.documentation.find(
            (doc) => doc.name === `cierre-${complaint.reportNumber}.pdf`
         );
         if (!necesaryDocuments) {
            showSnackBar(`Faltan archivos por enviar.`, true);
            setOpenCompleteDialog(false);
            setOpenCompleteAllSteps(false);
            return;
         } else {
            const date = new Date();
            try {
               const getCompanySubName = await UseGetCompanySubaname(complaint);
               await updateComplaintStatus(
                  complaint.reportNumber,
                  "cerrada",
                  date.getDay(),
                  date.getMonth(),
                  date.getFullYear(),
                  `${date.getHours()}:${date.getMinutes()}`,
                  `www.denuncias.lecosy.com.mx/${getCompanySubName}`,
                  complaint.complainerDetails ? complaint.complainerDetails.email : ""
               );
            } catch (error: any) {
               if (error.response.status === 400) {
                  showSnackBar("No se a encontrado la denuncia", true);
                  return;
               } else if (error.response.status === 401) {
                  showSnackBar("Error al modificar el estatus de la denuncia, favor de volver a intentar.", true);
                  return;
               }
            }
            try {
               await updateComplaintStateTracking(complaint.reportNumber, complaintState.stateTracking + 1, 0);
            } catch (error: any) {
               if (error.response.status === 400) {
                  showSnackBar("No se a encontrado la denuncia", true);
                  return;
               } else if (error.response.status === 401) {
                  showSnackBar("Error al modificar el estatus de la denuncia, favor de volver a intentar.", true);
                  return;
               }
            }
            showSnackBar("El estatus de la denuncia fué cambiado a 'cerrada'.", true);
            getComplaint(complaint.reportNumber);
         }
      }
      setComplaintState({ ...complaintState, processState: 0, stateTracking: complaintState.stateTracking + 1 });
      setOpenCompleteDialog(false);
      setOpenCompleteAllSteps(false);
   };

   const handleGetDocumentList = () => {
      const documents: IDocumentationDetails[] = [];
      let involved = "incompetencia";
      if (complaintState.stateTracking === 0 && complaintState.processState === 1) involved = "testigo";
      if (complaintState.stateTracking === 0 && complaintState.processState === 2) involved = "denunciado";
      if (complaintState.stateTracking === 0 && complaintState.processState === 3) involved = "denunciante";
      if (complaintState.stateTracking === 1 && complaintState.processState === 0) involved = "resolution";
      if (complaintState.stateTracking === 1 && complaintState.processState === 1) involved = "notificacion";
      if (complaintState.stateTracking === 1 && complaintState.processState === 2) involved = "cierre";
      documentation.forEach((doc) => {
         switch (involved) {
            case "incompetencia":
               if (doc.name.includes("incompetencia")) documents.push(doc);
               break;
            case "testigo":
               if (doc.name.includes("testigo")) documents.push(doc);
               break;
            case "denunciado":
               if (doc.name.includes("denunciado")) documents.push(doc);
               if (doc.name.includes("replica")) documents.push(doc);
               for (let i = 1; i <= complaint.offenderDetails.length; i++) {
                  for (let j = 1; j <= filteredWitnesses.length; j++) {
                     if (doc.name.includes(`testigo-${j}-${i}-`)) documents.push(doc);
                     if (doc.name.includes(`testigo-${complaint.reportNumber}-${i}-${j}`)) documents.push(doc);
                  }
               }
               break;
            case "denunciante":
               if (doc.name.includes("denunciante") || doc.name.includes("anonimo")) documents.push(doc);
               break;
            case "resolution":
               if (doc.name.includes("resolucion")) documents.push(doc);
               break;
            case "notificacion":
               if (doc.name.includes("notificacion-resolucion")) documents.push(doc);
               break;
            default:
               if (doc.name.includes("cierre")) documents.push(doc);
               break;
         }
      });
      return documents;
   };

   const handleGetDocumentNames = () => {
      const documents: string[] = [];
      let involved = "incompetencia";
      if (complaintState.stateTracking === 0 && complaintState.processState === 1) involved = "testigo";
      if (complaintState.stateTracking === 0 && complaintState.processState === 2) involved = "denunciado";
      if (complaintState.stateTracking === 0 && complaintState.processState === 3) involved = "denunciante";
      if (complaintState.stateTracking === 1 && complaintState.processState === 0) involved = "resolution";
      if (complaintState.stateTracking === 1 && complaintState.processState === 1) involved = "notificacion";
      if (complaintState.stateTracking === 1 && complaintState.processState === 2) involved = "cierre";
      documentation.forEach((doc) => {
         switch (involved) {
            case "incompetencia":
               if (doc.name.includes("incompetencia")) documents.push(doc.name);
               break;
            case "testigo":
               if (doc.name.includes("testigo")) documents.push(doc.name);
               break;
            case "denunciado":
               if (doc.name.includes("denunciado")) documents.push(doc.name);
               if (doc.name.includes("replica")) documents.push(doc.name);
               for (let i = 1; i <= complaint.offenderDetails.length; i++) {
                  for (let j = 1; j <= filteredWitnesses.length; j++) {
                     if (doc.name.includes(`testigo-${j}-${i}-`)) documents.push(doc.name);
                     if (doc.name.includes(`testigo-${complaint.reportNumber}-${i}-${j}`)) documents.push(doc.name);
                  }
               }
               break;
            case "denunciante":
               if (doc.name.includes("denunciante") || doc.name.includes("anonimo")) documents.push(doc.name);
               break;
            case "resolution":
               if (doc.name.includes("resolucion")) documents.push(doc.name);
               break;
            case "notificacion":
               if (doc.name.includes("notificacion-resolucion")) documents.push(doc.name);
               break;
            default:
               if (doc.name.includes("cierre")) documents.push(doc.name);
               break;
         }
      });
      return documents;
   };

   const InvestigationSection = () => {
      return (
         <React.Fragment>
            <Box sx={{ minHeight: "50vh" }}>
               <Stepper nonLinear activeStep={complaintState.processState}>
                  {steps.map((label, _index) => (
                     <Step key={label}>
                        <StepButton disabled color="inherit">
                           {label}
                        </StepButton>
                     </Step>
                  ))}
               </Stepper>
               <Box sx={{ mt: 4, mx: 2 }}>
                  {complaintState.processState === 0 ? (
                     <IncompetenceStep />
                  ) : complaintState.processState === 1 ? (
                     <WitnessStep />
                  ) : complaintState.processState === 2 ? (
                     <OffenderStep />
                  ) : (
                     <ComplainantStep />
                  )}
               </Box>
            </Box>
         </React.Fragment>
      );
   };

   const ResolutionAndClousureSection = () => {
      return (
         <React.Fragment>
            <Box sx={{ minHeight: "50vh" }}>
               <Stepper nonLinear activeStep={complaintState.processState}>
                  {stepsResolution.map((label, _index) => (
                     <Step key={label}>
                        <StepButton color="inherit">{label}</StepButton>
                     </Step>
                  ))}
               </Stepper>
               <div>
                  <Box sx={{ mt: 2, mx: 2 }}>
                     {complaintState.processState === 0 ? (
                        <ResolutionStep />
                     ) : complaintState.processState === 1 ? (
                        <NotificationStep />
                     ) : (
                        <ClosureStep />
                     )}
                  </Box>
               </div>
            </Box>
         </React.Fragment>
      );
   };

   return (
      <Box sx={{ bgcolor: "white", minHeight: "100%", p: 2, boxShadow: 3, display: "flex", flexDirection: "column" }}>
         <Box flex={1}>
            <Typography variant="h6" sx={{ pb: 1, px: 3 }}>
               {complaintState.stateTracking === 0
                  ? `Investigación de la ${complaint.type || "denuncia"}`
                  : complaintState.stateTracking === 1 && complaintState.processState > 1
                  ? "Cierre de expediente"
                  : `Resolución de la ${complaint?.type || "denuncia"}`}
            </Typography>
         </Box>
         <Box flex={2} sx={{ display: "flex", flexDirection: "column" }}>
            {!isLoading ? (
               <>
                  {subSteps === 0 ? (
                     <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        {complaintState.stateTracking === 0 ? (
                           <Box sx={{ flex: 1 }}>
                              <InvestigationSection />
                              <ConfirmationModal
                                 open={openCompleteDialog}
                                 title={`Completar sección "investigación"`}
                                 body={
                                    <>
                                       <Typography display="inline">
                                          ¿Estás seguro de que deseas completar esta sección?
                                       </Typography>
                                       <Typography display="block">Se cerrará permanentemente.</Typography>
                                    </>
                                 }
                                 onConfirm={() => {
                                    handleComplete();
                                 }}
                                 setOpen={setOpenCompleteDialog}
                                 documents={handleGetDocumentNames()}
                              />
                              <ConfirmationModal
                                 open={openCompleteAllSteps}
                                 title="Completar investigación de la denuncia"
                                 body={
                                    <>
                                       <Typography sx={{ display: "inline" }}>
                                          ¿Estás seguro de completar la investigación de la denuncia?
                                       </Typography>
                                       <Typography sx={{ display: "block" }}>Se cerrará permanentemente.</Typography>
                                    </>
                                 }
                                 onConfirm={() => {
                                    handleCompleteSection();
                                 }}
                                 setOpen={setOpenCompleteAllSteps}
                                 documents={handleGetDocumentNames()}
                              />
                           </Box>
                        ) : complaintState.stateTracking === 1 ? (
                           <>
                              <ResolutionAndClousureSection />
                              <ConfirmationModal
                                 open={openCompleteDialog}
                                 title={`Completar sección "resolución"`}
                                 body={
                                    <>
                                       <Typography sx={{ display: "inline" }}>
                                          ¿Seguro que deseas completar esta sección?
                                       </Typography>
                                       <Typography sx={{ display: "block" }}>Se cerrará permanentemente.</Typography>
                                    </>
                                 }
                                 onConfirm={handleComplete}
                                 setOpen={setOpenCompleteDialog}
                                 documents={handleGetDocumentNames()}
                              />
                              <ConfirmationModal
                                 open={openCompleteAllSteps}
                                 title="Completar resolución/cierre de la denuncia"
                                 body={
                                    <>
                                       <Typography sx={{ display: "inline" }}>
                                          {`¿Seguro que deseas completar la resolución y cierre de la ${
                                             complaint?.type || "denuncia"
                                          }?`}
                                       </Typography>
                                       <Typography sx={{ display: "block" }}>Se cerrará permanentemente.</Typography>
                                    </>
                                 }
                                 onConfirm={() => {
                                    handleCompleteSection();
                                 }}
                                 setOpen={setOpenCompleteAllSteps}
                                 documents={handleGetDocumentNames()}
                              />
                           </>
                        ) : (
                           <Box
                              sx={{
                                 display: "flex",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 pt: 2,
                                 pb: 4,
                                 flexDirection: "column",
                                 minHeight: "50vh",
                              }}
                           >
                              <CheckCircleIcon sx={{ width: 100, height: 100, color: "green" }} />
                              <Typography sx={{ fontSize: 20 }}>{`Proceso de la ${
                                 complaint?.type || "denuncia"
                              } finalizado.`}</Typography>
                           </Box>
                        )}
                     </Box>
                  ) : (
                     <Box sx={{ display: "flex", flex: 1, minHeight: "100%", flexDirection: "column" }}>
                        <UploadFile />
                     </Box>
                  )}
               </>
            ) : (
               <Box display={"flex"} justifyContent="center" sx={{ p: 4 }}>
                  <CircularProgress />
               </Box>
            )}
         </Box>
      </Box>
   );
};
