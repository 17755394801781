import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";
import { useContext, useState } from "react";
import { SnackBarContext } from "../../context/snackBarContext";
import { ConfirmationModal } from "../ConfirmationModal";
import { deletePdfEvidence, getUrlForDocumentationFile } from "../../lib/usersBEClient";
import { UserContext } from "../../context/UserContext";
import AnnouncementIcon from "@mui/icons-material/Announcement";

interface EvidenceTableProps {
   rows: any;
   headers: any[];
   reportNumber?: string;
   subject?: string;
   selectedUser?: string;
}

export default function EvidenceTable(props: EvidenceTableProps) {
   const { getComplaint, complaint } = useContext(ComplaintStepperContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Denuncias internas");
   const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
   const [document, setDocument] = useState("");

   const getFileSignedUrl = async (fileKey: string) => {
      const imagesUrlResponse = await getUrlForDocumentationFile(
         complaint.reportNumber,
         "files-lecosy",
         {
            folder: `evidences/${complaint.reportNumber}/${props.subject}/${
               props.selectedUser ? props.selectedUser : `anonimo`
            }`,
         },
         fileKey[0]
      );
      window.open(imagesUrlResponse, "_blank", "noopener,noreferrer");
   };

   const handleDeleteEvidence = async (fileKey: string) => {
      try {
         await deletePdfEvidence(
            "files-lecosy",
            {
               folder: `evidences/${complaint.reportNumber}/${props.subject}/${
                  props.selectedUser ? props.selectedUser : `anonimo`
               }`,
            },
            fileKey
         );
         getComplaint(props.reportNumber);
         showSnackBar("Evidencia eliminada correctamente.", false);
         setOpenConfirmationModal(false);
      } catch (error) {
         showSnackBar("Hubo un error al borar la evidencia.", true);
         setOpenConfirmationModal(false);
      }
   };

   const rows = (row: any) => {
      return (
         <TableRow key={row} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
               {row}
            </TableCell>
            {props.headers.length > 2 && props.subject !== "anonima" && props.subject !== "Denunciante" && !access ? (
               <TableCell align="center">
                  <Button
                     onClick={() => {
                        setDocument(row);
                        setOpenConfirmationModal(true);
                     }}
                  >
                     <DeleteForeverIcon fontSize="small" />
                  </Button>
               </TableCell>
            ) : null}
            {!access ? (
               <TableCell align="center">
                  <Button
                     onClick={() => {
                        getFileSignedUrl(row);
                     }}
                  >
                     <OpenInNewIcon fontSize="small" />
                  </Button>
               </TableCell>
            ) : (
               <TableCell colSpan={4} align="center">
                  <Tooltip
                     title={<Typography fontSize={13}>Permisos limitados para gestionar la denuncia.</Typography>}
                     placement="bottom"
                     sx={{ fontSize: 16 }}
                  >
                     <AnnouncementIcon sx={{ color: "#6A7F96" }} />
                  </Tooltip>
               </TableCell>
            )}
         </TableRow>
      );
   };

   return (
      <>
         <TableContainer>
            <Table sx={{ minWidth: "100%" }} aria-label="a dense table" size="small">
               <TableHead>
                  <TableRow>
                     {props.headers.map((header, i) => (
                        <TableCell variant="head" sx={{ fontWeight: 600 }} align={i > 0 ? "center" : "left"}>
                           {header.headerName}
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>
               <TableBody>{props.rows.map((row) => rows(row))}</TableBody>
            </Table>
         </TableContainer>
         <ConfirmationModal
            open={openConfirmationModal}
            setOpen={setOpenConfirmationModal}
            title={"Eliminar evidencia"}
            body={
               <Box>
                  <Typography>¿Estás seguro de eliminar la evidencia?</Typography>
                  <Typography variant="body2">Se va a eliminar la siguiente evidencia: </Typography>
                  <Typography variant="body2">{document}</Typography>
               </Box>
            }
            onConfirm={() => handleDeleteEvidence(document)}
         />
      </>
   );
}
