import axios from "axios";
import {
   BASE_ENDPOINT,
   DocumentationFile,
   DocumentationUpload,
   MASTERACCESS_ENDPOINT,
   SERVICES_ENDPOINT,
   handleInvalidRequest,
} from "./lecosyBackendClient";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IResource } from "../context/masterAccess/masterAccessContext";
import { getDateAndTimeFromISODate } from "../const/globalConst";

const base_url =
   window.location.hostname === "test.admin.lecosy.com.mx" ||
   window.location.hostname === "www.test.admin.lecosy.com.mx"
      ? "https://test.server.lecosy.com.mx"
      : process.env.NODE_ENV === "production"
      ? "https://server.lecosy.com.mx"
      : "http://localhost:8002";

const host = base_url;
const MASTER_ENDPOINT = "/master";
const CLIENT_ENDPOINT = "/client";
const USERS_ENPOINT = "/user";
const MAIL_ENDPOINT = "/mail";
const COMPANIES_ENDPOINT = "/companies";
const GROUPS_ENDPOINT = "/groups";
const BRANCHES_ENDPOINT = "/branches";
const SUPPORTNOTIF_ENDPOINT = "/sup-notif";
const INVOICE_DETAILS_ENDPOINT = "/invoiceDetails";
export const S3_ENDPOINT = "/s3";
const INVOICE_ENDPOINT = "/invoice";
const USERS_ENDPOINT = "/user";
const RECEIPT_ENDPOINT = "/receipt";
const CONTACT_ENDPOINT = "/contact";
const MASTER_ACCESS_ENDPOINT = "/access";
const COMPANYDETAILS_ENDPOINT = "/company_details";
const PLATFORM_INFO_ENDPOINT = "/platform-info";
const MASTER_ROLE = "/master-role";
const resources_url = "/resources";

export const sendSupportEmail = async (title, feedbackType, files, description?, email?) => {
   const body = {
      title,
      feedbackType,
      description,
      email,
      files,
   };
   return axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${MAIL_ENDPOINT}/send/support`, body);
};

export const restoreMasterUserPassword = async (email: string) => {
   const body = {
      email: email,
   };
   try {
      axios.defaults.withCredentials = true;
      return await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/masters/restore-password`, body);
   } catch (error) {}
};

export const resendEmailVerification = async (userEmail: string) => {
   try {
      const body = {
         email: userEmail,
      };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/auth/gevl`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const LoginMasterUser = () => {
   return useMutation({
      mutationFn: async (userInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/masters/login`, userInfo);
         return data;
      },
   });
};

export const createMasterUser = async (firstName: string, lastName: string, email: string, role: string[]) => {
   try {
      const body = {
         firstName: firstName,
         lastName: lastName,
         email: email,
         role: role,
      };
      axios.defaults.withCredentials = true;
      return await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/new`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const deleteMasterUser = async (uid: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.delete(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/${uid}`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updateMasterUser = async (
   uid: string,
   firstName?: string,
   lastName?: string,
   email?: string,
   role?: string
) => {
   try {
      let body = {};
      if (uid) body = { ...body, uid: uid };
      if (firstName) body = { ...body, firstName: firstName };
      if (lastName) body = { ...body, lastName: lastName };
      if (role) body = { ...body, role: role };
      if (email) body = { ...body, email: email };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/${uid}`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getMasterAccessById = async (_id: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${MASTER_ACCESS_ENDPOINT}/${_id}`);
   } catch (error) {
      return null;
   }
};

export const VerifyMasterUser = () => {
   return useMutation({
      mutationFn: async (userInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/masters/verify`, userInfo);
         return data;
      },
   });
};

export const useServicesWithResources = () => {
   return useQuery({
      queryKey: ["Services"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${SERVICES_ENDPOINT}/all`);
         return data;
      },
   });
};

export const LogoutMasterUser = async (email: string) => {
   axios.defaults.withCredentials = true;
   const body = {
      email: email,
   };
   await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/masters/logout`, body);
};

export const getMasterUserByUid = async () => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/masters/my-information`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const GetUserSession = async () => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}/session`);
   return data;
};

export const getGroupById = async (groupId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUPS_ENDPOINT}/${groupId}`);
   return data;
};
export const getGroupUsers = async (group: any) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/groupU/${group}/all`);
   return data;
};

export const CreateNewGroup = async (name: string) => {
   try {
      const body = {
         name: name,
      };
      axios.defaults.withCredentials = true;
      return await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUPS_ENDPOINT}/new`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const useCreateNewGroup = () => {
   return useMutation({
      mutationFn: async (group: { name: string; phoneNumber: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUPS_ENDPOINT}/new`, group);
         return data;
      },
   });
};

export const useUpdateGroup = () => {
   return useMutation({
      mutationFn: async (groupInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUPS_ENDPOINT}/update/${groupInfo._id}`,
            groupInfo
         );
         return data;
      },
   });
};

export const useUpdateGroupCompanies = () => {
   return useMutation({
      mutationFn: async (companies: { companies: readonly string[]; groupId: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUPS_ENDPOINT}/update/${companies.groupId}`,
            companies
         );
         return data;
      },
   });
};
export const useUpdateGroupInvoiceCompany = () => {
   return useMutation({
      mutationFn: async (invoiceCompany: {
         invoiceCompany?: string;
         groupId: string;
         invoiceData?: any;
         multiInvoice?: boolean;
      }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUPS_ENDPOINT}/update/${invoiceCompany.groupId}`,
            invoiceCompany
         );
         return data;
      },
   });
};
export const useUpdateGroupAdmin = () => {
   return useMutation({
      mutationFn: async (admin: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUPS_ENDPOINT}/update/${admin.groupId}`,
            admin
         );
         return data;
      },
   });
};
export const updateCompanyAdmin = async (userId: string, companydetailsId: string) => {
   const body = {
      admin: userId,
      role: "64f6261e2eb21c6d24e252c9",
   };
   axios.defaults.withCredentials = true;
   const { data } = await axios.put(
      `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANYDETAILS_ENDPOINT}/${companydetailsId}`,
      body
   );
   return data;
};
export const UpdateGroupAdmin = async (userId: string, groupId: string) => {
   const body = {
      admin: userId,
   };
   axios.defaults.withCredentials = true;
   const { data } = await axios.put(
      `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUPS_ENDPOINT}/update/${groupId}`,
      body
   );
   return data;
};

export const getAllCompanies = async () => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANIES_ENDPOINT}/all`);
   return data;
};

export const GetAllCompaniesWithoutGroups = async () => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANIES_ENDPOINT}/all/wg`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const GetGroupUsers = (group: any) => {
   return useQuery({
      queryKey: ["users", group],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${USERS_ENDPOINT}/groupU/${group._id}/all`
         );

         const processedData = data.map((row) => ({
            _id: row._id,
            firstName: row.firstName,
            lastName: row.lastName,
            phoneNumber: row.phoneNumber,
            role: row.role,
            email: row.email,
         }));
         return processedData;
      },
   });
};

export const useGetInCompletedGroups = () => {
   return useQuery({
      queryKey: ["Groups"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUPS_ENDPOINT}/all/filtered`);
         const processedData = data.map((row) => ({
            _id: row._id,
            name: row.name,
            createdAt: getDateAndTimeFromISODate(row.createdAt),
         }));
         return processedData;
      },
   });
};

export const GetCompanyById = async (companyId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANIES_ENDPOINT}/${companyId}`);
   return data;
};

export const GetCompaniesByIdList = async (companyIds: string[]) => {
   axios.defaults.withCredentials = true;
   const body = {
      companyIds: companyIds,
   };
   const { data } = await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANIES_ENDPOINT}/all/list`, body);
   return data;
};

export const GetBranchesById = async (branchId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${BRANCHES_ENDPOINT}/${branchId}`);
   return data;
};

export const getCompaniesAndBranchCountByGroup = async (groupId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(
      `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANIES_ENDPOINT}/all/group/${groupId}`
   );
   return data;
};

export const getLicencesByGroupAndModule = async (groupId: string, module: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUPS_ENDPOINT}/${groupId}/licences/${module}`
      );
      return data;
   } catch (err) {
      handleInvalidRequest(err);
   }
};

export const updateGroupModuleLicence = async (groupId: string, module: string, licences: number) => {
   axios.defaults.withCredentials = true;
   const body = {
      licences,
   };
   const { data } = await axios.put(
      `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUPS_ENDPOINT}/${groupId}/licences/${module}`,
      body
   );
   return data;
};

export const updateCompanyAdminUser = async (
   uid: string,
   firstName?: string,
   lastName?: string,
   email?: string,
   phoneNumber?: string,
   role?: string
) => {
   try {
      let body = {};
      if (uid) body = { ...body, uid: uid };
      if (firstName) body = { ...body, firstName: firstName };
      if (lastName) body = { ...body, lastName: lastName };
      if (role) body = { ...body, role: role };
      if (email) body = { ...body, email: email };
      if (phoneNumber) body = { ...body, phoneNumber: phoneNumber };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/update/${uid}`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};
export const updateClientUserRoles = async (
   _id: string,
   isCompany: boolean,
   isAdmin: boolean,
   idCompanyOrGroup: string,
   role?: any
) => {
   try {
      let body = {};
      if (_id) body = { ...body, _id: _id };
      if (isCompany) body = { ...body, isCompany: isCompany };
      if (isAdmin) body = { ...body, isAdmin: isAdmin };
      if (idCompanyOrGroup) body = { ...body, idCompanyOrGroup: idCompanyOrGroup };
      if (role) body = { ...body, role: role };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/compRoles`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getAllBranches = async () => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${BRANCHES_ENDPOINT}/all`);
   return data;
};

export const GetSupportNotifications = () => {
   return useQuery({
      queryKey: ["notifications"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${SUPPORTNOTIF_ENDPOINT}`);
         for (const n of data) {
            n.company = await GetCompanyById(n.company);
            n.company = n.company.person_details.comercialName;
         }
         return data;
      },
   });
};

export const getSupportNotifications = async () => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${SUPPORTNOTIF_ENDPOINT}`);
      for (const n of data) {
         n.company = await GetCompanyById(n.company);
         n.company = n.company.person_details.comercialName;
      }
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const deleteSupportNotificationById = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.delete(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${SUPPORTNOTIF_ENDPOINT}/${id}`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getMasterUserByUidInput = async (masterUid: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/${masterUid}`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getAllServices = async () => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${SERVICES_ENDPOINT}/all`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const GetServicesByCompanyDetails = (id: string) => {
   return useQuery({
      queryKey: ["services", id],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/company_details/${id}`);
         return data;
      },
   });
};

export const getServicesById = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${SERVICES_ENDPOINT}/${id}`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getMasterAccessByServiceId = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${SERVICES_ENDPOINT}${MASTERACCESS_ENDPOINT}/${id}`
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const createMasterRole = async (name: string, description: string, access: string[]) => {
   try {
      const body = {
         name: name,
         description: description,
         access: access,
      };
      axios.defaults.withCredentials = true;
      return await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/master-role/new`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const createMasterAccess = async (name: string, description: string, resources: IResource[]) => {
   try {
      const body = {
         name: name,
         description: description,
         resources: resources,
      };
      axios.defaults.withCredentials = true;
      return await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/access/new`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getAllMasterAccess = async () => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/access/get/all`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updateMasterAccessResources = async (resourceId: string) => {
   try {
      const body = {
         resourceId: resourceId,
      };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/access/update`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUserInfo = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${USERS_ENPOINT}/${id}`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUserClientInfo = async (uid: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USERS_ENPOINT}/${uid}`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getClientById = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANIES_ENDPOINT}/${id}`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const CreateNewCompany = () => {
   return useMutation({
      mutationFn: async (company: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/companies/new`, company);
         return data;
      },
   });
};

export const CreatePlatformInfo = () => {
   return useMutation({
      mutationFn: async (complaintsData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/platform-info/new`,
            complaintsData
         );
         return data;
      },
   });
};

export const UpdatePlatformInfo = (id: string) => {
   return useMutation({
      mutationFn: async (complaintsData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/platform-info/${id}`,
            complaintsData
         );
         return data;
      },
   });
};

export const getPlatformInfo = async (companyId: string) => {
   try {
      if (companyId === "") return;
      const { data } = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${PLATFORM_INFO_ENDPOINT}${COMPANIES_ENDPOINT}/${companyId}`
      );
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
   axios.defaults.withCredentials = true;
};

export const useCreateUser = () => {
   return useMutation({
      mutationFn: async (userInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/new`, userInfo);
         return data;
      },
   });
};

export const GetCompanyUsers = (company: any, group: { groupId?: any } = {}) => {
   return useQuery({
      queryKey: ["users", company],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const group_id = group.groupId;
         const { data } = await axios.get(
            group_id
               ? `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${USERS_ENDPOINT}/groupU/${group_id}/all`
               : `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${USERS_ENDPOINT}/${company._id}/all`
         );

         const processedData = data.map((row) => ({
            _id: row._id,
            firstName: row.firstName,
            lastName: row.lastName,
            phoneNumber: row.phoneNumber,
            role: row.role,
            email: row.email,
         }));
         return processedData;
      },
      enabled: !!company.company_details?.users,
   });
};

export const CreateInvoiceDetails = () => {
   return useMutation({
      mutationFn: async (details: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${INVOICE_DETAILS_ENDPOINT}/new`,
            details
         );
         return data;
      },
   });
};

export const UpdateInvoiceDetails = () => {
   return useMutation({
      mutationFn: async (details: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${INVOICE_DETAILS_ENDPOINT}/update/${details.id}`,
            details.invoice_details
         );
         return data;
      },
   });
};

export const useGetInCompletedCompanies = () => {
   return useQuery({
      queryKey: ["companies"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/companies/all/filtered`);
         const processedData = data.map((row) => ({
            _id: row._id,
            comercialName: row.person_details.comercialName,
            businessName: row.person_details.businessName,
            createdAt: getDateAndTimeFromISODate(row.createdAt),
            type: row.type,
         }));
         return processedData;
      },
   });
};

export const useUpdateCompanyPersonDetails = () => {
   return useMutation({
      mutationFn: async (userInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/companies/update/${userInfo.companyId}`,
            userInfo
         );
         return data;
      },
   });
};

export const GetAllServices = () => {
   return useQuery({
      queryKey: ["services"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${SERVICES_ENDPOINT}/all`);
         return data;
      },
   });
};

//#region users

export const GetAllGroups = () => {
   return useQuery({
      queryKey: ["groups"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/groups/all`);
         return data;
      },
   });
};

export const GetMasterUsers = () => {
   return useQuery({
      queryKey: ["masterUsers"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/masters`);
         return data;
      },
   });
};

export const getBranchesByCompanyId = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${BRANCHES_ENDPOINT}/company/${id}`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const GetAllUserRoles = () => {
   return useQuery({
      queryKey: ["userRoles"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user-roleall`);
         return data;
      },
   });
};

export const getUserStatus = async (usersId: any[]) => {
   const body = {
      usersId: usersId,
   };
   axios.defaults.withCredentials = true;
   const { data } = await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/userDetails/disabled`, body);
   return data;
};

export const getAllMasterRoles = async () => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/master-role/all`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const useCreateClientCompanyBranch = () => {
   return useMutation({
      mutationFn: async (branchInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${BRANCHES_ENDPOINT}/new`,
            branchInfo
         );
         return data;
      },
   });
};

export const useUpdateClientCompanyBranch = (branchId: string) => {
   return useMutation({
      mutationFn: async (branchInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${BRANCHES_ENDPOINT}/${branchId}`,
            branchInfo
         );
         return data;
      },
   });
};

export const UseBranchesByCompanyId = async (companyId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${BRANCHES_ENDPOINT}/company/${companyId}`
   );
   return data;
};

export const UseCreateCompanyInvoice = async (companyId: string, branchInfo: any) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INVOICE_ENDPOINT}/new/${companyId}`,
      branchInfo
   );
   return data;
};

export const UseInvoicesByCompanyId = async (companyId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INVOICE_ENDPOINT}${COMPANIES_ENDPOINT}/${companyId}`
   );
   return data;
};

export const useUpdateInvoiceDetails = (invoiceId: string) => {
   return useMutation({
      mutationFn: async (invoiceInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${INVOICE_DETAILS_ENDPOINT}/update/${invoiceId}`,
            invoiceInfo
         );
         return data;
      },
   });
};

export const useUpdateInvoiceDetailsProvider = (groupId: string) => {
   return useMutation({
      mutationFn: async (invoiceInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUPS_ENDPOINT}${INVOICE_DETAILS_ENDPOINT}/update/${groupId}`,
            invoiceInfo
         );
         return data;
      },
   });
};

export const useDeleteCompanyInvoice = () => {
   return useMutation({
      mutationFn: async (invoiceId: string) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.delete(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INVOICE_ENDPOINT}/${invoiceId}`
         );
         return data;
      },
   });
};

export const useUpdateInvoice = (invoiceId: string) => {
   return useMutation({
      mutationFn: async (invoiceInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INVOICE_ENDPOINT}/update/${invoiceId}`,
            invoiceInfo
         );
         return data;
      },
   });
};

export const useUpdateServiceDisable = () => {
   return useMutation({
      mutationFn: async (serviceUpdate: { service_id: string; companyDetails_id: string; disable: boolean }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/serviceUpdate/company_details/${serviceUpdate.companyDetails_id}${SERVICES_ENDPOINT}/${serviceUpdate.service_id}`,
            serviceUpdate
         );
         return data;
      },
   });
};

export const useUpdateUserLimit = () => {
   return useMutation({
      mutationFn: async (extraLicenses: { service_id: string; companyDetails_id: string; userLimit: number }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/extraLicenses/company_details/${extraLicenses.companyDetails_id}${SERVICES_ENDPOINT}/${extraLicenses.service_id}`,
            extraLicenses
         );
         return data;
      },
   });
};

export const UseLastCompanyInvoice = async (companyId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INVOICE_ENDPOINT}/lastInvoice/${companyId}`
   );
   return data;
};

export const GetReceiptWithoutFile = (companyId: string) => {
   return useQuery({
      queryKey: ["receiptWithoutFile"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${RECEIPT_ENDPOINT}/notFile${COMPANIES_ENDPOINT}/${companyId}`
         );

         const processedData = data.map((row) => ({
            _id: row._id,
            name: row.name,
            paymentDate: row.paymentDate,
            paymentPeriod: row.paymentPeriod,
            file: row.file,
         }));
         return processedData;
      },
   });
};

export const useUploadReceipt = (receiptId: string) => {
   return useMutation({
      mutationFn: async (receipt: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${RECEIPT_ENDPOINT}/upload/${receiptId}`,
            receipt
         );
         return data;
      },
   });
};

export const GetReceiptByCompanyId = (companyId: string) => {
   return useQuery({
      queryKey: ["companyReceipts"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${RECEIPT_ENDPOINT}${COMPANIES_ENDPOINT}/${companyId}`
         );
         return data;
      },
   });
};

export const useUpdateReceipt = (receiptId: string) => {
   return useMutation({
      mutationFn: async (receipt: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${RECEIPT_ENDPOINT}/update/${receiptId}`,
            receipt
         );
         return data;
      },
   });
};
//#endregion

//#region s3Client

export const getUrlForDocumentsList = async (bucket: string, objectId: any) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/list/${bucket}`, objectId);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUrlForDocumentationFile = async (
   reportNumber: string,
   bucket: string,
   folder: any,
   fileKey: string
) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/get/${bucket}/${fileKey}`, folder);
      if (data) {
         await DocumentationFile(reportNumber, fileKey);
         return data;
      }
      throw new Error("document not uploaded");
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUrlForDocumentationUpload = async (
   bucket: string,
   folder: any,
   fileKey: string,
   reportNumber: string
) => {
   try {
      const body = {
         bucket: bucket,
         folder: folder,
         name: fileKey,
      };
      axios.defaults.withCredentials = true;
      const { data } = await axios.post(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/upload`, body);
      if (data) {
         await DocumentationUpload(fileKey, reportNumber);
         return data;
      }
      throw new Error("document not uploaded");
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUrlForEvidenceUpload = async (
   evidences: string[],
   id: string,
   subject: string,
   reportNumber: string
) => {
   try {
      axios.defaults.withCredentials = true;
      const preSignedUrl: any[] = [];
      for (const fileKey of evidences) {
         const body = {
            bucket: "files-lecosy",
            folder: {
               folder: id
                  ? `evidences/${reportNumber}/${subject}/${id}`
                  : `evidences/${reportNumber}/${subject}/anonimo`,
            },
            name: fileKey,
         };
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/upload`, body);
         preSignedUrl.push(data);
      }
      if (preSignedUrl.length === 0) throw new Error("document not uploaded");
      return preSignedUrl;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const complaintUploadPdfEvidence = async (signedURL, file) => {
   try {
      const config = {
         headers: {
            "Content-Type": `${file.type}`,
         },
      };
      axios.defaults.withCredentials = false;
      return await axios.put(signedURL, file, config);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const deletePdfEvidence = async (bucket: string, folder: any, fileKey: string) => {
   try {
      axios.defaults.withCredentials = true;
      await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/delete/${bucket}/${fileKey}`, folder);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const deleteFolderS3 = async (bucket: string, folder: string) => {
   try {
      axios.defaults.withCredentials = true;
      await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/delete/folder`, { folder, bucket });
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const uploadFile = async (bucket: string, folder: string, type: string, companyId: string, fileKey: string) => {
   try {
      const body = {
         bucket: bucket,
         folder: folder,
         objectId: { type: type, name: companyId },
         name: fileKey,
      };
      axios.defaults.withCredentials = true;
      const { data } = await axios.post(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/upload`, body);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUrlS3 = async (bucket: string, folder: any, fileKey: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/get/${bucket}/${fileKey}`, folder);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getS3LastModifiedDate = async (bucket: string, folder: any, fileKey: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/get/date/${bucket}/${fileKey}`, folder);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const useDisableCompanyBranch = () => {
   return useMutation({
      mutationFn: async (companyBranchId: string) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${BRANCHES_ENDPOINT}/disable/${companyBranchId}`
         );
         return data;
      },
   });
};

export const useDisableCompany = () => {
   return useMutation({
      mutationFn: async (companyId: string) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPANIES_ENDPOINT}/disable/${companyId}`
         );
         return data;
      },
   });
};

export const useDisableGroup = () => {
   return useMutation({
      mutationFn: async (groupId: string) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${GROUPS_ENDPOINT}/disable/${groupId}`
         );
         return data;
      },
   });
};

export const findStatusService = async (companyDetailsId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANYDETAILS_ENDPOINT}/${companyDetailsId}/status`
      );
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getInvoiceById = async (invoiceId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INVOICE_ENDPOINT}/${invoiceId}`);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getReceiptById = async (receiptId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${RECEIPT_ENDPOINT}/${receiptId}`);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};
export const getContactInfoByCompanyId = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${CONTACT_ENDPOINT}${COMPANIES_ENDPOINT}/${companyId}`
      );
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

//#endregion

export const updateOwnerMasterUser = async (firstName: string, lastName: string) => {
   try {
      const body = {
         firstName: firstName,
         lastName: lastName,
      };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/modify/info`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updateUserEmail = async (email: string, uid: string) => {
   const body = {
      email,
      uid,
   };
   axios.defaults.withCredentials = true;
   return await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USERS_ENDPOINT}/modify/email`, body);
};

//pendiente
export const updateOwnerMasterUserEmail = async (email: string) => {
   try {
      const body = {
         email,
      };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/modify/email`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updateMasterUserPassword = async (password: string, newPassword: string) => {
   try {
      const body = {
         password,
         newPassword,
      };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/pass/v`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const createResource = async (resource: any) => {
   try {
      const body = {
         name: resource.name,
         type: resource.type,
      };
      axios.defaults.withCredentials = true;
      const { data } = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${resources_url}/new`, body);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const deleteResourceByName = async (resourceName: string) => {
   try {
      const body = {
         name: resourceName,
      };
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${resources_url}/delete`, body);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};
export const updateResourceByName = async (resourceName: string, resourceNewName: string) => {
   try {
      const body = {
         name: resourceName,
         newName: resourceNewName,
      };
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${resources_url}/update`, body);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updatePCservice = async (service: any) => {
   try {
      const body = {
         service: service.service,
         resources: service.resources,
         deleteResource: service.deleteResource,
         deletingResource: service.deletingResource,
      };
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${SERVICES_ENDPOINT}/updatepc`, body);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getCorporateData = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/corporateData/${companyId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getUserDataGB = async (arrayids: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/members`, arrayids);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getImplementationUsers = async () => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/implementation/users`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const addUserRole = async (body, userId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${BASE_ENDPOINT}/master/user/new/role/${userId}`, body);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const addUserRoleGroup = async (body, userId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${BASE_ENDPOINT}/master/user/new/role/${userId}/group`, body);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const removeUserFromCompany = async (userId, companyId, roleId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.delete(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/remove/implementation/${userId}/${companyId}/${roleId}`
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const removeUserFromGroup = async (userId, groupId, roleId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.delete(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/remove/implementation/${userId}/${groupId}/${roleId}/group`
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const removeRoleFromUser = async (userId, companyId, roleId, group: boolean = false) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/remove/${userId}/${companyId}/${roleId}`,
         { group }
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const ModifyMasterRole = async (body, roleId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${MASTER_ROLE}/update/${roleId}`, body);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const DeleteMasterRole = async (roleId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.delete(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${MASTER_ROLE}/${roleId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getManyCompaniesById = async (companiesIds: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANIES_ENDPOINT}/many`, {
         companiesIds,
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getManyCorporateData = async (companyArray: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/corporateData/many`, { companyArray });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getFileByGovernanceAndFoldername = async (governanceId: string, folderName: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/get/${governanceId}/${folderName}`
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const managePlatformInfoSuggestions = async (subName: string, suggestions: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const body = {
         suggestions,
      };
      const { data } = await axios.put(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${PLATFORM_INFO_ENDPOINT}/${subName}/sugestions`,
         body
      );
      return data;
   } catch (error) {
      console.error(error);
      handleInvalidRequest(error);
   }
};

export const createBeneficiaryApplicationConfig = async (configData) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary-application`, configData);
   return data;
};
