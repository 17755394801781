import { createContext, useContext, useState } from "react";
import { UserContext } from "./UserContext";

type SessionContextInferface = {
   expiredSession: boolean;
   expireSession: () => void;
};

export const SessionContext = createContext<SessionContextInferface>({
   expiredSession: false,
   expireSession: () => {},
});

export const SessionProvider = ({ children }) => {
   const { logoutUser } = useContext(UserContext);
   const [expiredSession, setExpiredSession] = useState(false);

   const expireSession = () => {
      setExpiredSession(true);
      setTimeout(() => {
         logoutUser();
         setExpiredSession(false);
      }, 3000);
   };

   return <SessionContext.Provider value={{ expireSession, expiredSession }}>{children}</SessionContext.Provider>;
};
