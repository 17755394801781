import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addResolution } from "../../../../lib/lecosyBackendClient";
import {
   Box,
   Button,
   Grid,
   MenuItem,
   Typography,
   CircularProgress,
   InputAdornment,
   Divider,
   Container,
} from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { complaintResolution } from "../../../../lib/DocmunetsTemplates/AcknowledgmentTemplates";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { WitnessInputComponent } from "../../../../components/ComplainantAndWitnessInputs";
import { Form, Formik } from "formik";
import { InputCheckBox } from "../../../../components/Inputs/InputCheckBox";
import { resolutionScreenSchema, sugestionResolutionSchema } from "../../../../lib/Validations/inputSchemas";
import { InputTextField } from "../../../../components/Inputs/InputTextField";
import { InputSelectField } from "../../../../components/Inputs/InputSelectField";
import { TitleAppBar } from "../../../../components/TitleAppBar";
import pdfMake from "../../../../const/pdfMakeFonts";
import { toISOString } from "../../../../const/globalConst";
import { ComplaintStepperContext } from "../../../../context/complaintStepperContext";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { UserContext } from "../../../../context/UserContext";
const clasificationsOptions = [
   "Posible comisión de un hecho delictivo",
   "Violación de derechos humanos",
   "Violación a las políticas internas y códigos de ética de la organización",
   "No es violatoria a ningún hecho contrario a la ley o a las políticas de la organización",
];

interface WitnessInputType {
   name: string;
   facts: string;
   proof: string;
}

export const ResolutionScreen = () => {
   const { reportNumber } = useParams();
   const { complaint, getComplaint, isLoading: loadingComplaint } = useContext(ComplaintStepperContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [witnessData, setWitnessData] = useState<WitnessInputType[]>([]);
   const [offenderData, setOffenderData] = useState<WitnessInputType[]>([]);
   const date = new Date();
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Usuarios");
   const navigate = useNavigate();

   useEffect(() => {
      const fetchComplaint = async () => {
         await getComplaint(reportNumber);
      };
      // eslint-disable-next-line
      if (loadingComplaint) fetchComplaint();
      // eslint-disable-next-line
   }, [loadingComplaint]);

   useEffect(() => {
      if (access) {
         navigate("/inicio");
      }
      // eslint-disable-next-line
   }, []);

   const handleSendForm = async (values, actions) => {
      setIsLoading(true);
      if (complaint.witnesses.length > 0 && witnessData.length < complaint.witnesses.length) {
         showSnackBar("Faltan datos del o los testigo/s.", true);
         setIsLoading(false);
         return null;
      }
      if (complaint.offenderDetails.length > 0 && offenderData.length < complaint.offenderDetails.length) {
         showSnackBar("Faltan datos del o los denunciado/s.", true);
         setIsLoading(false);
         return null;
      }

      const dd = complaintResolution(
         complaint.reportNumber,
         complaint.complainerDetails
            ? complaint.complainerDetails.name + " " + complaint.complainerDetails.lastName
            : "Denunciante anónimo",
         complaint.category,
         values.complainantStatedFacts,
         values.complainantEvidence,
         values.clasificationComplaint,
         values.fundamentals,
         values.measures,
         values.dateTime,
         values.organization,
         "",
         "",
         "",
         witnessData,
         offenderData,
         complaint?.type || "denuncia",
         values.address
      );
      try {
         await addResolution(complaint.reportNumber, values.measures, values.clasificationComplaint);
         showSnackBar("Datos correctos", false);
         setIsLoading(false);
         actions.resetForm();
      } catch (error) {
         showSnackBar("Hubo un error, favor de volver a intentarlo", true);
         setIsLoading(false);
      }
      const pdfDocGenerator = pdfMake.createPdf(dd);
      pdfDocGenerator.open();
      setTimeout(() => navigate(-1), 1000);
   };

   const validationSchema = complaint?.type === "denuncia" ? resolutionScreenSchema : sugestionResolutionSchema;
   return (
      <Box p={2}>
         <TitleAppBar
            head={
               (complaint.type || "denuncia") === "denuncia"
                  ? "Acuse de resolución de la denuncia"
                  : `Resolución de la ${complaint.type || "denuncia"}`
            }
            isScheduleAppointment={false}
         />
         <Container maxWidth="xl">
            {complaint.reportNumber !== "" ? (
               <Formik
                  initialValues={{
                     complainantStatedFacts: "",
                     organization: "",
                     address: "",
                     complainantEvidence: "",
                     clasificationComplaint: "",
                     fundamentals: "",
                     measures: "",
                     dateTime: toISOString(date),
                     // queryBank: false,
                  }}
                  validationSchema={validationSchema}
                  validateOnChange={false}
                  onSubmit={handleSendForm}
               >
                  <Form>
                     <Box
                        display={"flex"}
                        sx={{ px: 4, py: 2, minWidth: 950, flexDirection: "column", alignItems: "center", m: 2 }}
                     >
                        <Box
                           sx={{
                              width: "100%",
                              backgroundColor: "#FFFFFF",
                              border: 1,
                              borderRadius: 2,
                              borderColor: "#8A95A0",
                           }}
                        >
                           <Grid container sx={{ py: 2 }}>
                              <Grid item xs={3}>
                                 <Box
                                    display={"flex"}
                                    sx={{
                                       alignItems: "center",
                                       justifyContent: "center",
                                       flexDirection: "column",
                                       py: 2,
                                    }}
                                 >
                                    <Typography fontWeight={500} variant="body1">
                                       {(complaint.type || "denuncia") === "denuncia"
                                          ? "Reporte de denuncia"
                                          : "Reporte de la queja"}
                                    </Typography>
                                    <Typography variant="subtitle1">{reportNumber}</Typography>
                                 </Box>
                              </Grid>
                              <Grid item xs={6}>
                                 <Box
                                    display={"flex"}
                                    sx={{
                                       alignItems: "center",
                                       justifyContent: "center",
                                       flexDirection: "column",
                                       py: 2,
                                    }}
                                 >
                                    <Typography fontWeight={500} variant="body1">
                                       Clasificación
                                    </Typography>
                                    <Typography variant="subtitle1">
                                       {complaint.clasification || "No aplica"}
                                    </Typography>
                                 </Box>
                              </Grid>
                              <Grid item xs={3} sx={{ display: "flex", alignItems: "center", pr: 2 }}>
                                 <InputTextField
                                    variant="standard"
                                    fullWidth
                                    name="dateTime"
                                    id="dateTime"
                                    label="Fecha y hora"
                                    type="datetime-local"
                                    InputLabelProps={{
                                       shrink: true,
                                    }}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    Datos de la empresa
                                 </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="organization"
                                       label="Empresa"
                                       id="organization"
                                       type="text"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <StoreIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </Box>
                              </Grid>
                              <Grid item xs={6}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="address"
                                       label="Dirección"
                                       id="address"
                                       type="text"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <LocationOnIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </Box>
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           {(complaint.type || "denuncia") === "denuncia" && (
                              <div>
                                 <Grid container>
                                    <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                       <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                          Relación de hechos y pruebas del denunciante
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                       <Box display={"flex"} sx={{ px: 4, display: "flex", alignItems: "center" }}>
                                          <Typography fontWeight={500} variant="body1" sx={{ textAlign: "center" }}>
                                             Nombre:&nbsp;
                                          </Typography>
                                          <Typography variant="subtitle1">
                                             {complaint.complaintType === "anonima"
                                                ? "Anónimo"
                                                : `${complaint.complainerDetails.name} ${complaint.complainerDetails.lastName}`}
                                          </Typography>
                                       </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <InputTextField
                                             variant="standard"
                                             multiline={true}
                                             name="complainantStatedFacts"
                                             label="Hechos declarados"
                                             id="complainantStatedFacts"
                                             type="text"
                                             fullWidth={true}
                                             InputProps={{
                                                startAdornment: (
                                                   <InputAdornment position="start">
                                                      <RateReviewIcon />
                                                   </InputAdornment>
                                                ),
                                             }}
                                          />
                                       </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <InputTextField
                                             variant="standard"
                                             multiline={true}
                                             name="complainantEvidence"
                                             label="Pruebas otorgadas"
                                             id="complainantEvidence"
                                             type="text"
                                             fullWidth={true}
                                             InputProps={{
                                                startAdornment: (
                                                   <InputAdornment position="start">
                                                      <StickyNote2Icon />
                                                   </InputAdornment>
                                                ),
                                             }}
                                          />
                                       </Box>
                                    </Grid>
                                 </Grid>
                                 <Divider sx={{ mx: 2 }} />
                                 {complaint.witnesses.length > 0 ? (
                                    <>
                                       <Grid container>
                                          <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                             <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                                Relación de hechos y pruebas del testigo
                                             </Typography>
                                          </Grid>
                                          {complaint.witnesses.map((e, i) => {
                                             return (
                                                <WitnessInputComponent
                                                   e={e}
                                                   data={witnessData}
                                                   setData={setWitnessData}
                                                   index={i}
                                                />
                                             );
                                          })}
                                       </Grid>
                                       <Divider sx={{ mx: 2 }} />
                                    </>
                                 ) : (
                                    <></>
                                 )}
                                 {complaint.offenderDetails.length > 0 ? (
                                    <>
                                       <Grid container>
                                          <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                             <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                                Relación de hechos y pruebas del denunciado
                                             </Typography>
                                          </Grid>
                                          {complaint.offenderDetails.map((e, i) => {
                                             return (
                                                <WitnessInputComponent
                                                   e={e}
                                                   data={offenderData}
                                                   setData={setOffenderData}
                                                   index={i}
                                                />
                                             );
                                          })}
                                       </Grid>
                                       <Divider sx={{ mx: 2 }} />
                                    </>
                                 ) : (
                                    <></>
                                 )}
                                 <Grid container>
                                    <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                       <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                          Resolución
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                       <Box display={"flex"} sx={{ p: 2 }}>
                                          <InputSelectField
                                             labelId="state-select-label"
                                             id="clasification"
                                             name="clasificationComplaint"
                                             label="La denuncia se considera como"
                                             variant="standard"
                                             fullWidth={true}
                                          >
                                             {clasificationsOptions.map((a) => (
                                                <MenuItem value={a} key={a}>
                                                   {a}
                                                </MenuItem>
                                             ))}
                                          </InputSelectField>
                                       </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <InputTextField
                                             variant="standard"
                                             multiline={true}
                                             name="fundamentals"
                                             label="Fundamentos"
                                             id="fundamentals"
                                             type="text"
                                             fullWidth={true}
                                          />
                                       </Box>
                                    </Grid>
                                 </Grid>
                                 <Divider sx={{ mx: 2 }} />
                              </div>
                           )}
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    Medidas
                                 </Typography>
                              </Grid>
                              <Grid item xs={2} />
                              <Grid item xs={8}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="measures"
                                       label="Medidas"
                                       id="measures"
                                       type="text"
                                       fullWidth={true}
                                    />
                                 </Box>
                              </Grid>
                              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                 <Box sx={{ display: "flex" }}>
                                    <InputCheckBox
                                       label="Enviar expediente de la presente denuncia a banco de consultas jurídicas."
                                       name="queryBank"
                                       id="queryBank"
                                    />
                                 </Box>
                              </Grid>
                           </Grid>
                        </Box>
                        <Box display={"flex"} sx={{ width: "100%", justifyContent: "flex-end" }}>
                           <Button variant="contained" sx={{ mt: 2 }} type="submit">
                              {isLoading ? <CircularProgress size={25} sx={{ color: "white" }} /> : "Enviar"}
                           </Button>
                        </Box>
                     </Box>
                  </Form>
               </Formik>
            ) : (
               <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                  <CircularProgress size={24} />
               </Box>
            )}
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   py: 2,
   px: 4,
};
