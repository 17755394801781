import { useContext } from "react";
import { Box, Button } from "@mui/material";
import { ComplaintStepperContext } from "../../../context/complaintStepperContext";
import { DocumentsTableNew } from "../DocumentsTableNew";
import { UserContext } from "../../../context/UserContext";

export const ComplainantStep = () => {
   const { complaint, isLastStep, setOpenCompleteAllSteps, setOpenCompleteDialog, checkDocuments } =
      useContext(ComplaintStepperContext);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Denuncias internas");
   return (
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "50vh" }}>
         <Box sx={{ flex: 2 }}>
            <DocumentsTableNew
               rows={
                  complaint.complaintType === "anonima"
                     ? [
                          {
                             document: "Denunciante anonimo",
                             templateLink: `entrevista/4`,
                             documentName: ["constancia-anonimo"],
                             fileHeader: "Agregar documento de denunciante anonimo",
                          },
                       ]
                     : [
                          {
                             document: "Notificación de entrevista",
                             documentName: ["entrevista-denunciante", "constancia-incomparecencia-denunciante"],
                             templateLink: `notificacion/denunciante/1`,
                             fileHeader: "Agregar documento de notificación al denunciante",
                          },
                          {
                             document: "Entrevista",
                             templateLink: `entrevista/3`,
                             documentName: ["entrevista-denunciante", "constancia-incomparecencia-denunciante"],
                             fileHeader: "Agregar documento de entrevista al denunciante",
                          },
                          {
                             document: "Constancia de incomparecencia",
                             templateLink: `incomparecencia/3`,
                             documentName: ["constancia-incomparecencia-denunciante", "entrevista-denunciante"],
                             fileHeader: "Agregar documento de constancia de incomparecencia",
                          },
                       ]
               }
               disabled={access}
            />
         </Box>
         {!access ? (
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flex: 1,
                  mt: 2,
                  mr: 2,
               }}
            >
               <Box sx={{ flex: "1 1 auto" }} />
               <Box sx={{ maxHeight: 30 }}>
                  {isLastStep() ? (
                     <Button
                        disabled={!checkDocuments("denunciante")}
                        onClick={() => setOpenCompleteAllSteps(true)}
                        variant="outlined"
                     >
                        Finalizar investigación
                     </Button>
                  ) : (
                     <Button
                        disabled={!checkDocuments("denunciante")}
                        onClick={() => setOpenCompleteDialog(true)}
                        variant="outlined"
                     >
                        {`Completar denunciante`}
                     </Button>
                  )}
               </Box>
            </Box>
         ) : null}
      </Box>
   );
};
