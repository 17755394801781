import { Box } from "@mui/material";
import Container from "@mui/material/Container/Container";
import { TopHeader } from "../../../components/TopHeader";
import { CollapsibleTable } from "../../../containers/ComplaintsContainer/Extern/RejectedComplaintsTable";

export const RejectedComplaintsScreen = () => {
   return (
      <Box sx={{ padding: 2, display: "flex", flexDirection: "column" }}>
         <TopHeader head="Denuncias Finalizadas" content={<></>} />
         <Container maxWidth="xl">
            <Box sx={{ m: 2 }} />
            <CollapsibleTable />
         </Container>
      </Box>
   );
};
