import { Route } from "react-router-dom";
import { SnackBarComponent } from "../components/SnackBarComponent";
import { SupportDrawer } from "../components/Drawers/SupportDrawer";
import { LoginScreen } from "../screens/LoginScreen";
import { DashboardScreen } from "../screens/DashboardScreen";
import { UserConfiguration } from "../screens/UserInformation/UserConfiguration";
import { ClientInformationScreen } from "../screens/Clients/ClientInformationScreen";
import { ClientGroupInformationScreen } from "../screens/Clients/ClientGroupInformationScreen";
import { PrivateRoute } from "../components/RouteComponents/PrivateRoute";
import { MasterUsersScreen } from "../screens/MasterUsersScreen";
import { MasterUsersInfoScreen } from "../screens/MasterUsersInfoScreen";
import { NewUserInformation } from "../screens/AddNewUser/NewUserInformation";
import { MasterAccessProvider } from "../context/masterAccess/masterAccessContext";
import { CreateRolScreen } from "../screens/Role/CreateRolScreen";
import { ForgotPasswordScreen } from "../screens/ForgotPasswordScreen";
import { ValidatePasswordScreen } from "../screens/ValidatePasswordScrees";
import { NotificationsScreen } from "../screens/NotificationsScreen";
import { useComplaintChannelRoutes } from "./complaintChannelRoutes";
import { ClientsManagementScreen } from "../screens/ClientsManagementScreen";
import { EditRolScreen } from "../screens/Role/EditRoleScreen";
import { NotFoundScreen } from "../screens/NotFoundScreen";
import { RootBoundary } from "../screens/ErrorScreen";

export const useInternManagementRoutes = () => {
   const complaintChannelRoutes = useComplaintChannelRoutes();

   return (
      <>
         <Route
            path="/login"
            element={
               <>
                  <SnackBarComponent />
                  <SupportDrawer toolbar={false} />
                  <LoginScreen />
               </>
            }
         />
         <Route path="/recuperar-contrasena" element={<ForgotPasswordScreen />} />
         <Route
            path="/validar-contrasena"
            element={
               <>
                  <SnackBarComponent />
                  <SupportDrawer toolbar={false} />
                  <ValidatePasswordScreen />
               </>
            }
         />
         <Route
            path="/"
            errorElement={<RootBoundary></RootBoundary>}
            lazy={async () => {
               const { loader, ProtectedRoute } = await import("../components/RouteComponents/ProtectedRoute");
               return {
                  loader,
                  Component: ProtectedRoute,
               };
            }}
         >
            <Route path="inicio" index element={<DashboardScreen />} />
            <Route path="configuracion" element={<UserConfiguration />} />

            <Route element={<PrivateRoute section="Clientes" />}>
               <Route path="clientes" element={<ClientsManagementScreen />} />
               <Route path="clientes/:clientId" element={<ClientInformationScreen />} />
               <Route path="clientes/grupo/:groupId" element={<ClientGroupInformationScreen />} />
            </Route>
            <Route element={<PrivateRoute section="Usuarios" />}>
               <Route path="usuarios" element={<MasterUsersScreen />} />
               <Route path="usuarios/roles" element={<EditRolScreen />} />
               <Route path="usuarios/:masterUid" element={<MasterUsersInfoScreen />} />
               <Route path="usuarios/registro" element={<NewUserInformation />} />
               <Route path="usuarios/registro/:masterUid" element={<MasterUsersInfoScreen />} />
               <Route
                  path="usuarios/registro/crear-rol"
                  element={
                     <MasterAccessProvider>
                        <CreateRolScreen />
                     </MasterAccessProvider>
                  }
               />
            </Route>
            <Route path="notificaciones" element={<NotificationsScreen />} />
            {/* CANAL DE DENUNCIAS */}
            {complaintChannelRoutes}
         </Route>
         <Route path="*" element={<NotFoundScreen />} />
      </>
   );
};
