import {
   Box,
   Button,
   TableContainer,
   Table,
   TableHead,
   TableRow,
   TableCell,
   TableBody,
   CircularProgress,
   Tooltip,
   Typography,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useContext } from "react";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";
import { getUrlForDocumentationFile } from "../../lib/usersBEClient";
import { UserContext } from "../../context/UserContext";
import AnnouncementIcon from "@mui/icons-material/Announcement";

interface ComplaintFileRecordProps {
   includeStepper: boolean;
   clousure: boolean;
}

export const ComplaintFileRecord = (props: ComplaintFileRecordProps) => {
   const { documentation, complaint } = useContext(ComplaintStepperContext);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Denuncias internas");

   const getFileSignedUrl = async (docmunetKey: string) => {
      const noExtFileKey = docmunetKey.indexOf(".pdf");
      const fileUrlResponse = await getUrlForDocumentationFile(
         complaint.reportNumber,
         "files-lecosy",
         { folder: `docs/${complaint.reportNumber}` },
         docmunetKey.substring(0, noExtFileKey)
      );
      window.open(fileUrlResponse, "_blank");
   };

   return (
      // <Box sx={{ maxHeight: props.includeStepper ? 225 : props.clousure ? 400 : 200, minHeight: 300 }}>
      <Box>
         {documentation.length !== 0 ? (
            <>
               <TableContainer sx={{ maxHeight: documentation.length > 5 ? 260 : "auto" }}>
                  <Table size="small">
                     <TableHead>
                        <TableRow>
                           <TableCell sx={{ fontWeight: 600 }}>Folio:</TableCell>
                           <TableCell align="center" sx={{ fontWeight: 600 }}>
                              Ver documento:
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {documentation.map((document, i) => (
                           <TableRow key={i}>
                              <TableCell>{document.name}</TableCell>
                              {!access ? (
                                 <TableCell align="center">
                                    <Button size="small" onClick={() => getFileSignedUrl(document.name)}>
                                       <PictureAsPdfIcon />
                                    </Button>
                                 </TableCell>
                              ) : (
                                 <TableCell align="center">
                                    <Tooltip
                                       title={
                                          <Typography fontSize={13}>
                                             Permisos limitados para gestionar la denuncia.
                                          </Typography>
                                       }
                                       placement="bottom"
                                       sx={{ fontSize: 16 }}
                                    >
                                       <AnnouncementIcon sx={{ color: "#6A7F96" }} />
                                    </Tooltip>
                                 </TableCell>
                              )}
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
            </>
         ) : (
            <Box display="flex" justifyContent="center" sx={{ p: 4, width: "100%" }}>
               <CircularProgress />
            </Box>
         )}
      </Box>
   );
};
