import { useContext, useEffect, useState } from "react";
import { CustomModalComponent } from "../CustomModalComponent";
import { Box, Button, Chip, Divider, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { createRolSchema } from "../../lib/Validations/inputSchemas";
import { InputTextField } from "../Inputs/InputTextField";
import { LoadingButton } from "../LoadingButton";
import { Delete } from "@mui/icons-material";
import { ModifyMasterRole } from "../../lib/usersBEClient";
import { SnackBarContext } from "../../context/snackBarContext";

interface EditRoleModalComponentProps {
   role: any;
   open: boolean;
   setOpen: Function;
   setOpenConfirmation: Function;
   access: any[];
   setRefetch: Function;
}

const EditRoleModalComponent = (props: EditRoleModalComponentProps) => {
   const { role, open, setOpen, setOpenConfirmation, access, setRefetch } = props;
   const { showSnackBar } = useContext(SnackBarContext);
   const [selectedAccess, setSelectedAccess] = useState([]);
   const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

   useEffect(() => {
      const newAccessList = [];
      for (const roleA of role.access)
         for (const a of access) if (roleA === a._id) newAccessList.push({ _id: roleA, name: a.name });
      setSelectedAccess(newAccessList);
      //eslint-disable-next-line
   }, [role.access]);

   const handleSubmit = async (values) => {
      try {
         if (selectedAccess.length > 0) {
            setIsLoadingSubmit(true);
            const data = {
               name: values.name,
               description: values.description,
               resources: selectedAccess.map((access) => access._id),
            };
            await ModifyMasterRole(data, role.id);
            showSnackBar("Rol actualizado correctamente.", false);
            setRefetch(Math.random());
            setOpen(false);
            setIsLoadingSubmit(false);
         } else showSnackBar("Favor de seleccionar accesos", true);
      } catch (error) {
         showSnackBar("Error al actualizar rol", true);
         console.log(error);
      }
   };

   return (
      <CustomModalComponent title="Editar rol" open={open} setOpen={setOpen} timeStamp>
         <Box sx={{ width: 600 }}>
            <Formik
               initialValues={{
                  name: role.name || "",
                  description: role.description || "",
               }}
               validationSchema={createRolSchema}
               onSubmit={handleSubmit}
            >
               {(formProps: FormikProps<any>) => (
                  <Form>
                     <Stack sx={{ px: 4, height: 500, overflowY: "auto" }}>
                        <Box display={"flex"} sx={{ py: 2 }}>
                           <Typography sx={{ fontWeight: 600, color: "#162c44" }}>Detalles de rol</Typography>
                           <Divider
                              sx={{
                                 my: 1,
                                 flexGrow: 1,
                                 marginBottom: "auto",
                                 marginTop: "auto",
                                 marginLeft: 1,
                                 marginRight: 1,
                                 border: 1,
                                 borderColor: "#E4E4E7",
                              }}
                           />
                        </Box>
                        <Stack spacing={2}>
                           <InputTextField
                              variant="outlined"
                              size="small"
                              name="name"
                              id="name"
                              type="text"
                              label="Nombre"
                              fullWidth={true}
                              value={formProps.values.name}
                           />
                           <InputTextField
                              variant="outlined"
                              multiline={true}
                              size="small"
                              name="description"
                              id="description"
                              type="text"
                              label="Descripción"
                              fullWidth={true}
                              value={formProps.values.description}
                           />
                        </Stack>
                        <Box display={"flex"} sx={{ py: 2 }}>
                           <Typography sx={{ fontWeight: 600, color: "#162c44" }}>Accesos de rol</Typography>
                           <Divider
                              sx={{
                                 my: 1,
                                 flexGrow: 1,
                                 marginBottom: "auto",
                                 marginTop: "auto",
                                 marginLeft: 1,
                                 marginRight: 1,
                                 border: 1,
                                 borderColor: "#E4E4E7",
                              }}
                           />
                        </Box>

                        <Select
                           fullWidth
                           variant={"outlined"}
                           size="small"
                           multiple
                           value={selectedAccess}
                           MenuProps={{
                              PaperProps: {
                                 style: {
                                    maxHeight: 250,
                                    width: 250,
                                 },
                              },
                           }}
                           renderValue={(selected) => (
                              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                 {selected.map((value) => (
                                    <Chip key={value._id} label={value.name} />
                                 ))}
                              </Box>
                           )}
                        >
                           {access.map((option, index) => (
                              <MenuItem
                                 key={`$key-${index}`}
                                 value={option._id}
                                 onClick={() => {
                                    console.log(option);
                                    console.log(selectedAccess);
                                    if (selectedAccess.findIndex((a) => a._id === option._id) < 0)
                                       setSelectedAccess([...selectedAccess, { _id: option._id, name: option.name }]);
                                    else setSelectedAccess(selectedAccess.filter((a) => a._id !== option._id));
                                 }}
                                 sx={{ fontSize: 14 }}
                              >
                                 {option.name}
                              </MenuItem>
                           ))}
                        </Select>

                        {role.users.length > 0 && (
                           <>
                              <Box display={"flex"} sx={{ py: 2 }}>
                                 <Typography sx={{ fontWeight: 600, color: "#162c44" }}>Usuarios con el rol</Typography>
                                 <Divider
                                    sx={{
                                       my: 1,
                                       flexGrow: 1,
                                       marginBottom: "auto",
                                       marginTop: "auto",
                                       marginLeft: 1,
                                       marginRight: 1,
                                       border: 1,
                                       borderColor: "#E4E4E7",
                                    }}
                                 />
                              </Box>
                              <Box
                                 sx={{
                                    display: "flex",
                                    px: 2,
                                    py: 1,
                                    borderRadius: 2,
                                    bgcolor: "#E4E6E8",
                                    justifyContent: "space-between",
                                 }}
                              >
                                 <Typography fontWeight={600}>Nombre</Typography>
                                 <Typography fontWeight={600}>Correo electrónico</Typography>
                              </Box>
                              {role.users.map((user, i) => (
                                 <Box key={i}>
                                    <Box
                                       sx={{
                                          display: "flex",
                                          px: 2,
                                          py: 1,
                                          justifyContent: "space-between",
                                       }}
                                    >
                                       <Typography fontSize={14}>
                                          {user.firstName} {user.lastName}
                                       </Typography>
                                       <Typography fontSize={14}>{user.email}</Typography>
                                    </Box>
                                    <Divider />
                                 </Box>
                              ))}
                           </>
                        )}
                     </Stack>
                     <Box
                        sx={{
                           display: "flex",
                           mt: 2,
                           mx: 2,
                           justifyContent: "space-between",
                           columnGap: 3,
                        }}
                     >
                        <Button
                           variant="text"
                           sx={{ height: 40, columnGap: 1 }}
                           onClick={() => {
                              setOpenConfirmation(true);
                           }}
                        >
                           <Delete />
                           Eliminar rol
                        </Button>
                        <Box
                           sx={{
                              display: "flex",
                              columnGap: 3,
                           }}
                        >
                           <Button
                              variant="text"
                              sx={{ height: 40 }}
                              onClick={() => {
                                 setOpen(false);
                              }}
                           >
                              Cancelar
                           </Button>
                           <LoadingButton label="Guardar" isLoading={isLoadingSubmit} sx={{ m: 0 }} />
                        </Box>
                     </Box>
                  </Form>
               )}
            </Formik>
         </Box>
      </CustomModalComponent>
   );
};

export default EditRoleModalComponent;
