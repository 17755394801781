import { GetInternQueryBankComplaints } from "../../../lib/lecosyBackendClient";
import { useNavigate } from "react-router-dom";
import { HeadCell, TableComponent } from "../../../components/TableComponent";
import VerifyUserSession from "../../../hooks/verifyUserSession";
import GetTablesFilters from "../../../hooks/GetTablesFilters";
import { useContext } from "react";
import { ComplaintContext } from "../../../context/complaintsExternContext/complaintContext";
import { capitalizeFirstLetter } from "../../../const/globalConst";

const columns: HeadCell[] = [
   { field: "reportNumber", headerName: "No. de Reporte", type: "string" },
   { field: "company", headerName: "Empresa/Sucursal", type: "string" },
   { field: "complaintType", headerName: "Tipo", type: "string" },
   { field: "complainerType", headerName: "Denunciante", type: "string" },
   { field: "category", headerName: "Categoría", type: "string", align: "left" },
   { field: "date", headerName: "Recepción", type: "date" },
   { field: "status", headerName: "Estatus", type: "status" },
];

export const InternComplainsQueryBanckTable = () => {
   VerifyUserSession();
   const { branches } = useContext(ComplaintContext);
   const navigate = useNavigate();
   const { data: finishedComplaints, isLoading: finishedComplaintsLoader } = GetInternQueryBankComplaints(branches);

   const branchesNames = GetTablesFilters(finishedComplaints, "branches");

   const rows =
      !finishedComplaintsLoader &&
      finishedComplaints?.map((c) => ({
         reportNumber: c.complaint.reportNumber,
         company: c.branch?.name || c.foundCompany.person_details.comercialName,
         complaintType: capitalizeFirstLetter(c.complaint?.type || "denuncia"),
         complainerType: c.complaint.complainerType,
         date:
            c.complaint.status === "improcedente"
               ? c.complaint.rejectedDate
               : c.complaint.sentDate || c.complaint.createdAt,
         category: c.complaint.category,
         status: capitalizeFirstLetter(c.complaint.status),
      }));

   return (
      <TableComponent
         headerColor
         defaultColumnToOrder="date"
         defaultOrder="asc"
         defaultRowsPerPage={10}
         rowsPerPageOptions={[5, 10, 25]}
         emptyDataText="No hay denuncias en este momento"
         loader={finishedComplaintsLoader}
         onClick={(e, row) => {
            navigate(row.reportNumber);
         }}
         headers={columns}
         rows={rows || []}
         filters={[
            { allOption: "Todas", data: branchesNames, id: "company", label: "Empresas/Sucursales", type: "select" },
            { allOption: "", data: [], id: "reportNumber", label: "No. de reporte", type: "number" },
            {
               allOption: "Todos",
               data: ["Cerrada", "Improcedente"],
               id: "status",
               label: "Estatus",
               type: "select",
            },
         ]}
         filtersInitialState={{
            company: "Todas",
            reportNumber: "",
            status: "Todos",
         }}
      />
   );
};
