import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

type ProtectedRouteProps = {
   section: string;
};

export const PrivateRoute = (props: ProtectedRouteProps) => {
   const { GetAccess } = useContext(UserContext);

   if (GetAccess(props.section)) {
      return <Outlet />;
   }

   return <Navigate to={"/inicio"} replace />;
};
