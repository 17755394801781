import { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import { HeadCell, TableComponent } from "../TableComponent";
import { ClientConfigContext } from "../../context/clientConfigContext";
import { getBranchesByCompanyId, getUrlS3 } from "../../lib/usersBEClient";
import { useNavigate } from "react-router-dom";

const headCells: HeadCell[] = [
   { field: "logo", headerName: "Logo", type: "string" },
   { field: "company", headerName: "Empresa", type: "string" },
   { field: "sucursales", headerName: "Sucursales", type: "number" },
   { field: "status", headerName: "Estatus", type: "disabled" },
];

export const GroupCompaniesTable = () => {
   const navigate = useNavigate();
   const { group } = useContext(ClientConfigContext);
   const [companies, setCompanies] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   const fetchGroupCompanies = async () => {
      setIsLoading(true);

      const groupCompaniesData: any[] = [];
      for (const company of group.companies) {
         const branches = await getBranches(company._id);
         const logo = await getDataS3(company._id);
         groupCompaniesData.push({
            id: company._id,
            logo: logo,
            company: company.person_details.comercialName,
            sucursales: branches,
            status: false,
         });
      }
      try {
         setCompanies(groupCompaniesData);
         setIsLoading(false);
      } catch (error) {
         console.error("Error fetching roles:", error);
         setIsLoading(false);
      }
   };
   const getBranches = async (companyId: string) => {
      const branch = await getBranchesByCompanyId(companyId);
      const branchCount = branch.data.length;
      return branchCount;
   };
   const getDataS3 = async (companyId: string) => {
      const logoUrl: string = await getUrlS3("images-lecosy", { folder: companyId }, "logo.png");
      return (
         <img
            defaultValue={logoUrl}
            src={logoUrl}
            alt="logo"
            style={{ maxWidth: "140px", maxHeight: "70px", padding: 8 }}
         />
      );
   };

   const handleTableRowClickCompanie = async (_e, row) => {
      const screen = "clientes/" + row.id;
      return navigate(`/${screen}`);
   };

   useEffect(() => {
      fetchGroupCompanies();
      // eslint-disable-next-line
   }, [group]);

   return (
      <Box sx={{ width: "100%" }}>
         <TableComponent
            defaultColumnToOrder="company"
            defaultOrder="asc"
            defaultRowsPerPage={10}
            rowsPerPageOptions={[5, 10, 20, 50]}
            headers={headCells}
            rows={companies}
            loader={isLoading}
            onClick={handleTableRowClickCompanie}
            emptyDataText="No existen compañias registradas"
         />
      </Box>
   );
};
