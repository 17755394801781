import { getMonthWord, lecosyLogoBase64 } from "../../const/globalConst";
import { headerSpeech } from "../../const/templatesConst";

export const statusChangedNotification = (
   status: string,
   dia: number,
   mes: number,
   ano: number,
   horas: number,
   reportNumber: string,
   company: string
) => {
   const dd = {
      pageSize: "A4",
      pageMargins: [40, 60, 40, 60],
      content: [
         {
            canvas: [
               {
                  type: "rect",
                  x: 105,
                  y: 60,
                  w: 300,
                  h: 20,
                  color: "#e7e9ec",
               },
               {
                  type: "rect",
                  x: 75,
                  y: 68,
                  w: 360,
                  h: 5,
                  color: "#152c44",
               },
            ],
         },
         {
            image: lecosyLogoBase64,
            width: 150,
            absolutePosition: { x: 220, y: 50 },
         },
         {
            text: [
               {
                  text: "Por medio de la presente le informamos que el estatus de su denuncia establecida en el canal de denuncias el día  ",
               },
               {
                  text: `${dia}  de ${getMonthWord(mes.toString())} del ${ano} a las ${horas}, `,

                  bold: true,
               },
               {
                  text: "con número de reporte ",
               },
               { text: `${reportNumber}, `, bold: true },
               {
                  text: "se encuentra actualmente en el estatus ",
               },
               { text: `"${status}". `, bold: true },
            ],
            fontSize: 11,
            alignment: "justify",
            margin: [45, 90, 45, 0],
            lineHeight: 1.1,
         },
         {
            canvas: [
               {
                  type: "rect",
                  x: 160,
                  y: 300,
                  w: 190,
                  h: 0.7,
                  color: "#0000",
               },
            ],
         },
         {
            text: "Se le comunica por este medio, que para la revisión y consulta del estatus de su denuncia, esta se encontrará disponible en la plataforma remota: ",

            margin: [90, 10, 90, 0],
            fontSize: 10,
            alignment: "justify",
            color: "#73808F",
         },
         {
            text: `${company.trim()}`,

            bold: true,
            margin: [90, 10, 90, 0],
            fontSize: 10,
            alignment: "justify",
            color: "#73808F",
         },
         {
            text: "Bajo el nombre de estatus anteriormente mencionado, el cual se modifica conforme al avance de la misma.",

            margin: [90, 10, 90, 0],
            fontSize: 10,
            alignment: "justify",
            color: "#73808F",
         },
      ],
   };
   return dd;
};

export const denouncedNotification = (
   reportNumber: string,
   clasification: string,
   city: string,
   state: string,
   time: string,
   company: string,
   complainerName: string,
   interviewPlace: string,
   interviewDate: string,
   email: string,
   // issue: string,
   extract: string
) => {
   const date = time.split("-");
   const dateInterview = interviewDate.split("-");
   const timeDate = date[2].split("T");
   const timeDateInterview = dateInterview[2].split("T");
   const dd = {
      pageSize: "A4",
      pageMargins: [40, 60, 40, 60],
      content: [
         {
            canvas: [
               {
                  type: "rect",
                  x: 105,
                  y: 30,
                  w: 300,
                  h: 20,
                  color: "#e7e9ec",
               },
               {
                  type: "rect",
                  x: 75,
                  y: 38,
                  w: 360,
                  h: 5,
                  color: "#152c44",
               },
            ],
         },
         {
            image: lecosyLogoBase64,
            width: 160,
            absolutePosition: { x: 217.5, y: 20 },
         },
         {
            text: `NOTIFICACIÓN`,

            bold: true,
            fontSize: 17,
            alignment: "center",
            color: "#152c44",
            margin: [0, 10, 0, 10],
         },
         {
            margin: [140, 0, 0, 5],
            table: {
               widths: [200, 250],
               heights: [1, 10, 10, 1],
               body: [
                  [
                     { text: "", border: [false, false, false, false] },
                     { text: "", border: [false, false, false, false] },
                  ],
                  [
                     {
                        text: "Número de reporte de la denuncia:",
                        border: [false, false, false, false],
                        alignment: "right",
                        color: "#152c44",

                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: `${reportNumber}`,
                        fontSize: 11,

                        border: [false, false, false, false],
                        alignment: "left",
                     },
                  ],

                  [
                     {
                        text: "Clasificación de la denuncia:",
                        border: [false, false, false, false],
                        alignment: "right",

                        color: "#152c44",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: `${clasification}`,
                        fontSize: 11,

                        border: [false, false, false, false],
                        alignment: "left",
                        margin: [0, 0, 100, 0],
                     },
                  ],
                  [
                     { text: "", border: [false, false, false, false] },
                     { text: "", border: [false, false, false, false] },
                  ],
               ],
            },

            layout: {
               fillColor: "#e7e9ec",
            },
         },
         {
            text: [
               {
                  text: "En la ciudad de ",
               },
               { text: `${city}, ${state} `, bold: true },
               {
                  text: "siendo las ",
               },
               { text: `${timeDate[1]} `, bold: true },
               {
                  text: `horas del día `,
               },
               { text: `${timeDate[0]} `, bold: true },
               {
                  text: `del mes `,
               },
               { text: `${getMonthWord(date[1])} `, bold: true },
               {
                  text: `del año `,
               },
               { text: `${date[0]} `, bold: true },
               {
                  text: "se hace del conocimiento del C.",
               },
               { text: `${complainerName} `, bold: true },
               {
                  text: "de la existencia de una denuncia interpuesta ante el canal de denuncias interno de la organización ",
               },
               { text: `${company}, `, bold: true },
               {
                  text: "en el cual se mencionan posibles hechos contarios a la legislación de nuestro país y/o a las políticas internas de nuestra organización en la cual se le señala a usted como posible implicado de los hechos.",
               },
            ],
            fontSize: 11,
            alignment: "justify",

            margin: [45, 10, 45, 0],
            lineHeight: 1.1,
         },
         {
            text: [
               {
                  text: "Se anexa extracto de los hechos relatados, para efectos de que usted manifieste por escrito dirigido al correo electrónico ",
               },
               {
                  text: `${email} `,
                  bold: true,
               },
               {
                  text: "estableciendo como asunto del mismo EVIDENCIA ",
               },
               {
                  text: `${reportNumber} `,
                  bold: true,
               },
               {
                  text: "en un lapso no mayor a cinco días laborables, lo que a su derecho convenga, toda vez que conforme a nuestras políticas internas “el denunciado tiene derecho a ser oído y a efectuar las manifestaciones que considere pertinentes cuando se ve afectado en su persona o considera que la información es falsa”, apercibido que de no presentar dicho escrito se le tendrá por no presentada y/o sin intereses por manifestar información adicional respecto a la denuncia.",
               },
            ],
            fontSize: 11,
            alignment: "justify",
            margin: [45, 10, 45, 0],
            lineHeight: 1.1,
         },
         {
            text: [
               {
                  text: "En tal orden de ideas se le invita a asistir el día ",
               },
               {
                  text: `${timeDateInterview[0]} `,
                  bold: true,
               },
               {
                  text: "del mes ",
               },
               { text: `${getMonthWord(dateInterview[1])} `, bold: true },
               {
                  text: "del año ",
               },
               { text: `${dateInterview[0]} `, bold: true },
               {
                  text: "a las ",
               },
               {
                  text: `${timeDateInterview[1]} `,
                  bold: true,
               },
               {
                  text: "horas, a una reunión, con el propósito que se lleve a cabo una entrevista que contribuya al esclarecimiento de los hechos y el combate a las malas prácticas y violación a las políticas que rigen nuestra organización.",
               },
            ],
            fontSize: 11,
            alignment: "justify",
            margin: [45, 10, 45, 0],
            lineHeight: 1.1,
         },
         {
            table: {
               heights: [20, 50],
               widths: [415],
               body: [
                  [
                     {
                        border: [false, false, false, false],
                        text: "Medio y/o lugar de la entrevista. ",
                        fontSize: 9,
                        color: "#152c44",
                        bold: true,
                     },
                  ],
                  [
                     {
                        borderColor: ["#152c44", "#152c44", "#152c44", "#152c44"],
                        text: `${interviewPlace}`,
                        fontSize: 10,
                        color: "#152c44",
                        bold: true,
                     },
                  ],
               ],
            },
            margin: [45, 10, 45, 0],
         },

         {
            table: {
               heights: [20, 50],
               widths: [415],
               body: [
                  [
                     {
                        border: [false, false, false, false],
                        text: "Extracto de denuncia:",
                        fontSize: 9,
                        color: "#152c44",
                        bold: true,
                     },
                  ],
                  [
                     {
                        borderColor: ["#152c44", "#152c44", "#152c44", "#152c44"],
                        text: `${extract}`,
                        fontSize: 10,
                        color: "#152c44",
                        bold: true,
                     },
                  ],
               ],
            },
            margin: [45, 10, 45, 0],
         },
      ],
   };

   return dd;
};

export const complainantNotification = (
   reportNumber: string,
   clasification: string,
   city: string,
   state: string,
   time: string,
   company: string,
   complainerName: string,
   interviewPlace: string,
   interviewDate: string,
   officerName: string,
   name: string
) => {
   const date = time.split("-");
   const dateInterview = interviewDate.split("-");
   const timeDate = date[2].split("T");
   const timeDateInterview = dateInterview[2].split("T");
   const dd = {
      pageSize: "A4",
      pageMargins: [40, 60, 40, 60],
      content: [
         {
            canvas: [
               {
                  type: "rect",
                  x: 105,
                  y: 30,
                  w: 300,
                  h: 20,
                  color: "#e7e9ec",
               },
               {
                  type: "rect",
                  x: 75,
                  y: 38,
                  w: 360,
                  h: 5,
                  color: "#152c44",
               },
            ],
         },
         {
            image: lecosyLogoBase64,
            width: 160,
            absolutePosition: { x: 217.5, y: 30 },
         },
         {
            text: `NOTIFICACIÓN`,

            bold: true,
            fontSize: 17,
            alignment: "center",
            color: "#152c44",
            margin: [0, 10, 0, 10],
         },
         {
            margin: [140, 10, 0, 10],
            table: {
               widths: [200, 250],
               heights: [1, 10, 10, 1],
               body: [
                  [
                     { text: "", border: [false, false, false, false] },
                     { text: "", border: [false, false, false, false] },
                  ],
                  [
                     {
                        text: "Número de reporte de la denuncia:",
                        border: [false, false, false, false],
                        alignment: "right",
                        color: "#152c44",

                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: `${reportNumber}`,
                        fontSize: 11,

                        border: [false, false, false, false],
                        alignment: "left",
                     },
                  ],

                  [
                     {
                        text: "Clasificación de la denuncia:",
                        border: [false, false, false, false],
                        alignment: "right",

                        color: "#152c44",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: `${clasification}`,
                        fontSize: 11,

                        border: [false, false, false, false],
                        alignment: "left",
                        margin: [0, 0, 100, 0],
                     },
                  ],
                  [
                     { text: "", border: [false, false, false, false] },
                     { text: "", border: [false, false, false, false] },
                  ],
               ],
            },

            layout: {
               fillColor: "#e7e9ec",
            },
         },
         {
            text: [
               {
                  text: "En la ciudad de ",
               },
               { text: `${city}, ${state} `, bold: true },
               {
                  text: "siendo las ",
               },
               { text: `${timeDate[1]} `, bold: true },
               {
                  text: `horas del día `,
               },
               { text: `${timeDate[0]} `, bold: true },
               {
                  text: `del mes `,
               },
               { text: `${getMonthWord(date[1])} `, bold: true },
               {
                  text: `del año `,
               },
               { text: `${date[0]} `, bold: true },
               {
                  text: "se hace del conocimiento del C.",
               },
               { text: `${complainerName} `, bold: true },
               {
                  text: "de la existencia de una denuncia interpuesta ante el canal de denuncias interno de la organización ",
               },
               { text: `${company}, `, bold: true },
               {
                  text: "en el cual se mencionan posibles hechos contarios a la legislación de nuestro país y/o a las políticas internas de nuestra organización en la cual usted funge como denunciante de la misma.",
               },
            ],
            fontSize: 11,
            alignment: "justify",

            margin: [45, 10, 45, 0],
            lineHeight: 1.1,
         },
         {
            text: [
               {
                  text: "En tal orden de ideas se le invita a asistir el día ",
               },
               {
                  text: `${timeDateInterview[0]} `,
                  bold: true,
               },
               {
                  text: "del mes ",
               },
               { text: `${getMonthWord(dateInterview[1])} `, bold: true },
               {
                  text: "del año ",
               },
               { text: `${dateInterview[0]} `, bold: true },
               {
                  text: "a las ",
               },
               {
                  text: `${timeDateInterview[1]} `,
                  bold: true,
               },
               {
                  text: "horas, a una reunión, con el propósito que se lleve a cabo una entrevista que contribuya al esclarecimiento de los hechos y el combate a las malas prácticas y violación a las políticas que rigen nuestra organización.",
               },
            ],
            fontSize: 11,
            alignment: "justify",
            margin: [45, 10, 45, 0],
            lineHeight: 1.1,
         },

         {
            table: {
               heights: [20, 50],
               widths: [415],
               body: [
                  [
                     {
                        border: [false, false, false, false],
                        text: "Medio y/o lugar de la entrevista. ",
                        fontSize: 9,
                        color: "#152c44",
                        bold: true,
                     },
                  ],
                  [
                     {
                        borderColor: ["#152c44", "#152c44", "#152c44", "#152c44"],
                        text: `${interviewPlace}`,
                        fontSize: 10,
                        color: "#152c44",
                        bold: true,
                     },
                  ],
               ],
            },
            margin: [45, 10, 45, 0],
         },
      ],
   };

   return dd;
};

export const witnessNotification = (
   reportNumber: string,
   clasification: string,
   city: string,
   state: string,
   time: string,
   company: string,
   complainerName: string,
   interviewPlace: string,
   interviewDate: string,
   officerName: string,
   name: string,
   type: string
) => {
   const date = time.split("-");
   const dateInterview = interviewDate.split("-");
   const timeDate = date[2].split("T");
   const timeDateInterview = dateInterview[2].split("T");
   const dd = {
      pageSize: "A4",
      pageMargins: [40, 60, 40, 60],
      content: [
         {
            canvas: [
               {
                  type: "rect",
                  x: 105,
                  y: 30,
                  w: 300,
                  h: 20,
                  color: "#e7e9ec",
               },
               {
                  type: "rect",
                  x: 75,
                  y: 38,
                  w: 360,
                  h: 5,
                  color: "#152c44",
               },
            ],
         },
         {
            image: lecosyLogoBase64,
            width: 160,
            absolutePosition: { x: 217.5, y: 20 },
         },

         {
            text: `NOTIFICACIÓN`,

            bold: true,
            fontSize: 17,
            alignment: "center",
            color: "#152c44",
            margin: [0, 10, 0, 10],
         },
         {
            margin: [140, 10, 0, 10],
            table: {
               widths: [200, 250],
               heights: [1, 10, 10, 1],
               body: [
                  [
                     { text: "", border: [false, false, false, false] },
                     { text: "", border: [false, false, false, false] },
                  ],
                  [
                     {
                        text: `Número de reporte de la ${type}:`,
                        border: [false, false, false, false],
                        alignment: "right",
                        color: "#152c44",

                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: `${reportNumber}`,
                        fontSize: 11,
                        border: [false, false, false, false],
                        alignment: "left",
                     },
                  ],

                  [
                     {
                        text: `Clasificación de la ${type}:`,
                        border: [false, false, false, false],
                        alignment: "right",
                        color: "#152c44",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: `${clasification}`,
                        fontSize: 11,
                        border: [false, false, false, false],
                        alignment: "left",
                        margin: [0, 0, 100, 0],
                     },
                  ],
                  [
                     { text: "", border: [false, false, false, false] },
                     { text: "", border: [false, false, false, false] },
                  ],
               ],
            },
            layout: {
               fillColor: "#e7e9ec",
            },
         },
         {
            text: [
               {
                  text: "En la ciudad de ",
               },
               { text: `${city}, ${state} `, bold: true },
               {
                  text: "siendo las ",
               },
               { text: `${timeDate[1]} `, bold: true },
               {
                  text: `horas del día `,
               },
               { text: `${timeDate[0]} `, bold: true },
               {
                  text: `del mes `,
               },
               { text: `${getMonthWord(date[1])} `, bold: true },
               {
                  text: `del año `,
               },
               { text: `${date[0]} `, bold: true },
               {
                  text: "se hace del conocimiento del C.",
               },
               { text: `${complainerName} `, bold: true },
               {
                  text: `de la existencia de una ${type} interpuesta ante el canal de denuncias interno de la organización `,
               },
               { text: `${company}, `, bold: true },
               {
                  text: "en el cual se mencionan posibles hechos contrarios a la legislación de nuestro país y/o a las políticas internas de nuestra organización, en la cual se le señala a usted como posible testigo de los hechos.",
               },
            ],
            fontSize: 11,
            alignment: "justify",
            margin: [45, 10, 45, 0],
            lineHeight: 1.1,
         },
         {
            text: [
               {
                  text: "En tal orden de ideas se le invita a asistir el día ",
               },
               {
                  text: `${timeDateInterview[0]} `,
                  bold: true,
               },
               {
                  text: "del mes ",
               },
               { text: `${getMonthWord(dateInterview[1])} `, bold: true },
               {
                  text: "del año ",
               },
               { text: `${dateInterview[0]} `, bold: true },
               {
                  text: "a las ",
               },
               {
                  text: `${timeDateInterview[1]} `,
                  bold: true,
               },
               {
                  text: "horas, a una reunión, con el propósito que se lleve a cabo una entrevista que contribuya al esclarecimiento de los hechos y el combate a las malas prácticas y violación a las políticas que rigen nuestra organización.",
               },
            ],
            fontSize: 11,
            alignment: "justify",
            margin: [45, 10, 45, 0],
            lineHeight: 1.1,
         },

         {
            table: {
               heights: [20, 50],
               widths: [415],
               body: [
                  [
                     {
                        border: [false, false, false, false],
                        text: "Medio y/o lugar de la entrevista. ",
                        fontSize: 9,
                        color: "#152c44",
                        bold: true,
                     },
                  ],
                  [
                     {
                        borderColor: ["#152c44", "#152c44", "#152c44", "#152c44"],
                        text: `${interviewPlace}`,
                        fontSize: 10,
                        color: "#152c44",
                        bold: true,
                     },
                  ],
               ],
            },
            margin: [45, 10, 45, 0],
         },
      ],
   };

   return dd;
};

export const resolutionNotification = (
   reportNumber: string,
   clasification: string,
   city: string,
   state: string,
   time: string,
   measures: string,
   newClasification: string,
   type: string
) => {
   const date = time.split("-");
   const timeDate = date[2].split("T");
   const dd = {
      pageSize: "A4",
      pageMargins: [40, 60, 40, 60],
      content: [
         headerSpeech("NOTIFICACIÓN DE RESOLUCIÓN").map((object) => {
            return object;
         }),
         {
            margin: [140, 40, 0, 10],
            table: {
               widths: [200, 250],
               heights: [1, 10, 10, 1],
               body: [
                  [
                     { text: "", border: [false, false, false, false] },
                     { text: "", border: [false, false, false, false] },
                  ],
                  [
                     {
                        text:
                           type === "denuncia"
                              ? "Número de reporte de denuncia:"
                              : `Número de reporte de ${type || "denuncia"}:`,
                        border: [false, false, false, false],
                        alignment: "right",
                        color: "#152c44",

                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: `${reportNumber}`,
                        fontSize: 11,

                        border: [false, false, false, false],
                        alignment: "left",
                     },
                  ],

                  [
                     {
                        text:
                           type === "denuncia"
                              ? "Clasificación de la denuncia:"
                              : `Clasificación de la ${type || "denuncia"}:`,
                        border: [false, false, false, false],
                        alignment: "right",

                        color: "#152c44",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: `${clasification || "No aplica"}`,
                        fontSize: 11,

                        border: [false, false, false, false],
                        alignment: "left",
                        margin: [0, 0, 100, 0],
                     },
                  ],
                  [
                     { text: "", border: [false, false, false, false] },
                     { text: "", border: [false, false, false, false] },
                  ],
               ],
            },

            layout: {
               fillColor: "#e7e9ec",
            },
         },
         {
            text: [
               {
                  text: "En la ciudad de ",
               },
               { text: `${city}, ${state} `, bold: true },
               {
                  text: "siendo las ",
               },
               { text: `${timeDate[1]} `, bold: true },
               {
                  text: `horas del día `,
               },
               { text: `${timeDate[0]} `, bold: true },
               {
                  text: `del mes `,
               },
               { text: `${getMonthWord(date[1])} `, bold: true },
               {
                  text: `del año `,
               },
               { text: `${date[0]}, `, bold: true },
               {
                  text: "se hace de su conocimiento que: ",
               },
            ],
            fontSize: 11,
            alignment: "justify",
            margin: [45, 30, 45, 0],
            lineHeight: 1.1,
         },
         {
            text:
               type !== "denuncia"
                  ? `Con base en los hechos declarados y las pruebas aportadas, el órgano interno de control determina que la ${type} interpuesta es considerada como:`
                  : "Con base en los hechos declarados y las pruebas aportadas por las partes y los testigos respecto a la denuncia, con el número de reporte que se señala al rubro, el órgano interno de control determina que la denuncia interpuesta es considerada como:",
            margin: [45, 30, 45, 0],
            fontSize: 11,
            alignment: "justify",
         },
         {
            text: `${newClasification}`,
            bold: true,
            fontSize: 11,
            margin: [45, 10, 45, 30],
            alignment: "center",
         },
         {
            text: "En tal orden de ideas, se ordena la imposición de las siguientes:",
            fontSize: 11,
            color: "#152c44",
            lineHeight: 1.1,
            margin: [45, 5, 45, 10],
         },
         {
            text: `         MEDIDAS          `,
            bold: true,
            fontSize: 15,
            color: "#fff",
            background: "#152c44",
            alignment: "center",
         },
         {
            table: {
               heights: [100],
               widths: [415],
               body: [
                  [
                     {
                        border: [false, false, false, false],
                        fillColor: "#e7e9ec",
                        text: `${measures}`,
                        fontSize: 10,
                        color: "#152c44",
                        bold: true,
                     },
                  ],
               ],
            },
            margin: [45, 0, 45, 0],
         },
      ],
   };
   return dd;
};
