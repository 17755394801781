import { Box, Typography } from "@mui/material";
import { formatCurrency } from "../../../../../../const/globalConst";
import ListComponent from "../../../../../ListComponent";
import useGetMembersInfo from "../../../../../../hooks/GC/useGetMembersInfo";
import { Info } from "@mui/icons-material";

interface MembersListProps {
   setSelectedUser?: Function;
   setOpenModal?: Function;
   hideFullInfo?: boolean;
}

const MembersList = (props: MembersListProps) => {
   const { setSelectedUser, setOpenModal, hideFullInfo = false } = props;
   const { formatedMembers, members, seriesheaders, series } = useGetMembersInfo(hideFullInfo);
   return (
      <>
         {formatedMembers?.length > 0 ? (
            <ListComponent
               title={"Cuadro accionario"}
               titleProps={{
                  py: 1.5,
                  width: "100%",
                  bgcolor: "#162c44",
                  color: "white",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
               }}
               headers={seriesheaders}
               headerProps={{
                  bgcolor: "#E2E6EA",
                  px: 3,
                  py: 1,
                  borderRadius: 1,
                  color: "#162c44",
               }}
               rowProps={{
                  bgcolor: "white",
                  mb: 0.5,
                  border: 1,
                  borderColor: "#D9D9D9",
               }}
               rows={(formatedMembers?.length > 0 && formatedMembers) || []}
               onClick={(_e, _row, index) => {
                  setSelectedUser && setSelectedUser(members[index]);
                  setOpenModal && setOpenModal(true);
               }}
               footer={
                  formatedMembers?.length > 0
                     ? [
                          <Typography key={"total"}>Totales</Typography>,
                          <Typography fontWeight={600} key={"founders"}>
                             {formatedMembers.reduce((sum, member) => {
                                if (member.founder) return sum + 1;
                                else return sum;
                             }, 0)}
                          </Typography>,
                          <Typography fontWeight={600} key={"property"}>
                             100%
                          </Typography>,
                          (!hideFullInfo &&
                             series.map((serie) => (
                                <Typography key={`${serie.title}`}>
                                   {formatCurrency(
                                      formatedMembers.reduce((sum, member) => sum + member[serie.title], 0)
                                   )}
                                </Typography>
                             ))) ||
                             [],
                          <Typography key={"actionsValue"}>
                             {formatCurrency(formatedMembers.reduce((sum, member) => sum + member.totalActions, 0))}
                          </Typography>,
                          <Typography key={"socialCapital"}>
                             {`$${formatCurrency(
                                formatedMembers.reduce((sum, member) => sum + member.socialCapital, 0)
                             )}`}
                          </Typography>,
                          <Typography key={"votesQuantity"}>
                             {formatCurrency(formatedMembers.reduce((sum, member) => sum + member.votes, 0))}
                          </Typography>,
                       ].flat()
                     : undefined
               }
            />
         ) : (
            <Box
               display={"flex"}
               flexDirection={"column"}
               justifyContent={"center"}
               alignItems={"center"}
               rowGap={1}
               sx={{ py: 2, opacity: 0.5 }}
            >
               <Info sx={{ fontSize: "50px" }} />
               <Typography textAlign={"center"}>No se han registrado accionistas</Typography>
            </Box>
         )}
      </>
   );
};

export default MembersList;
