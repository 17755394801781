import { useState, useContext, useEffect } from "react";
import { Box, Typography, Drawer, CircularProgress } from "@mui/material";
import { FilterComponent } from "../../components/FilterComponent";
import EditIcon from "@mui/icons-material/Edit";
import { HeadCell, TableComponent } from "../../components/TableComponent";
import { ClientConfigContext } from "../../context/clientConfigContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ModifyReceiptDrawer } from "./Drawer/ModifyReceiptDrawer";
import { GetReceiptByCompanyId, getUrlS3 } from "../../lib/usersBEClient";
import { FilesModalDownload } from "./Modal/filesModalDownload";

export const ReceiptTableComponent = () => {
   const { clientInfo, group } = useContext(ClientConfigContext);
   const [openEditInvoiceDrawer, setOpenEditInvoiceDrawer] = useState(false);
   const [branchSelected, setBranchSelected] = useState(null);
   const [receiptId, setReceiptId] = useState("");
   const { data: branches, isLoading: isLoadingBranches } = GetReceiptByCompanyId(
      clientInfo ? clientInfo._id : clientInfo ? clientInfo._id : group.invoiceCompany._id
   );
   const [branchSelectedFiltered, setBranchSelectedFiltered] = useState(branches);
   const [open, setOpen] = useState<boolean>(false);
   const [file1, setFile1] = useState(null);
   const [file2, setFile2] = useState(null);
   const [fileName1, setFileName1] = useState(null);
   const [fileName2, setFileName2] = useState(null);
   const [idReceipt, setIdReceipt] = useState(null);
   const [nameReceipt, setNameReceipt] = useState(null);

   const getFile1 = async (clientId: string, year: string, month: string, file1: string, id: string, name: string) => {
      const fileData1: string = await getUrlS3(
         "files-lecosy",
         { folder: `receipts/${clientId}/${year}/${month}` },
         file1
      );
      setNameReceipt(name);
      setIdReceipt(id);
      setFile1(fileData1);
      setFileName1(file1);
   };

   const getFile2 = async (clientId: string, year: string, month: string, file2: string) => {
      const fileData2: string = await getUrlS3(
         "files-lecosy",
         { folder: `receipts/${clientId}/${year}/${month}` },
         file2
      );
      setFile2(fileData2);
      setFileName2(file2);
   };

   useEffect(() => {
      if (branches) {
         const refetchReceipt = async () => {
            setBranchSelectedFiltered(branches);
         };
         refetchReceipt();
      }
   }, [branches]);

   const listBranches = isLoadingBranches
      ? []
      : branches.map((e) => ({
           _id: e._id,
           name: e.name,
           paymentDate: e.paymentDate,
           unformatedDate: new Date(e.paymentDate.split("T")[0]).getTime(),
           createdAt: e.createdAt,
           unformatedDate2: new Date(e.createdAt.split("T")[0]).getTime(),
           file1: e.file1,
           file2: e.file2,
        }));

   const branchesColumns: HeadCell[] = [
      { field: "name", headerName: "Nombre", type: "string", width: 50 },
      { field: "paymentDate", headerName: "Fecha de pago", type: "date", width: 50 },
      { field: "createdAt", headerName: "Fecha de envió", type: "date", width: 50 },
      {
         field: "icon",
         headerName: "Ver",
         type: "button",
         width: 50,
         icon: <VisibilityIcon fontSize="small" />,
         onClick: async (e, row) => {
            setOpen(true);
            await getFile1(
               clientInfo ? clientInfo._id : group.invoiceCompany._id,
               row.paymentDate.split("-")[0],
               row.paymentDate.split("-")[1],
               row.file1,
               row._id,
               row.name
            );
            await getFile2(
               clientInfo ? clientInfo._id : group.invoiceCompany._id,
               row.paymentDate.split("-")[0],
               row.paymentDate.split("-")[1],
               row.file2
            );
         },
      },
      {
         field: "dsdsd",
         headerName: "Modificar",
         type: "button",
         width: 50,
         icon: <EditIcon fontSize="small" />,
         onClick: async (e, row) => {
            await getFile1(
               clientInfo ? clientInfo._id : group.invoiceCompany._id,
               row.paymentDate.split("-")[0],
               row.paymentDate.split("-")[1],
               row.file1,
               row._id,
               row.name
            );
            await getFile2(
               clientInfo ? clientInfo._id : group.invoiceCompany._id,
               row.paymentDate.split("-")[0],
               row.paymentDate.split("-")[1],
               row.file2
            );
            setOpenEditInvoiceDrawer(true);
            setBranchSelected(row);
            setReceiptId(row._id);
         },
      },
   ];

   return (
      <>
         <Box sx={{ width: "100%" }}>
            <Box sx={{ bgcolor: "white", p: 2, boxShadow: 2, borderRadius: 0 }}>
               {!isLoadingBranches ? (
                  <>
                     <Box my={2}>
                        <Typography variant="h6">Complemento de pago</Typography>
                     </Box>
                     <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Box flex={1}>
                           <FilterComponent
                              originaltableOneRows={listBranches}
                              tableOneFilteredRows={branchSelectedFiltered}
                              period={true}
                              setTableOneFilteredRows={setBranchSelectedFiltered}
                              filters={["daterange"]}
                              searchBar={
                                 <Box width={"100%"} sx={{ display: "flex", flexDirection: "row", flex: 1 }}></Box>
                              }
                           />
                        </Box>
                     </Box>
                     <TableComponent
                        defaultColumnToOrder="name"
                        defaultOrder="asc"
                        defaultRowsPerPage={5}
                        emptyDataText="No hay complementos registrados por el momento"
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        loader={isLoadingBranches}
                        headers={branchesColumns}
                        onClick={() => {}}
                        rows={branchSelectedFiltered || listBranches}
                        disableBorders
                     />
                  </>
               ) : (
                  <Box display={"flex"}>
                     <CircularProgress sx={{ display: "flex", flex: 1, justifyContent: "center" }} />
                  </Box>
               )}
            </Box>
         </Box>
         <Drawer
            anchor="right"
            open={openEditInvoiceDrawer}
            hideBackdrop
            onClose={() => {
               setOpenEditInvoiceDrawer(false);
            }}
            sx={{ maxWidth: 450 }}
         >
            {openEditInvoiceDrawer ? (
               <ModifyReceiptDrawer
                  setOpen={setOpenEditInvoiceDrawer}
                  open={openEditInvoiceDrawer}
                  receiptId={receiptId}
                  receiptData={branchSelected}
                  file1={file1}
                  file2={file2}
                  file1Name={fileName1}
                  file2Name={fileName2}
               />
            ) : (
               <></>
            )}
         </Drawer>
         <FilesModalDownload
            setOpen={setOpen}
            open={open}
            file1URL={file1}
            file2URL={file2}
            file1Name={fileName1}
            file2Name={fileName2}
            id={idReceipt}
            name={nameReceipt}
            receipt={true}
         />
      </>
   );
};
