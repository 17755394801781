import { createContext, useState } from "react";

export interface IResource {
   resource: string;
   permission: number;
}

interface IMasterAccessContext {
   resourcesSelected: IResource[];
   setResourcesSelected: Function;
   checkedFields: any[][];
   setCheckedFields: Function;
}

export const MasterAccessContext = createContext<IMasterAccessContext>({
   resourcesSelected: [],
   setResourcesSelected: () => {},
   checkedFields: [],
   setCheckedFields: () => {},
});

export const MasterAccessProvider = ({ children }) => {
   const [resourcesSelected, setResourcesSelected] = useState([]);
   const [checkedFields, setCheckedFields] = useState([]);
   return (
      <MasterAccessContext.Provider
         value={{ resourcesSelected, setResourcesSelected, checkedFields, setCheckedFields }}
      >
         {children}
      </MasterAccessContext.Provider>
   );
};
