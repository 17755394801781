import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Button, Typography, Stack, CircularProgress, Divider } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { TopHeader } from "../../components/TopHeader";
import { createNewUserSchema } from "../../lib/Validations/inputSchemas";
import { InputTextField } from "../../components/Inputs/InputTextField";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../context/snackBarContext";
import { RoleTransferList, TransferListItem } from "../../components/RoleTransferList";
import { getAllMasterRoles, createMasterUser } from "../../lib/usersBEClient";
import { UserContext } from "../../context/UserContext";
import { LoadingButton } from "../../components/LoadingButton";
import { ConfirmationModal } from "../../components/ConfirmationModal";

export const NewUserInformation = () => {
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Usuarios");
   const navigate = useNavigate();
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = React.useState(false);
   const [isLoadingRoles, setIsLoadingRoles] = React.useState(false);
   const rightlist: TransferListItem[] = [];
   const leftlist: TransferListItem[] = [];
   const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

   useEffect(() => {
      if (access) {
         navigate("/inicio");
      }
      getRoles();
      // eslint-disable-next-line
   }, []);

   const getRoles = async () => {
      setIsLoadingRoles(true);
      try {
         const response = await getAllMasterRoles();
         for (const list of response.data) {
            leftlist.push(list);
         }
         setIsLoadingRoles(false);
      } catch (error) {
         setIsLoadingRoles(false);
      }
   };
   const [leftlistState, setLeftlistState] = useState(leftlist);
   const [rightlistState, setRightlistState] = useState(rightlist);

   async function handleSubmit(values) {
      setIsLoading(true);
      try {
         if (values.role.length > 0) {
            const response = await createMasterUser(values.firstName, values.lastName, values.email, values.role);

            const resData = response.data;
            if (resData) {
               showSnackBar("Usuario añadido exitosamente", false);
               setIsLoading(false);
               setTimeout(() => {
                  navigate(-1);
               }, 3000);
            }
         } else {
            showSnackBar("Favor de seleccionar almenos un rol", true);
         }
      } catch (error: any) {
         if (error.response.data.message === "email already in use") {
            showSnackBar("Ya existe un usuario con ese correo", true);
         } else {
            showSnackBar("Error al agregar usuario", true);
         }
         setIsLoading(false);
         return;
      }
      setIsLoading(false);
   }

   function getIds() {
      const idList = [];
      for (const iterator of rightlist) {
         idList.push(iterator._id);
      }
      return idList;
   }

   return (
      <>
         <Box sx={{ padding: 2, display: "flex", flexDirection: "column", backgroundColor: "#F5F5F5" }}>
            <TopHeader head="Agregar usuario" content={<></>} />
            <Container maxWidth="xl" sx={{ mt: 1 }}>
               <Formik
                  initialValues={{
                     firstName: "",
                     lastName: "",
                     email: "",
                     role: getIds(),
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={createNewUserSchema}
               >
                  {(formProps: FormikProps<any>) => (
                     <Form>
                        <Box sx={{ display: "flex", rowGap: 2, flexDirection: "column" }}>
                           <Box bgcolor="white" p={2} borderRadius={1} boxShadow={1}>
                              <Box display="flex" alignItems="center" px={5} py={1}>
                                 <Typography variant="h5" fontWeight={500} style={{ flex: "0 0 auto" }}>
                                    Datos del usuario
                                 </Typography>
                                 <Divider sx={{ my: 1, flexGrow: 1, ml: 2 }} />
                              </Box>

                              <Box display="flex">
                                 <Box
                                    flex={1}
                                    px={5}
                                    py={1}
                                    sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}
                                 >
                                    <Box>
                                       <InputTextField
                                          variant="outlined"
                                          multiline
                                          size="small"
                                          name="firstName"
                                          id="firstName"
                                          type="text"
                                          label="Nombre(s)"
                                          sx={{ width: "80%" }}
                                       />
                                    </Box>
                                    <Box>
                                       <InputTextField
                                          variant="outlined"
                                          multiline
                                          size="small"
                                          name="lastName"
                                          id="lastName"
                                          type="text"
                                          label="Apellidos"
                                          sx={{ width: "80%" }}
                                       />
                                    </Box>
                                    <Box>
                                       <InputTextField
                                          variant="outlined"
                                          multiline
                                          size="small"
                                          name="email"
                                          id="email"
                                          type="text"
                                          label="Correo electrónico"
                                          sx={{ width: "100%" }}
                                       />
                                    </Box>
                                 </Box>
                              </Box>
                           </Box>
                        </Box>
                        <Box sx={{ display: "flex", rowGap: 2, flexDirection: "column", my: 2 }}>
                           {!isLoadingRoles ? (
                              <Box bgcolor="white" p={2} borderRadius={1} boxShadow={1} py={3}>
                                 <Box display="flex" alignItems="center" px={5} py={1} pb={3}>
                                    <Typography variant="h5" fontWeight={500}>
                                       Roles
                                    </Typography>
                                    <Divider sx={{ my: 1, flexGrow: 1, ml: 2, mr: 2 }} />
                                    <Button
                                       variant="contained"
                                       sx={{ width: "150px" }}
                                       onClick={() => {
                                          setOpenConfirmation(true);
                                       }}
                                    >
                                       Editar roles
                                    </Button>
                                 </Box>
                                 <Box>
                                    <RoleTransferList
                                       title1="Seleccionar:"
                                       title2="Rol a desempeñar:"
                                       leftlist={leftlistState}
                                       rightlist={rightlistState}
                                       setLeftlist={setLeftlistState}
                                       setRightlist={setRightlistState}
                                       onSaveRightList={(newRightList) => formProps.setFieldValue("role", newRightList)}
                                    />
                                 </Box>
                              </Box>
                           ) : (
                              <CircularProgress size={24} />
                           )}
                           <Stack direction="row" spacing={1} justifyContent="flex-end">
                              <Button variant="outlined" type="button" onClick={() => navigate(-1)}>
                                 Cancelar
                              </Button>
                              <LoadingButton label={"Guardar"} isLoading={isLoading} />
                           </Stack>
                        </Box>
                     </Form>
                  )}
               </Formik>
            </Container>
         </Box>
         <ConfirmationModal
            title="Confirmación"
            body={
               <div>
                  <Typography sx={{ fontSize: "14px" }}>¿Esta seguro que deseas continuar?</Typography>
                  <br />
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                     Una vez confirmado, la información registrada no podrá ser recuperada
                  </Typography>
               </div>
            }
            onConfirm={() => {
               navigate("/usuarios/roles");
            }}
            open={openConfirmation}
            setOpen={setOpenConfirmation}
         />
      </>
   );
};
