import { createContext, useState, useEffect, useContext } from "react";
import { getInboxInternComplaints } from "../../lib/lecosyBackendClient";
import { UserContext } from "../UserContext";
import { ComplaintContext } from "../complaintsExternContext/complaintContext";
import { capitalizeFirstLetter } from "../../const/globalConst";

type ComplaintInternContextInferface = {
   internComplaints: ComplaintsInternInterface[];
   newInternComplaints: number;
   getInternComplaints: Function;
   isLoading: boolean;
};

export interface ComplaintsInternInterface {
   reportNumber: string;
   company: any;
   category: string;
   complaintType: string;
   complainerType: string;
   status: string;
   createdAt: string;
   incoming: boolean;
}

export const ComplaintInternContext = createContext<ComplaintInternContextInferface>({
   internComplaints: [],
   newInternComplaints: 0,
   getInternComplaints: () => {},
   isLoading: true,
});

export const ComplaintInternProvider = ({ children }) => {
   const { user, GetAccess } = useContext(UserContext);
   const [internComplaints, setInternComplaints] = useState<ComplaintsInternInterface[]>([]);
   const [newInternComplaints, setNewInternComplaints] = useState(0);
   const [isLoading, setIsLoading] = useState(true);
   const { branches, loadingBranches } = useContext(ComplaintContext);
   const access = GetAccess(["Denuncias internas"]);

   const getInternComplaints = async () => {
      try {
         const complaintsInboxInternResponse = await getInboxInternComplaints(branches);

         const formattedComplaints: ComplaintsInternInterface[] = complaintsInboxInternResponse
            ? complaintsInboxInternResponse.map((c) => ({
                 reportNumber: c.complaint.reportNumber,
                 company: c.branch?.name || c.foundCompany.person_details.comercialName,
                 category: c.complaint.category || "N/A",
                 complaintType: capitalizeFirstLetter(c.complaint?.type || "denuncia"),
                 complainerType: c.complaint.complainerType,
                 status: capitalizeFirstLetter(c.complaint.status),
                 createdAt: c.complaint.createdAt,
                 incoming: c.complaint.incoming,
              }))
            : [];

         let newComplaints = 0;
         if (complaintsInboxInternResponse) {
            complaintsInboxInternResponse.forEach((e) => {
               if (e.complaint.incoming) {
                  newComplaints++;
               }
            });
         }
         setNewInternComplaints(newComplaints);
         setInternComplaints(formattedComplaints);
         setIsLoading(false);
         return null;
      } catch (error: any) {
         if (error.message === "Network Error") setIsLoading(false);
         return error;
      }
   };

   useEffect(() => {
      const refetchComplaint = async () => {
         if (!loadingBranches && user.auth && access) {
            const refetched = await getInternComplaints();
            if (refetched?.message === "Network Error") setInternComplaints([]);
         }
      };
      if (!loadingBranches && user.auth && access) refetchComplaint();
      const interval = setInterval(refetchComplaint, 40000);
      return () => clearInterval(interval);
      // eslint-disable-next-line
   }, [user.auth, loadingBranches, access]);

   return (
      <ComplaintInternContext.Provider
         value={{ internComplaints, newInternComplaints, getInternComplaints, isLoading }}
      >
         {children}
      </ComplaintInternContext.Provider>
   );
};
