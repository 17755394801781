import { Box, Typography, Button, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ConfirmationModal } from "./ConfirmationModal";
import { useState } from "react";

interface TitleAppBarProps {
   head: React.ReactNode;
   isScheduleAppointment: boolean;
   isConfirmationNeeded?: boolean;
}

export const TitleAppBar = (props: TitleAppBarProps) => {
   const navigate = useNavigate();
   const [isConfirming, setIsConfirming] = useState<boolean>(false);

   function goBack() {
      setIsConfirming(false);
      navigate(-1);
   }

   return (
      <>
         <Box
            sx={{
               display: "flex",
               px: 3,
               borderRadius: 4,
               justifyContent: "space-between",
            }}
         >
            <Box sx={{ display: "flex", alignItems: "center", textTransform: "none" }}>
               <Button
                  variant="contained"
                  sx={{ minWidth: 5, height: 30, backgroundColor: "#142c44" }}
                  onClick={() => {
                     if (props.isConfirmationNeeded) return setIsConfirming(true);
                     navigate(-1);
                  }}
               >
                  <ArrowBackIcon sx={{ width: 20 }} />
               </Button>
               <Typography variant="h6" sx={{ ml: 1 }}>
                  {props.head}
               </Typography>
            </Box>
         </Box>
         <Divider sx={{ my: 1 }} variant="middle" />
         <ConfirmationModal
            open={isConfirming}
            setOpen={setIsConfirming}
            onConfirm={goBack}
            title="Salir"
            body={
               <>
                  <Typography>¿Está seguro que desea salir sin guardar los cambios?</Typography>
                  <Typography color={"red"} paddingTop={1} fontStyle={"italic"}>
                     Una vez confirmado, la información no podrá ser recuperada
                  </Typography>
               </>
            }
         />
      </>
   );
};
