import { createContext, useState, useEffect, useContext } from "react";
import { getSupportNotifications } from "../../lib/usersBEClient";
import { UserContext } from "../UserContext";

interface NotificationsContextInterface {
   notifications: NotificationsType[];
   isLoading: boolean;
   selectedNotification: NotificationsType;
   setSelectedNotification: Function;
   refetch: Function;
}

export interface NotificationsType {
   company: string;
   name: string;
   email: string;
   phoneNumber: string;
   body: string;
   isCR: boolean;
   reportNumber: string;
   createdAt: string;
   _id: string;
}

export const NotificationsContext = createContext<NotificationsContextInterface>({
   notifications: [],
   isLoading: true,
   selectedNotification: {
      company: "",
      name: "",
      email: "",
      phoneNumber: "",
      body: "",
      isCR: false,
      reportNumber: "",
      createdAt: "",
      _id: "",
   },
   setSelectedNotification: () => {},
   refetch: () => {},
});

export const NotificationsProvider = ({ children }) => {
   const { user, GetAccess } = useContext(UserContext);
   const [selectedNotification, setSelectedNotification] = useState<NotificationsType>(undefined);
   const [notifications, setNotifications] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const access = GetAccess(["Denuncias externas", "Denuncias internas"]);
   const refetch = async () => {
      if (!user.auth) return;
      const notificationResponse = await getSupportNotifications();
      setNotifications(notificationResponse);
      setIsLoading(false);
   };

   useEffect(() => {
      const refetchNotifications = async () => {
         if (user.auth && access) await refetch();
      };
      if (user.auth && access) refetch();
      const interval = setInterval(refetchNotifications, 60000);
      return () => clearInterval(interval);
      // eslint-disable-next-line
   }, [user.auth]);

   return (
      <NotificationsContext.Provider
         value={{ notifications, isLoading, selectedNotification, setSelectedNotification, refetch }}
      >
         {children}
      </NotificationsContext.Provider>
   );
};
