import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import * as locales from "@mui/material/locale";
import React from "react";

type ThemeProps = {
   children: React.JSX.Element | React.JSX.Element[];
};

enum themePalette {
   BG = "#FFFFFF",
   BTN_PRIMARY = "#2d4357",
   BTN_WARNING = "#DB3B1E",
}

const theme = createTheme(
   {
      palette: {
         mode: "light",
         background: {
            default: themePalette.BG,
         },
         primary: {
            main: themePalette.BTN_PRIMARY,
         },
         warning: {
            main: themePalette.BTN_WARNING,
         },
      },
      components: {
         MuiTableSortLabel: {
            styleOverrides: {
               root: {
                  "&.Mui-active": {
                     color: "#1A1A1A",
                  },
               },
            },
         },
      },
      typography: {
         button: {
            textTransform: "none",
         },
         fontFamily: ["'Open Sans'"].join(","),
      },
   },
   locales["esES"]
);

export const SystemThemeConfig: React.FC<ThemeProps> = ({ children }) => {
   return (
      <ThemeProvider theme={theme}>
         <CssBaseline />
         {children}
      </ThemeProvider>
   );
};
