import { Box, Button, Grid, List, ListItem, Stack, Typography, Checkbox } from "@mui/material";
import { useState } from "react";
import { LocaleDateString, formatCurrency } from "../const/globalConst";
import CheckIcon from "@mui/icons-material/Check";

export interface HeadersProps {
   headerPosition?: "left" | "right" | "center";
   bodyPosition?: "left" | "right" | "center";
   headerTitle: string;
   type:
      | "text"
      | "list"
      | "punctual"
      | "date"
      | "check"
      | "percentage"
      | "boolean"
      | "currency"
      | "number"
      | "checkIcon";
}

interface ListComponentProps {
   title?: string;
   titleProps?: any;
   headers: HeadersProps[];
   headerProps?: any;
   rows: any[];
   footer?: (React.ReactNode | string)[];
   footerProps?: any;
   rowProps?: any;
   onClick?: (e, row, i) => void;
}
const ListComponent = (props: ListComponentProps) => {
   const { headers, rows, rowProps, headerProps, footer, footerProps, title, titleProps, onClick } = props;
   if (rows.length === 0) return;

   const keys = Object.keys(rows[0]);
   if (keys.length > headers.length) return;

   const handleClick = (event: React.MouseEvent<unknown>, row: any, index: number) => {
      onClick && onClick(event, row, index);
   };

   return (
      <Stack direction={"column"} width={"100%"} sx={{ overflowX: "auto" }}>
         {title ? (
            <Box sx={[titleProps, { minWidth: headers.length > 10 ? `${headers.length * 140}px` : "100%" }]}>
               <Typography textAlign={"center"}>{title}</Typography>
            </Box>
         ) : undefined}
         <Grid
            container
            py={0.5}
            sx={[
               headerProps || defaultHeaderStyle,
               { minWidth: headers.length > 10 ? `${headers.length * 140}px` : "100%" },
            ]}
         >
            {headers.length > 0 &&
               headers.map((header) => {
                  return (
                     <Grid
                        item
                        xs={12 / headers.length}
                        key={header.headerTitle}
                        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                     >
                        <Typography fontSize={"14px"} textAlign={header.headerPosition || "left"} fontWeight={600}>
                           {header.headerTitle}
                        </Typography>
                     </Grid>
                  );
               })}
         </Grid>
         <List
            sx={{
               maxHeight: "300px",
               overflowY: "auto",
               pt: 1,
               minWidth: headers.length > 10 ? `${headers.length * 140}px` : "100%",
            }}
            disablePadding
            dense
         >
            {rows.map((row, i) => {
               const keys = Object.keys(row);
               return (
                  <ListItem
                     onClick={(e) => handleClick(e, row, i)}
                     sx={[
                        rowProps || defaultRowStyle,
                        {
                           cursor: onClick ? "pointer" : "default",
                           "&:hover": onClick && { bgcolor: "whitesmoke" },
                        },
                     ]}
                     key={`listC-row${i + 1}`}
                  >
                     <Grid container display={"flex"}>
                        {headers.map((headerType, i) => {
                           const selectedKey = keys[i];
                           return (
                              <Grid
                                 item
                                 xs={12 / headers.length}
                                 px={1}
                                 display={"flex"}
                                 key={headerType.headerTitle}
                                 justifyContent={headerType.bodyPosition || "left"}
                              >
                                 {componentSelector(row, selectedKey, headerType.type)}
                              </Grid>
                           );
                        })}
                     </Grid>
                  </ListItem>
               );
            })}
         </List>
         {footer && (
            <Stack
               width={"100%"}
               px={2}
               py={1}
               sx={[
                  footerProps || defaultFooterStyle,
                  { minWidth: headers.length > 10 ? `${headers.length * 140}px` : "100%" },
               ]}
            >
               <Grid container>
                  {footer.map((foot, i) => {
                     return (
                        <Grid
                           item
                           xs={12 / headers.length}
                           display={"flex"}
                           justifyContent={"center"}
                           key={`footerC-${i + 1}`}
                        >
                           {foot}
                        </Grid>
                     );
                  })}
               </Grid>
            </Stack>
         )}
      </Stack>
   );
};

function componentSelector(row, rowKey, key) {
   switch (key) {
      case "text":
         return <PlainText text={row[rowKey]} />;
      case "number":
         return <NumberComponent number={row[rowKey]} />;
      case "list":
         return <ItemList itemList={row[rowKey]} />;
      case "punctual":
         return <IsPunctual isPunctual={row[rowKey]} />;
      case "date":
         return <CompleteDate date={row[rowKey]} />;
      case "check":
         return <CheckedBoxContent row={row[rowKey]} />;
      case "percentage":
         return <PercentageComponent percentage={row[rowKey]} />;
      case "boolean":
         return <BooleanComponent boolean={row[rowKey]} />;
      case "currency":
         return <CurrencyComponent currency={row[rowKey]} />;
      case "checkIcon":
         return <CheckIconComponent boolean={row[rowKey]} />;
      default:
         return <PlainText text={row[rowKey]} />;
   }
}

//#region ComponentTypes

const PlainText = ({ text }) => (
   <Typography variant="subtitle1" lineHeight={1.2} alignSelf={"center"}>
      {text}
   </Typography>
);

const NumberComponent = ({ number }) => (
   <Typography variant="subtitle1" lineHeight={1.2} alignSelf={"center"}>
      {formatCurrency(number)}
   </Typography>
);

const ItemList = ({ itemList }) => {
   const [showAllList, setShowAllList] = useState<boolean>(false);
   const handleShowList = () => setShowAllList((s) => !s);
   return (
      <Box display={"flex"} flexWrap={"wrap"} columnGap={1} rowGap={1}>
         {itemList.length > 0 &&
            itemList.map((item, i) => {
               if (i > 1 && !showAllList) return;
               return (
                  <Typography variant="button" bgcolor={"#D8D8D8"} px={0.5} alignSelf={"center"} key={item.name}>
                     {item.name}
                  </Typography>
               );
            })}
         {itemList.length > 2 ? (
            <Button onClick={handleShowList} sx={{ bgcolor: "#EFEFEF" }}>
               <Typography>{showAllList ? "Ver menos" : "Ver más"}</Typography>
            </Button>
         ) : undefined}
      </Box>
   );
};

const IsPunctual = ({ isPunctual }) => {
   return (
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={"100%"}>
         <Typography bgcolor={isPunctual ? "#C8F2C4" : "#F08E8E"} px={1}>
            {isPunctual ? "Puntualmente" : "Impuntual"}
         </Typography>
      </Box>
   );
};

const CompleteDate = ({ date }) => <Typography alignSelf={"center"}>{LocaleDateString(date)}</Typography>;

const CheckedBoxContent = ({ row }) => {
   let booleanOption = false;
   let descriptionOption = "";
   const keys = Object.keys(row);
   for (const option of keys) {
      if (typeof row[option] === "boolean") booleanOption = row[option];
      if (typeof row[option] === "string") descriptionOption = row[option];
   }
   return (
      <Box display={"flex"}>
         <Checkbox defaultChecked={booleanOption} disabled />
         <Typography alignSelf={"center"}>{descriptionOption}</Typography>
      </Box>
   );
};

const PercentageComponent = ({ percentage }) => (
   <Typography fontWeight={600} alignSelf={"center"}>{`${percentage}%`}</Typography>
);

const BooleanComponent = ({ boolean }) => (
   <Typography fontWeight={600} alignSelf={"center"}>
      {boolean ? "V" : "F"}
   </Typography>
);

const CurrencyComponent = ({ currency }) => (
   <Stack direction={"row"}>
      <Typography alignSelf={"center"}>{`$${formatCurrency(currency)}`}</Typography>
   </Stack>
);

const CheckIconComponent = ({ boolean }) => (boolean && <CheckIcon sx={{ alignSelf: "center" }} />) || undefined;

//#endregion

const defaultHeaderStyle = {
   px: 3,
   py: 0.5,
   bgcolor: "#D9D9D9",
   borderRadius: 1,
};

const defaultRowStyle = {
   bgcolor: "#F5F5F5",
   py: 1,
   my: 0.5,
};

const defaultFooterStyle = { borderBottomLeftRadius: 10, borderBottomRightRadius: 10, bgcolor: "#E8E8E8" };
export default ListComponent;
