import { Box, Button } from "@mui/material";
import { useContext } from "react";
import { ComplaintStepperContext } from "../../../context/complaintStepperContext";
import { DocumentsTable } from "../DocumentsTable";
import { UserContext } from "../../../context/UserContext";
import { capitalizeFirstLetter } from "../../../const/globalConst";

export const NotificationStep = () => {
   const { complaint, setOpenCompleteDialog, checkDocuments } = useContext(ComplaintStepperContext);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Denuncias internas");
   return (
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "50vh" }}>
         <Box sx={{ flex: 2 }}>
            <DocumentsTable
               rows={[
                  {
                     document: `Notificación de resolución (${capitalizeFirstLetter(complaint?.type || "denuncia")})`,
                     documentName: `notificacion-resolucion-${complaint?.type || "denuncia"}`,
                     templateLink: `notificacion/5`,
                     fileHeader: `Agregar documento de notificación de resolución (${complaint?.type || "denuncia"})`,
                  },
               ]}
               disabled={access}
            />
         </Box>
         {!access ? (
            <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mr: 2 }}>
               <Box sx={{ flex: "1 1 auto" }} />
               <Button
                  disabled={!checkDocuments("notification")}
                  onClick={() => setOpenCompleteDialog(true)}
                  variant="outlined"
               >
                  {`Completar notificación de resolución`}
               </Button>
            </Box>
         ) : null}
      </Box>
   );
};
