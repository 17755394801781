import { createContext, useState } from "react";
import { IServiceDetails } from "../types/BaseTypes";

interface IComplaints {
   subName: string;
   color: string;
   message: string;
   totalLicenses: number;
}

interface IServiceInfoContext {
   complaintsInfo: IComplaints;
   isLoading: boolean;
   updateComplaintsInfo: Function;
   getService: Function;
}

export const ServiceInfoContext = createContext<IServiceInfoContext>({
   complaintsInfo: null,
   isLoading: true,
   updateComplaintsInfo: () => {},
   getService: () => {},
});

export const ServiceInfoProvider = ({ children }) => {
   const [isLoading, setIsLoading] = useState(false);
   const [complaintsInfo, setComplaintsInfo] = useState<IComplaints>({
      subName: "",
      color: "",
      message: "",
      totalLicenses: 0,
   });

   async function updateComplaintsInfo(data: IComplaints) {
      setIsLoading(true);
      setComplaintsInfo(data);
      setIsLoading(false);
   }

   function getService(services: IServiceDetails[], serviceId: string) {
      for (const service of services) {
         if (service.serviceId._id.toString() === serviceId) {
            return service;
         }
      }
   }

   return (
      <ServiceInfoContext.Provider
         value={{
            complaintsInfo,
            updateComplaintsInfo,
            getService,
            isLoading,
         }}
      >
         {children}
      </ServiceInfoContext.Provider>
   );
};
