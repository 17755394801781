import {
   Box,
   Grid,
   Typography,
   InputAdornment,
   Divider,
   Container,
   Accordion,
   AccordionSummary,
   AccordionDetails,
   MenuItem,
   Tooltip,
   Stack,
   IconButton,
   CircularProgress,
} from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { resolutionNotification } from "../../../../lib/DocmunetsTemplates/NotificationTemplates";
import { TitleAppBar } from "../../../../components/TitleAppBar";
import { Form, Formik, FormikProps } from "formik";
import { InputTextField } from "../../../../components/Inputs/InputTextField";
import { resolutionNotificationSchema } from "../../../../lib/Validations/inputSchemas";
import pdfMake from "../../../../const/pdfMakeFonts";
import { toISOString } from "../../../../const/globalConst";
import { Email, Store, LocationOn, LocationCity, ExpandMore, Add } from "@mui/icons-material";
import { InputSelectField } from "../../../../components/Inputs/InputSelectField";
import { LoadingButton } from "../../../../components/LoadingButton";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { ComplaintStepperContext } from "../../../../context/complaintStepperContext";
import { UserContext } from "../../../../context/UserContext";
import { sendResolutionNotificationEmail } from "../../../../lib/lecosyBackendClient";
import { createDocumentPdf } from "../../../../lib/uploadDocuments";

export const ResolutionNotificaionScreen = () => {
   const { reportNumber } = useParams();
   const { showSnackBar } = useContext(SnackBarContext);
   const { complaint, getComplaint, isLoading } = useContext(ComplaintStepperContext);
   const [submitLoader, setSubmitLoader] = useState(false);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Usuarios");
   const navigate = useNavigate();
   const date = new Date();

   useEffect(() => {
      const fetchComplaint = async () => {
         await getComplaint(reportNumber);
      };
      if (isLoading) fetchComplaint();
      // eslint-disable-next-line
   }, [isLoading]);

   useEffect(() => {
      if (access) {
         navigate("/inicio");
      }
      // eslint-disable-next-line
   }, []);

   const handleSendForm = async (values, actions) => {
      setSubmitLoader(true);
      const necesaryDocuments = complaint.documentation.find(
         (doc) =>
            doc.name === `notificacion-resolucion-denuncia-${complaint.reportNumber}.pdf` ||
            doc.name === `notificacion-resolucion-queja-${complaint.reportNumber}.pdf`
      );
      if (!necesaryDocuments) {
         const dd = resolutionNotification(
            complaint.reportNumber,
            complaint.clasification,
            values.city,
            values.state,
            values.dateTime,
            complaint.resolution ? complaint.resolution : values.measures,
            complaint.newClasification ? complaint.newClasification : "",
            complaint.type
         );
         const pdfDocGenerator = pdfMake.createPdf(dd);
         pdfDocGenerator.getBase64(async (doc) => {
            await sendResolutionNotificationEmail(
               complaint.reportNumber,
               values.complainerEmail,
               values.complainerEmail,
               doc
            );
         });
         pdfDocGenerator.getBlob(async (blob) => {
            await createDocumentPdf(
               complaint.reportNumber,
               `notificacion-resolucion-${complaint?.type || "denuncia"}-${complaint.reportNumber}`,
               blob
            );
            showSnackBar("Documento creado y subido correctamente", false);
            setSubmitLoader(false);
            setTimeout(() => navigate(-1), 1000);
         });
      } else {
         showSnackBar("Este documento ya fue enviado.", true);
         setTimeout(() => navigate(-1), 1000);
      }
   };

   return (
      <Box p={2}>
         <TitleAppBar head="Notificación de resolución de denuncia" isScheduleAppointment={false} />
         <Container maxWidth="xl">
            {complaint.reportNumber !== "" ? (
               <Formik
                  initialValues={{
                     city: "",
                     state: "",
                     measures: "",
                     dateTime: toISOString(date),
                     complainerEmail: complaint.complainerDetails ? complaint.complainerDetails.email : "",
                     offenderEmail: [],
                  }}
                  validationSchema={resolutionNotificationSchema(!complaint.resolution)}
                  onSubmit={handleSendForm}
               >
                  {(formProps: FormikProps<any>) => (
                     <Form>
                        <Box
                           display={"flex"}
                           sx={{ px: 4, py: 2, minWidth: 950, flexDirection: "column", alignItems: "center", m: 2 }}
                        >
                           <Box
                              sx={{
                                 width: "100%",
                                 backgroundColor: "#FFFFFF",
                                 border: 1,
                                 borderRadius: 2,
                                 borderColor: "#8A95A0",
                              }}
                           >
                              <Grid container>
                                 <Grid item xs={6}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <Typography fontWeight={600} variant="body1">
                                          {complaint.complaintType === "Quejas"
                                             ? "Reporte de la queja"
                                             : "Reporte de denuncia"}
                                       </Typography>
                                       <Typography variant="subtitle1">{reportNumber}</Typography>
                                    </Box>
                                 </Grid>
                                 {
                                    <Grid item xs={6}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <Typography fontWeight={600} variant="body1">
                                             {complaint?.type !== "denuncia"
                                                ? `Clasificación de la ${complaint.type}`
                                                : "Clasificación de la denuncia"}
                                          </Typography>
                                          <Typography variant="subtitle1">
                                             {complaint.clasification || "No aplica"}
                                          </Typography>
                                       </Box>
                                    </Grid>
                                 }
                              </Grid>
                              <Divider sx={{ mx: 2 }} />
                              <Grid container>
                                 <Grid item xs={12}>
                                    <Box display={"flex"} sx={{ ...dataFieldSX, alignItems: "flex-start" }}>
                                       <InputTextField
                                          variant="standard"
                                          label="Fecha y hora actual"
                                          name="dateTime"
                                          id="dateTime"
                                          type="datetime-local"
                                          InputLabelProps={{
                                             shrink: true,
                                          }}
                                          sx={{ mt: 1 }}
                                       />
                                    </Box>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          id="state"
                                          name="state"
                                          type="text"
                                          variant="standard"
                                          label="Estado"
                                          fullWidth={true}
                                          InputProps={{
                                             startAdornment: (
                                                <InputAdornment position="start">
                                                   <LocationOn />
                                                </InputAdornment>
                                             ),
                                          }}
                                       />
                                    </Box>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          id="city"
                                          name="city"
                                          type="text"
                                          variant="standard"
                                          label="Ciudad"
                                          fullWidth={true}
                                          InputProps={{
                                             startAdornment: (
                                                <InputAdornment position="start">
                                                   <LocationCity />
                                                </InputAdornment>
                                             ),
                                          }}
                                       />
                                    </Box>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Divider sx={{ m: 2 }} />
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Box sx={{ p: "8px 16px" }}>
                                       <Typography variant="body2">
                                          Ingresa el correo electrónico del denunciante y el/los denunciados en sus
                                          campos respectivos, para hacerles llegar la notificación de resolución
                                       </Typography>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          id="complainerEmail"
                                          name="complainerEmail"
                                          type="text"
                                          variant="standard"
                                          label="Correo electrónico del denunciante"
                                          fullWidth
                                          InputProps={{
                                             startAdornment: (
                                                <InputAdornment position="start">
                                                   <Email />
                                                </InputAdornment>
                                             ),
                                             endAdornment: !complaint.complainerDetails ? (
                                                <Tooltip
                                                   title="Usar correo de seguimiento del denunciante"
                                                   onClick={() => {
                                                      if (formProps.values.complainerEmail === "") {
                                                         formProps.setFieldValue(
                                                            "complainerEmail",
                                                            complaint.followUpEmail
                                                         );
                                                      }
                                                   }}
                                                >
                                                   <IconButton size="small">
                                                      <Add fontSize="small" />
                                                   </IconButton>
                                                </Tooltip>
                                             ) : null,
                                          }}
                                       />
                                    </Box>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <InputSelectField
                                          multiple
                                          fullWidth
                                          variant="standard"
                                          label="Correo electrónico del denunciado"
                                          labelId="offenderEmail"
                                          id="offenderEmail"
                                          name="offenderEmail"
                                       >
                                          {complaint.offenderDetails.length > 0 ? (
                                             complaint.offenderDetails.map((e) =>
                                                e.aditionalInformation ? (
                                                   <MenuItem key={e._id} value={e.aditionalInformation.email}>
                                                      {e.aditionalInformation.email}
                                                   </MenuItem>
                                                ) : (
                                                   <MenuItem key={""} value={""} disabled>
                                                      {`Correo de ${e.name} no registrado`}
                                                   </MenuItem>
                                                )
                                             )
                                          ) : (
                                             <MenuItem key={"none"}>Correos electrónicos no registrados</MenuItem>
                                          )}
                                       </InputSelectField>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <Box sx={dataFieldSX}>
                                       <ComplainerDetailsAccordion complainer={complaint.complainerDetails} />
                                    </Box>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <Box sx={dataFieldSX}>
                                       <OffendersDetailsAccordion offenders={complaint.offenderDetails} />
                                    </Box>
                                 </Grid>
                                 {complaint.resolution ? null : (
                                    <Grid item xs={12}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <InputTextField
                                             id="measures"
                                             name="measures"
                                             type="text"
                                             variant="standard"
                                             label="Medidas"
                                             fullWidth={true}
                                             InputProps={{
                                                startAdornment: (
                                                   <InputAdornment position="start">
                                                      <Store />
                                                   </InputAdornment>
                                                ),
                                             }}
                                             sx={{ mx: 2 }}
                                          />
                                       </Box>
                                    </Grid>
                                 )}
                              </Grid>
                           </Box>
                           <Box display={"flex"} sx={{ width: "100%", justifyContent: "flex-end", pt: 2 }}>
                              <LoadingButton label="Enviar" isLoading={submitLoader} />
                           </Box>
                        </Box>
                     </Form>
                  )}
               </Formik>
            ) : (
               <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                  <CircularProgress size={24} />
               </Box>
            )}
         </Container>
      </Box>
   );
};

const ComplainerDetailsAccordion = ({ complainer }) => {
   return (
      <div>
         {complainer ? (
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMore />} id="complainerDetails" sx={{ bgcolor: "#EEEEEE80" }}>
                  <Typography>Denunciante</Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Stack spacing={1}>
                     <Typography variant="body2">
                        <strong>Nombre:</strong> {`${complainer.name} ${complainer.lastName}`}
                     </Typography>
                     <Typography variant="body2">
                        <strong>Correo electrónico:</strong> {complainer.email}
                     </Typography>
                  </Stack>
               </AccordionDetails>
            </Accordion>
         ) : (
            <Accordion disabled>
               <AccordionSummary expandIcon={<ExpandMore />} id="complainerDetails">
                  <Typography>Anónimo</Typography>
               </AccordionSummary>
            </Accordion>
         )}
      </div>
   );
};

const OffendersDetailsAccordion = ({ offenders }) => {
   return (
      <div>
         {offenders.length > 0 ? (
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMore />} id="offenderDetails" sx={{ bgcolor: "#EEEEEE80" }}>
                  <Typography>Denunciado/s</Typography>
               </AccordionSummary>
               {offenders.map((e, i) => (
                  <AccordionDetails>
                     <Stack spacing={1}>
                        <Typography variant="body2">
                           <strong>Nombre:</strong> {`${e.name} ${e.lastName}`}
                        </Typography>
                        <Typography variant="body2">
                           <strong>Correo electrónico: </strong>
                           {e.aditionalInformation ? `${e.aditionalInformation.email}` : "No registrado"}
                        </Typography>
                     </Stack>
                     {i + 1 === offenders.length ? <></> : <Divider />}
                  </AccordionDetails>
               ))}
            </Accordion>
         ) : (
            <Accordion disabled>
               <AccordionSummary expandIcon={<ExpandMore />} id="offenderDetails">
                  <Typography>No existen</Typography>
               </AccordionSummary>
            </Accordion>
         )}
      </div>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   p: 2,
};
