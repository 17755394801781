import { useState, useEffect, useContext } from "react";
import { Box, Button, CircularProgress, Container, Divider, Drawer, TextField, Typography } from "@mui/material";
import { TopHeader } from "../components/TopHeader";
import { useParams } from "react-router-dom";
import { AddModifyMasterUserDrawer } from "../components/MasterUser/AddModifyMasterUserDrawer";
import { getMasterUserByUidInput } from "../lib/usersBEClient";
import RoleAccordion, { RoleAccordionInterface } from "../components/RoleAccordion";
import { UserContext } from "../context/UserContext";

interface MasterUserInfoInterface {
   createdAt: string;
   email: string;
   firstName: string;
   lastName: string;
   role: RoleAccordionInterface[];
   uid: string;
   updatedAt: string;
   validPwd: boolean;
}

export const MasterUsersInfoScreen = () => {
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Usuarios");
   const { masterUid } = useParams();
   const [masterUserInfo, setMasterUserInfo] = useState<MasterUserInfoInterface>(null);
   const [isLoading, setIsLoading] = useState(true);
   const [openModifyMasterDrawer, setOpenModifyMasterDrawer] = useState(false);

   useEffect(() => {
      getMasterUserInfo();
      // eslint-disable-next-line
   }, []);

   async function getMasterUserInfo() {
      setIsLoading(true);
      const response = await getMasterUserByUidInput(masterUid);
      setMasterUserInfo(response.data.masterFound);
      setIsLoading(false);
   }

   return (
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
         {!isLoading ? (
            <>
               <TopHeader
                  head={"Información del usuario" /*`${masterUserInfo.firstName} ${masterUserInfo.lastName}`*/}
                  content={<></>}
               />
               <Container maxWidth="xl" sx={{ mt: 1 }}>
                  <Box bgcolor={"white"} borderRadius={1} p={2} boxShadow={2}>
                     <Box display={"flex"} justifyContent={"space-between"} sx={{ marginBottom: 2 }}>
                        <Typography variant="h5" sx={{ color: "#162c44" }}>
                           Datos del usuario
                        </Typography>
                        <Divider
                           sx={{
                              my: 1,
                              flexGrow: 1,
                              marginBottom: "auto",
                              marginTop: "auto",
                              marginLeft: 1,
                              marginRight: 1,
                           }}
                        />
                        {!access ? (
                           <Box>
                              <Button
                                 variant="contained"
                                 onClick={() => setOpenModifyMasterDrawer(true)}
                                 sx={{ mr: 1 }}
                                 disabled={access}
                              >
                                 Modificar información
                              </Button>
                           </Box>
                        ) : null}
                     </Box>
                     <Box
                        display={"flex"}
                        alignItems={"center"}
                        flex={1}
                        px={5}
                        py={1}
                        sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}
                     >
                        <TextField
                           variant="outlined"
                           multiline
                           size="small"
                           name="firstName"
                           id="firstName"
                           type="text"
                           label="Nombre(s)"
                           sx={{ width: "80%" }}
                           value={masterUserInfo.firstName}
                        />
                        <TextField
                           variant="outlined"
                           multiline
                           size="small"
                           name="lastName"
                           id="lastName"
                           type="text"
                           label="Apellidos"
                           sx={{ width: "80%" }}
                           value={masterUserInfo.lastName}
                        />
                        <TextField
                           variant="outlined"
                           multiline
                           size="small"
                           name="email"
                           id="email"
                           type="text"
                           label="Correo electrónico"
                           sx={{ width: "100%" }}
                           value={masterUserInfo.email}
                        />
                     </Box>
                     <Box display="flex" sx={{ marginTop: 5, marginBottom: 3 }}>
                        <Typography variant="h5" style={{ flex: "0 0 auto", color: "#162c44" }}>
                           Roles asignados
                        </Typography>
                        <Divider
                           sx={{
                              my: 1,
                              flexGrow: 1,
                              marginBottom: "auto",
                              marginTop: "auto",
                              marginLeft: 1,
                              marginRight: 1,
                           }}
                        />
                     </Box>
                     <RoleAccordion roles={masterUserInfo.role} />
                  </Box>
                  <Drawer
                     hideBackdrop
                     anchor="right"
                     open={openModifyMasterDrawer}
                     onClose={() => setOpenModifyMasterDrawer(false)}
                     sx={{ maxWidth: 450 }}
                  >
                     <AddModifyMasterUserDrawer
                        isModify
                        setOpen={setOpenModifyMasterDrawer}
                        masterUserData={masterUserInfo}
                        updateMasterInfo={getMasterUserInfo}
                     />
                  </Drawer>
               </Container>
            </>
         ) : (
            <CircularProgress sx={{ alignSelf: "center" }} />
         )}
      </Box>
   );
};
