import { FormControl, FormControlLabel, RadioGroup, Radio, FormHelperText } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { useContext } from "react";
import { MasterAccessContext } from "../../context/masterAccess/masterAccessContext";

interface RadioButtonInterfaceProps {
   fullWidth?: boolean;
   variant?: any;
   id: string;
   name: string;
   sx?: object;
   row: boolean;
   value?: any;
   onChange?: any;
   setfile?: Function;
   access?: boolean;
   resource?: string;
}

export const InputRadioButton = (props: RadioButtonInterfaceProps) => {
   const { setResourcesSelected, resourcesSelected } = useContext(MasterAccessContext);
   const { setFieldValue } = useFormikContext();
   const [field, meta] = useField(props);

   const validateSelected = (permission: number) => {
      const validate = resourcesSelected.filter((resource) => resource.resource === props.resource);
      if (validate.length > 0) {
         const newList = resourcesSelected.filter((resource) => resource.resource !== props.resource);
         const newValue = {
            resource: props.resource,
            permission: permission,
         };
         newList.push(newValue);
         setResourcesSelected(newList);
      } else {
         const newList = resourcesSelected;
         const newValue = {
            resource: props.resource,
            permission: permission,
         };
         newList.push(newValue);
         setResourcesSelected(newList);
      }
   };

   const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.name.includes("accessControl") && event.target.value === "2") {
         validateSelected(2);
      } else if (event.target.name.includes("accessControl") && event.target.value === "1") {
         validateSelected(1);
      } else if (event.target.name.includes("accessControl") && event.target.value === "") {
         const newList = resourcesSelected.filter((resource) => resource.resource !== props.resource);
         setResourcesSelected(newList);
      }

      if (event.target.name === "worksInPlace" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("witnessActivity", "");
         setFieldValue("witnessTime", "");
         return;
      }
      if (event.target.name === "denouncedResponsible" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("denouncedPersonName", (event.target as HTMLInputElement).value);
         setFieldValue("denouncedPersonNameAnswer", "");
         return;
      }
      if (event.target.name === "denouncedPersonName" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("denouncedPersonNameAnswer", "");
         return;
      }
      if (event.target.name === "denouncedCategoryPartake" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("denouncedNarration", "");
         return;
      }
      if (event.target.name === "denouncedWitness" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("denouncedWitnessAnswer", "");
         return;
      }
      if (event.target.name === "trueComplaint" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("knowsPeople", (event.target as HTMLInputElement).value);
         setFieldValue("witnessPeople", "");
         return;
      }
      if (event.target.name === "perceptionVariation" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("witnessNarration", "");
         return;
      }
      if (event.target.name === "complainantLabors") {
         if (event.target.value === "No") {
            setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
            setFieldValue("complainantActivity", "");
            setFieldValue("complainantTime", "");
            return;
         }
         if (event.target.value === "Si") {
            setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
            setFieldValue("complainantExplanation", "");
            return;
         }
      }
      if (event.target.name === "complainantInvolved" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("complainantInvolvedNarration", "");
         return;
      }
      if (event.target.name === "complainantEvidence" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("complainantGivesEvidence", "No");
         return;
      }
      if (event.target.name === "complainantWitness" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("complainantWitnessAnswer", "No");
         return;
      }
      if (event.target.name === "online" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         props.setfile(null);
         return;
      }
      setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
   };
   return (
      <FormControl component="fieldset" {...props.sx} error={meta.error && meta.touched}>
         <RadioGroup {...props} name={field.name} onChange={handleRadioChange} value={field.value} defaultValue={""}>
            <FormControlLabel
               value={props.access ? "1" : "Si"}
               control={<Radio />}
               label={props.access ? "Visualizar" : "Si"}
               labelPlacement="top"
            />
            <FormControlLabel
               value={props.access ? "2" : "No"}
               control={<Radio />}
               label={props.access ? "Gestionar" : "No"}
               labelPlacement="top"
            />
            {props.access ? (
               <FormControlLabel
                  value={props.access ? "" : "Si"}
                  control={<Radio />}
                  label={props.access ? "Sin acceso" : "Si"}
                  labelPlacement="top"
               />
            ) : null}
         </RadioGroup>
         <FormHelperText>{meta.error && meta.touched ? meta.error : null}</FormHelperText>
      </FormControl>
   );
};
