import { AxiosError } from "axios";
import { createContext, useState, useEffect, useMemo, useCallback } from "react";
import { getMasterUserByUid, getUrlS3, LogoutMasterUser } from "../lib/usersBEClient";
import { MasterUserInterface, Resources, UserRole } from "../types/BaseTypes";

type UserContextInferface = {
   user: {
      id: string;
      auth: boolean;
      masterUserName: string;
      masterUserLastName: string;
      email: string;
      role: UserRole[];
      validPwd: boolean;
      access: Resources[];
      createdAt: string;
   };
   userProfilePicture: string;
   setUserProfilePicture: Function;
   loginUser: () => void;
   logoutUser: () => void;
   setUser: Function;
   GetAccess: Function;
   GetPermission: Function;
   getMasterUserInfo: () => void;
};

export const UserContext = createContext<UserContextInferface>({
   user: {
      id: "",
      auth: false,
      masterUserName: "",
      masterUserLastName: "",
      email: "",
      role: [],
      validPwd: false,
      access: [],
      createdAt: "",
   },
   userProfilePicture: "",
   setUserProfilePicture: () => {},
   loginUser: () => {},
   logoutUser: () => {},
   setUser: () => {},
   GetAccess: () => {},
   GetPermission: () => {},
   getMasterUserInfo: () => {},
});

export const UserProvider = ({ children }) => {
   const [user, setUser] = useState({
      id: "",
      auth: false,
      masterUserName: "",
      masterUserLastName: "",
      email: "",
      role: [],
      validPwd: false,
      access: [],
      createdAt: "",
   });
   const [userProfilePicture, setUserProfilePicture] = useState("");

   const getMasterUserInfo = useCallback(async () => {
      try {
         const response = await getMasterUserByUid();
         const userInfo: MasterUserInterface = response.data;
         if (!userInfo) return;
         const UserAccess = [];
         for (const role of userInfo.role) {
            for (const access of role.access) {
               for (const resources of access.resources) {
                  const newResource = {
                     name: resources.resource.name,
                     permission: resources.permission,
                  };
                  UserAccess.push(newResource);
               }
            }
         }
         const formatedData = {
            id: userInfo._id,
            auth: true,
            masterUserName: userInfo.firstName,
            masterUserLastName: userInfo.lastName,
            email: userInfo.email,
            role: userInfo.role,
            validPwd: userInfo.validPwd,
            access: UserAccess,
            createdAt: userInfo.createdAt,
         };
         setUser({
            ...user,
            ...formatedData,
         });
         return formatedData;
      } catch (error) {
         return { auth: false };
      }
   }, [user]);

   useEffect(() => {
      if (user.auth === false) {
         getMasterUserInfo();
      }
      // eslint-disable-next-line
   }, []);

   const logoutUser = useCallback(async () => {
      setUser({
         ...user,
         id: "",
         auth: false,
         access: [],
         masterUserName: "",
         masterUserLastName: "",
         email: "",
         role: [],
         validPwd: false,
         createdAt: "",
      });
      await LogoutMasterUser(user.email);
   }, [user]);

   const loginUser = useCallback(async () => {
      try {
         await getMasterUserInfo();
      } catch (error) {
         const errorResponse: AxiosError = error as AxiosError;
         const errorMessage: any = errorResponse.response.data;
         if (errorMessage.message === "Unauthorized") {
            logoutUser();
         }
      }
   }, [getMasterUserInfo, logoutUser]);

   useEffect(() => {
      const getProfilePicture = async () => {
         const url = await getUrlS3("files-lecosy", { folder: `masters/${user.id}` }, "profile.png");
         setUserProfilePicture(url);
      };
      if (user.id) getProfilePicture();
      // eslint-disable-next-line
   }, [user]);

   const GetPermission = useCallback(
      (section: string) => {
         return !user.access.some((access) => {
            return access.name === section && access.permission === 2;
         });
      },
      [user.access]
   );

   const GetAccess = useCallback(
      (section: string | string[]) => {
         return !!user.access.some((access) => {
            if (typeof section === "string") return access.name === section;
            return section.includes(access.name);
         });
      },
      [user.access]
   );

   const value = useMemo(
      () => ({
         user,
         userProfilePicture,
         setUserProfilePicture,
         loginUser,
         logoutUser,
         setUser,
         GetAccess,
         getMasterUserInfo,
         GetPermission,
      }),
      [user, userProfilePicture, GetAccess, GetPermission, getMasterUserInfo, loginUser, logoutUser]
   );
   return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
