import { Box, Stack, Tab, Tabs } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { ConsultiveGroupInfo } from "./ConsultiveGroup/ConsultiveGroupInfo";
import ConsultiveGroup from "./ConsultiveGroup";
import { DenounceChannelMembersTable } from "./DenounceChannelGroup/DenounceChannelMembersTable";
import { DenounceChannelCompanies } from "./DenounceChannelGroup/DenounceChannelCompanies";
import { ClientConfigContext } from "../../../context/clientConfigContext";
import { getManyCompaniesById } from "../../../lib/usersBEClient";
import DenounceChannelGroupLicences from "./DenounceChannelGroup/DenounceChannelGroupLicences";

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

const GroupServices = () => {
   const [value, setValue] = useState(0);
   const [governanceExists, setGovernanceExists] = useState(false);
   const { group } = useContext(ClientConfigContext);

   //VERIFICAR SI ALGUNA EMPRESA DENTRO DEL GOBIERNO TIENE MODULO DE GC
   useEffect(() => {
      const fetchCompanies = async () => {
         const companiesIds = group.companies.map((c) => c._id);
         const companiesResponse = await getManyCompaniesById(companiesIds);
         const exists = companiesResponse.some((c) =>
            c.company_details.servicesDetails.some((s) => s.serviceId === "64e7851d978b71bd4f011ee4" && !s.disabled)
         );
         setGovernanceExists(exists);
      };
      if (group) fetchCompanies();
   }, [group]);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   function CustomTabPanel(props: TabPanelProps) {
      const { children, value, index, ...other } = props;

      return (
         <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
         >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
         </div>
      );
   }
   const SubComponent = useMemo(() => {
      if (value === 0)
         return (
            <>
               <DenounceChannelMembersTable />
               <DenounceChannelCompanies />
            </>
         );
      if (value === 1) return <ConsultiveGroup />;
      return <Box></Box>;
   }, [value]);

   return (
      <Stack direction={"column"} spacing={2}>
         <Stack sx={{ borderBottomLeftRadius: 4, borderBottomRightRadius: 4, bgcolor: "white", boxShadow: 2 }}>
            <Tabs value={value} onChange={handleChange}>
               <Tab value={0} label={"Canal de denuncias"} />
               {governanceExists && <Tab value={1} label={"Gobierno corporativo"} />}
            </Tabs>
            <CustomTabPanel value={value} index={0}>
               <DenounceChannelGroupLicences />
            </CustomTabPanel>
            {governanceExists && (
               <CustomTabPanel value={value} index={1}>
                  <ConsultiveGroupInfo />
               </CustomTabPanel>
            )}
         </Stack>
         {SubComponent}
      </Stack>
   );
};

export default GroupServices;
