import { useContext, useState } from "react";
import { Box, Divider, Typography, Toolbar } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Form, Formik, FormikProps } from "formik";
import { InputTextField } from "../Inputs/InputTextField";
import { updateOwnerMasterUser, updateOwnerMasterUserEmail } from "../../lib/usersBEClient";
import { UserContext } from "../../context/UserContext";
import { modifyUserSchema } from "../../lib/Validations/inputSchemas";
import { SnackBarContext } from "../../context/snackBarContext";
import { LoadingButton } from "../LoadingButton";
import { ConfirmationModal } from "../ConfirmationModal";
import LoginModal from "../ClientInformation/Modal/loginModal";

interface ModifyUserInfoDrawerProps {
   open: boolean;
   setOpen: Function;
   setOpenEmail: Function;
   dataToModify: any;
}

export const ModifyUserInfoDrawer = (props: ModifyUserInfoDrawerProps) => {
   const { loginUser } = useContext(UserContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState(false);
   const [isLoadingEmail, setIsLoadingEmail] = useState(false);
   const [modifyInfo, setModifyInfo] = useState(false);

   function handleOpen() {
      props.setOpen(!props.open);
   }

   const modifyUserEmail = async (values) => {
      try {
         const response = await updateOwnerMasterUserEmail(values.email);
         if (response.status === 200) {
            showSnackBar("Correo electrónico modificado correctamente", false);
            props.setOpenEmail(true);
            setIsLoadingEmail(false);
            props.setOpen(!props.open);
         }
         if (
            values.userName !== props.dataToModify.masterUserName ||
            values.userLastName !== props.dataToModify.masterUserLastName
         )
            await modifyUserInfo(values);
         setIsLoadingEmail(false);
      } catch (error: any) {
         showSnackBar("Este correo electrónico ya existe", true);
         setIsLoadingEmail(false);
      }
   };

   const modifyUserInfo = async (values) => {
      try {
         const response = await updateOwnerMasterUser(values.userName, values.userLastName);
         if (response.data) {
            loginUser();
            showSnackBar("Datos modificados correctamente", false);
            props.setOpen(!props.open);
         }
         setIsLoading(false);
      } catch (error: any) {
         if (error.response.status === 400) {
            showSnackBar("Ocurrió un error.", true);
            return null;
         }
         setIsLoading(false);
      }
   };

   return (
      <Box sx={{ width: 400, display: "flex", maxHeight: "100vh", height: "100%" }} role="presentation">
         <Box
            display="flex"
            alignItems="center"
            onClick={handleOpen}
            sx={{
               "&:hover": {
                  bgcolor: "#F7F7F7",
               },
            }}
         >
            <ChevronRightIcon fontSize="small" />
         </Box>
         <Box sx={{ display: "flex", flexDirection: "column", flex: 1, mb: 1, mt: 2 }}>
            <Toolbar />
            <Box p={1}>
               <Typography variant="h5">Modificar cuenta</Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 1 }}>
               <Formik
                  initialValues={{
                     userName: props.dataToModify["masterUserName"],
                     userLastName: props.dataToModify["masterUserLastName"],
                     email: props.dataToModify["email"],
                  }}
                  onSubmit={() => {
                     modifyInfo ? setIsLoading(true) : setIsLoadingEmail(true);
                  }}
                  validationSchema={modifyUserSchema}
               >
                  {(formProps: FormikProps<any>) => (
                     <Form>
                        <Box mr={2}>
                           <InputTextField
                              sx={{ my: 2 }}
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="userName"
                              id="userName"
                              type="text"
                              label="Nombre(s)"
                              fullWidth={true}
                           />
                           <InputTextField
                              sx={{ my: 2 }}
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="userLastName"
                              id="userLastName"
                              type="text"
                              label="Apellidos"
                              fullWidth={true}
                           />
                           <InputTextField
                              sx={{ my: 2 }}
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="email"
                              id="email"
                              type="email"
                              label="Correo electrónico"
                              fullWidth={true}
                           />

                           <Box my={2} display={"flex"} justifyContent={"end"}>
                              <LoadingButton
                                 isLoading={false}
                                 label="Modificar información"
                                 onClick={() => setModifyInfo(formProps.values.email === props.dataToModify.email)}
                              />
                           </Box>
                        </Box>
                        <ConfirmationModal
                           open={isLoading}
                           setOpen={setIsLoading}
                           generic
                           onConfirm={() => modifyUserInfo(formProps.values)}
                        />
                        <LoginModal
                           open={isLoadingEmail}
                           setOpen={setIsLoadingEmail}
                           onConfirm={() => modifyUserEmail(formProps.values)}
                        />
                     </Form>
                  )}
               </Formik>
            </Box>
         </Box>
      </Box>
   );
};
