import { Select, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { useField } from "formik";

interface InputSelectFieldInterfaceProps {
   label: string;
   labelId: string;
   fullWidth?: boolean;
   multiple?: boolean;
   id: string;
   name: string;
   renderValue?: any;
   children: any;
   variant?: any;
   size?: any;
   onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
   onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
   value?: any;
   sx?: any;
   disabled?: boolean;
}

export const InputSelectField = (props: InputSelectFieldInterfaceProps) => {
   const [field, meta] = useField(props);
   return (
      <FormControl fullWidth={props.fullWidth} sx={props.sx} disabled={props.disabled}>
         <InputLabel id={props.labelId} size={props.size}>
            {props.label}
         </InputLabel>
         <Select error={meta.error && meta.touched ? true : false} {...props} {...field} disabled={props.disabled}>
            {props.children}
         </Select>
         <FormHelperText error={meta.error && meta.touched ? true : false}>
            {meta.error && meta.touched ? meta.error : null}
         </FormHelperText>
      </FormControl>
   );
};
