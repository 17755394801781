import { Box, Typography, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

interface NotificationBarProps {
   head: string;
   content: React.ReactNode;
}

export const TopHeader = (props: NotificationBarProps) => {
   const navigate = useNavigate();
   return (
      <>
         <Box
            sx={{
               display: "flex",
               px: 3,
               borderRadius: 4,
               justifyContent: "space-between",
            }}
         >
            <Box sx={{ display: "flex", alignItems: "center" }}>
               <Button variant="contained" sx={{ minWidth: 10, height: 30 }} onClick={() => navigate(-1)}>
                  <ArrowBackIcon sx={{ width: 20 }} />
               </Button>
               <Typography variant="h6" sx={{ ml: 1 }}>
                  {props.head}
               </Typography>
            </Box>
            {props.content}
         </Box>
         <Divider sx={{ my: 1 }} variant="middle" />
      </>
   );
};
