import { useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { useNavigate } from "react-router-dom";
import { HeadCell, TableComponent } from "../../../TableComponent";
import { Box, Stack, Typography } from "@mui/material";
import { getManyCompaniesById, getManyCorporateData, getUrlS3 } from "../../../../lib/usersBEClient";
import { ClientConfigContext } from "../../../../context/clientConfigContext";

let columns: HeadCell[] = [
   { field: "logo", headerName: "Logo", type: "string" },
   { field: "cName", headerName: "Empresa", type: "string", align: "center" },
   { field: "councilType", headerName: "Tipo de consejo", type: "string" },
   { field: "status", headerName: "Estatus", type: "disabled" },
];

export const ConsultiveCompaniesTable = () => {
   const navigate = useNavigate();
   const { group, consultiveGroup } = useContext(ClientConfigContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [companiesRow, setCompaniesRow] = useState([]);
   const [isLoading, setIsLoading] = useState(false);

   const handleClick = async (_e, row) => {
      const screen = "clientes/" + row.id;
      return navigate(`/${screen}`);
   };

   const fetchCompaniesInfo = async () => {
      const companyData: any[] = [];
      setIsLoading(true);
      try {
         const companyCorporateDatas = await getManyCorporateData(group.companies.map((company) => company._id));
         const allCompanies = await getManyCompaniesById(group.companies.map((company) => company._id));

         for (const company of allCompanies) {
            companyData.push({
               id: company._id,
               logo: (
                  <img
                     defaultValue={company.logo}
                     src={company.logo}
                     alt="logo"
                     style={{ maxWidth: "140px", maxHeight: "50px" }}
                  />
               ),
               cName: company.person_details.comercialName,
               status: company.disabled,
               councilType: companyCorporateDatas?.find((c) => c.companyId === company._id)?.councilType ?? "---",
            });
         }
         setCompaniesRow(companyData);
      } catch (error) {
         showSnackBar("Error al conseguir empresas", true);
      }
      setIsLoading(false);
   };

   useEffect(() => {
      if (consultiveGroup && consultiveGroup._id === group._id) fetchCompaniesInfo();
      // eslint-disable-next-line
   }, [group, consultiveGroup]);

   return (
      <Box
         sx={{
            bgcolor: "white",
            boxShadow: 2,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            borderRadius: 1,
         }}
      >
         <Stack direction={"row"} alignContent={"center"} sx={{ px: 4, pt: 3 }}>
            <Typography sx={{ fontWeight: 700 }}>Empresas</Typography>
         </Stack>
         <TableComponent
            defaultColumnToOrder="empresa"
            defaultOrder="asc"
            defaultRowsPerPage={5}
            emptyDataText={"No existen empresas registradas"}
            disableBorders
            headers={columns}
            loader={isLoading}
            rows={companiesRow}
            rowsPerPageOptions={[5, 10, 15]}
            onClick={handleClick}
         />
      </Box>
   );
};
