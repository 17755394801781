import { Box, Grid, Typography, Button, Skeleton, Stack } from "@mui/material";
import React, { useContext } from "react";
import { capitalizeFirstLetter, tableStatusColors } from "../../const/globalConst";
import CircleIcon from "@mui/icons-material/Circle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";
import { EvidencesModal } from "../Evidences/EvidencesModal";

interface ComplaintRelevantInfoProps {
   setOpenWitnessModal: Function;
   setOpenOffenderModal: Function;
   includeStepper: boolean;
}

export const ComplaintRelevantInfo = (props: ComplaintRelevantInfoProps) => {
   const { complaint, isLoading } = useContext(ComplaintStepperContext);
   const [openModal, setOpenModal] = React.useState<boolean>(false);
   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setOpenModal(true);
   };

   return (
      <Box sx={{ bgcolor: "white", p: 2, minHeight: "100%", boxShadow: 3 }}>
         <Grid container spacing={1.2}>
            <Grid item xs={12} sx={{ mb: 1 }}>
               <Typography variant="h6">{`Información de la ${complaint.type || "denuncia"}`}</Typography>
            </Grid>
            <Box display={"flex"} flexWrap={"wrap"} columnGap={4} rowGap={2} p={1}>
               <Stack direction={"column"} sx={{ minWidth: "180px" }}>
                  <Typography fontWeight={600} sx={{ px: 2 }}>
                     Número de reporte
                  </Typography>
                  <Box sx={{ px: 2 }}> {isLoading ? <Skeleton variant="rectangular" /> : complaint.reportNumber}</Box>
               </Stack>
               {complaint.category && (
                  <Stack direction={"column"} sx={{ minWidth: "180px", maxWidth: "180px" }}>
                     <Typography fontWeight={600} sx={{ px: 2 }}>
                        Categoría
                     </Typography>
                     <Box sx={{ px: 2 }}>
                        {isLoading ? <Skeleton variant="rectangular" /> : complaint.category || "N/A"}
                     </Box>
                  </Stack>
               )}
               {(!props.includeStepper && (
                  <>
                     <Stack direction={"column"} sx={{ minWidth: "180px" }}>
                        <Box fontWeight={600} sx={{ px: 2 }}>
                           Estatus
                        </Box>
                        <Box>
                           {isLoading ? (
                              <Box sx={{ px: 2 }}>
                                 <Skeleton variant="rectangular" />
                              </Box>
                           ) : (
                              <Box display="flex" sx={{ px: 2 }}>
                                 {capitalizeFirstLetter(complaint.status)}
                                 <CircleIcon
                                    sx={{
                                       width: 10,
                                       color: tableStatusColors(complaint.status),
                                       ml: 1,
                                    }}
                                 />
                              </Box>
                           )}
                        </Box>
                     </Stack>
                     <Stack direction={"column"} sx={{ minWidth: "180px" }}>
                        <Box fontWeight={600} sx={{ px: 2 }}>
                           Empresa/Sucursal
                        </Box>
                        <Box sx={{ px: 2 }}>
                           {isLoading ? (
                              <Skeleton variant="rectangular" />
                           ) : (
                              complaint.companyBranch.name ||
                              complaint.companyBranch.company.person_details.comercialName
                           )}
                        </Box>
                     </Stack>
                  </>
               )) || (
                  <>
                     <Stack direction={"column"} sx={{ minWidth: "180px" }}>
                        <Box fontWeight={600} sx={{ px: 2 }}>
                           Estatus
                        </Box>
                        <Box>
                           {isLoading ? (
                              <Box sx={{ px: 2 }}>
                                 <Skeleton variant="rectangular" />
                              </Box>
                           ) : (
                              <Box display="flex" sx={{ px: 2 }}>
                                 {capitalizeFirstLetter(complaint.status)}
                                 <CircleIcon
                                    sx={{
                                       width: 10,
                                       color: tableStatusColors(complaint.status),
                                       ml: 1,
                                    }}
                                 />
                              </Box>
                           )}
                        </Box>
                     </Stack>
                     <Stack direction={"column"} sx={{ minWidth: "180px" }}>
                        <Box fontWeight={600} sx={{ px: 2 }}>
                           Empresa/Sucursal
                        </Box>
                        <Box sx={{ px: 2 }}>
                           {isLoading ? (
                              <Skeleton variant="rectangular" />
                           ) : (
                              complaint.companyBranch.name ||
                              complaint.companyBranch.company.person_details.comercialName
                           )}
                        </Box>
                     </Stack>
                  </>
               )}
               <Stack direction={"column"} sx={{ minWidth: "180px" }}>
                  <Typography fontWeight={600} sx={{ px: 2 }}>
                     {complaint.witnesses.length > 1 ? "Testigos" : "Testigo"}
                  </Typography>
                  <Box sx={{ px: 2 }}>
                     {isLoading ? (
                        <Skeleton variant="rectangular" />
                     ) : (
                        <>
                           {complaint.witnesses && complaint.witnesses.length > 0 ? (
                              <Button
                                 variant="outlined"
                                 size="small"
                                 endIcon={<VisibilityIcon />}
                                 onClick={() => props.setOpenWitnessModal(true)}
                              >
                                 {isLoading ? <Skeleton variant="rectangular" /> : "Ver"}
                              </Button>
                           ) : (
                              "No Aplica"
                           )}
                        </>
                     )}
                  </Box>
               </Stack>
               <Stack direction={"column"} sx={{ minWidth: "180px" }}>
                  <Typography fontWeight={600} sx={{ px: 2 }}>
                     {complaint.offenderDetails.length > 1 ? "Denunciados" : "Denunciado"}
                  </Typography>
                  <Box sx={{ px: 2 }}>
                     {isLoading ? (
                        <Skeleton variant="rectangular" />
                     ) : (
                        <>
                           {complaint.offenderDetails && complaint.offenderDetails.length > 0 ? (
                              <Button
                                 variant="outlined"
                                 size="small"
                                 endIcon={<VisibilityIcon />}
                                 onClick={() => props.setOpenOffenderModal(true)}
                              >
                                 {isLoading ? <Skeleton variant="rectangular" /> : "Ver"}
                              </Button>
                           ) : (
                              "No Aplica"
                           )}
                        </>
                     )}
                  </Box>
               </Stack>
               {complaint.clasification && (
                  <Stack direction={"column"} sx={{ minWidth: "180px", maxWidth: "180px" }}>
                     <Box fontWeight={600} sx={{ px: 2 }}>
                        {complaint.status === "improcedente" ? "Observación" : "Clasificación"}
                     </Box>
                     <Box sx={{ px: 2 }}>
                        {isLoading ? (
                           <Skeleton variant="rectangular" />
                        ) : complaint.status === "improcedente" ? (
                           complaint.observations
                        ) : (
                           complaint.clasification || "N/A"
                        )}
                     </Box>
                  </Stack>
               )}
               <Stack direction={"column"} sx={{ minWidth: "180px" }}>
                  <Typography fontWeight={600} sx={{ px: 2 }}>
                     Evidencias
                  </Typography>
                  {isLoading ? (
                     <Box sx={{ px: 2 }}>
                        <Skeleton variant="rectangular" />
                     </Box>
                  ) : (
                     <Box sx={{ display: "flex", px: 2 }}>
                        <Button variant="outlined" onClick={handleClick}>
                           <FindInPageIcon />
                        </Button>
                     </Box>
                  )}
               </Stack>
            </Box>
            <Grid item xs={12} flex={1}>
               <Typography fontWeight={600} sx={{ px: 2 }}>
                  Descripción de la denuncia
               </Typography>
               <Box
                  sx={{
                     px: 2,
                     maxHeight: "calc(5rem + 10vh)",
                     overflowY: "scroll",
                  }}
               >
                  {isLoading ? <Skeleton variant="rectangular" /> : complaint.body}
               </Box>
            </Grid>
         </Grid>
         <EvidencesModal open={openModal} setOpen={setOpenModal} onConfirm={() => {}} />
      </Box>
   );
};
