import axios, { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { GetBranchesById, GetCompaniesByIdList, GetCompanyById } from "./usersBEClient";
import { BranchesInterface } from "../context/complaintsExternContext/complaintContext";

export const base_url =
   window.location.hostname === "test.admin.lecosy.com.mx" ||
   window.location.hostname === "www.test.admin.lecosy.com.mx"
      ? "https://test.server.lecosy.com.mx/cd"
      : process.env.NODE_ENV === "production"
      ? "https://server.lecosy.com.mx/cd"
      : "http://localhost:3030/cd";

const host = base_url;
export const BASE_ENDPOINT = "/api";
const MASTER_ENDPOINT = "/master";
const CLIENT_ENDPOINT = "/client";
const MAIL_ENDPOINT = "/mail";
const COMPLAINTS_ENDPOINT = "/complaints";
const INTERVIEWS_ENDPOINT = "/interviews";
export const SERVICES_ENDPOINT = "/services";
export const MASTERACCESS_ENDPOINT = "/access";

export const handleInvalidRequest = (error) => {
   const details: AxiosError = error as AxiosError;
   if (details.response.data === "expired") {
      console.log("request session expired");
      return null;
   }
   if (details.response.data === "no user") {
      console.log("request session expired");
      return null;
   }
};

export const catchAxiosError = (error) => {
   const errorDetails: AxiosError = error as AxiosError;
   const errorMessage: any = errorDetails.response.data;
   return errorMessage;
};

// ================== BackEnd Requests ==========================
axios.defaults.withCredentials = true;

//#region Extern Complaints Requests

export const getAllComplaints = async () => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}`);
   return data;
};

export const GetInboxComplaints = (branches: BranchesInterface[]) => {
   return useQuery({
      queryKey: ["inboxComplaints", branches],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/inbox`);
         const newData: any[] = [];
         for (const complaint of data)
            if (branches) {
               for (const branch of branches) {
                  if (branch._id.toString() === complaint.companyBranch) {
                     const newBranch = complaint;
                     newBranch.companyBranch = branch.name;
                     newData.push(newBranch);
                  }
               }
            }
         return newData;
      },
   });
};
export const getInboxComplaints = async (branches: BranchesInterface[]) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/inbox`);
   const companies = await GetCompaniesByIdList(data.complaintCompanies);
   const newData: any[] = [];
   for (const complaint of data.complaintsFound) {
      if (complaint.company) {
         const foundCompany = companies.find((company) => company._id === complaint.company);
         if (foundCompany) newData.push({ complaint, foundCompany });
         continue;
      }
      const branch = branches.find((branch) => branch._id === complaint.companyBranch);
      if (branch) newData.push({ complaint, branch });
   }
   return newData;
};

export const GetActiveComplaints = (branches: BranchesInterface[]) => {
   return useQuery({
      queryKey: ["activeInternComplaints", branches],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/active`);
         const companies = await GetCompaniesByIdList(data.complaintCompanies);
         const newData: any[] = [];
         for (const complaint of data.complaintsFound) {
            if (complaint.company) {
               const foundCompany = companies.find((company) => company._id === complaint.company);
               if (foundCompany) newData.push({ complaint, foundCompany });
               continue;
            }
            const branch = branches.find((branch) => branch._id === complaint.companyBranch);
            if (branch) newData.push({ complaint, branch });
         }
         return newData;
      },
   });
};
export const GetRejectedComplaints = (branches: BranchesInterface[]) => {
   return useQuery({
      queryKey: ["internFinishedComplaints", branches],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/rejected`);
         const companies = await GetCompaniesByIdList(data.complaintCompanies);
         const newData: any[] = [];
         for (const complaint of data.complaintsFound) {
            if (complaint.company) {
               const foundCompany = companies.find((company) => company._id === complaint.company);
               if (foundCompany) newData.push({ complaint, foundCompany });
               continue;
            }
            const branch = branches.find((branch) => branch._id === complaint.companyBranch);
            if (branch) newData.push({ complaint, branch });
         }
         return newData;
      },
   });
};
//#endregion

//#region Intern Complaints Requests

export const getInboxInternComplaints = async (branches: BranchesInterface[]) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/intern/inbox`);
      const companies = await GetCompaniesByIdList(data.complaintCompanies);
      const newData: any[] = [];
      for (const complaint of data.complaintsFound) {
         if (complaint.company) {
            const foundCompany = companies.find((company) => company._id === complaint.company);
            if (foundCompany) newData.push({ complaint, foundCompany });
            continue;
         }
         const branch = branches.find((branch) => branch._id === complaint.companyBranch);
         if (branch) newData.push({ complaint, branch });
      }
      return newData;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const GetInboxInternComplaints = (branches: BranchesInterface[]) => {
   return useQuery({
      queryKey: ["internInboxComplaints", branches],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/intern/inbox`
         );
         const companies = await GetCompaniesByIdList(data.complaintCompanies);
         const newData: any[] = [];
         for (const complaint of data.complaintsFound) {
            if (complaint.company) {
               const foundCompany = companies.find((company) => company._id === complaint.company);
               if (foundCompany) newData.push({ complaint, foundCompany });
               continue;
            }
            const branch = branches.find((branch) => branch._id === complaint.companyBranch);
            if (branch) newData.push({ complaint, branch });
         }
         return newData;
      },
   });
};

export const GetInternActiveComplaints = (branches: BranchesInterface[]) => {
   return useQuery({
      queryKey: ["activeInternComplaints", branches],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/intern/active`
         );
         const companies = await GetCompaniesByIdList(data.complaintCompanies);
         const newData: any[] = [];
         for (const complaint of data.complaintsFound) {
            if (complaint.company) {
               const foundCompany = companies.find((company) => company._id === complaint.company);
               if (foundCompany) newData.push({ complaint, foundCompany });
               continue;
            }
            const branch = branches.find((branch) => branch._id === complaint.companyBranch);
            if (branch) newData.push({ complaint, branch });
         }
         return newData;
      },
   });
};
export const GetInternRejectedComplaints = (branches: BranchesInterface[]) => {
   return useQuery({
      queryKey: ["finishedInternComplaints"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/intern/rejected`
         );
         const companies = await GetCompaniesByIdList(data.complaintCompanies);
         const newData: any[] = [];
         for (const complaint of data.complaintsFound) {
            if (complaint.company) {
               const foundCompany = companies.find((company) => company._id === complaint.company);
               if (foundCompany) newData.push({ complaint, foundCompany });
               continue;
            }
            const branch = branches.find((branch) => branch._id === complaint.companyBranch);
            if (branch) newData.push({ complaint, branch });
         }
         return newData;
      },
   });
};
export const GetInternQueryBankComplaints = (branches: BranchesInterface[]) => {
   return useQuery({
      queryKey: ["finishedExternComplaints", branches],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/intern/qbank`
         );
         const companies = await GetCompaniesByIdList(data.complaintCompanies);
         const newData: any[] = [];
         for (const complaint of data.complaintsFound) {
            if (complaint.company) {
               const foundCompany = companies.find((company) => company._id === complaint.company);
               if (foundCompany) newData.push({ complaint, foundCompany });
               continue;
            }
            const branch = branches.find((branch) => branch._id === complaint.companyBranch);
            if (branch) newData.push({ complaint, branch });
         }
         return newData;
      },
   });
};

export const getComplaintsByReportNumber = async (reportNumber: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/review/${reportNumber}`
      );
      if (data.company) {
         const foundCompany = await GetCompanyById(data.company);
         data.companyBranch = { company: foundCompany };
      } else {
         const branch = await GetBranchesById(data.companyBranch);
         const company = await GetCompanyById(branch.company);
         data.companyBranch = { name: branch.name, company: company };
      }
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getComplaintsByBranches = async (branch: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/all/${branch}`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updateProceededComplaintInfo = async (reportNumber: string, clasification: string) => {
   try {
      const body = {
         clasification: clasification,
      };
      axios.defaults.withCredentials = true;
      return await axios.put(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/review/p/${reportNumber}`,
         body
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updateDeclinedComplaintInfo = async (reportNumber: string, observations: string) => {
   try {
      const body = {
         observations: observations,
      };
      axios.defaults.withCredentials = true;
      return await axios.put(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/review/d/${reportNumber}`,
         body
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUrlForEvidencesFile = async (reportNumber: string, fileKey: string, subjectId: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/evidences/${reportNumber}/${fileKey}/${subjectId}`
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const DocumentationFile = async (reportNumber: string, fileKey: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/documentation/${reportNumber}/${fileKey}`
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const DocumentationUpload = async (fileKey: string, reportNumber: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/documentation/u/${reportNumber}/${fileKey}`
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const complaintUploadDocumentation = async (signedURL, file) => {
   try {
      const config = {
         headers: {
            "Content-Type": `${file.type}`,
         },
      };
      axios.defaults.withCredentials = false;
      await axios.put(signedURL, file, config);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getComplaintStateTracking = async (reportNumber) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/tracking/${reportNumber}`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updateComplaintStateTracking = async (reportNumber, stateTracking, processState) => {
   try {
      const body = {
         stateTracking,
         processState,
      };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/tracking/update/${reportNumber}`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getInterviewDate = async (reportNumber, involved) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INTERVIEWS_ENDPOINT}/${reportNumber}/${involved}`
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getInterviewDateByDate = async (reportNumber, involved, date) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INTERVIEWS_ENDPOINT}/${reportNumber}/${involved}/${date}`
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const createInterview = async (reportNumber, involved, date, place, email, dd, mailSpeech) => {
   try {
      const body = {
         email,
         date,
         place,
         dd,
         mailSpeech,
      };
      axios.defaults.withCredentials = true;
      return await axios.post(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INTERVIEWS_ENDPOINT}/${reportNumber}/${involved}/new`,
         body
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updateComplaintStatus = async (
   reportNumber: string,
   status: string,
   dia: string | number,
   mes: string | number,
   ano: string | number,
   horas: string | number,
   company: string,
   email: string
) => {
   try {
      const payload = {
         status,
         dia,
         mes,
         ano,
         horas,
         company,
         email,
      };
      axios.defaults.withCredentials = true;
      return await axios.put(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/modify/status/${reportNumber}`,
         payload
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const changeIncomingComplaint = async (reportNumber) => {
   axios.defaults.withCredentials = true;
   return axios.put(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/modify/incoming/${reportNumber}`,
      {}
   );
};

export const addResolution = async (reportNumber, measures, resolution) => {
   const payload = {
      measures,
      resolution,
   };
   axios.defaults.withCredentials = true;
   return axios.put(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/resolution/${reportNumber}`,
      payload
   );
};

export const sendFairnessEmail = async (status, email, reportNumber, pdfBuffer) => {
   const body = {
      status: status,
      email: email,
      reportNumber: reportNumber,
      pdfBuffer: pdfBuffer,
   };
   return axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${MAIL_ENDPOINT}/send/fairness`, body);
};

export const sendSupportTicketMail = async (emailTo: string) => {
   axios.defaults.withCredentials = true;
   const body = {
      emailTo: emailTo,
   };
   return axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${MAIL_ENDPOINT}/send/support/ticket`, body);
};

export const sendResolutionNotificationEmail = async (
   reportNumber: string,
   complainerEmail: string,
   offendersEmails: string[],
   pdfBuffer: any
) => {
   try {
      const body = {
         reportNumber,
         complainerEmail,
         offendersEmails,
         pdfBuffer,
      };
      return await axios.post(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${MAIL_ENDPOINT}/send/notification/resolution`,
         body
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const createWitness = async (reportNumber: string, witnessData: any) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.post(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/witness/${reportNumber}`,
         witnessData
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const addAditionalInfo = async (subjectId: string, information: any) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.put(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/ai/${subjectId}`,
         information
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const reassignCompetence = async (reportNumber, involved, competentName, involvedName) => {
   try {
      const body = {
         competentName,
         involvedName,
      };
      axios.defaults.withCredentials = true;
      return await axios.post(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/${reportNumber}/incompetence/${involved}`,
         body
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const deleteDocument = async (fileKey: string, reportNumber: string) => {
   axios.defaults.withCredentials = true;

   return await axios.delete(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/document/d/${reportNumber}/${fileKey}`
   );
};
