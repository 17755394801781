import * as yup from "yup";
import { freeTextRegex, textOnlyRegex, textandNumbersOnlyRegex } from "../../const/globalConst";

export const expression = {
   onlylowercase: /(^[a-z]+-?[a-z]+$)/, // Only letters
   password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*-_])(?=.{8,})/, // mínimo 8, máximo 15, al menos una mayuscula y una minuscula, al menos un dígito, no espacios en blanco, al menos un carácter especial
   onlyNumbers: /^\d+$/,
   numbersDecimal: /^\d+(\.\d+)?$/,
   rfc: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
   textOnly: /^[a-zA-ZáéíóúÁÉÍÓÚñ\s]*$/,
   textandNumbersOnly: /^[a-zA-Z0-9áéíóúÁÉÍÓÚñ\s]*$/,
   noSpaces: /^\S+$/,
   // eslint-disable-next-line
   URL: /^((https?|ftp):\/\/)(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
};

// Common usage =================================
export const textValidation = yup
   .string()
   .min(2, "Se requiere un mínimo de 2 caracteres.")
   .max(50, "Se requiere un máximo de 50 caracteres.")
   .required("Este campo es obligatorio");

const passWordValidation = yup
   .string()
   // .matches(expression.password, "Formato inválido")
   .min(6, "Se requiere de un mínimo de 6 caracteres")
   .max(50, "Se requiere de un máximo de 50 caracteres")
   .required("Este campo es obligatorio");

const phoneNumberValidation = yup
   .string()
   .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
   .min(10, "Se requiere exactamente 10 números.")
   .max(10, "Se requiere exactamente 10 números.")
   .required("Este campo es obligatorio");

const phoneNumberValidationNotRequired = yup
   .string()
   .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
   .min(10, "Se requiere exactamente 10 números.")
   .max(10, "Se requiere exactamente 10 números.");

const emailValidation = yup
   .string()
   .min(3, "Se requiere un mínimo de 3 caracteres.")
   .max(50, "Se requiere un máximo de 50 caracteres.")
   .required("Este campo es obligatorio")
   .email("Correo electrónico inválido");

const emailValidationNotRequired = yup
   .string()
   .min(3, "Se requiere un mínimo de 3 caracteres.")
   .max(50, "Se requiere un máximo de 50 caracteres.")
   .email("Correo electrónico inválido");

const zipcodeValidation = yup
   .string()
   .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
   .min(5, "C.P inválido")
   .max(5, "C.P inválido")
   .required("Este campo es obligatorio");

const dependenceValidation = (dependenceName: string) => {
   return yup.string().when(dependenceName, {
      is: "Si",
      then: longTextValidation,
      otherwise: yup.string(),
   });
};

const dependenceRadioValidation = (dependenceName: string) => {
   return yup.string().when(dependenceName, {
      is: "Si",
      then: yup.string().required("Este campo es requerido"),
      otherwise: yup.string(),
   });
};

export const longTextValidation = yup
   .string()
   .min(2, "Se requiere un mínimo de 2 caracteres.")
   .max(1000, "Se requiere un máximo de 1000 caracteres.")
   .required("Este campo es obligatorio");

export const mediumTextValidation = yup
   .string()
   .min(2, "Se requiere un mínimo de 2 caracteres.")
   .max(200, "Se requiere un máximo de 200 caracteres.")
   .required("Este campo es obligatorio");

// ================================================

export const clientType = yup.object().shape({
   type: yup.string().required("Este campo es obligatorio"),
});

export const addInvoiceSchema = yup.object().shape({
   name: textValidation,
   start: yup.string().required("Este campo es obligatorio"),
   end: yup.string().required("Este campo es obligatorio"),
});

export const addInvoiceDetailsSchema = yup.object().shape({
   denomination: mediumTextValidation,
   rfc: textValidation.matches(expression.rfc, "Ingrese un RFC valido"),
   email: emailValidation,
   city: textValidation,
   state: textValidation,
   address: textValidation,
   suburb: textValidation,
   phone: phoneNumberValidation,
   zipCode: zipcodeValidation,
   regime: textValidation,
   amount: textValidation.matches(expression.numbersDecimal, "Favor de introducir únicamente números."),
});

export const createUserSchema = yup.object().shape({
   firstName: textValidation,
   lastName: textValidation,
   email: emailValidation,
   phone: phoneNumberValidation,
});

export const createNewUserSchema = yup.object().shape({
   firstName: textValidation,
   lastName: textValidation,
   email: emailValidation,
});

export const addCompanyBranchSchema = yup.object().shape({
   name: textValidation,
   address: yup.string().required("Este campo es obligatorio"),
   state: yup.string().required("Este campo es obligatorio"),
   city: yup.string().required("Este campo es obligatorio"),
   zipCode: zipcodeValidation,
   phoneNumber: phoneNumberValidation,
});

export const modifyCompanySchema = yup.object().shape({
   name: textValidation,
   field: yup.string().required("Este campo es obligatorio"),
   platformInfoSubName: yup
      .string()
      .lowercase("Solo se permiten minúsculas")
      .required("Este campo es obligatorio")
      .matches(expression.noSpaces, "No se permiten espacios")
      .strict(),
   platformInfoMessage: yup.string().max(500, "Máximo 500 caracteres").required("Este campo es obligatorio"),
});

export const platformInfoSchema = yup.object().shape({
   subName: textValidation,
   message: longTextValidation,
});

export const addCompanySchema = yup.object().shape({
   name: textValidation,
   field: yup.string().required("Este campo es obligatorio"),
   branchName: textValidation,
   branchAddress: yup.string().required("Este campo es obligatorio"),
   branchState: yup.string().required("Este campo es obligatorio"),
   branchCity: yup.string().required("Este campo es obligatorio"),
   branchZipCode: zipcodeValidation,
   branchPhoneNumber: phoneNumberValidation,
   platformInfoSubName: yup
      .string()
      .lowercase("Solo se permiten minúsculas")
      .required("Este campo es obligatorio")
      .matches(expression.noSpaces, "No se permiten espacios")
      .strict(),
   platformInfoMessage: yup.string().max(500, "Máximo 500 caracteres").required("Este campo es obligatorio"),
});

export const modifyUserSchema = yup.object().shape({
   userName: textValidation,
   userLastName: textValidation,
   email: emailValidation,
});

export const companyLegalSchema = yup.object().shape({
   businessName: mediumTextValidation,
   comercialName: textValidation,
   activity: mediumTextValidation,
});
export const groupSchema = yup.object().shape({
   name: textValidation,
   phoneNumber: phoneNumberValidationNotRequired,
});

export const userChangePasswordSchema = yup.object().shape({
   passwordOriginal: passWordValidation.test(
      "match",
      "Las contraseñas no deben ser iguales", // your error message
      function () {
         return this.parent.passwordOriginal !== this.parent.passwordNew;
      }
   ),
   passwordNew: passWordValidation,
   passwordNewConfirmation: yup
      .string()
      .oneOf([yup.ref("passwordNew"), null], "Las contraseñas no coinciden.")
      .required("Este campo es obligatorio"),
});

export const userCreateSchema = yup.object().shape({
   firstName: textValidation,
   lastName: textValidation,
   email: emailValidation,
   password: passWordValidation,
   passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden.")
      .required("Este campo es obligatorio"),
   type: yup
      .string()
      .oneOf(["superadmin", "gestor de denuncias", "coordinador administrativo"], "Este rol no existe")
      .required("Este campo es obligatorio"),
});
export const masterUserModifyeSchema = yup.object().shape({
   firstName: textValidation,
   lastName: textValidation,
   email: emailValidation,
});
export const userModifyeSchema = yup.object().shape({
   firstName: textValidation,
   lastName: textValidation,
   email: emailValidation,
   phoneNumber: phoneNumberValidation,
});

export const userLoginSchema = yup.object().shape({
   email: emailValidation,
   password: passWordValidation,
});

export const userInternModifySchema = yup.object().shape({
   firstName: textValidation,
   lastName: textValidation,
});

export const userInternModifyEmailSchema = yup.object().shape({
   email: emailValidation,
});

export const incompetenceSchema = (role: boolean) => {
   return yup.object().shape({
      state: textValidation,
      city: textValidation,
      date: yup.string().required("Este campo es obligatorio"),
      competentWork: role ? textValidation : yup.string(),
      competentName: role ? textValidation : yup.string(),
      involvedName: textValidation,
   });
};

export const notificationsSchema = (type: boolean) => {
   return yup.object().shape({
      name: textValidation,
      organization: textValidation,
      state: textValidation,
      city: textValidation,
      interviewPlace: yup
         .string()
         .min(2, "Se requiere un mínimo de 2 caracteres.")
         .max(200, "Se requiere un máximo de 200 caracteres.")
         .required("Este campo es obligatorio"),
      email: type ? emailValidation : yup.string(),
      emailTo: type
         ? yup
              .string()
              .min(3, "Se requiere un mínimo de 3 caracteres.")
              .max(50, "Se requiere un máximo de 50 caracteres.")
              .required("Este campo es obligatorio")
              .email("Formato inválido")
         : yup.string(),
      dateTime: yup.string().required("Este campo es obligatorio"),
      // issue: type ? textValidation : yup.string(),
      extract: type ? longTextValidation : yup.string(),
   });
};

export const resolutionNotificationSchema = (measures: boolean) => {
   return yup.object().shape({
      city: textValidation,
      state: textValidation,
      measures: measures ? longTextValidation : yup.string(),
      dateTime: textValidation,
      // complainerEmail: emailValidation,
      complainerEmail: emailValidationNotRequired,
      offenderEmail: yup.array().of(emailValidationNotRequired),
   });
};

export const witnessInterviewSchema = yup.object().shape({
   online: yup.string().required("Este campo es requerido."),
   witnessName: textValidation,
   worksInPlace: yup.string().required("Este campo es requerido"),
   witnessActivity: dependenceValidation("worksInPlace"),
   witnessTime: dependenceValidation("worksInPlace"),
   witnessKnowledge: textValidation,
   presentInComplaint: yup.string().required("Este campo es requerido"),
   trueComplaint: yup.string().required("Este campo es requerido"),
   knowsPeople: dependenceRadioValidation("trueComplaint"),
   witnessEvidence: yup.string().required("Este campo es requerido"),
   witnessHasEvidence: dependenceRadioValidation("witnessEvidence"),
   witnessPeople: dependenceRadioValidation("knowsPeople"),
   perceptionVariation: yup.string().required("Este campo es requerido"),
   witnessNarration: dependenceRadioValidation("perceptionVariation"),
   witnessDateActivities: longTextValidation,
   witnessAddWitnessNames: dependenceValidation("witnessOfWitness"),
   witnessOfWitness: yup.string().required("Este campo es requerido"),
   witnessAddInfo: longTextValidation,
   modifyActions: longTextValidation,
   witnessTruth: yup.string().required("Este campo es requerido"),
});

export const denouncedInterviewSchema = yup.object().shape({
   online: yup.string().required("Este campo es requerido."),
   denouncedName: textValidation,
   denouncedActivity: textValidation,
   denouncedTime: textValidation,
   denouncedRecognition: yup.string().required("Este campo es requerido"),
   denouncedFacts: yup.string().required("Este campo es requerido"),
   denouncedFactsAnswer: dependenceValidation("denouncedFacts"),
   denouncedResponsible: yup.string().required("Este campo es requerido"),
   denouncedPersonName: dependenceRadioValidation("denouncedResponsible"),
   denouncedPersonNameAnswer: dependenceValidation("denouncedPersonName"),
   denouncedDayActivities: textValidation,
   denouncedWitness: yup.string().required("Este campo es requerido"),
   denouncedWitnessAnswer: dependenceValidation("denouncedWitness"),
   denouncedFundamentals: yup.string().required("Este campo es requerido"),
   denouncedFundamentalsAnswer: dependenceValidation("denouncedFundamentals"),
   denouncedCategoryPartake: yup.string().required("Este campo es requerido"),
   denouncedNarration: dependenceValidation("denouncedCategoryPartake"),
   denouncedTruth: yup.string().required("Este campo es requerido"),
});

export const complainantInterviewSchema = yup.object().shape({
   online: yup.string().required("Este campo es requerido."),
   complainantName: textValidation,
   complainantLabors: yup.string().required("Este campo es requerido"),
   complainantActivity: dependenceValidation("complainantLabors"),
   complainantTime: dependenceValidation("complainantLabors"),
   complainantExplanation: yup.string().when("complainantLabors", {
      is: "No",
      then: longTextValidation,
      otherwise: yup.string(),
   }),
   complainantIsComplainter: yup.string().required("Este campo es requerido"),
   complainantInvolved: yup.string().required("Este campo es requerido"),
   complainantInvolvedNarration: dependenceRadioValidation("complainantInvolved"),
   complainantConfirmation: yup.string().required("Este campo es requerido"),
   complainantWitness: yup.string().required("Este campo es requerido"),
   complainantWitnessAnswer: dependenceValidation("complainantWitness"),
   complainantMainVictim: yup.string().required("Este campo es requerido"),
   complainantEvidence: yup.string().required("Este campo es requerido"),
   complainantGivesEvidence: dependenceRadioValidation("complainantEvidence"),
   complainantMeasures: longTextValidation,
   complainantFixDamage: longTextValidation,
   complainantTruth: yup.string().required("Este campo es requerido"),
});

export const resolutionScreenSchema = yup.object().shape({
   complainantStatedFacts: longTextValidation,
   organization: textValidation,
   address: textValidation,
   complainantEvidence: longTextValidation,
   clasificationComplaint: longTextValidation,
   fundamentals: longTextValidation,
   measures: longTextValidation,
   dateTime: yup.string().required("Este campo es obligatorio"),
   // queryBank: yup.boolean().default(false),
});

export const sugestionResolutionSchema = yup.object().shape({
   organization: textValidation,
   address: textValidation,
   measures: longTextValidation,
   dateTime: yup.string().required("Este campo es obligatorio"),
});

export const complaintResolutionScreenSchema = yup.object().shape({
   offenderFacts: longTextValidation,
   organization: textValidation,
   address: textValidation,
   offenderEvidence: longTextValidation,
   clasificationComplaint: longTextValidation,
   fundamentals: longTextValidation,
   measures: longTextValidation,
   queryBank: yup.boolean().default(false),
   dateTime: yup.string().required("Este campo es obligatorio"),
});

export const SugestionResolutionScreenSchema = yup.object().shape({
   organization: textValidation,
   address: textValidation,
   measures: longTextValidation,
   queryBank: yup.boolean().default(false),
   dateTime: yup.string().required("Este campo es obligatorio"),
});

export const recordClousureSchema = yup.object().shape({
   investigationProcess: longTextValidation,
   measures: longTextValidation,
   evidences: yup
      .string()
      .min(2, "Se requiere un mínimo de 2 caracteres.")
      .max(500, "Se requiere un máximo de 500 caracteres.")
      .optional(),
   // resolution: longTextValidation,
});

export const recordSuggestionClousureSchema = yup.object().shape({
   measures: longTextValidation,
   evidences: yup
      .string()
      .min(2, "Se requiere un mínimo de 2 caracteres.")
      .max(500, "Se requiere un máximo de 500 caracteres.")
      .optional(),
   // resolution: longTextValidation,
});

export const absenseConstancySchema = yup.object().shape({
   city: textValidation,
   state: textValidation,
   organization: textValidation,
   involvedName: textValidation,
});

export const witnessInfractorSchema = yup.object().shape(
   {
      name: yup.string().when("name", (val, schema) => {
         if (val?.length > 0) {
            return yup
               .string()
               .matches(textandNumbersOnlyRegex, "Formato inválido")
               .max(50, "Máximo 50 caracteres")
               .required("Campo obligatorio");
         } else {
            return yup.string().optional();
         }
      }),
      lastName: yup.string().when("name", (val, schema) => {
         if (val?.length > 0) {
            return yup
               .string()
               .matches(textandNumbersOnlyRegex, "Formato inválido")
               .max(50, "Máximo 50 caracteres")
               .required("Campo obligatorio");
         } else {
            return yup.string().optional();
         }
      }),
      gender: yup.string().matches(textOnlyRegex, "Formato inválido").required("Campo obligatorio"),
      position: yup.string().when("position", (val, schema) => {
         if (val?.length > 0) {
            return yup
               .string()
               .matches(freeTextRegex, "Formato inválido")
               .min(3, "Mínimo 3 caracteres")
               .max(50, "Máximo 50 caracteres")
               .required("Campo obligatorio");
         } else {
            return yup.string().optional();
         }
      }),
      observations: yup
         .string()
         .min(5, "Mínimo 5 caracteres")
         .max(500, "Máximo 500 caracteres")
         .notRequired()
         .when(["name", "lastName"], (val, schema) => {
            if (val?.length > 0) {
               return yup.string().optional();
            } else {
               return (
                  yup
                     .string()
                     // .matches(textOnlyRegex, "Formato inválido")
                     .max(500, "Máximo 500 caracteres")
                     .required("Campo obligatorio")
               );
            }
         }),
   },
   [
      ["name", "name"],
      ["lastName", "lastName"],
      ["position", "position"],
   ]
);
export const aditionalInformationSchema = yup.object().shape({
   email: emailValidation,
   phoneNumber: phoneNumberValidation,
});

export const supportSchema = (supportType: string) => {
   return yup.object().shape({
      title: yup
         .string()
         .min(3, "Mínimo de tres caracteres")
         .max(50, "Máximo de caracteres")
         .required("Este campo es obligatorio"),
      description:
         supportType === "Haz una pregunta"
            ? yup.string().min(3, "Mínimo de tres caracteres").max(1000, "Máximo de caracteres")
            : yup
                 .string()
                 .min(3, "Mínimo de tres caracteres")
                 .max(1000, "Máximo de caracteres")
                 .required("Este campo es obligatorio"),
      email: supportType === "Sugiere una mejora" ? emailValidationNotRequired : emailValidation,
      type: yup.string().required("Este campo es obligatorio"),
   });
};

export const subscriptionExpitesAtSchema = yup.object().shape({
   date: yup.date().required("Este campo es obligatorio"),
});

export const proceedComplaintReviewSchema = yup.object().shape({
   calification: yup.string().required("Este campo es obligatorio"),
   observations: yup
      .string()
      .when("calification", { is: "Improcedente", then: yup.string().required("Este campo es obligatorio") }),
   clasification: yup
      .string()
      .when("calification", { is: "Procedente", then: yup.string().required("Este campo es obligatorio") }),
   city: yup.string().required("Este campo es obligatorio"),
   state: yup.string().required("Este campo es obligatorio"),
});

export const proceedNiggleSugestionReviewSchema = yup.object().shape({
   calification: yup.string().required("Este campo es obligatorio"),
   observations: yup
      .string()
      .when("calification", { is: "Improcedente", then: yup.string().required("Este campo es obligatorio") }),
   city: yup.string().required("Este campo es obligatorio"),
   state: yup.string().required("Este campo es obligatorio"),
});

export const createRolSchema = yup.object().shape({
   name: textValidation,
   description: longTextValidation,
   /* access: yup.string().required("Este campo es obligatorio"), */
});

export const modifyService = yup.object().shape({
   subName: yup.string().required("Este campo es obligatorio"),
   message: yup.string().required("Este campo es obligatorio"),
   color: yup.string().required("Este campo es obligatorio"),
});

export const modifyInvoice = yup.object().shape({
   name: yup.string().required("Este campo es obligatorio"),
   startPeriod: yup.date().required("Este campo es obligatorio"),
   endPeriod: yup.date().required("Este campo es obligatorio"),
});

export const modifyReceipt = yup.object().shape({
   name: yup.string().required("Este campo es obligatorio"),
   paymentDate: yup.date().required("Este campo es obligatorio"),
});

export const uploadReceipt = yup.object().shape({
   name: yup.string().required("Este campo es obligatorio"),
   paymentPeriod: yup.string().required("Este campo es obligatorio"),
   paymentDate: yup.date().required("Este campo es obligatorio"),
});

export const uploadInvoice = yup.object().shape({
   name: yup.string().required("Este campo es obligatorio"),
   startPeriod: yup.date().required("Este campo es obligatorio"),
   endPeriod: yup.date().required("Este campo es obligatorio"),
});
