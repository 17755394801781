import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

interface InputLoadingButtonProps {
   disabled?: boolean;
   label: string;
   isLoading: boolean;
   icon?: React.ReactNode;
   onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
   fullWidth?: boolean;
   size?: string;
   type?: string;
   sx?: any;
}

export const LoadingButton = (props: InputLoadingButtonProps) => {
   return (
      <>
         <Button
            sx={{
               ...props.sx,
               bgcolor: props.sx?.bgcolor || "#142c44",
               mb: 2,
               "&:hover": {
                  boxShadow: props.sx?.hoverBgColor ? 0 : "inherit",
                  bgcolor: props.sx?.hoverBgColor || "#142c4490",
               },
            }}
            fullWidth={props.fullWidth}
            type="submit"
            variant="contained"
            disabled={props.isLoading || props.disabled}
            onClick={props.onClick}
            style={{ textTransform: "none" }}
         >
            {props.isLoading ? (
               <SaveIcon />
            ) : (
               <>
                  {props.icon}
                  {props.label}
               </>
            )}
            {props.isLoading && <CircularProgress size={24} sx={{ color: "#162c44" }} />}
         </Button>
      </>
   );
};
