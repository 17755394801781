import { useState } from "react";
import { CustomModalComponent } from "../../../../../CustomModalComponent";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { TabPanelProps } from "../../../../../ClientInformation/ClientInfoTabs";
import CompanyImplementationUserComponent from "../CompanyImplementationUserComponent";
import CompanyCoordinatorUserComponent from "../CompanyCoordinatorUserComponent";
import GroupCoordinatorComponent from "../../../../../ClientInformation/Group/ConsultiveGroup/GovernanceCoordination/GroupCoordinatorComponent";
import GroupImplementationComponent from "../../../../../ClientInformation/Group/ConsultiveGroup/GovernanceCoordination/GroupImplementationComponent";

function CustomTabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ px: 2 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

const GovernanceCoordination = (props: { group?: boolean }) => {
   const [openModal, setOpenModal] = useState(false);
   const [value, setValue] = useState(0);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   return (
      <>
         <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography fontWeight={600} sx={{ px: 1 }}>
               Coordinación de gobierno corporativo
            </Typography>
            <Button
               variant="contained"
               onClick={() => {
                  setOpenModal(true);
               }}
            >
               Abrir
            </Button>
         </Box>
         <CustomModalComponent open={openModal} setOpen={setOpenModal} title="Coordinación de gobierno corporativo">
            <Box sx={{ minWidth: 800 }}>
               <Tabs value={value} onChange={handleChange} centered>
                  <Tab label="Usuario de implementación" sx={{ flex: 1, p: 2.5 }} />
                  <Tab label="Coordinador de gobierno corporativo" sx={{ flex: 1, p: 2.5 }} />
               </Tabs>
               <CustomTabPanel value={value} index={0}>
                  {props.group ? <GroupImplementationComponent /> : <CompanyImplementationUserComponent />}
               </CustomTabPanel>
               <CustomTabPanel value={value} index={1}>
                  {props.group ? <GroupCoordinatorComponent /> : <CompanyCoordinatorUserComponent />}
               </CustomTabPanel>
            </Box>
         </CustomModalComponent>
      </>
   );
};

export default GovernanceCoordination;
