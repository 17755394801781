import InfoIcon from "@mui/icons-material/Info";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import {
   Box,
   Button,
   Grid,
   List,
   ListItem,
   ListItemText,
   ListItemIcon,
   Checkbox,
   Typography,
   Tooltip,
   IconButton,
   Stack,
} from "@mui/material";
import { ConfirmationModal } from "./ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export interface TransferListItem {
   _id: string;
   name: string;
   description: string;
}

interface RoleTransferListProps {
   title1: string;
   title2: string;
   leftlist: TransferListItem[];
   rightlist: TransferListItem[];
   setLeftlist: Function;
   setRightlist: Function;
   onSaveRightList: (rightlist: string[]) => void;
}

function not(a: readonly string[], b: readonly string[]) {
   return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]) {
   return a.filter((value) => b.indexOf(value) !== -1);
}
export const RoleTransferList = (props: RoleTransferListProps) => {
   const [checked, setChecked] = useState<readonly string[]>([]);
   const [left, setLeft] = useState<readonly string[]>(props.leftlist.map((value, index) => value._id));
   const [right, setRight] = useState<string[]>(props.rightlist.map((value, index) => value._id));
   const numberToTextMap: Record<number, string> = {};
   const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
   const navigate = useNavigate();

   props.leftlist.forEach((item, index) => {
      numberToTextMap[index] = item.name;
   });
   props.rightlist.forEach((item, index) => {
      numberToTextMap[props.leftlist.length + index] = item.name;
   });

   const numberToTextMapForDescription: Record<number, string> = {};
   props.leftlist.forEach((item, index) => {
      numberToTextMapForDescription[index] = item.description;
   });
   props.rightlist.forEach((item, index) => {
      numberToTextMapForDescription[props.leftlist.length + index] = item.description;
   });

   const leftChecked = intersection(checked, left);
   const rightChecked = intersection(checked, right);

   const handleToggle = (value: string) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
         newChecked.push(value);
      } else {
         newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
   };

   const handleCheckedRight = () => {
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));

      const newList: TransferListItem[] = [];
      for (const iterator of leftChecked) {
         newList.push(props.leftlist.filter((item) => item._id === iterator)[0]);
      }

      let newListLeft: TransferListItem[] = [];
      for (const iterator of leftChecked) {
         newListLeft = props.leftlist.filter((item) => item._id !== iterator);
      }
      props.setRightlist(props.rightlist.concat(newList));
      props.setLeftlist(newListLeft);
      props.onSaveRightList(right.concat(leftChecked));
   };

   const handleCheckedLeft = () => {
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));

      const newList: TransferListItem[] = [];
      for (const iterator of rightChecked) {
         newList.push(props.rightlist.filter((item) => item._id === iterator)[0]);
      }

      let newListLeft: TransferListItem[] = [];
      for (const iterator of rightChecked) {
         newListLeft = props.rightlist.filter((item) => item._id !== iterator);
      }

      let newIdList = right;
      for (const iterator of rightChecked) {
         newIdList = newIdList.filter((item) => item !== iterator);
      }

      props.setRightlist(newListLeft);
      props.setLeftlist(props.leftlist.concat(newList));
      props.onSaveRightList(newIdList);
   };

   const customList = (title: React.ReactNode, items: readonly string[], isLeftList: boolean) => {
      return (
         <Box sx={{ border: "1px solid #B8C5D0", borderRadius: "10px" }}>
            <Typography variant="h6" color={"#3F5063"} fontWeight={500} sx={{ px: 3, py: 2 }}>
               {title}
            </Typography>
            <List
               sx={{
                  width: "auto",
                  height: 230,
                  overflow: "auto",
                  "::-webkit-scrollbar": {
                     display: "none",
                  },
                  px: 2,
               }}
               dense
               component="div"
               role="list"
            >
               {items.map((value: string) => {
                  const labelId = `transfer-list-all-item-${value}-label`;
                  return (
                     <ListItem
                        key={value}
                        sx={{ bgcolor: "#E4E6E88C", borderRadius: "10px", my: "10px" }}
                        role="listitem"
                        button
                        onClick={handleToggle(value)}
                     >
                        <ListItemIcon>
                           <Checkbox
                              checked={checked.indexOf(value) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{
                                 "aria-labelledby": labelId,
                              }}
                           />
                        </ListItemIcon>
                        <ListItemText
                           id={labelId}
                           primary={
                              isLeftList
                                 ? props.leftlist.filter((item) => item._id === value)[0]["name"]
                                 : props.rightlist.filter((item) => item._id === value)[0]["name"]
                           }
                        />
                        <Tooltip
                           title={
                              isLeftList
                                 ? props.leftlist
                                      .filter((item) => item._id === value)[0]
                                      ["description"].substring(0, 100)
                                 : props.rightlist
                                      .filter((item) => item._id === value)[0]
                                      ["description"].substring(0, 100)
                           }
                           placement="right-start"
                        >
                           <IconButton sx={{ visibility: isLeftList ? "visible" : "hidden" }}>
                              <InfoIcon />
                           </IconButton>
                        </Tooltip>
                     </ListItem>
                  );
               })}
            </List>
         </Box>
      );
   };

   return (
      <Box>
         <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item sx={{ minWidth: "40%" }}>
               {customList(props.title1, left, true)}
               <Stack sx={{ alignItems: "flex-end" }}>
                  <Button
                     variant="contained"
                     sx={{ width: "150px", mt: 2 }}
                     onClick={() => {
                        setOpenConfirmation(true);
                     }}
                  >
                     Crear rol
                  </Button>
               </Stack>
            </Grid>
            <Grid item>
               <Grid container direction="column">
                  <Button
                     sx={{ my: 0.5, border: "none" }}
                     size="small"
                     onClick={handleCheckedRight}
                     disabled={leftChecked.length === 0}
                     aria-label="move selected right"
                  >
                     <EastOutlinedIcon />
                  </Button>

                  <Button
                     sx={{ my: 0.5, border: "none" }}
                     size="small"
                     onClick={handleCheckedLeft}
                     disabled={rightChecked.length === 0}
                     aria-label="move selected left"
                  >
                     <WestOutlinedIcon />
                  </Button>
               </Grid>
            </Grid>
            <Grid item sx={{ minWidth: "40%", mb: 6 }}>
               {customList(props.title2, right, false)}
            </Grid>
         </Grid>
         <ConfirmationModal
            title="Confirmación"
            body={
               <div>
                  <Typography sx={{ fontSize: "14px" }}>¿Esta seguro que deseas continuar?</Typography>
                  <br />
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                     Una vez confirmado, la información registrada no podrá ser recuperada
                  </Typography>
               </div>
            }
            onConfirm={() => {
               navigate("crear-rol");
            }}
            open={openConfirmation}
            setOpen={setOpenConfirmation}
         />
      </Box>
   );
};
