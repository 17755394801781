import { Box, Stack, Typography } from "@mui/material";

export const NotFoundScreen = () => {
   return (
      <Box
         display="flex"
         sx={{
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
            justifyContent: "center",
         }}
      >
         <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
            <Box
               component={"img"}
               src={"https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png"}
               width={200}
            />
            <Box display={"flex"} sx={{ width: "100%", height: "100%", justifyContent: "center" }}>
               <Box display={"flex"} sx={{ m: 4, flexDirection: "column", alignItems: "center" }}>
                  <Typography fontWeight={700} fontSize={120} color="#2d4357" variant="h1">
                     404
                  </Typography>
                  <Typography color="#2d4357" variant="h4" sx={{ textAlign: "center" }}>
                     ¡No hemos encontrado esta ruta en lecosy!
                  </Typography>
                  <Typography color="#2d4357" variant="h5" sx={{ mt: 2, textAlign: "center" }}>
                     Verifique que la ruta sea correcta
                  </Typography>
               </Box>
            </Box>
         </Stack>
      </Box>
   );
};
