import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
   Box,
   Button,
   Grid,
   Typography,
   InputAdornment,
   MenuItem,
   Divider,
   ListItemText,
   Container,
   CircularProgress,
} from "@mui/material";
import { getDateAndTimeFromISODate, getDateFromISODate, toISOString } from "../../../../const/globalConst";
import { TitleAppBar } from "../../../../components/TitleAppBar";
import MapIcon from "@mui/icons-material/Map";
import { incompetenceDeclaration } from "../../../../lib/DocmunetsTemplates/AcknowledgmentTemplates";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { incompetenceSchema } from "../../../../lib/Validations/inputSchemas";
import { InputTextField } from "../../../../components/Inputs/InputTextField";
import { InputSelectField } from "../../../../components/Inputs/InputSelectField";
import { ComplaintStepperContext } from "../../../../context/complaintStepperContext";
import pdfMake from "../../../../const/pdfMakeFonts";
import { reassignCompetence } from "../../../../lib/lecosyBackendClient";
import { SnackBarContext } from "../../../../context/snackBarContext";
import GetMasterUsers from "../../../../hooks/GetAllMasterUsers";
import { UserContext } from "../../../../context/UserContext";

export const IncompetenceScreen = () => {
   const { reportNumber, involved } = useParams();
   const { complaint } = useContext(ComplaintStepperContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const date = new Date();
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Usuarios");
   const navigate = useNavigate();

   const { data: users, isLoading } = GetMasterUsers();

   useEffect(() => {
      if (access) {
         navigate("/inicio");
      }
      // eslint-disable-next-line
   }, []);

   const getComplaintManagersUsers = (competent: boolean, field?: string) => {
      const complaintManagersUsers = users.filter((user: any) => {
         return competent
            ? user.role.some((role) => role.name === "superadmin" || role.name === "gestor de denuncias")
            : user.email !== field &&
                 user.role.some((role) => role.name === "superadmin" || role.name === "gestor de denuncias");
      });
      return complaintManagersUsers;
   };

   const FormObserver: React.FC = () => {
      const { values, setFieldValue } = useFormikContext();

      useEffect(() => {
         const valuess: any = values;
         if (valuess.competentName !== "" && involved === "1") {
            setFieldValue("competentWork", getUserRoleByEmail(valuess.competentName));
         }
         // eslint-disable-next-line
      }, [values]);

      return null;
   };

   const getUserNameByEmail = (id: string) => {
      let userName = "";
      for (const user of users) if (user._id === id) userName = `${user.firstName} ${user.lastName}`;

      return userName;
   };

   const getUserRoleByEmail = (id: string) => {
      const userFound: any = users.find((user: any) => id === user._id);
      let roles = "";
      for (const role of userFound.role) {
         if (roles === "") roles = roles.concat(role.name);
         else roles = roles.concat(`, ${role.name}`);
      }
      return roles;
   };

   const handleSendForm = async (
      values: {
         competentName: string;
         involvedName: string;
         city: string;
         state: string;
         date: string;
         competentWork: string;
      },
      actions: { resetForm: () => void }
   ) => {
      showSnackBar("Datos correctos", false);
      await reassignCompetence(reportNumber, involved, values.competentName, values.involvedName);
      if (involved) {
         const dd = incompetenceDeclaration(
            values.city,
            values.state,
            values.date,
            getUserNameByEmail(values.involvedName),
            getDateFromISODate(complaint.date),
            complaint.reportNumber,
            involved,
            values.competentWork,
            getUserNameByEmail(values.competentName)
         );
         pdfMake.createPdf(dd).open();
      }
      actions.resetForm();
      setTimeout(() => navigate(-1), 1000);
   };

   return (
      <>
         {!isLoading ? (
            <Box p={2}>
               <TitleAppBar head="Declaración de incompetencia" isScheduleAppointment={false} />
               <Container maxWidth="xl">
                  <Formik
                     initialValues={{
                        state: "",
                        city: "",
                        date: toISOString(date),
                        competentWork: involved === "1" ? "" : "a",
                        competentName: involved === "1" ? "" : "a",
                        involvedName: "",
                     }}
                     validationSchema={incompetenceSchema(involved === "1")}
                     onSubmit={handleSendForm}
                  >
                     {(formProps: FormikProps<any>) => (
                        <Form>
                           <FormObserver />
                           <Box
                              display={"flex"}
                              sx={{
                                 px: 4,
                                 minWidth: 950,
                                 flexDirection: "column",
                                 alignItems: "center",
                                 m: 2,
                              }}
                           >
                              <Box
                                 sx={{
                                    width: "100%",
                                    bgcolor: "#FFFFFF",
                                    borderRadius: 2,
                                    boxShadow: "2px 2px 5px #AFAFAF",
                                 }}
                              >
                                 <Grid container>
                                    <Grid item xs={6}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <Typography fontWeight={500} variant="body1">
                                             Reporte de la denuncia
                                          </Typography>
                                          <Typography variant="subtitle1">{reportNumber}</Typography>
                                       </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <Typography fontWeight={500} variant="body1">
                                             Fecha de interposición
                                          </Typography>
                                          <Typography variant="subtitle1">
                                             {getDateAndTimeFromISODate(complaint.createdAt)}
                                          </Typography>
                                       </Box>
                                    </Grid>
                                 </Grid>
                                 <Divider sx={{ mb: 3 }} variant="middle" />
                                 <Grid container>
                                    <Grid
                                       item
                                       xs={4}
                                       sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                       }}
                                    >
                                       <InputTextField
                                          id="state"
                                          name="state"
                                          type="text"
                                          variant="standard"
                                          label="Estado"
                                          fullWidth={true}
                                          sx={{ ml: 2 }}
                                          InputProps={{
                                             startAdornment: (
                                                <InputAdornment position="start">
                                                   <MapIcon />
                                                </InputAdornment>
                                             ),
                                          }}
                                       />
                                    </Grid>
                                    <Grid
                                       item
                                       xs={4}
                                       sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          pr: 2,
                                       }}
                                    >
                                       <InputTextField
                                          id="city"
                                          name="city"
                                          type="text"
                                          variant="standard"
                                          label="Ciudad"
                                          fullWidth={true}
                                          sx={{ ml: 2 }}
                                          InputProps={{
                                             startAdornment: (
                                                <InputAdornment position="start">
                                                   <MapIcon />
                                                </InputAdornment>
                                             ),
                                          }}
                                       />
                                    </Grid>
                                    <Grid
                                       item
                                       xs={4}
                                       sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                       }}
                                    >
                                       <InputTextField
                                          fullWidth
                                          id="date"
                                          variant="standard"
                                          name="date"
                                          type="datetime-local"
                                          label="Fecha y hora"
                                          sx={{ mr: 2 }}
                                       />
                                    </Grid>
                                 </Grid>
                                 <Divider sx={{ mt: 3 }} variant="middle" />
                                 <Grid container>
                                    <Grid item xs={12} sx={{ display: "flex", px: 2, pt: 2.5, pb: 1 }}>
                                       <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                          Datos del involucrado
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <InputSelectField
                                             fullWidth
                                             label="Nombre"
                                             labelId="name-select"
                                             id="involvedName"
                                             name="involvedName"
                                             variant="standard"
                                          >
                                             {getComplaintManagersUsers(involved === "1").map((e: any, i) => (
                                                <MenuItem key={`user-${i}`} value={e._id}>
                                                   <ListItemText primary={`${e.firstName} ${e.lastName}`} />
                                                </MenuItem>
                                             ))}
                                          </InputSelectField>
                                       </Box>
                                    </Grid>
                                    <Grid item xs={5}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <Typography fontWeight={500} variant="body1" sx={{ textAlign: "center" }}>
                                             Puesto:
                                          </Typography>
                                          <Typography variant="subtitle1">
                                             {formProps.values.involvedName !== ""
                                                ? users.map((user: { _id: any; role: any }) => {
                                                     if (user._id === formProps.values.involvedName) {
                                                        let roles = "";
                                                        user.role.map((role) => {
                                                           if (roles === "") roles = roles.concat(role.name);
                                                           else roles = roles.concat(`, ${role.name}`);
                                                           return "";
                                                        });
                                                        return roles;
                                                     }
                                                     return null;
                                                  })
                                                : "No definido"}
                                          </Typography>
                                       </Box>
                                    </Grid>
                                 </Grid>
                                 {involved === "1" ? (
                                    <>
                                       <Divider sx={{ mt: 3 }} variant="middle" />
                                       <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                                          <Grid item xs={12} sx={{ display: "flex", px: 2, py: 1 }}>
                                             <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                                Asignar oficial de cumplimiento
                                             </Typography>
                                          </Grid>
                                          <Grid item xs={7}>
                                             <Box display={"flex"} sx={dataFieldSX}>
                                                <InputSelectField
                                                   fullWidth
                                                   id="competentName"
                                                   name="competentName"
                                                   variant="standard"
                                                   label="Nombre(s)"
                                                   labelId="competentName-select"
                                                >
                                                   {getComplaintManagersUsers(false, formProps.values.involvedName).map(
                                                      (e: any, i: any) => (
                                                         <MenuItem key={`user-${i}`} value={e._id}>
                                                            <ListItemText primary={`${e.firstName} ${e.lastName}`} />
                                                         </MenuItem>
                                                      )
                                                   )}
                                                </InputSelectField>
                                             </Box>
                                          </Grid>
                                          <Grid item xs={5}>
                                             <Box display={"flex"} sx={dataFieldSX}>
                                                <Typography
                                                   fontWeight={500}
                                                   variant="body1"
                                                   sx={{ textAlign: "center" }}
                                                >
                                                   Puesto:
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                   {formProps.values.competentName !== ""
                                                      ? users.map((user: { _id: any; role: any }) => {
                                                           if (user._id === formProps.values.competentName) {
                                                              let roles = "";
                                                              user.role.map((role) => {
                                                                 if (roles === "") roles = roles.concat(role.name);
                                                                 else roles = roles.concat(`, ${role.name}`);
                                                                 return "";
                                                              });
                                                              return roles;
                                                           }
                                                           return null;
                                                        })
                                                      : "No definido"}
                                                </Typography>
                                             </Box>
                                          </Grid>
                                       </Grid>
                                    </>
                                 ) : (
                                    <></>
                                 )}
                              </Box>
                              <Box display={"flex"} sx={{ justifyContent: "flex-end", width: "100%" }}>
                                 <Button variant="contained" sx={{ mt: 2 }} type="submit">
                                    Enviar
                                 </Button>
                              </Box>
                           </Box>
                        </Form>
                     )}
                  </Formik>
               </Container>
            </Box>
         ) : (
            <CircularProgress size={24} />
         )}
      </>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   py: 2,
   px: 4,
};
