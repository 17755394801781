import { Box } from "@mui/material";
import Container from "@mui/material/Container";
import { TopHeader } from "../components/TopHeader";
import { MasterUsersTable } from "../containers/MasterUsersContainer/masterUsersTable";

export const MasterUsersScreen = () => {
   return (
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
         <TopHeader head="Usuarios" content={<></>} />
         <Container maxWidth="xl">
            <Box sx={{ m: 2 }} />
            <MasterUsersTable />
         </Container>
      </Box>
   );
};
