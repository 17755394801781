import { createContext, useState } from "react";

interface ISupportContext {
   supportOpen: boolean;
   setSupportOpen: any;
}

export const SupportContext = createContext<ISupportContext>({
   supportOpen: false,
   setSupportOpen: undefined,
});

export const SupportDrawerProvider = ({ children }) => {
   const [supportOpen, setSupportOpen] = useState(false);

   return <SupportContext.Provider value={{ supportOpen, setSupportOpen }}>{children}</SupportContext.Provider>;
};
