import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { ClientCompanyAdminInformationComponent } from "../ClientCompanyAdminInformationComponent";
import { TabPanel } from "../ClientInfoTabs";
import { CompanieUsersTable } from "../CompanieUsersTable";

const GroupUsers = () => {
   const [value, setValue] = useState(0);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   return (
      <Box>
         <Box
            display="flex"
            sx={{
               borderBottom: 1,
               borderTopRightRadius: 5,
               borderTopLeftRadius: 5,
               borderColor: "#E0E0E0",
            }}
            bgcolor="white"
            boxShadow={2}
         >
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
               <Tab label="Información de administrador de sistema" />
            </Tabs>
         </Box>
         <TabPanel value={value} index={0}>
            <ClientCompanyAdminInformationComponent group={true} />
         </TabPanel>
         <Box mt={2}>
            <Box display="flex" sx={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }} bgcolor="white" boxShadow={2}>
               <Tabs value={value} onChange={handleChange}>
                  <Tab label="Información de usuarios" />
               </Tabs>
            </Box>
            <CompanieUsersTable group={true} />
         </Box>
      </Box>
   );
};

export default GroupUsers;
