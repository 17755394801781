import { useContext, useEffect, useMemo, useState } from "react";
import { HeadCell, TableComponent } from "../TableComponent";
import { DeleteMasterRole, GetMasterUsers, getAllMasterAccess, getAllMasterRoles } from "../../lib/usersBEClient";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { ConfirmationModal } from "../ConfirmationModal";
import EditRoleModalComponent from "./EditRoleModalComponent";
import { SnackBarContext } from "../../context/snackBarContext";
import { FilterComponent } from "../FilterComponent";
import { useNavigate } from "react-router-dom";

const useGetRoles = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [roles, setRoles] = useState([]);
   const [users, setUsers] = useState([]);
   const [refetch, setRefetch] = useState(0);
   const [access, setAccess] = useState([]);
   const { data: usersResponse, isLoading: isLoadingMasters } = GetMasterUsers();

   useEffect(() => {
      const getData = async () => {
         setIsLoading(true);
         const masterAccessResponse = await getAllMasterAccess();
         const response = await getAllMasterRoles();
         setRoles(response.data);
         setAccess(masterAccessResponse.data);
         setUsers(usersResponse);
         setIsLoading(false);
      };
      if (!isLoadingMasters) getData();
   }, [refetch, isLoadingMasters]);

   return { roles, users, access, isLoading, setRefetch };
};

const headCells: HeadCell[] = [
   { field: "name", headerName: "Nombre del rol", type: "string", align: "center" },
   { field: "descriptionShort", headerName: "Descripción", type: "string", align: "left" },
   { field: "usersCount", headerName: "Usuarios", type: "string", align: "center" },
   { field: "actions", headerName: "Acciones", type: "string", align: "center" },
];

const EditRolesComponent = () => {
   const navigate = useNavigate();
   const { showSnackBar } = useContext(SnackBarContext);
   const { roles, users, access, isLoading, setRefetch } = useGetRoles();
   const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
   const [openModal, setOpenModal] = useState<boolean>(false);
   const [selectedRole, setSelectedRole] = useState(null);
   const [filteredRoles, setFilteredRoles] = useState([]);

   const formatRoles = (rolesArray) => {
      return rolesArray.map((role) => {
         const usersFound = users.filter((user) => user.role.find((uRole) => uRole._id === role._id));
         return {
            id: role._id,
            name: role.name,
            descriptionShort: role.description.length >= 80 ? role.description.slice(0, 80) + "..." : role.description,
            description: role.description,
            users: usersFound.map((user) => user),
            usersCount: usersFound.length,
            access: role.access,
            actions: (
               <Stack flexDirection={"row"} sx={{ justifyContent: "center", columnGap: 2 }}>
                  <IconButton
                     sx={{ p: 0 }}
                     onClick={() => {
                        setOpenConfirmation(true);
                     }}
                  >
                     <Delete />
                  </IconButton>
                  <IconButton
                     sx={{ p: 0 }}
                     onClick={() => {
                        setOpenModal(true);
                     }}
                  >
                     <Edit />
                  </IconButton>
               </Stack>
            ),
         };
      });
   };

   const formattedRoles = useMemo(() => {
      setFilteredRoles(formatRoles(roles));
      return formatRoles(roles);
   }, [roles]);

   const handleDelete = async () => {
      try {
         if (selectedRole.name === "superadmin")
            return showSnackBar("No es posible eliminar este rol de administrador", true);
         const response = await DeleteMasterRole(selectedRole.id);
         showSnackBar("Rol eliminado correctamente.", false);
         if (response) {
            setRefetch(Math.random());
            setOpenConfirmation(false);
            setOpenModal(false);
         }
      } catch (error) {
         console.log(error);
         showSnackBar("Error al eliminar rol", false);
      }
   };

   return (
      <>
         <FilterComponent
            originaltableOneRows={formattedRoles}
            tableOneFilteredRows={filteredRoles}
            setTableOneFilteredRows={setFilteredRoles}
            filters={[]}
            disableRefresh
            width="100%"
            searchBar={
               <Button
                  variant="contained"
                  sx={{ width: "150px" }}
                  onClick={() => {
                     navigate("/usuarios/registro/crear-rol");
                  }}
               >
                  Crear rol
               </Button>
            }
         />
         <TableComponent
            headerColor
            defaultColumnToOrder="usersCount"
            defaultOrder="desc"
            defaultRowsPerPage={10}
            rowsPerPageOptions={[5, 10, 15]}
            headers={headCells}
            rows={filteredRoles}
            loader={isLoading}
            emptyDataText={formattedRoles.length === 0 ? "No se han registrado roles" : "No se encontraron roles"}
            onClick={(e, row) => {
               setSelectedRole(row);
            }}
         />

         <ConfirmationModal
            title="Eliminar rol"
            body={
               <div>
                  <Typography
                     sx={{ fontSize: "14px" }}
                  >{`¿Esta seguro que desea eliminar el rol ${selectedRole?.name}?`}</Typography>
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                     Una vez confirmado, sé eliminara toda la información registrada y no podrá ser recuperada.
                  </Typography>
               </div>
            }
            onConfirm={handleDelete}
            open={openConfirmation}
            setOpen={setOpenConfirmation}
         />
         {selectedRole && access.length > 0 && (
            <EditRoleModalComponent
               open={openModal}
               setOpen={setOpenModal}
               role={selectedRole}
               setOpenConfirmation={setOpenConfirmation}
               access={access}
               setRefetch={setRefetch}
            />
         )}
      </>
   );
};

export default EditRolesComponent;
