import { Box, Typography, Button, Autocomplete, TextField } from "@mui/material";
import { InputTextField } from "../../Inputs/InputTextField";
import { Form, Formik, useFormikContext } from "formik";
import { createUserSchema } from "../../../lib/Validations/inputSchemas";
import { UpdateGroupAdmin, updateCompanyAdminUser, useCreateUser } from "../../../lib/usersBEClient";
import { useContext, useEffect, useState } from "react";
import { ClientConfigContext } from "../../../context/clientConfigContext";
import { SnackBarContext } from "../../../context/snackBarContext";
import { Users } from "../../../types/BaseTypes";
import { LoadingButton } from "../../LoadingButton";
import { catchAxiosError } from "../../../lib/lecosyBackendClient";

interface UserDetailsComponentProps {
   handleCancel: Function;
   setValue: Function;
   value: number;
}

export const UserAdminGroupComponent = (props: UserDetailsComponentProps) => {
   const { group, refreshGroupInfo, setGroup } = useContext(ClientConfigContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate, isLoading: isLoadingCreate } = useCreateUser();
   const [userSelected, setUserSelected] = useState<Users | null>(null);

   const [userSelectedFlag, setUserSelectedFlag] = useState(false);
   const [userfromGroup, setUserfromGroup] = useState(false);

   const handleSubmit = async (values) => {
      try {
         if (userfromGroup) {
            await UpdateGroupAdmin(userSelected._id, group._id);
            showSnackBar("El administrador fue agregado correctamente.", false);
            await refreshGroupInfo();
            props.setValue(3);
         } else {
            if(group.admin){
               const adminGroupUser = group.admin;
               if(adminGroupUser.email === values.email){//mismo correo se actualiza el usuario que ya existe
                  try {
                     const response = await updateCompanyAdminUser(
                        adminGroupUser.uid,
                        values.firstName,
                        values.lastName,
                        values.email,
                        values.phoneNumber
                     );
                     if (response.data) {
                        if (group) {
                           refreshGroupInfo();
                        }
                        refreshGroupInfo();
                        showSnackBar("Usuario modificado exitosamente", false);
                     }
                  } catch (error) {
                     const errorMessage = catchAxiosError(error);
                     if (errorMessage.message.includes("no user found")) {
                        showSnackBar("Error, intente de nuevo", true);
                     }
                     if (errorMessage.message.includes("email-already-exists")) {
                        showSnackBar("Correo electrónico en uso", true);
                     }
                  }
                  return;
                  }
                  else{
                     mutate(
                        {
                           userData: {
                              firstName: values.firstName,
                              lastName: values.lastName,
                              email: values.email,
                              phoneNumber: values.phone,
                              role: null,
                           },
                           additionalData: {
                              admin: true,
                              group: group._id,
                           },
                        },
                        {
                           onError: (error: any) => {
                              error.response.data.message === "email already in use"
                                 ? showSnackBar("Ya existe un usuario con ese correo", true)
                                 : showSnackBar("Error al agregar administrador.", true);
                           },
                           onSuccess: async () => {
                              showSnackBar("El administrador fue agregado correctamente.", false);
                              props.setValue(3);
                              await refreshGroupInfo();
                           },
                        }
                     );
                     return;
                  }  
            }else{
               mutate(
                  {
                     userData: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        phoneNumber: values.phone,
                        role: null,
                     },
                     additionalData: {
                        admin: true,
                        group: group._id,
                     },
                  },
                  {
                     onError: (error: any) => {
                        error.response.data.message === "email already in use"
                           ? showSnackBar("Ya existe un usuario con ese correo", true)
                           : showSnackBar("Error al agregar administrador.", true);
                     },
                     onSuccess: async () => {
                        showSnackBar("El administrador fue agregado correctamente.", false);
                        props.setValue(3);
                        await refreshGroupInfo();
                     },
                  }
               );
            }
         }
      } catch (error) {
         console.log({ error });
         return;
      }
   };

   const usersGroupList = group.users.map((user) => user);

   const FormObserver: React.FC = () => {
      const { setFieldValue } = useFormikContext();

      useEffect(() => {
         if (userSelected) {
            setUserSelectedFlag(true);
         } else {
            setUserSelectedFlag(false);
         }
         if (userSelected === null && userSelectedFlag) {
            setFieldValue("firstName", "");
            setFieldValue("lastName", "");
            setFieldValue("phone", "");
            setFieldValue("email", "");
            setUserfromGroup(false);
         } else if (userSelected) {
            setFieldValue("firstName", userSelected?.firstName);
            setFieldValue("lastName", userSelected?.lastName);
            setFieldValue("phone", userSelected?.phoneNumber);
            setFieldValue("email", userSelected?.email);
            setUserfromGroup(true);
         }
      }, [userSelected]);

      return null;
   };
   return (
      <Box flex={1}>
         <Formik
            initialValues={{
               firstName: group.admin ? group.admin.firstName : "",
               lastName: group.admin ? group.admin.lastName : "",
               phone: group.admin ? group.admin.phoneNumber : "",
               email: group.admin ? group.admin.email : "",
            }}
            validationSchema={createUserSchema}
            onSubmit={handleSubmit}
         >
            <Form>
               <FormObserver />
               <Box
                  sx={{
                     border: 1,
                     borderColor: "#E0E0E0",
                     borderRadius: 2,
                     flex: 1,
                  }}
               >
                  <Box sx={{ p: 2 }}>
                     <Typography fontWeight={600} variant="h6" mb={2}>
                        {props.value === 2 ? `Registro de administrador de sistema` : `Registro de usuarios`}
                     </Typography>
                     <Autocomplete
                        limitTags={2}
                        id="role"
                        options={usersGroupList}
                        getOptionLabel={(option) => `${(option as any).firstName} ${(option as any).lastName}`}
                        onChange={(event, newValue) => {
                           setUserSelected(newValue);
                        }}
                        renderInput={(params) => (
                           <TextField
                              {...params}
                              label="Usuarios del grupo empresarial"
                              placeholder="Selecciona un usuario"
                              name="role"
                           />
                        )}
                        sx={{ width: "auto" }}
                     />
                  </Box>
                  <Box
                     sx={{
                        p: 4,
                        borderTop: 1,
                        borderColor: "#E0E0E0",
                        display: "flex",
                        rowGap: 2,
                        flexDirection: "column",
                        maxHeight: "420px",
                        overflowY: "auto",
                     }}
                  >
                     <Box sx={{ display: "flex", columnGap: 2 }}>
                        <InputTextField
                           sx={{ my: 0.5 }}
                           variant="outlined"
                           size="small"
                           name="firstName"
                           id="firstName"
                           type="text"
                           label="Nombre"
                           fullWidth={true}
                           disabled={userfromGroup}
                        />
                        <InputTextField
                           sx={{ my: 0.5 }}
                           variant="outlined"
                           size="small"
                           name="lastName"
                           id="lastName"
                           type="text"
                           label="Apellido"
                           fullWidth={true}
                           disabled={userfromGroup}
                        />
                     </Box>
                     <Box sx={{ display: "flex", columnGap: 2 }}>
                        <InputTextField
                           sx={{ my: 0.5 }}
                           variant="outlined"
                           size="small"
                           name="phone"
                           id="phone"
                           type="text"
                           label="Teléfono"
                           fullWidth={true}
                           disabled={userfromGroup}
                        />
                        <InputTextField
                           sx={{ my: 0.5 }}
                           variant="outlined"
                           size="small"
                           name="email"
                           id="email"
                           type="text"
                           label="Correo electrónico"
                           fullWidth={true}
                           disabled={userfromGroup}
                        />
                     </Box>
                     <InputTextField
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        value="DefaultPassword"
                        name="comercialName"
                        id="comercialName"
                        type="password"
                        label="Contraseña"
                        disabled
                        fullWidth={true}
                     />
                  </Box>
               </Box>
               <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                  <Button
                     sx={{ height: "100%" }}
                     variant="outlined"
                     onClick={() => {
                        props.handleCancel(0);
                        setGroup(null);
                     }}
                  >
                     Cancelar
                  </Button>
                  <LoadingButton label={"Guardar"} isLoading={isLoadingCreate} />
               </Box>
            </Form>
         </Formik>
      </Box>
   );
};
