import { useState, useContext } from "react";
import { NotificationInfoModal } from "../../components/NotificationInfoModal";
import { NotificationsContext } from "../../context/notificationsContext/notificationsContext";
import { HeadCell, TableComponent } from "../../components/TableComponent";

export const NotificationsTable = () => {
   const { notifications, isLoading, setSelectedNotification } = useContext(NotificationsContext);
   const [openNotifInfoModal, setOpenNotifInfoModal] = useState(false);

   const columns: HeadCell[] = [
      { field: "body", headerName: "Duda", type: "string" },
      { field: "email", headerName: "Correo electrónico", type: "string" },
      { field: "company", headerName: "Empresa", type: "string" },
      { field: "isCR", headerName: "Denuncia existente", type: "string" },
      { field: "reportNumber", headerName: "Número de Reporte", type: "string" },
      { field: "createdAt", headerName: "Fecha de recepción", type: "date" },
   ];

   const rows = notifications.map((c) => ({
      id: c._id,
      company: c.company,
      name: c.name,
      body: `${c.body.substring(0, 120)}${c.body.length > 120 ? "..." : ""}`,
      email: c.email,
      isCR: c.isCR ? "Si" : "No",
      reportNumber: c.reportNumber ? c.reportNumber : "N/A",
      createdAt: c.createdAt,
   }));

   return (
      <>
         <TableComponent
            defaultColumnToOrder="createdAt"
            defaultOrder="asc"
            headerColor
            defaultRowsPerPage={10}
            rowsPerPageOptions={[10, 25, 50]}
            emptyDataText="No hay notificaciones en este momento"
            headers={columns}
            rows={rows}
            onClick={(e, row) => {
               setSelectedNotification(notifications.find((element) => element._id === row.id));
               setOpenNotifInfoModal(true);
            }}
            loader={isLoading}
            filters={[
               { allOption: "", data: [], id: "reportNumber", label: "No. de reporte", type: "text" },
               { allOption: "", data: [], id: "company", label: "Empresa", type: "text" },
               { allOption: "", data: [], id: "email", label: "Correo electrónico", type: "text" },
            ]}
            filtersInitialState={{
               reportNumber: "",
               company: "",
               email: "",
            }}
         />
         <NotificationInfoModal open={openNotifInfoModal} setOpen={setOpenNotifInfoModal} />
      </>
   );
};
