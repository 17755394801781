import { Box, Divider, Typography } from "@mui/material";
import { HeadCell, TableComponent } from "../TableComponent";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getContactInfoByCompanyId } from "../../lib/usersBEClient";

export const AdditionalContacts = () => {
   const { clientId } = useParams();
   const [contacts, setContacts] = useState([]);

   const branchesColumns = (): HeadCell[] => {
      const headers: HeadCell[] = [
         { field: "firstName", headerName: "Nombre(s)", type: "string", width: 50 },
         { field: "lastName", headerName: "Apellidos", type: "string", width: 50 },
         { field: "email", headerName: "Correo electrónico", type: "string", width: 50 },
         { field: "phoneNumber", headerName: "Teléfono", type: "string", width: 50 },
      ];

      return headers;
   };

   useEffect(() => {
      const fetchInfo = async () => {
         const data = await getContactInfoByCompanyId(clientId);
         setContacts(data);
      };
      fetchInfo();
      // eslint-disable-next-line
   }, []);

   return (
      <Box sx={{ bgcolor: "white", boxShadow: 2, borderRadius: 2, width: "100%" }}>
         <Typography fontSize={16} fontWeight={600} sx={{ p: 2 }}>
            Contactos adicionales
         </Typography>
         <Divider />
         <TableComponent
            defaultColumnToOrder="name"
            defaultOrder="asc"
            defaultRowsPerPage={5}
            emptyDataText="No hay contactos adicionales por el momento"
            rowsPerPageOptions={[5, 10, 15, 20]}
            loader={false}
            headers={branchesColumns()}
            rows={contacts}
            onClick={() => {}}
            disableBorders
         />
      </Box>
   );
};
