import { useContext, useEffect, useState } from "react";
import { Box, Typography, Divider, Grid, Button, CircularProgress, Drawer, Tooltip } from "@mui/material";
import { getDateFromISODate } from "../../const/globalConst";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { ClientConfigContext } from "../../context/clientConfigContext";
import { AddModifyCompanyDrawer } from "./Drawer/AddModifyCompanyDrawer";
import { getUrlS3 } from "../../lib/usersBEClient";
import { UserContext } from "../../context/UserContext";

export const GroupInformationComponent = () => {
   const { isLoading, group } = useContext(ClientConfigContext);
   const [contractFile, setContractFile] = useState("");
   const [openEditGroupDrawer, setOpenEditGroupDrawer] = useState(false);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Clientes");

   const getDataS3 = async (groupId: string) => {
      const contractUrl: string = await getUrlS3(
         "files-lecosy",
         { folder: `contracts/group/${groupId}` },
         "contract.pdf"
      );
      setContractFile(contractUrl);
   };

   useEffect(() => {
      if (!group._id) return;
      getDataS3(group._id);
   }, [group]);

   return (
      <>
         <Box bgcolor="white" sx={{ borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }} boxShadow={2} p={1}>
            {!isLoading && group ? (
               <>
                  <Box sx={{ display: "felx", flexDirection: "column", m: 1 }}>
                     <Grid container rowSpacing={4} columnSpacing={6} px={1}>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Denominación o razón social:</Typography>
                           <Typography>{group.name}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Empresas:</Typography>
                           <Typography>{group.companies.length}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Tipo de cliente:</Typography>
                           <Typography>{"Grupo empresarial"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Teléfono:</Typography>
                           <Typography>{group.phoneNumber}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Fecha de creacion:</Typography>
                           <Typography>{getDateFromISODate(group.createdAt)}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Color corporativo:</Typography>
                           <Box display={"flex"} sx={{ columnGap: 2, mt: 0.5 }}>
                              <Tooltip title={"Color primario"}>
                                 <Box display={"flex"} sx={{ columnGap: 1 }}>
                                    <Box
                                       bgcolor={group.primaryColor || "#162c44"}
                                       sx={{ borderRadius: 1, width: 50, height: 20 }}
                                    />
                                    <Typography>{group.primaryColor || "#162c44"}</Typography>
                                 </Box>
                              </Tooltip>

                              <Tooltip title={"Color secundario"}>
                                 <Box display={"flex"} sx={{ columnGap: 1 }}>
                                    <Box
                                       bgcolor={group.secondaryColor || "#162c44"}
                                       sx={{ borderRadius: 1, width: 50, height: 20 }}
                                    />
                                    <Typography>{group.secondaryColor || "#162c44"}</Typography>
                                 </Box>
                              </Tooltip>
                           </Box>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Estatus:</Typography>
                           <Typography>{group.disabled ? "Inhabilitado" : "Habilitado"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600} mb={1}>
                              Contrato:
                           </Typography>
                           {group ? (
                              <Button
                                 variant="outlined"
                                 onClick={async () => {
                                    window.open(contractFile, "_blank", "noopener,noreferrer");
                                 }}
                              >
                                 <Typography>Ver contrato</Typography>
                                 <PictureAsPdfIcon sx={{ mx: 1 }} />
                              </Button>
                           ) : (
                              "No tiene politicas"
                           )}
                        </Grid>
                     </Grid>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}>
                     <Box>
                        <Typography fontSize={14}>
                           Ultima modificación: {getDateFromISODate(group.updatedAt)}
                        </Typography>
                     </Box>
                     {!access ? (
                        <Box>
                           <Button
                              variant="contained"
                              onClick={() => {
                                 setOpenEditGroupDrawer(true);
                              }}
                           >
                              Modificar información
                           </Button>
                        </Box>
                     ) : null}
                  </Box>
               </>
            ) : (
               <Box width={"100%"} display="flex" justifyContent={"center"} p={1}>
                  <CircularProgress sx={{ alignSelf: "center" }} />
               </Box>
            )}
         </Box>
         <Drawer
            anchor="right"
            open={openEditGroupDrawer}
            hideBackdrop
            onClose={() => {
               setOpenEditGroupDrawer(false);
            }}
            sx={{ maxWidth: 450 }}
         >
            {openEditGroupDrawer ? (
               <AddModifyCompanyDrawer setOpen={setOpenEditGroupDrawer} open={openEditGroupDrawer} groupInfo={group} />
            ) : (
               <></>
            )}
         </Drawer>
      </>
   );
};
