import { Box, Button, Typography } from "@mui/material";
import { useContext } from "react";
import { ComplaintStepperContext } from "../../../context/complaintStepperContext";
import { ComplainantAndWitnessCards } from "../ComplainantAndWitnessCards";
import { DocumentsTable } from "../DocumentsTable";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { ReplicaStep } from "./ReplicaStep";
import { DocumentsTableNew } from "../DocumentsTableNew";
import { UserContext } from "../../../context/UserContext";

export const OffenderStep = () => {
   const {
      complaint,
      dataState,
      setDataState,
      selectedData,
      getFilterWitnesses,
      setOpenCompleteDialog,
      replicaOpen,
      setReplicaOpen,
      setReplicaSubStep,
      replicaSubStep,
      checkDocuments,
   } = useContext(ComplaintStepperContext);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Denuncias internas");

   return (
      <>
         {!replicaOpen ? (
            <Box sx={{ display: "flex", flexDirection: "column", minHeight: "50vh" }}>
               <Box sx={{ flex: 2 }}>
                  {dataState === 0 && complaint.offenderDetails.length > 0 ? (
                     <Box sx={{ p: 2 }}>
                        <Box sx={{ height: "280px", overflowY: "scroll", borderRadius: 1 }}>
                           <ComplainantAndWitnessCards data={complaint.offenderDetails} isOffender={true} />
                        </Box>
                     </Box>
                  ) : (
                     <>
                        {complaint.offenderDetails.length === 0 ? (
                           <DocumentsTable
                              rows={[
                                 {
                                    document: "Constancia de inexistencia",
                                    documentName: "constancia-inexistencia-denunciado",
                                    templateLink: `inexistencia/2`,
                                 },
                              ]}
                              disabled={access}
                           />
                        ) : (
                           <>
                              <Box
                                 sx={{
                                    my: 1,
                                    px: 2,
                                    py: 1,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    bgcolor: "#f8f8f8",
                                    borderRadius: 4,
                                    alignItems: "center",
                                 }}
                              >
                                 <Typography fontWeight="400" variant="h5">
                                    {selectedData.e.lastName
                                       ? selectedData.e.name + " " + selectedData.e.lastName
                                       : selectedData.e.name}
                                 </Typography>
                                 <Typography sx={{ display: "flex", alignItems: "center" }}>
                                    {`Documentos obligatorios: ${
                                       complaint.documentation.find(
                                          (doc) =>
                                             doc.name ===
                                                `constancia-inexistencia-denunciado-${complaint.reportNumber}-${selectedData.index}.pdf` ||
                                             doc.name ===
                                                `notificacion-entrevista-denunciado-${complaint.reportNumber}-${selectedData.index}.pdf` ||
                                             doc.name ===
                                                `entrevista-denunciado-${complaint.reportNumber}-${selectedData.index}.pdf`
                                       )
                                          ? 1
                                          : 0
                                    }/${1}`}
                                    {complaint.documentation.find(
                                       (doc) =>
                                          doc.name ===
                                             `constancia-inexistencia-denunciado-${complaint.reportNumber}-${selectedData.index}.pdf` ||
                                          doc.name ===
                                             `notificacion-entrevista-denunciado-${complaint.reportNumber}-${selectedData.index}.pdf` ||
                                          doc.name ===
                                             `entrevista-denunciado-${complaint.reportNumber}-${selectedData.index}.pdf`
                                    ) ? (
                                       <CheckCircleOutlineIcon sx={{ ml: 1, color: "green" }} />
                                    ) : null}
                                 </Typography>
                              </Box>
                              <DocumentsTableNew
                                 index={selectedData.index}
                                 rows={[
                                    {
                                       document: "Notificación de entrevista",
                                       documentName: ["entrevista-denunciado", "constancia-incomparecencia-denunciado"],
                                       templateLink: `notificacion/denunciado/${selectedData.index}`,
                                       fileHeader: "Agregar documento de notificación al denunciado",
                                    },
                                    {
                                       document: "Entrevista",
                                       templateLink: `${selectedData.index}/entrevista/2`,
                                       documentName: ["entrevista-denunciado", "constancia-incomparecencia-denunciado"],
                                       fileHeader: "Agregar documento de entrevista al denunciado",
                                    },
                                    {
                                       document: "Réplica",
                                       documentName: ["replica"],
                                       fileHeader: "Agregar documento de réplica",
                                    },
                                    {
                                       document: "Constancia de incomparecencia",
                                       templateLink: `incomparecencia/2`,
                                       documentName: ["constancia-incomparecencia-denunciado", "entrevista-denunciado"],
                                       fileHeader: "Agregar documento de constancia de incomparecencia",
                                    },
                                 ]}
                                 disabled={access}
                              />
                           </>
                        )}
                     </>
                  )}
               </Box>

               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "row",
                     flex: 1,
                     mt: 2,
                     mx: 2,
                     justifyContent: "space-between",
                     alignItems: "center",
                  }}
               >
                  {dataState === 0 ? null : (
                     <>
                        <Button
                           onClick={() => {
                              replicaSubStep ? setReplicaSubStep(false) : setDataState(0);
                           }}
                           variant="outlined"
                        >
                           {`Volver`}
                        </Button>
                        {complaint.documentation.some(
                           (doc) => doc.name === `replica-${complaint.reportNumber}-${selectedData.index}.pdf`
                        ) ? (
                           <Button
                              variant="outlined"
                              onClick={() => {
                                 setReplicaOpen(true);
                                 getFilterWitnesses(selectedData.e._id);
                              }}
                           >
                              Testigos de réplica
                           </Button>
                        ) : null}
                     </>
                  )}
                  {dataState === 2 && !access ? null : (
                     <>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Box sx={{ maxHeight: 30 }}>
                           <Button
                              disabled={!checkDocuments("denunciado")}
                              onClick={() => setOpenCompleteDialog(true)}
                              variant="outlined"
                           >
                              {`Completar denunciado`}
                           </Button>
                        </Box>
                     </>
                  )}
               </Box>
            </Box>
         ) : (
            <ReplicaStep />
         )}
      </>
   );
};
