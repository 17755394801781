import { useState, useEffect } from "react";
import {
   Box,
   Button,
   Checkbox,
   Grid,
   IconButton,
   List,
   ListItem,
   ListItemIcon,
   ListItemText,
   Tooltip,
   Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import CircularProgress from "@mui/material/CircularProgress";

interface Resource {
   resource: string;
   permission: number;
}

export interface TransferListItem {
   id: string;
   name: string;
   description: string;
   resources?: Resource[]; // Usa el nuevo tipo Resource[]
}

interface RoleTransferListProps {
   title1: string;
   title2: string;
   leftlist: TransferListItem[];
   rightlist: TransferListItem[];
   setLeftlist: (leftlist: TransferListItem[]) => void;
   setRightlist: (rightlist: TransferListItem[]) => void;
   onSaveRightList: (rightlist: string[]) => void;
   isLoading?: boolean;
}

function not(a: readonly string[], b: readonly string[]) {
   return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]) {
   return a.filter((value) => b.indexOf(value) !== -1);
}

export const RoleTransferListComponent = (props: RoleTransferListProps) => {
   const [checked, setChecked] = useState<string[]>([]);
   const [left, setLeft] = useState<string[]>([]);
   const [right, setRight] = useState<string[]>([]);

   const leftChecked = intersection(checked, left);
   const rightChecked = intersection(checked, right);

   useEffect(() => {
      setLeft(props.leftlist.map((value) => value.id));
      setRight(props.rightlist.map((value) => value.id));
      setChecked([]);
   }, [props.leftlist, props.rightlist]);

   const handleToggle = (value: string) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
         newChecked.push(value);
      } else {
         newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
   };

   const handleCheckedRight = () => {
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));

      // Extract IDs of the objects in the updated rightlist
      const updatedRightListIds = right.concat(leftChecked);

      // Update rightlist and leftlist using set functions
      props.setRightlist(props.rightlist.concat(props.leftlist.filter((item) => checked.includes(item.id))));
      props.setLeftlist(props.leftlist.filter((item) => !checked.includes(item.id)));

      // Call onSaveRightList to save the updated rightlist IDs
      props.onSaveRightList(updatedRightListIds);
   };

   const handleCheckedLeft = () => {
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));

      props.setLeftlist(props.leftlist.concat(props.rightlist.filter((item) => checked.includes(item.id))));
      props.setRightlist(props.rightlist.filter((item) => !checked.includes(item.id)));
   };

   const customList = (title: string, items: string[], isLeftList: boolean) => (
      <>
         {!props.isLoading ? (
            <>
               <Box>{props.isLoading}</Box>
               <Box sx={{ border: "1px solid #B8C5D0", borderRadius: "10px" }}>
                  <Typography variant="h6" color={"#3F5063"} fontWeight={500} sx={{ px: 3, py: 2 }}>
                     {title}
                  </Typography>
                  <List
                     sx={{
                        width: "auto",
                        height: 230,
                        overflow: "auto",
                        "::-webkit-scrollbar": {
                           display: "none",
                        },
                        px: 2,
                     }}
                     dense
                     component="div"
                     role="list"
                  >
                     {items.map((value) => {
                        const labelId = `transfer-list-all-item-${value}-label`;

                        return (
                           <ListItem
                              key={value}
                              role="listitem"
                              onClick={handleToggle(value)}
                              sx={{ bgcolor: "#E4E6E88C", borderRadius: "10px", my: "10px" }}
                           >
                              <ListItemIcon>
                                 <Checkbox
                                    checked={checked.includes(value)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                       "aria-labelledby": labelId,
                                    }}
                                 />
                              </ListItemIcon>
                              <ListItemText
                                 id={labelId}
                                 primary={
                                    isLeftList
                                       ? props.leftlist.find((item) => item.id === value)?.name || ""
                                       : props.rightlist.find((item) => item.id === value)?.name || ""
                                 }
                              />
                              <Tooltip
                                 title={
                                    isLeftList
                                       ? props.leftlist
                                            .find((item) => item.id === value)
                                            ?.description.substring(0, 100) || ""
                                       : props.rightlist
                                            .find((item) => item.id === value)
                                            ?.description.substring(0, 100) || ""
                                 }
                                 placement="right-start"
                              >
                                 <IconButton sx={{ visibility: isLeftList ? "visible" : "hidden" }}>
                                    <InfoIcon />
                                 </IconButton>
                              </Tooltip>
                           </ListItem>
                        );
                     })}
                  </List>
               </Box>
            </>
         ) : (
            <CircularProgress size={24} />
         )}
      </>
   );

   return (
      <Box>
         <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item sx={{ minWidth: "40%" }}>
               {customList(props.title1, left, true)}
            </Grid>
            <Grid item>
               <Grid container direction="column" alignItems="center">
                  <Button
                     sx={{ my: 0.5, border: "none" }}
                     size="small"
                     onClick={handleCheckedRight}
                     disabled={leftChecked.length === 0}
                     aria-label="move selected right"
                  >
                     <EastOutlinedIcon />
                  </Button>
                  <Button
                     sx={{ my: 0.5, border: "none" }}
                     size="small"
                     onClick={handleCheckedLeft}
                     disabled={rightChecked.length === 0}
                     aria-label="move selected left"
                  >
                     <WestOutlinedIcon />
                  </Button>
               </Grid>
            </Grid>
            <Grid item sx={{ minWidth: "40%" }}>
               {customList(props.title2, right, false)}
            </Grid>
         </Grid>
      </Box>
   );
};
