import { useContext, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { TopHeader } from "../../components/TopHeader";
import EditRolesComponent from "../../components/Roles/EditRolesComponent";

export const EditRolScreen = () => {
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Usuarios");
   const navigate = useNavigate();

   useEffect(() => {
      if (access) {
         navigate("/inicio");
      }
      // eslint-disable-next-line
   }, []);

   return (
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
         <TopHeader head="Editar información de roles" content={<></>} />
         <Container maxWidth="xl">
            <EditRolesComponent />
         </Container>
      </Box>
   );
};
