import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { TitleAppBar } from "../../../components/TitleAppBar";
import { capitalizeFirstLetter, getDateFromISODate } from "../../../const/globalConst";
// MUI Imports
import { Box, Typography, Grid, Button, CircularProgress, Skeleton, Stack } from "@mui/material";
// MUI Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ComplainerDetailsModal } from "../../../components/ComplainerDetailsModal";
import { ComplainantAndWitnessDetailsModal } from "../../../components/ComplainantAndWitnessDetailsModal";
import Container from "@mui/material/Container/Container";
import { ComplaintRelevantInfo } from "../../../components/ComplaintInfo/ComplaintRelevantInfo";
import { ClosingFormComplaint } from "../../../components/ClosingFormComplaint/ClosingFormComplaint";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails/AccordionDetails";
import { ComplaintStepperContext } from "../../../context/complaintStepperContext";
import { ComplaintProccessStepper } from "../../../components/stepper/ComplaintProccessStepper";
import { ComplaintFileRecord } from "../../../components/ComplaintInfo/ComplaintFileRecord";
import VerifyUserSession from "../../../hooks/verifyUserSession";

interface ComplaintInfoScreenProps {
   includeStepper: boolean;
}

export const ComplaintInfoScreen = (props: ComplaintInfoScreenProps) => {
   VerifyUserSession();
   const { reportNumber } = useParams();
   const { getComplaint, complaint, isLoading } = useContext(ComplaintStepperContext);
   const [openComplainerModal, setOpenComplainerModal] = useState(false);
   const [openOffenderModal, setOpenOffenderModal] = useState(false);
   const [openWitnessModal, setOpenWitnessModal] = useState(false);
   const [loadingScreen, setLoadingScreen] = useState(true);

   useEffect(() => {
      const fetchComplaint = async () => {
         try {
            await getComplaint(reportNumber);
            setLoadingScreen(false);
         } catch (error: any) {
            console.log(error);
         }
      };
      fetchComplaint();
      // eslint-disable-next-line
   }, [reportNumber]);

   const AditionalInformationGrid = () => {
      return (
         <Grid container item xs={12} spacing={1}>
            <Grid item xs={4}>
               <Box fontWeight={600}>Fecha del incidente</Box>
               {isLoading ? (
                  <Box sx={{ pr: 2 }}>
                     <Skeleton variant="rectangular" />
                  </Box>
               ) : (
                  <Box display="flex">{getDateFromISODate(complaint.date || complaint.createdAt)}</Box>
               )}
            </Grid>
            <Grid item xs={4}>
               <Box fontWeight={600}>{complaint.sentDate ? "Fecha de envío" : "Fecha de rechazo"}</Box>
               {isLoading ? (
                  <Box sx={{ pr: 2 }}>
                     <Skeleton variant="rectangular" />
                  </Box>
               ) : (
                  <Box>
                     {complaint.status === "improcedente"
                        ? getDateFromISODate(complaint.rejectedDate)
                        : complaint.sentDate
                        ? getDateFromISODate(complaint.sentDate)
                        : ""}
                  </Box>
               )}
            </Grid>
            <Grid item xs={4}>
               <Box fontWeight={600}>Lugar del incidente</Box>
               {isLoading ? (
                  <Box sx={{ pr: 2 }}>
                     <Skeleton variant="rectangular" />
                  </Box>
               ) : (
                  <Box>{complaint.specificPlace || "No aplica"}</Box>
               )}
            </Grid>
            <Grid item xs={4}>
               <Box fontWeight={600}>{(complaint.type || "denuncia") !== "denuncia" ? "Quejoso" : "Denunciante"}</Box>
               {isLoading ? (
                  <Box sx={{ pr: 2 }}>
                     <Skeleton variant="rectangular" />
                  </Box>
               ) : (
                  <Box>{complaint.complainerType}</Box>
               )}
            </Grid>
            <Grid item xs={4}>
               {complaint.complaintType === "anonima" ? (
                  <Typography fontWeight={600}>{`${capitalizeFirstLetter(
                     complaint.type || "denuncia"
                  )} Anónima`}</Typography>
               ) : (
                  <Typography fontWeight={600}>{`${capitalizeFirstLetter(
                     complaint.type || "denuncia"
                  )} no anónima`}</Typography>
               )}
               {isLoading ? (
                  <Box sx={{ pr: 2 }}>
                     <Skeleton variant="rectangular" />
                  </Box>
               ) : (
                  <Box display="flex" alignItems="center">
                     {complaint.complaintType === "anonima" ? (
                        <Typography>Si</Typography>
                     ) : (
                        <Button
                           variant="outlined"
                           size="small"
                           endIcon={<VisibilityIcon />}
                           onClick={() => setOpenComplainerModal(true)}
                        >
                           Ver
                        </Button>
                     )}
                  </Box>
               )}
            </Grid>
            <Grid item xs={4}>
               <Box fontWeight={600}>Resuelto</Box>
               {isLoading ? (
                  <Box sx={{ pr: 2 }}>
                     <Skeleton variant="rectangular" />
                  </Box>
               ) : (
                  <Box>{complaint.resolution ? "Si" : "No"}</Box>
               )}
            </Grid>
            <Grid item xs={12}>
               <Box fontWeight={600}>Medidas impuestas</Box>
               {isLoading ? (
                  <Box sx={{ pr: 2 }}>
                     <Skeleton variant="rectangular" />
                  </Box>
               ) : (
                  <Box>{complaint.resolution ? complaint.resolution : "No aplica"}</Box>
               )}
            </Grid>
         </Grid>
      );
   };

   return (
      <Box p={2}>
         <TitleAppBar
            head={
               <Typography variant="h6">{`${capitalizeFirstLetter(
                  complaint?.type || "denuncia"
               )} ${reportNumber}`}</Typography>
            }
            isScheduleAppointment={false}
         />
         <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {!isLoading && !loadingScreen ? (
               <Container maxWidth="xl">
                  {complaint ? (
                     <Grid container spacing={2} sx={{ py: 2 }}>
                        {complaint.intern && (complaint.status === "cerrada" || complaint.status === "improcedente") ? (
                           <Grid item xs={12}>
                              <Box sx={infoBox}>
                                 {complaint.status === "cerrada" ? (
                                    <Typography variant="h6">{`Esta ${
                                       complaint.type || "denuncia"
                                    } se encuentra cerrada.`}</Typography>
                                 ) : complaint.status === "improcedente" ? (
                                    <Typography variant="h6">{`Esta ${
                                       complaint.type || "denuncia"
                                    } fue rechazada.`}</Typography>
                                 ) : null}
                              </Box>
                           </Grid>
                        ) : !complaint.intern ? (
                           <Grid item xs={12}>
                              <Box sx={infoBox}>
                                 {complaint.status === "cerrada" ? (
                                    <Typography variant="h6">{`Esta ${
                                       complaint.type || "denuncia"
                                    } se encuentra cerrada.`}</Typography>
                                 ) : complaint.status === "improcedente" ? (
                                    <Typography variant="h6">{`Esta ${
                                       complaint.type || "denuncia"
                                    } fue rechazada.`}</Typography>
                                 ) : (
                                    <Typography variant="h6">{`Esta ${
                                       complaint.type || "denuncia"
                                    } es gestionada por la empresa.`}</Typography>
                                 )}
                              </Box>
                           </Grid>
                        ) : null}
                        {props.includeStepper && complaint.intern ? (
                           <>
                              <Grid item xs={12} md={12} lg={12}>
                                 {isLoading ? (
                                    <Box sx={infoBox}>
                                       <CircularProgress size={"1.5em"} sx={{ mr: 2 }} />
                                       <Typography>Espere un momento, por favor.</Typography>
                                    </Box>
                                 ) : (
                                    <ClosingFormComplaint />
                                 )}
                              </Grid>
                              {}
                              <Grid item xs={12} md={12} lg={8}>
                                 {isLoading ? (
                                    <Box sx={infoBox}>
                                       <CircularProgress size={"1.5em"} sx={{ mr: 2 }} />
                                       <Typography>Espere un momento, por favor.</Typography>
                                    </Box>
                                 ) : (
                                    <ComplaintProccessStepper />
                                 )}
                              </Grid>
                              <Grid item xs={12} lg={props.includeStepper ? 4 : 12}>
                                 <ComplaintRelevantInfo
                                    setOpenWitnessModal={setOpenWitnessModal}
                                    setOpenOffenderModal={setOpenOffenderModal}
                                    includeStepper={!complaint.intern ? false : props.includeStepper}
                                 />
                              </Grid>
                           </>
                        ) : (
                           <></>
                        )}
                        <Grid item xs={12} sx={{ display: "grid", gridAutoColumns: "1fr", gridAutoFlow: "column" }}>
                           <Stack direction={{ xs: "column", lg: "row" }} spacing={2} sx={{ display: "flex" }}>
                              <Box flex={1}>
                                 <Accordion square sx={{ boxShadow: 2 }}>
                                    <AccordionSummary
                                       expandIcon={<KeyboardArrowDownIcon />}
                                       aria-controls="panel1a-content"
                                       id="panel1a-header"
                                    >
                                       <Typography variant="h6">Expediente del caso</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ minHeight: "100%" }}>
                                       {isLoading ? (
                                          <Box sx={{ display: "flex", justifyContent: "center" }}>
                                             <CircularProgress />
                                          </Box>
                                       ) : (
                                          <ComplaintFileRecord includeStepper={props.includeStepper} clousure={false} />
                                       )}
                                    </AccordionDetails>
                                 </Accordion>
                              </Box>
                              <Box flex={1}>
                                 <Accordion square sx={{ boxShadow: 2 }}>
                                    <AccordionSummary
                                       expandIcon={<KeyboardArrowDownIcon />}
                                       aria-controls="panel2a-content"
                                       id="panel2a-header"
                                       sx={{ minHeight: "100%" }}
                                    >
                                       <Typography variant="h6">Información adicional</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ minHeight: "100%" }}>
                                       <AditionalInformationGrid />
                                    </AccordionDetails>
                                 </Accordion>
                              </Box>
                           </Stack>
                        </Grid>
                     </Grid>
                  ) : (
                     <Typography textAlign={"center"}>Error al cargar la denuncia</Typography>
                  )}
               </Container>
            ) : (
               <Box display={"flex"} width={"100%"} justifyContent={"center"}>
                  <CircularProgress size={24} />
               </Box>
            )}
            {complaint?.complaintType !== "anonima" && (
               <ComplainerDetailsModal open={openComplainerModal} setOpen={setOpenComplainerModal} />
            )}
            {complaint ? (
               <ComplainantAndWitnessDetailsModal
                  open={openOffenderModal}
                  setOpen={setOpenOffenderModal}
                  info={complaint.offenderDetails}
                  isWitness={false}
               />
            ) : (
               <></>
            )}
            {complaint ? (
               <ComplainantAndWitnessDetailsModal
                  open={openWitnessModal}
                  setOpen={setOpenWitnessModal}
                  info={complaint.witnesses}
                  isWitness={true}
               />
            ) : (
               <></>
            )}
         </Box>
      </Box>
   );
};

const infoBox = {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   bgcolor: "white",
   boxShadow: 2,
   p: 2,
};
