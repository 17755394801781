import { Alert, Snackbar } from "@mui/material";
import { useContext } from "react";
import { SnackBarContext } from "../context/snackBarContext";

export const SnackBarComponent = () => {
   const { snackBarOpen, snackBarMessage, isErrorSnackBar, hideSnackBar } = useContext(SnackBarContext);
   return (
      <Snackbar open={snackBarOpen} autoHideDuration={3000} onClose={hideSnackBar}>
         <Alert onClose={hideSnackBar} severity={isErrorSnackBar ? "error" : "success"} sx={{ width: "100%" }}>
            {snackBarMessage}
         </Alert>
      </Snackbar>
   );
};
