import { Box, Stack, Switch, Typography } from "@mui/material";
import { ComplaintsFormComponent } from "./ServiceInfoForm/ComplaintsForm";
import { useState, useContext } from "react";
import { ServiceInfoContext } from "../../../context/serviceInfoContext";
import { ClientConfigContext } from "../../../context/clientConfigContext";
import { ConfirmationModal } from "../../ConfirmationModal";
import LoginModal from "../../ClientInformation/Modal/loginModal";
import { SnackBarContext } from "../../../context/snackBarContext";
import { createBeneficiaryApplicationConfig, useUpdateServiceDisable } from "../../../lib/usersBEClient";
import CorporateDataInformation from "./ServiceInfoForm/Governance/CorporateDataInformation.tsx";
import { GoverningBodiesComponent } from "./ServiceInfoForm/Governance/GoverningBodiesComponent";
import GovernanceCordination from "./ServiceInfoForm/Governance/subcomponents/GovernanceCoordination";
import { BeneficiaryRequiredDocs } from "../../../const/globalConst";
interface ServiceLicencesComponentProps {
   service: any;
   modify: boolean;
}

export const ServiceLicencesComponent = (props: ServiceLicencesComponentProps) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { getService } = useContext(ServiceInfoContext);
   const [openLoginModal, setOpenLoginModal] = useState(false);
   const { clientInfo, refreshClientInfo } = useContext(ClientConfigContext);
   const [openDisableServiceModal, setOpenDisableServiceModal] = useState(false);
   const { mutate: serviceStatus } = useUpdateServiceDisable();
   const serviceDetails = getService(clientInfo.company_details?.servicesDetails, props.service._id);
   const [checked, setChecked] = useState<boolean>(serviceDetails !== undefined && !serviceDetails?.disable);

   const serviceUpdate = async () => {
      serviceStatus(
         {
            service_id: props.service._id,
            companyDetails_id: clientInfo.company_details._id,
            disable: checked,
         },
         {
            onSuccess: async () => {
               showSnackBar(`Servicio ${!checked ? "Habilitado" : "Inhabilitado"} correctamente`, false);
               if (checked && props.service.service === "Beneficiario controlador") {
                  const data = {
                     company: clientInfo._id,
                     files: BeneficiaryRequiredDocs.map((file) => {
                        return {
                           fileName: file,
                           fileType: "PDF",
                           maxFileSize: 10 * (1024 * 1024),
                           periodType: "monthly",
                           periodFrequency: 3,
                        };
                     }),
                  };
                  await createBeneficiaryApplicationConfig(data);
               }
               refreshClientInfo();
            },
            onError: () => {
               showSnackBar(`Error al ${!checked ? "Habilitar" : "Inhabilitar"}`, true);
               refreshClientInfo();
            },
         }
      );
   };

   const getserviceInfo = (service: string) => {
      switch (service) {
         case "Canal de denuncias":
            return <ComplaintsFormComponent modify={props.modify ?? false} checked={checked} service={props.service} />;
         case "Gobierno corporativo":
            return props.modify && <CorporateDataInformation checked={checked} service={props.service} />;
         case "Beneficiario controlador":
            return props.modify && <CorporateDataInformation checked={checked} service={props.service} />;
         default:
            break;
      }
   };

   return (
      <>
         <Box
            sx={{
               px: 3,
               py: 2,
               borderBottomRightRadius: 5,
               borderBottomLeftRadius: 5,
               alignItems: "center",
               boxShadow: 2,
               backgroundColor: "white",
            }}
         >
            <Box
               sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
               }}
            >
               <Typography fontWeight={600} variant="subtitle1">
                  {props.service.service}
               </Typography>
               <Stack direction={"row"} columnGap={2}>
                  <Switch
                     checked={checked}
                     onChange={() => {
                        setOpenDisableServiceModal(true);
                     }}
                     sx={{ display: "flex" }}
                  />
               </Stack>
            </Box>
            {getserviceInfo(props.service.service)}
            <ConfirmationModal
               open={openDisableServiceModal}
               setOpen={setOpenDisableServiceModal}
               title={!checked ? `Habilitar servicio` : `Inhabilitar servicio`}
               body={`¿Estás seguro de ${!checked ? "habilitar" : "inhabilitar"} este servicio?`}
               onConfirm={() => {}}
               setOpenLogin={setOpenLoginModal}
            />
            <LoginModal
               open={openLoginModal}
               setOpen={setOpenLoginModal}
               onConfirm={() => {
                  serviceUpdate();
                  setChecked(!checked);
               }}
            />
         </Box>

         {props.service.service === "Gobierno corporativo" && props.modify && checked && (
            <>
               <Box
                  sx={{
                     px: 3,
                     py: 2,
                     borderRadius: 1,
                     alignItems: "center",
                     boxShadow: 2,
                     backgroundColor: "white",
                  }}
               >
                  <Box sx={{ opacity: checked ? 1 : 0.5, pointerEvents: checked ? "auto" : "none" }}>
                     <GoverningBodiesComponent company={clientInfo} />
                  </Box>
               </Box>
               <Box
                  sx={{
                     px: 3,
                     py: 2,
                     borderRadius: 1,
                     alignItems: "center",
                     boxShadow: 2,
                     backgroundColor: "white",
                  }}
               >
                  <Box sx={{ opacity: checked ? 1 : 0.5, pointerEvents: checked ? "auto" : "none" }}>
                     <GovernanceCordination />
                  </Box>
               </Box>
            </>
         )}
      </>
   );
};
