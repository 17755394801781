import { Box, Checkbox, IconButton, Stack, Typography, Button, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { capitalizeFirstLetter, getDateFromISODate, tableStatusColors } from "../../const/globalConst";
import { Check, Circle, Close } from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import { DinamicTableComponentProps } from "./TableComponentContentMap";

interface TableComponentContentProps {
   isUpRow: boolean;
   isItemSelected: boolean;
   labelId: string;
   header: any;
}

const centeredDiv: React.CSSProperties = { display: "flex", alignItems: "center", justifyContent: "center" };

const getArray = (text: any[]) => {
   if (text === undefined) return "No tiene";
   const rolName: any[] = [];
   for (const role of text) {
      rolName.push(role.name);
   }
   const data = rolName.join(", ");
   return data;
};

const splitList = (sector: any) => {
   const foundSectors: string[] = sector;
   let listString: string = "";
   for (let i = 0; i < foundSectors.length; i++) {
      listString = listString.concat(foundSectors[i]);
      if (i !== foundSectors.length - 1) listString = listString.concat(", ");
   }
   return listString;
};

export const DateTypeComponent = (row, mapProps: TableComponentContentProps) => {
   return <>{getDateFromISODate(row[mapProps.header.field])}</>;
};

export const StringNumberTypeComponent = (row, mapProps: TableComponentContentProps) => {
   return <>{row[mapProps.header.field]}</>;
};

export const DisabledComponent = (row, mapProps: TableComponentContentProps) => {
   return (
      <>
         {row[mapProps.header.field] ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
               Inhabilitado&nbsp;
               <Close color="warning" fontSize="small" />
            </Box>
         ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
               Habilitado&nbsp;
               <Check color="success" fontSize="small" />
            </Box>
         )}
      </>
   );
};

export const CompletedComponent = (row, mapProps: TableComponentContentProps) => {
   return (
      <>
         {row[mapProps.header.field] ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
               <Check color="primary" />
            </Box>
         ) : (
            <Box sx={{ display: "flex", justifyContent: "center", opacity: 0.2 }}>
               <Check color="primary" />
            </Box>
         )}
      </>
   );
};

export const CollapsibleTypeComponent = (props: DinamicTableComponentProps, row, value: boolean) => {
   return value ? (
      <Tooltip title={"Ver más"}>
         <Button
            onClick={(e) => {
               props.setOpened(row.id === props.opened ? "" : row.id);
               e.stopPropagation();
            }}
         >
            <Typography fontWeight={600}> {row.sub_activities_num} </Typography>
         </Button>
      </Tooltip>
   ) : (
      <IconButton
         aria-label="expand row"
         size="small"
         onClick={(e) => {
            props.setOpened(row.id === props.opened ? "" : row.id);
            e.stopPropagation();
         }}
      >
         {props.opened === row.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
   );
};

export const CheckTypeComponent = (mapProps: TableComponentContentProps) => {
   return (
      <Checkbox
         color="primary"
         checked={mapProps.isUpRow ? true : mapProps.isItemSelected}
         inputProps={{
            "aria-labelledby": mapProps.labelId,
         }}
      />
   );
};

export const ButtonTypeComponent = (props: DinamicTableComponentProps, mapProps: TableComponentContentProps, row) => {
   return (
      <IconButton
         onClick={(e) =>
            props.icon ? props.onClick(e, row) : mapProps.header.icon ? mapProps.header.onClick(e, row) : null
         }
         sx={{ p: 0 }}
         size="small"
      >
         {mapProps.header.icon ? mapProps.header.icon : props.icon}
      </IconButton>
   );
};

function renderIcon(param: string) {
   switch (param) {
      case "link":
         return <LinkIcon color="action" />;
      case "pdf":
         return <PictureAsPdfIcon color="error" />;
      case "doc":
         return <DescriptionIcon sx={{ fontSize: 20 }} color="info" />;
      case "xlsx":
         return <DescriptionIcon sx={{ color: "#347D55", fontSize: 20 }} />;
      case "folder":
         return <FolderIcon sx={{ color: "#F8D775", fontSize: 20 }} />;
      case "lecosy":
         return <DescriptionIcon sx={{ color: "#152c44", fontSize: 20 }} />;
      default:
         return <InsertDriveFileIcon />;
   }
}

export const FileTypeComponent = (props: DinamicTableComponentProps, mapProps: TableComponentContentProps, row) => {
   return (
      <Stack
         sx={{ display: "flex", flexDirection: "row", columnGap: 2 }}
         onClick={(e) =>
            props.icon ? props.onClick(e, row) : mapProps.header.icon ? mapProps.header.onClick(e, row) : null
         }
      >
         {renderIcon(row.file)}
         {row[mapProps.header.field]}
      </Stack>
   );
};

export const ImageTypeComponent = (row, mapProps: TableComponentContentProps, props: DinamicTableComponentProps) => {
   return (
      <>
         <img
            onKeyDown={(e) => props.onClick(e, row)}
            style={{ maxWidth: 100, maxHeight: 50 }}
            src={row[mapProps.header.field] as string}
            alt={`${row.name} logo`}
            onClick={(e) => props.onClick(e, row)}
         />
         {capitalizeFirstLetter(row.status)}
      </>
   );
};

export const StatusTypeComponent = (row, mapProps: TableComponentContentProps) => {
   return (
      <div style={centeredDiv}>
         <Circle
            sx={{
               width: 10,
               color: tableStatusColors(row.status),
               mr: 1,
            }}
         />
         <Typography>{row[mapProps.header.field]}</Typography>
      </div>
   );
};

export const ArrayTypeComponent = (row, mapProps: TableComponentContentProps) => {
   return <>{getArray(row[mapProps.header.field])}</>;
};

export const ListTypeComponent = (row, mapProps: TableComponentContentProps) => {
   return <>{splitList(row[mapProps.header.field])}</>;
};
