import { Box, Stack, Typography } from "@mui/material";
import { HeadCell, TableComponent } from "../../../TableComponent";
import { getUrlS3 } from "../../../../lib/usersBEClient";
import { useContext, useEffect, useState } from "react";
import { ClientConfigContext } from "../../../../context/clientConfigContext";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { useNavigate } from "react-router-dom";

export const DenounceChannelCompanies = () => {
   const navigate = useNavigate();
   const { group, complaintsGroup } = useContext(ClientConfigContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [companiesRow, setCompaniesRow] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   const columns: HeadCell[] = [
      { field: "logo", headerName: "Logo", type: "string" },
      { field: "cName", headerName: "Empresa", type: "string", align: "center" },
      { field: "branches", headerName: "Sucursales", type: "number", align: "center" },
      { field: "status", headerName: "Estatus", type: "disabled" },
   ];

   const fetchCompaniesInfo = async () => {
      const companyData: any[] = [];
      setIsLoading(true);
      try {
         const complaintCompanies = complaintsGroup.filter((company) =>
            group.companies.some((c) => company._id === c._id)
         );
         for (const company of complaintCompanies) {
            const logo = await getDataS3(company._id);
            companyData.push({
               id: company._id,
               logo: logo,
               cName: company.person_details.comercialName,
               branches: company.branches,
               status: company.disabled,
            });
         }
         setCompaniesRow(companyData);
      } catch (error) {
         showSnackBar("Error al conseguir empresas", true);
      }
      setIsLoading(false);
   };

   useEffect(() => {
      fetchCompaniesInfo();
   }, [group]);

   const getDataS3 = async (companyId: string) => {
      const logoUrl: string = await getUrlS3("images-lecosy", { folder: companyId }, "logo.png");
      return <img defaultValue={logoUrl} src={logoUrl} alt="logo" style={{ maxWidth: "140px", maxHeight: "50px" }} />;
   };

   const handleClick = async (_e, row) => {
      const screen = "clientes/" + row.id;
      return navigate(`/${screen}`);
   };

   return (
      <Box
         sx={{
            bgcolor: "white",
            boxShadow: 2,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            borderRadius: 1,
         }}
      >
         <Stack direction={"row"} alignContent={"center"} sx={{ px: 4, pt: 3 }}>
            <Typography sx={{ fontWeight: 700 }}>Empresas</Typography>
         </Stack>
         <TableComponent
            defaultColumnToOrder="empresa"
            defaultOrder="asc"
            defaultRowsPerPage={3}
            emptyDataText={"No existen empresas registradas"}
            disableBorders
            headers={columns}
            loader={isLoading}
            rows={companiesRow}
            rowsPerPageOptions={[3, 5, 10]}
            onClick={handleClick}
         />
      </Box>
   );
};
