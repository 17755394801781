import { useState } from "react";
import { Typography, Box, Button, Divider } from "@mui/material";
import { TableComponent, TableComponentProps } from "./TableComponent";
import { FilterComponent } from "./FilterComponent";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

interface IDualTableComponentProps {
   tableOneProps: TableComponentProps;
   tableTwoProps: TableComponentProps;
   rowsOne: any[];
   rowsTwo: any[];
   labelOne: string;
   labelTwo: string;
   onClick?: () => void;
   disabled?: boolean;
}

export const DualTableComponent = (props: IDualTableComponentProps) => {
   const [tableOneFilteredRows, setTableOneFilteredRows] = useState(props.rowsOne);
   const [tableTwoFilteredRows, setTableTwoFilteredRows] = useState(props.rowsTwo);
   const [opened, setOpened] = useState<string>();

   return (
      <>
         <FilterComponent
            originaltableOneRows={props.rowsOne}
            originaltableTwoRows={props.rowsTwo}
            tableOneFilteredRows={tableOneFilteredRows}
            tableTwoFilteredRows={tableTwoFilteredRows}
            setTableOneFilteredRows={setTableOneFilteredRows}
            setTableTwoFilteredRows={setTableTwoFilteredRows}
            opened={opened}
            disableRefresh
            setOpened={setOpened}
            filters={["daterange"]}
            searchBar={
               !props.disabled ? (
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }}>
                     <Button
                        onClick={props.onClick}
                        size="small"
                        variant="contained"
                        sx={{ maxHeight: "100%" }}
                        fullWidth
                     >
                        <Typography variant="button" sx={{ mr: 1 }}>
                           Agregar cliente
                        </Typography>
                        <PersonAddIcon />
                     </Button>
                  </Box>
               ) : (
                  <></>
               )
            }
         />
         <Box sx={{ boxShadow: 2, bgcolor: "white", borderRadius: 2 }}>
            <Typography fontSize={20} p={2}>
               {props.labelOne}
            </Typography>
            <Divider />
            <TableComponent {...props.tableOneProps} rows={tableOneFilteredRows} />
         </Box>

         <Box sx={{ boxShadow: 2, bgcolor: "white", borderRadius: 2, mt: 2 }}>
            <Typography fontSize={20} p={2}>
               {props.labelTwo}
            </Typography>
            <Divider />
            <TableComponent
               {...props.tableTwoProps}
               rows={tableTwoFilteredRows}
               collapsible
               opened={opened}
               onClick={props.tableTwoProps.onClick}
               setOpened={setOpened}
            />
         </Box>
      </>
   );
};
