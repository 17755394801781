import { useContext, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ClientInformationHome } from "./ClientInformationHome";
import { InvoiceClientInfoComponent } from "./InvoiceClientInfoComponent";
import { BranchesTableClientInfo } from "../../containers/ClientInfoContainer/BranchesTable";
import { ServiceStatusComponent } from "./ServiceStatusComponent";
import { GetAllServices, UseBranchesByCompanyId } from "../../lib/usersBEClient";
import { ClientConfigContext } from "../../context/clientConfigContext";
import ClientStatusAccount from "./ClientStatusAccount";
import { CompanieUsersTable } from "./CompanieUsersTable";
import { ClientCompanyAdminInformationComponent } from "./ClientCompanyAdminInformationComponent";
import { useParams } from "react-router-dom";
import { AdditionalContacts } from "./AdditionalContacts";
import { ServiceInfoProvider } from "../../context/serviceInfoContext";
import { ServiceLicencesComponent } from "../Clients/SubComponents/ServiceLicencesComponent";

export interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

export interface ClientInfoTabsProps {
   group: boolean;
   menuState: number;
}

export function TabPanel(props: Readonly<TabPanelProps>) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

export function a11yProps(index: number) {
   return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   };
}

export function ClientInfoTabs(props: Readonly<ClientInfoTabsProps>) {
   const [value, setValue] = useState(0);
   const { clientInfo, group } = useContext(ClientConfigContext);
   const { clientId } = useParams();
   const [branches, setBranches] = useState<any[]>();
   const { data: services } = GetAllServices();
   const servicesOrder = [
      "Canal de denuncias",
      "Programa de cumplimiento",
      "Gobierno corporativo",
      "Beneficiario controlador",
   ];

   useEffect(() => {
      const clientInfoEffect = async () => {
         if (!props.group) {
            const responseBranches = await UseBranchesByCompanyId(clientId);
            setBranches(responseBranches);
         } else if (group.invoiceCompany) {
            const responseBranches = await UseBranchesByCompanyId(group.invoiceCompany._id);
            setBranches(responseBranches);
         }
      };
      clientInfoEffect();
      // eslint-disable-next-line
   }, [clientInfo, clientId, group]);

   const listBranches = !branches
      ? []
      : branches.map((e) => ({
           _id: e._id,
           name: e.name,
           state: e.state,
           city: e.city,
           phoneNumber: e.phoneNumber,
           address: e.address,
           zipCode: e.zipCode,
           disabled: e.disabled,
           createdAt: e.createdAt,
        }));

   useEffect(() => {
      if (!props.group && props.menuState === 1) {
         setValue(0);
      }
   }, [props.menuState, props.group]);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   return (
      <>
         {props.group ? (
            <></>
         ) : props.menuState === 0 ? (
            <>
               <Box
                  display="flex"
                  sx={{ borderBottom: 1, borderTopRightRadius: 5, borderTopLeftRadius: 5, borderColor: "#E0E0E0" }}
                  bgcolor="white"
                  boxShadow={2}
               >
                  <Tabs value={value} onChange={handleChange}>
                     <Tab sx={{ fontSize: 14, fontWeight: 600 }} label="Información general" {...a11yProps(0)} />
                     <Tab sx={{ fontSize: 14, fontWeight: 600 }} label="Facturas" {...a11yProps(1)} />
                  </Tabs>
               </Box>
               <TabPanel value={value} index={0}>
                  <ClientInformationHome />
                  <Box my={2}>
                     <BranchesTableClientInfo branches={listBranches} />
                  </Box>
                  <AdditionalContacts />
               </TabPanel>
               <TabPanel value={value} index={1}>
                  <InvoiceClientInfoComponent />
                  <ClientStatusAccount />
               </TabPanel>
            </>
         ) : props.menuState === 1 ? (
            <>
               <Box
                  display="flex"
                  sx={{ borderBottom: 1, borderTopRightRadius: 5, borderTopLeftRadius: 5, borderColor: "#E0E0E0" }}
                  bgcolor="white"
                  boxShadow={2}
                  width={"100%"}
               >
                  <Tabs value={value} onChange={handleChange}>
                     <Tab
                        sx={{ fontSize: 14, fontWeight: 600 }}
                        label="Información de administrador de sistema"
                        {...a11yProps(0)}
                     />
                  </Tabs>
               </Box>
               <TabPanel value={value} index={0}>
                  <ClientCompanyAdminInformationComponent group={false} />
               </TabPanel>

               <Box
                  display="flex"
                  sx={{ borderBottom: 1, borderTopRightRadius: 5, borderTopLeftRadius: 5, borderColor: "#E0E0E0" }}
                  bgcolor="white"
                  boxShadow={2}
                  mt={2}
               >
                  <Tabs value={value} onChange={handleChange}>
                     <Tab
                        sx={{ fontSize: 14, fontWeight: 600, cursor: "default" }}
                        label="Información de usuarios"
                        {...a11yProps(0)}
                     />
                  </Tabs>
               </Box>
               <TabPanel index={0} value={value}>
                  <CompanieUsersTable group={false} />
               </TabPanel>
            </>
         ) : (
            <>
               <Box
                  display="flex"
                  sx={{ borderBottom: 1, borderColor: "divider", borderTopRightRadius: 5, borderTopLeftRadius: 5 }}
                  bgcolor="white"
                  boxShadow={2}
               >
                  <Tabs value={value} onChange={handleChange}>
                     {services
                        .filter((s) => s.service !== "Gestión interna" && s.service !== "Programa de cumplimiento")
                        .sort((a, b) => {
                           const aKey = a.service;
                           const bKey = b.service;
                           return servicesOrder.indexOf(aKey) - servicesOrder.indexOf(bKey);
                        })
                        .map((service, index) => (
                           <Tab
                              key={index}
                              sx={{ fontSize: 14, fontWeight: 600 }}
                              label={service.service}
                              {...a11yProps(index)}
                           />
                        ))}
                  </Tabs>
               </Box>
               <ServiceInfoProvider>
                  {services
                     .filter((s) => s.service !== "Gestión interna" && s.service !== "Programa de cumplimiento")
                     .sort((a, b) => {
                        const aKey = a.service;
                        const bKey = b.service;
                        return servicesOrder.indexOf(aKey) - servicesOrder.indexOf(bKey);
                     })
                     .map((service, index) => (
                        <TabPanel key={index} value={value} index={index}>
                           <Box mb={2} display={"flex"} flexDirection={"column"} rowGap={2}>
                              <ServiceLicencesComponent modify={true} service={service} />
                           </Box>
                        </TabPanel>
                     ))}
                  <ServiceStatusComponent />
               </ServiceInfoProvider>
            </>
         )}
      </>
   );
}
