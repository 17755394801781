import { Grid, Modal } from "@mui/material";

interface ReLogInModalProps {
   open: boolean;
   setOpen: Function;
}

export default function ReLogInModal(props: ReLogInModalProps) {
   const handleClose = (reason) => {
      if (reason === "backdropClick") return;
      props.setOpen(false);
   };

   return (
      <div>
         <Modal
            disableEscapeKeyDown
            open={props.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: "flex" }}
         >
            <Grid container sx={grid}>
               <Grid item xs={12} sx={topRow}>
                  <label htmlFor="text" style={{ fontSize: "20px", fontWeight: "bold" }}>
                     Favor de volver a iniciar sesión
                  </label>
               </Grid>

               <Grid item xs={12} sx={middleRow}>
                  <label
                     htmlFor="text"
                     style={{ fontSize: "14px", paddingLeft: 20, paddingRight: 20, textAlign: "center" }}
                  >
                     Debido al cambio de contraseña, se requiere que vuelva a iniciar sesión. Se le redirigirá
                     automáticamente a la pantalla de inicio.
                  </label>
               </Grid>
               {/* <Grid item xs={12} sx={bottomRow}>
                  <Button sx={{ height: "70%" }} variant="contained" color="primary" onClick={props.onConfirm}>
                     Confirmar
                  </Button>
               </Grid> */}
            </Grid>
         </Modal>
      </div>
   );
}

const grid = {
   display: "flex",
   width: "450px",
   height: "220px",
   margin: "auto",
   backgroundColor: (theme) => theme.palette.background.paper,
   borderRadius: "8px",
};

const topRow = {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   //    height: 100,
};

const middleRow = {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   border: "solid #EBEBEB",
   borderWidth: "thin 0px",
   height: "36%",
};
