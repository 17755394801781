import { Box, Button } from "@mui/material";
import { useContext } from "react";
import { ComplaintStepperContext } from "../../../context/complaintStepperContext";
import { DocumentsTable } from "../DocumentsTable";
import { UserContext } from "../../../context/UserContext";

export const IncompetenceStep = () => {
   const { isLastStep, setOpenCompleteDialog } = useContext(ComplaintStepperContext);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Denuncias internas");

   return (
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "50vh" }}>
         <Box sx={{ flex: 2 }}>
            <DocumentsTable
               rows={[
                  {
                     document: "Declaración de incompetencia (Oficial de cumplimiento)",
                     templateLink: "incompetencia/1",
                     documentName: "declaracion-incompetencia-oc",
                     fileHeader: "Agregar documento declaración de incompetencia (Oficial de cumplimiento):",
                  },
                  {
                     document: "Declaración de incompetencia (Miembro del órgano interno de control)",
                     templateLink: "incompetencia/2",
                     documentName: "declaracion-incompetencia-moic",
                     fileHeader:
                        "Agregar documento declaración de incompetencia (Miembro del órgano interno de control):",
                  },
               ]}
               disabled={access}
            />
         </Box>
         {!access ? (
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  mt: 2,
                  mr: 2,
                  alignItems: "center",
               }}
            >
               <Box sx={{ flex: "1 1 auto" }} />
               <Box sx={{ maxHeight: 30 }}>
                  <Button onClick={() => setOpenCompleteDialog(true)} variant="outlined">
                     {isLastStep() ? "Finalizar investigación" : `Completar incompetencia`}
                  </Button>
               </Box>
            </Box>
         ) : null}
      </Box>
   );
};
