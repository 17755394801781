import React from "react";
import { Box, Typography, Divider, Button, CircularProgress } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Form, Formik, FormikProps } from "formik";
import { InputTextField } from "../../Inputs/InputTextField";
import { SnackBarContext } from "../../../context/snackBarContext";
import Toolbar from "@mui/material/Toolbar";
import { ConfirmationModal } from "../../ConfirmationModal";
import { GetReceiptByCompanyId, useDeleteCompanyInvoice, useUpdateReceipt } from "../../../lib/usersBEClient";
import { ClientConfigContext } from "../../../context/clientConfigContext";
import { modifyReceipt } from "../../../lib/Validations/inputSchemas";
import { UseGetPresignedURLUpload, uploadFileToS3 } from "../../../lib/s3Client";
import { CustomInputFiles } from "../../Inputs/CustomInputFiles";

interface ModifyInvoiceDrawerProps {
   setOpen: Function;
   receiptId: string;
   open: boolean;
   receiptData: any;
   file1: string;
   file2: string;
   file1Name: string;
   file2Name: string;
}

export const ModifyReceiptDrawer = (props: ModifyInvoiceDrawerProps) => {
   const [uploadedReceiptBlob1, setUploadedReceiptBlob1] = React.useState<File>();
   const [uploadedReceiptBlob2, setUploadedReceiptBlob2] = React.useState<File>();
   const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = React.useState(false);
   const { showSnackBar } = React.useContext(SnackBarContext);
   const inputRef = React.useRef(null);
   const { mutate: deleteInvoice, isLoading: deleteLoading } = useDeleteCompanyInvoice();
   const { mutate: updateReceipt, isLoading: updateLoading } = useUpdateReceipt(props.receiptId);
   const { clientInfo, group } = React.useContext(ClientConfigContext);
   const { refetch: receiptRefetch } = GetReceiptByCompanyId(clientInfo ? clientInfo._id : group.invoiceCompany._id);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [currentDate, setCurrentDate] = React.useState<number>();

   const fetchFiles = async () => {
      setIsLoading(true);
      const file1 = await fetch(props.file1).then(async (data) => {
         const newBlob = await data.blob();
         const filePdf = new File([newBlob], props.file1Name, { type: "application/pdf" });
         return filePdf;
      });
      const file2 = await fetch(props.file2).then(async (data) => {
         const newBlob = await data.blob();
         const fileXml = new File([newBlob], props.file2Name, { type: "text/xml" });
         return fileXml;
      });
      setUploadedReceiptBlob1(file1);
      setUploadedReceiptBlob2(file2);
      setIsLoading(false);
   };

   const handleUploadFiles = async (values) => {
      const urlReceipt1 = await UseGetPresignedURLUpload({
         bucket: "files-lecosy",
         folder: `receipts/${clientInfo._id}/${values.paymentDate.split("-")[0]}/${values.paymentDate.split("-")[1]}`,
         name: uploadedReceiptBlob1.name,
      });
      const urlReceipt2 = await UseGetPresignedURLUpload({
         bucket: "files-lecosy",
         folder: `receipts/${clientInfo._id}/${values.paymentDate.split("-")[0]}/${values.paymentDate.split("-")[1]}`,
         name: uploadedReceiptBlob2.name,
      });
      await uploadFileToS3(urlReceipt1.data, uploadedReceiptBlob1);
      await uploadFileToS3(urlReceipt2.data, uploadedReceiptBlob2);
   };

   async function handleSubmit(values) {
      if (!uploadedReceiptBlob1 || !uploadedReceiptBlob2) {
         setIsLoading(false);
         return showSnackBar("Favor de adjuntar documentos", true);
      }
      if (new Date(values.paymentDate).getTime() >= currentDate) {
         setIsLoading(false);
         return showSnackBar("La fecha de pago no puede ser igual o mayor al dia actual", true);
      }
      updateReceipt(
         {
            name: values.name,
            paymentDate: values.paymentDate,
            file1: uploadedReceiptBlob1.name,
            file2: uploadedReceiptBlob2.name,
         },
         {
            onSuccess: () => {
               handleUploadFiles(values);
               showSnackBar("Los datos se han modificado", false);
               receiptRefetch();
               setTimeout(() => {
                  props.setOpen(false);
               }, 1000);
            },
            onError: () => {
               setUploadedReceiptBlob1(null);
               setUploadedReceiptBlob2(null);
               inputRef.current.value = null;
               showSnackBar("Error al modificar los datos", true);
            },
         }
      );
   }

   React.useEffect(() => {
      const date = new Date().getTime();
      setCurrentDate(date);
      fetchFiles();
      // eslint-disable-next-line
   }, []);

   const handleMasterUserDelete = async () => {
      deleteInvoice(props.receiptId, {
         onSuccess: () => {
            showSnackBar("Factura eliminada", false);
            setTimeout(() => {
               setOpenDeleteConfirmationModal(false);
               props.setOpen(false);
            }, 1000);
         },
         onError: () => {
            showSnackBar("Error al eliminar la factura", true);
         },
      });
   };

   return (
      <>
         <Toolbar />
         {props.receiptData.paymentDate ? (
            <>
               <Box display="flex" maxWidth={450} width={420} mt={1}>
                  <Box
                     zIndex={2}
                     height="100%"
                     position="fixed"
                     display="flex"
                     alignItems="center"
                     onClick={() => props.setOpen(false)}
                     sx={{
                        "&:hover": {
                           bgcolor: "#2d435710",
                        },
                     }}
                  >
                     <ChevronRightIcon fontSize="small" />
                  </Box>
                  <Box flex={1} ml={2.5}>
                     <Box px={2} py={1} position="fixed" width="100%" zIndex={1}>
                        <Typography variant="h5">Modificar complemento de pago</Typography>
                        <Divider flexItem sx={{ my: 2 }} />
                     </Box>
                     <Box pr={1} py={1} pl={0.5} mt={8}>
                        <Formik
                           initialValues={{
                              name: props.receiptData.name,
                              paymentDate: props.receiptData.paymentDate.split("T")[0],
                           }}
                           onSubmit={async (values) => {
                              await handleSubmit(values);
                           }}
                           validationSchema={modifyReceipt}
                        >
                           {(formProps: FormikProps<any>) => (
                              <Form>
                                 <Box m={2}>
                                    <InputTextField
                                       variant="standard"
                                       multiline
                                       size="small"
                                       name="name"
                                       id="name"
                                       type="text"
                                       label="Nombre de factura"
                                       fullWidth
                                       value={formProps.values.name}
                                    />
                                 </Box>
                                 <Box m={2}>
                                    <Typography fontSize={12}>Fecha de envió</Typography>
                                    <InputTextField
                                       fullWidth
                                       variant="standard"
                                       size="small"
                                       name="paymentDate"
                                       id="paymentDate"
                                       type="date"
                                       value={formProps.values.paymentDate}
                                    />
                                 </Box>
                                 <Box m={2}>
                                    <Box display="flex" justifyContent="flex-start" flexDirection={"column"}>
                                       <Typography>Complemento de pago</Typography>
                                       {!isLoading ? (
                                          <CustomInputFiles
                                             file1={uploadedReceiptBlob1}
                                             setFile1={setUploadedReceiptBlob1}
                                             file2={uploadedReceiptBlob2}
                                             setFile2={setUploadedReceiptBlob2}
                                          ></CustomInputFiles>
                                       ) : (
                                          <CircularProgress sx={{ alignSelf: "center" }} />
                                       )}
                                    </Box>
                                 </Box>
                                 <Divider sx={{ mb: 1 }} />
                                 <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                                    <>
                                       <Button
                                          variant="outlined"
                                          color="error"
                                          onClick={() => {
                                             setOpenDeleteConfirmationModal(true);
                                          }}
                                       >
                                          {!deleteLoading ? "Eliminar" : <CircularProgress size={24} color="success" />}
                                       </Button>
                                       <Button variant="contained" type="submit">
                                          {!updateLoading ? "Guardar" : <CircularProgress size={24} color="success" />}
                                       </Button>
                                    </>
                                 </Box>
                              </Form>
                           )}
                        </Formik>
                     </Box>
                  </Box>
               </Box>
               <ConfirmationModal
                  open={openDeleteConfirmationModal}
                  setOpen={setOpenDeleteConfirmationModal}
                  title="Eliminar factura"
                  body={
                     <Typography>
                        {`¿Estás seguro de eliminar a ${props.receiptData.name}?`}
                        <Typography>Sus datos serán borrados permanentemente.</Typography>
                     </Typography>
                  }
                  onConfirm={handleMasterUserDelete}
               />
            </>
         ) : (
            <></>
         )}
      </>
   );
};
