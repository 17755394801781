import { useContext, useState } from "react";
import { Box, Stack, Divider, Typography, Toolbar, IconButton, Tooltip } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Form, Formik } from "formik";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../ConfirmationModal";
import { updateMasterUserPassword } from "../../lib/usersBEClient";
import { userChangePasswordSchema } from "../../lib/Validations/inputSchemas";
import { SnackBarContext } from "../../context/snackBarContext";
import { InputTextField } from "../Inputs/InputTextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "../LoadingButton";

interface ModifyPasswordDrawerProps {
   open: boolean;
   setOpen: Function;
   user: any;
   setModifiedPassword: Function;
}

export const ModifyPasswordDrawer = (props: ModifyPasswordDrawerProps) => {
   const { logoutUser } = useContext(UserContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState({
      passwordOriginal: "",
      passwordNew: "",
   });
   const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
   const [showPwd, setShowPwd] = useState(false);
   const [showNewPwd, setNewShowPwd] = useState(false);
   const navigate = useNavigate();

   function handleOpen() {
      props.setOpen(!props.open);
   }

   const modifyUserPassword = async () => {
      try {
         setIsLoading(true);
         await updateMasterUserPassword(data.passwordOriginal, data.passwordNew);
         showSnackBar("La contraseña fue modificada correctamente.", false);
         props.setModifiedPassword(true);
         props.setOpen(!props.open);
         setIsLoading(false);
         setTimeout(() => {
            logoutUser();
            navigate("/login");
         }, 4000);
      } catch (error: any) {
         showSnackBar("La contraseña no fue modificada.", true);
         setIsLoading(false);
      }
   };

   const handleOpenConfirmationModal = (values) => {
      setData({
         passwordNew: values.passwordNew,
         passwordOriginal: values.passwordOriginal,
      });
      setOpenConfirmationModal(true);
   };

   return (
      <Box sx={{ width: 400, display: "flex", maxHeight: "100vh", height: "100%" }} role="presentation">
         <Box
            display="flex"
            alignItems="center"
            onClick={handleOpen}
            sx={{
               "&:hover": {
                  bgcolor: "#F7F7F7",
               },
            }}
         >
            <ChevronRightIcon fontSize="small" />
         </Box>
         <Box sx={{ display: "flex", flexDirection: "column", flex: 1, mb: 1, mt: 2 }}>
            <Toolbar />
            <Box p={1}>
               <Typography variant="h5">{"Modificar contraseña"}</Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 1, mx: 1 }}>
               <Formik
                  initialValues={{
                     passwordOriginal: "",
                     passwordNew: "",
                     passwordNewConfirmation: "",
                  }}
                  onSubmit={(values) => {
                     handleOpenConfirmationModal(values);
                  }}
                  validationSchema={userChangePasswordSchema}
               >
                  <Form>
                     <Stack spacing={1} sx={{ alignItems: "baseline" }}>
                        <InputTextField
                           fullWidth
                           id="passwordOriginal"
                           name="passwordOriginal"
                           size="small"
                           label="Contraseña actual"
                           type={showPwd ? "text" : "password"}
                           variant="standard"
                           endAdornment={
                              <>
                                 <IconButton size="small" onClick={() => setShowPwd(!showPwd)}>
                                    {showPwd ? <VisibilityOff /> : <Visibility />}
                                 </IconButton>
                              </>
                           }
                        />
                        <InputTextField
                           fullWidth
                           id="passwordNew"
                           name="passwordNew"
                           size="small"
                           label="Nueva contraseña"
                           type={showNewPwd ? "text" : "password"}
                           variant="standard"
                           endAdornment={
                              <>
                                 <IconButton size="small" onClick={() => setNewShowPwd(!showNewPwd)}>
                                    {showNewPwd ? <VisibilityOff /> : <Visibility />}
                                 </IconButton>
                                 <Tooltip
                                    title={
                                       "Se requiere al menos una mayúscula, una minúscula, un dígito, un carácter especial y no espacios en blanco."
                                    }
                                 >
                                    <InfoIcon fontSize="small" color="action" />
                                 </Tooltip>
                              </>
                           }
                        />
                        <InputTextField
                           fullWidth
                           id="passwordNewConfirmation"
                           name="passwordNewConfirmation"
                           size="small"
                           label="Confirmar nueva contraseña"
                           type={showNewPwd ? "text" : "password"}
                           variant="standard"
                        />
                     </Stack>
                     <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                        <LoadingButton isLoading={isLoading} label="Guardar" />
                     </Box>
                  </Form>
               </Formik>
            </Box>
         </Box>
         <ConfirmationModal
            open={openConfirmationModal}
            setOpen={setOpenConfirmationModal}
            title="Modificar contraseña"
            body={
               <Box>
                  <Typography>¿Seguro que deseas modificar la contraseña?</Typography>
                  <Typography>Este cambio requiere volver a iniciar sesión.</Typography>
               </Box>
            }
            onConfirm={modifyUserPassword}
         />
      </Box>
   );
};
