import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

interface GroupClientCreationTabsProps {
   value: number;
   setValue: Function;
   completed: boolean;
}

function a11yProps(index: number) {
   return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
   };
}

const steps = ["Grupo","Empresas", "Administrador del sistema","Usuarios", "Facturas",];

export const GroupClientCreationTabs = (props: GroupClientCreationTabsProps) => {
   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      props.setValue(newValue);
   };

   return (
      <Box
         sx={{
            border: 1,
            borderColor: "#E0E0E0",
         }}
      >
         <Tabs
            orientation="vertical"
            // variant="scrollable"
            value={props.value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
         >
            {steps.map((step, index) => (
               <Tab label={step} {...a11yProps(index)} disabled={!props.completed && index !== 0} />
            ))}
         </Tabs>
      </Box>
   );
};
