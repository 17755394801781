import React, { useEffect, useState } from "react";
import {
   CircularProgress,
   IconButton,
   MenuItem,
   Stack,
   TextField,
   Typography,
   Box,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TablePagination,
   TableRow,
   TableSortLabel,
   Paper,
   Tooltip,
   Collapse,
   SxProps,
   styled,
   Chip,
   Popover,
   Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { visuallyHidden } from "@mui/utils";
import { Order, getComparator, stableSort } from "../const/globalConst";
import { Refresh } from "@mui/icons-material";
import { EmptyDataTableCell } from "./EmptyDataTableCell";
import TableComponentContentMap from "./TableComponentHeader/TableComponentContentMap";

const ListItem = styled("li")(({ theme }) => ({
   margin: theme.spacing(0.5),
}));

export interface HeadCell {
   field: string;
   headerName: string;
   width?: number;
   type:
      | "string"
      | "date"
      | "number"
      | "button"
      | "image"
      | "status"
      | "disabled"
      | "completed"
      | "check"
      | "collapsible"
      | "popover"
      | "list"
      | "file";
   align?: "right" | "left" | "inherit" | "center" | "justify";
   icon?: React.ReactNode;
   onClick?: (e, row, group?: boolean) => void;
}

interface EnhancedTableProps {
   onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
   order: Order;
   orderBy: string;
   headers: HeadCell[];
   headerColor?: boolean;
}

function EnhancedTableHead(props: Readonly<EnhancedTableProps>) {
   const { order, orderBy, onRequestSort } = props;
   const createSortHandler = (newOrderBy: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
   };

   return (
      <TableHead>
         <TableRow sx={{ bgcolor: props.headerColor ? "#2d4357" : "white" }}>
            {props.headers.map((headCell) => (
               <TableCell
                  key={headCell.field}
                  align={headCell.align ? headCell.align : "center"}
                  sortDirection={orderBy === headCell.field ? order : false}
                  width={headCell.width}
               >
                  <TableSortLabel
                     sx={{
                        ".MuiTableSortLabel-icon": {
                           color: props.headerColor ? "#f2f4f4 !important" : "#64748B !important",
                        },
                     }}
                     hideSortIcon={headCell.field === "icon" || headCell.field === "logo"}
                     active={orderBy === headCell.field}
                     direction={orderBy === headCell.field ? order : "asc"}
                     onClick={createSortHandler(headCell.field)}
                  >
                     <Typography color={props.headerColor ? "#f2f4f4" : "#64748B"} fontWeight={600}>
                        {headCell.headerName}
                     </Typography>
                     {orderBy === headCell.field ? (
                        <Box component="span" sx={visuallyHidden}>
                           {order === "desc" ? "sorted descending" : "sorted ascending"}
                        </Box>
                     ) : null}
                  </TableSortLabel>
               </TableCell>
            ))}
         </TableRow>
      </TableHead>
   );
}

export interface TableComponentFilter {
   id: string;
   label: string;
   type: "select" | "text" | "number" | "date";
   allOption: string;
   data: string[];
}

export interface TableComponentProps {
   headers: HeadCell[];
   rows?: any[];
   selectedRows?: readonly string[];
   setSelected?: Function;
   defaultColumnToOrder: string;
   defaultOrder: Order;
   defaultRowsPerPage: number;
   rowsPerPageOptions: number[];
   icon?: any;
   loader: boolean;
   emptyDataText: string;
   onClick: (e, row, group?) => void;
   onClickCollapsible?: (e, row, group?) => void;
   disableBorders?: boolean;
   dense?: boolean;
   filters?: TableComponentFilter[];
   filtersInitialState?: any;
   showBadges?: boolean;
   checkboxSelection?: boolean;
   multiselect?: boolean;
   collapsible?: boolean;
   collapsiblesubActivity?: boolean;
   opened?: string;
   setOpened?: Function;
   upRows?: any[];
   withoutfilterText?: boolean;
   headerColor?: boolean;
}

export const TableComponent = (props: TableComponentProps) => {
   const [order, setOrder] = useState<Order>(props.defaultOrder);
   const [orderBy, setOrderBy] = useState(props.defaultColumnToOrder);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(props.defaultRowsPerPage);
   const [filters, setFilters] = useState(props.filtersInitialState);
   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
   const [isHovered, setIsHovered] = useState(null);
   const handleRequestSort = (event: React.MouseEvent<unknown>, newOrderBy: string) => {
      const isAsc = orderBy === newOrderBy && order === "asc";
      const toggledOrder = isAsc ? "desc" : "asc";
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
   };
   const allRows = props.upRows ? [...props.upRows, ...props.rows] : props.rows;
   useEffect(() => {
      setPage(0);
   }, [props.rows, props.upRows, filters]);

   const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handlePopoverClose = () => {
      setIsHovered(null);
      setAnchorEl(null);
   };

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(updatedRowsPerPage);
      setPage(0);
   };

   const filteredRows = (rows: any[]) => {
      let newFilters: any[] = rows;
      if (props.filters) {
         for (const e of props.filters) {
            if (filters[e.id] !== e.allOption) {
               if (e.type === "select") {
                  newFilters = newFilters.filter((row) => row[e.id].includes(filters[e.id]));
               }
               if (e.type === "text" || e.type === "number" || e.type === "date") {
                  newFilters = newFilters.filter((row) => row[e.id].startsWith(filters[e.id]));
               }
            }
         }
      }
      return newFilters;
   };

   const handleFilters = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldId: string) => {
      setFilters({ ...filters, [fieldId]: e.target.value });
   };

   const filterStackSx = {
      display: "flex",
      bgcolor: "white",
      p: "8px 16px",
      borderRadius: 1,
      boxShadow: props.disableBorders ? 0 : 1,
      alignItems: "center",
      mb: 1,
   };

   const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
      if (props.multiselect) {
         const selectedIndex = props.selectedRows.indexOf(row.id);
         let newSelected: readonly string[] = [];

         if (selectedIndex === -1) {
            newSelected = newSelected.concat(props.selectedRows, row.id);
         } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(props.selectedRows.slice(1));
         } else if (selectedIndex === props.selectedRows.length - 1) {
            newSelected = newSelected.concat(props.selectedRows.slice(0, -1));
         } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
               props.selectedRows.slice(0, selectedIndex),
               props.selectedRows.slice(selectedIndex + 1)
            );
         }
         props.setSelected(newSelected);
      } else {
         const isSelected = props.selectedRows.includes(row.id);
         if (isSelected) {
            props.setSelected([]);
         } else {
            props.setSelected([row.id]);
         }
         props.onClick(event, row);
      }
   };

   const isSelected = (name: string) => (props.selectedRows ? props.selectedRows.indexOf(name) !== -1 : false);

   // Avoid a layout jump when reaching the last page with empty rows.
   // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows(props.rows).length) : 0;

   const rowStyle: SxProps = {
      bgcolor: "white",
      border: 1,
      borderColor: "#E0E0E0",
      borderBottom: 1,
      borderBottomColor: "#E0E0E0",
      ":hover": { bgcolor: "#E0E0E0", cursor: "pointer" },
   };

   return (
      <Box sx={{ width: "100%" }}>
         {props.filters && (
            <Stack direction={"row"} spacing={1.5} sx={filterStackSx}>
               <Typography> {props.withoutfilterText ? "" : "Filtros"} </Typography>
               {props.filters.map((field, i) => {
                  if (field.type === "select") {
                     return (
                        <TextField
                           key={`${field.id}-filter-${i}`}
                           fullWidth
                           select
                           value={filters[field.id]}
                           onChange={(e) => handleFilters(e, field.id)}
                           label={field.label}
                           size="small"
                        >
                           {field.data.map((element, i) => (
                              <MenuItem key={element + i} value={element}>
                                 {element}
                              </MenuItem>
                           ))}
                           <MenuItem value={field.allOption}>{field.allOption}</MenuItem>
                        </TextField>
                     );
                  }
                  if (field.type === "text") {
                     return (
                        <TextField
                           key={`${field.id}-filter-${i}`}
                           fullWidth
                           value={filters[field.id]}
                           onChange={(e) => handleFilters(e, field.id)}
                           label={field.label}
                           size="small"
                        />
                     );
                  }
                  if (field.type === "number") {
                     return (
                        <TextField
                           key={`${field.id}-filter-${i}`}
                           fullWidth
                           type="number"
                           value={filters[field.id]}
                           onChange={(e) => handleFilters(e, field.id)}
                           label={field.label}
                           size="small"
                        />
                     );
                  }
                  if (field.type === "date") {
                     return (
                        <TextField
                           key={`${field.id}-filter-${i}`}
                           fullWidth
                           type="date"
                           value={filters[field.id]}
                           onChange={(e) => handleFilters(e, field.id)}
                           label={filters[field.id] === "" ? "" : field.label}
                           size="small"
                        />
                     );
                  }
                  return <></>;
               })}

               <IconButton
                  onClick={() => setFilters(props.filtersInitialState)}
                  size="small"
                  disabled={filters === props.filtersInitialState}
               >
                  <Tooltip title="Restablecer filtros">
                     <Refresh />
                  </Tooltip>
               </IconButton>
            </Stack>
         )}
         <Paper sx={{ width: "100%" }} elevation={props.disableBorders ? 0 : 2}>
            <TableContainer>
               <Table sx={{ minWidth: 450 }} aria-labelledby="tableTitle" size={props.dense ? "small" : "medium"}>
                  <EnhancedTableHead
                     order={order}
                     orderBy={orderBy}
                     onRequestSort={handleRequestSort}
                     headers={props.headers}
                     headerColor={props.headerColor}
                  />
                  <TableBody>
                     {props.loader ? (
                        <TableRow>
                           <TableCell align="center" colSpan={props.headers ? props.headers.length : 0}>
                              <CircularProgress size={24} />
                           </TableCell>
                        </TableRow>
                     ) : props.rows.length > 0 ? (
                        <>
                           {stableSort(
                              filteredRows(allRows).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), //cambiar aqui para ver como agregar las filas que no se deben modificar
                              getComparator(order, orderBy)
                           ).map((row: any, index) => {
                              const isUpRow = props.upRows?.includes(row);
                              const isItemSelected = isSelected(row.id);
                              const labelId = `enhanced-table-checkbox-${index}`;
                              return (
                                 <React.Fragment key={index}>
                                    <TableRow
                                       role="checkbox" //cambiar
                                       onClick={(e) =>
                                          isUpRow
                                             ? null
                                             : props.icon
                                             ? null
                                             : props.checkboxSelection
                                             ? handleClick(e, row)
                                             : props.onClick(e, row)
                                       }
                                       aria-checked={isItemSelected}
                                       key={`vr-${index}`}
                                       selected={isItemSelected}
                                       sx={{
                                          cursor: isUpRow ? "default" : "pointer",
                                          bgcolor: isUpRow ? "#E4E4E9" : "auto",
                                          borderLeft: props.showBadges && row.incoming ? 2 : 0,
                                          borderLeftColor: props.showBadges && row.incoming ? "#162C44" : "",
                                          "&:hover": {
                                             bgcolor: "#E4E4E7",
                                          },
                                       }}
                                    >
                                       {props.headers.map((header, index) => (
                                          <TableCell
                                             id={labelId}
                                             align={
                                                header.field === "category" ||
                                                header.field === "body" ||
                                                header.field === "name" ||
                                                header.field === "comercialName" ||
                                                header.align === "left"
                                                   ? "left"
                                                   : header.align === "right"
                                                   ? "right"
                                                   : "center"
                                             }
                                             key={`tc${index}`}
                                             padding={props.checkboxSelection ? "checkbox" : "normal"}
                                          >
                                             {header.type === "popover" ? (
                                                <>
                                                   {`${row[header.field][0]}`}
                                                   {row[header.field].length - 1 > 0 ? (
                                                      <IconButton
                                                         onMouseEnter={() =>
                                                            setIsHovered(`${row._id}${row[header.field]}`)
                                                         }
                                                         sx={{ p: 0, mx: 1, bgcolor: "#F5F5F5" }}
                                                      >
                                                         <Box
                                                            sx={{ borderRadius: 5, px: 1 }}
                                                            display={"flex"}
                                                            aria-owns={
                                                               isHovered === `${row._id}${row[header.field]}`
                                                                  ? `${row._id}${row[header.field]}}`
                                                                  : undefined
                                                            }
                                                            aria-haspopup="true"
                                                            onMouseEnter={handlePopoverOpen}
                                                            onMouseLeave={handlePopoverClose}
                                                         >
                                                            <Typography fontWeight={600}>{`+${
                                                               row[header.field].length - 1
                                                            }`}</Typography>

                                                            <Popover
                                                               id={`${row._id}${row[header.field]}`}
                                                               sx={{
                                                                  pointerEvents: "none",
                                                                  boxShadow: "none",
                                                               }}
                                                               open={isHovered === `${row._id}${row[header.field]}`}
                                                               anchorEl={anchorEl}
                                                               anchorOrigin={{
                                                                  vertical: "bottom",
                                                                  horizontal: "left",
                                                               }}
                                                               transformOrigin={{
                                                                  vertical: "top",
                                                                  horizontal: "left",
                                                               }}
                                                               onClose={handlePopoverClose}
                                                               disableRestoreFocus
                                                            >
                                                               <Box p={1}>
                                                                  {row[header.field].slice(1).map((c) => (
                                                                     <Typography key={c} fontSize={"14px"}>
                                                                        {c}
                                                                     </Typography>
                                                                  ))}
                                                               </Box>
                                                            </Popover>
                                                         </Box>
                                                      </IconButton>
                                                   ) : null}
                                                </>
                                             ) : (
                                                TableComponentContentMap(row, props, {
                                                   isUpRow,
                                                   isItemSelected,
                                                   labelId,
                                                   header,
                                                   collapsible: props.collapsiblesubActivity,
                                                })
                                             )}
                                          </TableCell>
                                       ))}
                                    </TableRow>
                                    {props.collapsible || props.collapsiblesubActivity ? (
                                       <TableRow sx={{ bgcolor: "#F5F5F5" }}>
                                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                             <Collapse in={props.opened === row.id} timeout="auto" unmountOnExit>
                                                <Box sx={{ margin: 1 }}>
                                                   <Table size="small" aria-label="purchases">
                                                      {props.collapsible ? (
                                                         <>
                                                            <TableHead>
                                                               <TableRow>
                                                                  <TableCell
                                                                     variant="head"
                                                                     sx={{ fontWeight: 600 }}
                                                                     align="center"
                                                                  >
                                                                     Nombre de la empresa
                                                                  </TableCell>
                                                                  <TableCell
                                                                     variant="head"
                                                                     sx={{ fontWeight: 600 }}
                                                                     align="center"
                                                                  >
                                                                     Usuarios
                                                                  </TableCell>
                                                                  <TableCell
                                                                     variant="head"
                                                                     sx={{ fontWeight: 600 }}
                                                                     align="center"
                                                                  >
                                                                     Módulos
                                                                  </TableCell>
                                                                  <TableCell
                                                                     variant="head"
                                                                     sx={{ fontWeight: 600 }}
                                                                     align="center"
                                                                  >
                                                                     Fecha de creación
                                                                  </TableCell>
                                                               </TableRow>
                                                            </TableHead>

                                                            <TableBody>
                                                               {row.companies.length < 1 ? (
                                                                  <TableRow key={"companies._id"}>
                                                                     <EmptyDataTableCell
                                                                        text={
                                                                           "No hay empresas registrados por el momento"
                                                                        }
                                                                     />
                                                                  </TableRow>
                                                               ) : (
                                                                  <>
                                                                     {row.companies.map((companies) => (
                                                                        <TableRow
                                                                           sx={rowStyle}
                                                                           key={companies._id}
                                                                           onClick={(e) => {
                                                                              props.onClickCollapsible(e, companies);
                                                                           }}
                                                                        >
                                                                           <TableCell
                                                                              component="th"
                                                                              scope="row"
                                                                              align="center"
                                                                           >
                                                                              {companies.comercialName}
                                                                           </TableCell>
                                                                           <TableCell
                                                                              component="th"
                                                                              scope="row"
                                                                              align="center"
                                                                           >
                                                                              {companies.users}
                                                                           </TableCell>
                                                                           <TableCell
                                                                              component="th"
                                                                              scope="row"
                                                                              align="center"
                                                                              width={450}
                                                                           >
                                                                              <Box
                                                                                 sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    flexWrap: "wrap",
                                                                                    listStyle: "none",
                                                                                    m: 0,
                                                                                    maxWidth: 400,
                                                                                 }}
                                                                                 component="ul"
                                                                              >
                                                                                 {companies.services.length > 0
                                                                                    ? companies.services.map(
                                                                                         (service: string) => {
                                                                                            return (
                                                                                               <ListItem key={service}>
                                                                                                  <Chip
                                                                                                     sx={{
                                                                                                        bgcolor:
                                                                                                           "#64748B",
                                                                                                        color: "white",
                                                                                                     }}
                                                                                                     label={service}
                                                                                                  />
                                                                                               </ListItem>
                                                                                            );
                                                                                         }
                                                                                      )
                                                                                    : "Ninguno"}
                                                                              </Box>
                                                                           </TableCell>
                                                                           <TableCell
                                                                              component="th"
                                                                              scope="row"
                                                                              align="center"
                                                                           >
                                                                              {companies.createdAt}
                                                                           </TableCell>
                                                                        </TableRow>
                                                                     ))}
                                                                  </>
                                                               )}
                                                            </TableBody>
                                                         </>
                                                      ) : (
                                                         <>
                                                            <TableHead>
                                                               <TableRow>
                                                                  <TableCell
                                                                     variant="head"
                                                                     sx={{ fontWeight: 600 }}
                                                                     align="center"
                                                                  >
                                                                     Actividad económica especifica
                                                                  </TableCell>
                                                                  <TableCell
                                                                     variant="head"
                                                                     sx={{ fontWeight: 600 }}
                                                                     align="center"
                                                                  >
                                                                     Actividades que incluye
                                                                  </TableCell>
                                                                  <TableCell
                                                                     variant="head"
                                                                     sx={{ fontWeight: 600 }}
                                                                     align="center"
                                                                  >
                                                                     Fecha de actualización
                                                                  </TableCell>
                                                                  <TableCell
                                                                     variant="head"
                                                                     sx={{ fontWeight: 600 }}
                                                                     align="center"
                                                                  >
                                                                     Modificar
                                                                  </TableCell>
                                                               </TableRow>
                                                            </TableHead>

                                                            <TableBody>
                                                               {!row.sub_activities ||
                                                               row?.sub_activities.length < 1 ? (
                                                                  <TableRow key={"sub_activities._id"}>
                                                                     <EmptyDataTableCell
                                                                        text={
                                                                           "No tiene actividades especificas asignadas"
                                                                        }
                                                                     />
                                                                  </TableRow>
                                                               ) : (
                                                                  <>
                                                                     {row.sub_activities.map((sub_activity) => (
                                                                        <TableRow
                                                                           sx={rowStyle}
                                                                           key={"sub_activities._id"}
                                                                        >
                                                                           <TableCell
                                                                              component="th"
                                                                              scope="row"
                                                                              align="center"
                                                                              sx={{ maxWidth: 50 }}
                                                                           >
                                                                              {sub_activity.name
                                                                                 ? sub_activity.name.length > 130
                                                                                    ? sub_activity.name
                                                                                         .substring(0, 130)
                                                                                         .concat("...")
                                                                                    : sub_activity.name
                                                                                 : "No aplica"}
                                                                           </TableCell>
                                                                           <TableCell
                                                                              component="th"
                                                                              scope="row"
                                                                              align="center"
                                                                              sx={{ maxWidth: 50 }}
                                                                           >
                                                                              <Typography
                                                                                 maxHeight={100}
                                                                                 sx={{
                                                                                    textOverflow: "ellipsis",
                                                                                    overflow: "hidden",
                                                                                 }}
                                                                                 title={
                                                                                    sub_activity.observations ||
                                                                                    "No aplica"
                                                                                 }
                                                                              >
                                                                                 {sub_activity.observations
                                                                                    ? sub_activity.observations.length >
                                                                                      130
                                                                                       ? sub_activity.observations
                                                                                            .substring(0, 130)
                                                                                            .concat("...")
                                                                                       : sub_activity.observations
                                                                                    : "No aplica"}
                                                                              </Typography>
                                                                           </TableCell>
                                                                           <TableCell
                                                                              component="th"
                                                                              scope="row"
                                                                              align="center"
                                                                           >
                                                                              {sub_activity.updatedAt}
                                                                           </TableCell>
                                                                           <TableCell
                                                                              component="th"
                                                                              scope="row"
                                                                              align="center"
                                                                           >
                                                                              <Button>
                                                                                 <EditIcon
                                                                                    fontSize="small"
                                                                                    sx={{ color: "gray" }}
                                                                                    onClick={(e) => {
                                                                                       props.onClick(e, sub_activity);
                                                                                    }}
                                                                                 />
                                                                              </Button>
                                                                           </TableCell>
                                                                        </TableRow>
                                                                     ))}
                                                                  </>
                                                               )}
                                                            </TableBody>
                                                         </>
                                                      )}
                                                   </Table>
                                                </Box>
                                             </Collapse>
                                          </TableCell>
                                       </TableRow>
                                    ) : null}
                                 </React.Fragment>
                              );
                           })}
                        </>
                     ) : (
                        <TableRow>
                           <EmptyDataTableCell text={props.emptyDataText} />
                        </TableRow>
                     )}
                  </TableBody>
               </Table>
            </TableContainer>
         </Paper>
         <TablePagination
            rowsPerPageOptions={props.rowsPerPageOptions}
            component="div"
            count={filteredRows(allRows).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
         />
      </Box>
   );
};
