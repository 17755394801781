import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";

interface ComplainantAndWitnessCardsProps {
   info: any[];
   isWitness: boolean;
}

export const ComplainantAndWitnessCards = (props: ComplainantAndWitnessCardsProps) => {
   const [expanded, setExpanded] = useState<string | false>(false);

   const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
   };

   const items = (): any[] => {
      const content: any[] = [];

      props.info.forEach((e, i) => {
         content.push(
            <Accordion
               key={`acc-${i}`}
               expanded={expanded === `panel${i}`}
               onChange={handleChange(`panel${i}`)}
               sx={{ borderRadius: 0 }}
            >
               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                     <PersonIcon />
                     <Typography sx={{ p: 1 }}>
                        {e.name !== "" ? `${e.name}  ${e.lastName}` : `Testigo ${i + 1}`}
                     </Typography>
                  </Box>
               </AccordionSummary>
               <AccordionDetails>
                  <Box sx={{ px: 2 }}>
                     <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                        <Box flex={1}>
                           <Typography>
                              <strong style={{ fontWeight: 500 }}>Género </strong>
                           </Typography>
                           <Typography gutterBottom>{e.gender}</Typography>
                        </Box>
                        <Box flex={1}>
                           <Typography>
                              <strong style={{ fontWeight: 500 }}>Puesto </strong>
                           </Typography>
                           <Typography gutterBottom>{e.position}</Typography>
                        </Box>
                     </Box>
                     <Box>
                        <Typography fontWeight={500}>Observaciones</Typography>
                        <Typography gutterBottom>{e.observations}</Typography>
                     </Box>
                  </Box>
               </AccordionDetails>
            </Accordion>
         );
      });
      return content.map((e) => e);
   };

   const rows = items();

   return (
      <Box sx={{ maxHeight: "350px", overflowY: "scroll", m: 2 }}>
         {rows.map((e) => {
            return e;
         })}
      </Box>
   );
};
