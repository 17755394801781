import { useState, useEffect, useContext } from "react";
import { Box, Typography, Divider, Grid, Button, Drawer } from "@mui/material";
import { getDateFromISODate } from "../../const/globalConst";
import { ClientConfigContext } from "../../context/clientConfigContext";
import { UpdateCompanyAdminUserDrawer } from "./Drawer/UpdateCompanyAdminUser";
import ErrorIcon from "@mui/icons-material/Error";
import { UserContext } from "../../context/UserContext";

interface ClientCompanyAdminInfoProps {
   group: boolean;
}

export const ClientCompanyAdminInformationComponent = (props: ClientCompanyAdminInfoProps) => {
   const { group, clientInfo } = useContext(ClientConfigContext);
   const [adminUser, setAdminUser] = useState<any>(null);
   const [openModifyCompanyAdminUser, setOpenModifyCompanyAdminUser] = useState(false);
   const groupCheck = !!props.group;
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Clientes");

   const fetchCompanyAdminUser = async () => {
      try {
         const adminUserFinded = props.group ? group.admin : clientInfo.company_details.admin;
         setAdminUser(adminUserFinded);
      } catch (error) {
         console.error("Error fetching roles:", error);
      }
   };

   useEffect(() => {
      fetchCompanyAdminUser();
      // eslint-disable-next-line
   }, [group, clientInfo]);

   return (
      <Box bgcolor="white" sx={{ borderBottomRightRadius: 5, borderBottomLeftRadius: 5 }} boxShadow={2} p={1}>
         {adminUser ? (
            <>
               <Box m={4} display={"flex"} flexDirection={"column"}>
                  <Grid container rowSpacing={4} columnSpacing={6} px={1}>
                     <Grid item xs={4}>
                        <Typography fontWeight={600}>Nombre(s):</Typography>
                        <Typography>{adminUser.firstName}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600}>Apellidos:</Typography>
                        <Typography>{adminUser.lastName}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600}>Correo electrónico:</Typography>
                        <Typography>{adminUser.email}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600}>Teléfono:</Typography>
                        <Typography>{adminUser.phoneNumber}</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600}>Tipo de acceso:</Typography>
                        <Typography>FullAccess</Typography>
                     </Grid>
                     <Grid item xs={4}>
                        <Typography fontWeight={600}>Fecha de creación:</Typography>
                        <Typography>{adminUser.createdAt ? getDateFromISODate(adminUser.createdAt) : ""}</Typography>
                     </Grid>
                  </Grid>
               </Box>
               <Divider />
               <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} ml={3}>
                  <Typography variant="body2">
                     Ultima modificación: {adminUser.updatedAt ? getDateFromISODate(adminUser.updatedAt) : ""}
                  </Typography>
                  {!access ? (
                     <Button
                        variant="contained"
                        sx={{ marginLeft: "auto", marginRight: 2, my: 2 }}
                        onClick={() => setOpenModifyCompanyAdminUser(true)}
                     >
                        <Typography fontSize={14}>Modificar información</Typography>
                     </Button>
                  ) : null}
               </Box>
            </>
         ) : (
            <Box width={"100%"} display={"flex"} justifyContent={"center"} p={1}>
               <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", color: "#2D4357" }}>
                  <ErrorIcon sx={{ width: "18px" }} />
                  <Typography sx={{ p: 1 }} fontSize={"17px"}>
                     No existe administrador registrado.
                  </Typography>
                  <Button
                     variant="contained"
                     sx={{ marginLeft: "auto", marginRight: 2, my: 2 }}
                     onClick={() => setOpenModifyCompanyAdminUser(true)}
                  >
                     <Typography fontSize={14}>Modificar información</Typography>
                  </Button>
               </Box>
            </Box>
         )}

         <Drawer
            hideBackdrop
            anchor="right"
            open={openModifyCompanyAdminUser}
            onClose={() => setOpenModifyCompanyAdminUser(false)}
            sx={{ maxWidth: 450 }}
         >
            <UpdateCompanyAdminUserDrawer
               isModify={!!adminUser}
               setOpen={setOpenModifyCompanyAdminUser}
               adminUserData={adminUser}
               group={groupCheck}
               updateAdminInfo={fetchCompanyAdminUser}
            />
         </Drawer>
      </Box>
   );
};
