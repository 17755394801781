import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useRef } from "react";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export const LineChart = ({ scores, labels, labelNames }) => {
   const chartLabels: any = useRef();
   const colors = ["#162c44", "#8a95a0"];
   return (
      <Line
         className="line-chart"
         ref={chartLabels}
         data={{
            datasets: scores.map((e: [], index) => {
               return {
                  label: labelNames[index],
                  data: e,
                  tension: 0.4,
                  borderWidth: 0.7,
                  borderColor: colors[index],
                  pointRadius: 0, //Tamaño de puntos
                  pointBackgroundColor: "#162c44",
                  fill: true,
                  backgroundColor: colors[index] + "90",
               };
            }),
            labels,
         }}
         options={{
            plugins: {
               datalabels: { display: false },
               legend: {
                  display: true,
                  labels: { boxWidth: 20 },
                  position: "bottom",
                  fullSize: false,
                  align: "start",
                  onHover: function (event) {
                     //@ts-ignore
                     event.native.target.style.cursor = "pointer";
                  },
               },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
               x: { grid: { color: "transparent" } },
               y: { min: 0, grid: { color: "transparent" } },
            },
         }}
      />
   );
};

export const SingleLineChart = ({ scores, labels, labelNames }) => {
   return (
      <Line
         data={{
            datasets: [
               {
                  label: labelNames[2],
                  data: scores,
                  tension: 0.4,
                  borderWidth: 0.7,
                  borderColor: "colors[index]",
                  pointRadius: 0,
                  pointBackgroundColor: "#162c44",
               },
            ],
            labels,
         }}
         options={{
            //   line: { tension: 0.2 },
            plugins: {
               datalabels: { display: false },
               legend: { display: false, labels: { boxWidth: 3 }, position: "right", align: "start" },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
               x: { min: 0, grid: { color: "transparent" } },
               y: { min: 0, grid: { color: "transparent" } },
            },
         }}
      />
   );
};
