import { createContext, useEffect, useContext, useMemo, useState } from "react";
import { getInboxComplaints } from "../../lib/lecosyBackendClient";
import { UserContext } from "../UserContext";
import { getAllBranches } from "../../lib/usersBEClient";

interface ComplaintContextInferface {
   complaints: any[];
   newComplaintsCount: number;
   refetch: Function;
   loadingComplaints: boolean;
   branches: BranchesInterface[];
   loadingBranches: boolean;
}

export type ComplaintsInterface = {
   reportNumber: string;
   companyBranch: string;
   category: string;
   complaintType: string;
   complainerType: string;
   status: string;
   createdAt: string;
   incoming: boolean;
};

export type BranchesInterface = {
   _id: any;
   name: string;
   disabled: boolean;
   company: any;
};

export const ComplaintContext = createContext<ComplaintContextInferface>({
   complaints: [],
   newComplaintsCount: 0,
   refetch: () => {},
   loadingComplaints: true,
   branches: [],
   loadingBranches: true,
});

export const ComplaintProvider = ({ children }) => {
   const { user, GetAccess } = useContext(UserContext);
   const [branches, setBranches] = useState<any[]>();
   const [complaints, setComplaints] = useState([]);
   const [loadingBranches, setLoadingBranches] = useState(true);
   const [loadingComplaints, setLoadingComplaints] = useState(true);
   const access = GetAccess(["Denuncias externas", "Denuncias internas"]);

   const refetch = async () => {
      try {
         const inboxComplaints = await getInboxComplaints(branches);
         setComplaints(inboxComplaints);
         setLoadingComplaints(false);
         return null;
      } catch (error: any) {
         if (error.message === "Network Error") setLoadingComplaints(false);
         return error;
      }
   };

   const getComplaints = () => {
      let newComplaintsValue = 0;
      !loadingComplaints &&
         complaints.forEach((e) => {
            if (e.complaint.incoming) {
               newComplaintsValue++;
            }
         });
      return newComplaintsValue;
   };
   // eslint-disable-next-line
   const newComplaintsCount = useMemo(getComplaints, [complaints]); //No moverle

   useEffect(() => {
      const refetchComplaint = async () => {
         try {
            if (user.auth && access) {
               const branchesResponse = await getAllBranches();
               setBranches(branchesResponse);
               setLoadingBranches(false);
               const refetched = await refetch();
               if (refetched?.message === "Network Error") setComplaints([]);
            }
         } catch (error) {
            setLoadingBranches(true);
            setBranches([]);
         }
      };
      if (user.auth && access) {
         refetchComplaint();
      }
      const interval = setInterval(refetchComplaint, 40000);
      return () => clearInterval(interval);
      // eslint-disable-next-line
   }, [user.auth, loadingBranches]);

   return (
      <ComplaintContext.Provider
         value={{
            complaints,
            newComplaintsCount,
            refetch,
            loadingComplaints,
            branches,
            loadingBranches,
         }}
      >
         {children}
      </ComplaintContext.Provider>
   );
};
