import { Route } from "react-router-dom";
import { ComplaintsInboxScreen } from "../screens/Complaints/ExternComplaints/ComplaintsInboxScreen";
import { ComplaintReviewScreen } from "../screens/Complaints/ComplaintReviewScreen";
import { ActiveComplaintsScreen } from "../screens/Complaints/ExternComplaints/ActiveComplaintsScreen";
import { ComplaintInfoScreen } from "../screens/Complaints/Intern Complaints/ComplaintInfoScreen";
import { RejectedComplaintsScreen } from "../screens/Complaints/ExternComplaints/RejectedComplaintsScreen";
import { InternInboxScreen } from "../screens/Complaints/Intern Complaints/InternInboxScreen";
import { InternActiveScreen } from "../screens/Complaints/Intern Complaints/InternActiveScreen";
import { IncompetenceScreen } from "../screens/Complaints/Intern Complaints/DocumentsForms/IncompetenceScreen";
import { InexistentConstancyScreen } from "../screens/Complaints/Intern Complaints/DocumentsForms/InexistenceConstancyScreen";
import { AnonymousComplainerScreen } from "../screens/Complaints/Intern Complaints/DocumentsForms/AnonymousComplainerScreen";
import { AbsenseConstancyScreen } from "../screens/Complaints/Intern Complaints/DocumentsForms/AbsenseConstancyScreen";
import { InterviewNotificaionScreen } from "../screens/Complaints/Intern Complaints/DocumentsForms/InterviewNotificationScreen";
import { WitnessInterview } from "../screens/Complaints/Intern Complaints/DocumentsForms/WitnessInterview";
import { OffenderInterview } from "../screens/Complaints/Intern Complaints/DocumentsForms/OffenderInterview";
import { ComplainantInterview } from "../screens/Complaints/Intern Complaints/DocumentsForms/ComplainantInterview";
import { ResolutionScreen } from "../screens/Complaints/Intern Complaints/DocumentsForms/ResolutionScreen";
import { ComplaintResolutionScreen } from "../screens/Complaints/Intern Complaints/DocumentsForms/ComplaintResolutionScreen";
import { RecordClosureScreen } from "../screens/Complaints/Intern Complaints/DocumentsForms/RecordClosureScreen";
import { InternRejectedScreen } from "../screens/Complaints/Intern Complaints/InternRejectedScreen";
import { ComplaintQueryBankScreen } from "../screens/Complaints/Intern Complaints/ComplaintQueryBankScreen";
import { ResolutionNotificaionScreen } from "../screens/Complaints/Intern Complaints/DocumentsForms/ResolutionNotificationScreen";
import { PrivateRoute } from "../components/RouteComponents/PrivateRoute";

export const useComplaintChannelRoutes = () => {
   return (
      <>
         <Route element={<PrivateRoute section="Denuncias externas" />}>
            <Route path="buzon-de-denuncias" element={<ComplaintsInboxScreen />} />
            <Route path="buzon-de-denuncias/:reportNumber" element={<ComplaintReviewScreen intern={false} />} />
            <Route path="denuncias-activas" element={<ActiveComplaintsScreen />} />
            <Route path="denuncias-activas/:reportNumber" element={<ComplaintInfoScreen includeStepper={false} />} />
            <Route path="denuncias-finalizadas" element={<RejectedComplaintsScreen />} />
            <Route
               path="denuncias-finalizadas/:reportNumber"
               element={<ComplaintInfoScreen includeStepper={false} />}
            />
         </Route>
         <Route element={<PrivateRoute section="Denuncias internas" />}>
            <Route path="internas/buzon-de-denuncias" element={<InternInboxScreen />} />
            <Route path="internas/buzon-de-denuncias/:reportNumber" element={<ComplaintReviewScreen intern={true} />} />
            <Route path="internas/denuncias-activas" element={<InternActiveScreen />} />
            <Route
               path="internas/denuncias-activas/:reportNumber"
               element={<ComplaintInfoScreen includeStepper={true} />}
            />
            <Route
               path="internas/denuncias-activas/:reportNumber/incompetencia/:involved"
               element={<IncompetenceScreen />}
            />
            <Route
               path="internas/denuncias-activas/:reportNumber/inexistencia/:towards"
               element={<InexistentConstancyScreen />}
            />
            <Route
               path="internas/denuncias-activas/:reportNumber/entrevista/4"
               element={<AnonymousComplainerScreen />}
            />
            <Route
               path="internas/denuncias-activas/:reportNumber/incomparecencia/:towards"
               element={<AbsenseConstancyScreen />}
            />
            <Route
               path="internas/denuncias-activas/:reportNumber/notificacion/:type/:number"
               element={<InterviewNotificaionScreen />}
            />
            <Route
               path="internas/denuncias-activas/:reportNumber/:witness/entrevista/1"
               element={<WitnessInterview />}
            />
            <Route
               path="internas/denuncias-activas/:reportNumber/:offender/entrevista/2"
               element={<OffenderInterview />}
            />
            <Route path="internas/denuncias-activas/:reportNumber/entrevista/3" element={<ComplainantInterview />} />
            <Route path="internas/denuncias-activas/:reportNumber/acuse-resolucion" element={<ResolutionScreen />} />
            <Route
               path="internas/denuncias-activas/:reportNumber/resolucion-queja"
               element={<ComplaintResolutionScreen />}
            />
            <Route path="internas/denuncias-activas/:reportNumber/acuse-cierre" element={<RecordClosureScreen />} />
            <Route path="internas/denuncias-finalizadas" element={<InternRejectedScreen />} />
            <Route
               path="internas/denuncias-finalizadas/:reportNumber"
               element={<ComplaintInfoScreen includeStepper={true} />}
            />
            <Route path="internas/denuncias-finalizadas/:reportNumber/acuse-cierre" element={<RecordClosureScreen />} />
            <Route
               path="internas/banco-de-consultas/:reportNumber"
               element={<ComplaintInfoScreen includeStepper={false} />}
            />
            <Route
               path="internas/denuncias-activas/:reportNumber/notificacion/5"
               element={<ResolutionNotificaionScreen />}
            />
         </Route>
         <Route element={<PrivateRoute section="Banco de consultas" />}>
            <Route path="internas/banco-de-consultas" element={<ComplaintQueryBankScreen />} />
         </Route>
      </>
   );
};
