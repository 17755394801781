import React, { createContext, useState } from "react";

interface ISnackBarContext {
   showSnackBar: (msg: string, isError: boolean) => void;
   hideSnackBar: () => void;
   snackBarOpen: boolean;
   snackBarMessage: string;
   isErrorSnackBar: boolean;
}

export const SnackBarContext = createContext<ISnackBarContext>({
   showSnackBar: () => {},
   hideSnackBar: () => {},
   snackBarOpen: false,
   snackBarMessage: "",
   isErrorSnackBar: false,
});

export const SnackBarProvider = ({ children }) => {
   const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
   const [snackBarMessage, setSnackBarMessage] = useState<string>("");
   const [isErrorSnackBar, setIsErrorSnackBar] = useState<boolean>(false);

   function showSnackBar(msg: string, isError: boolean) {
      setIsErrorSnackBar(isError);
      setSnackBarMessage(msg);
      setSnackBarOpen(true);
   }
   function hideSnackBar() {
      setSnackBarOpen(false);
      setTimeout(() => {
         setSnackBarMessage("");
         setIsErrorSnackBar(false);
      }, 500);
   }

   return (
      <SnackBarContext.Provider value={{ showSnackBar, hideSnackBar, snackBarOpen, snackBarMessage, isErrorSnackBar }}>
         {children}
      </SnackBarContext.Provider>
   );
};
