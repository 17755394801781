import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { ComplaintInternContext } from "../../../context/complaintsInternContext/complaintInternContext";
import { changeIncomingComplaint } from "../../../lib/lecosyBackendClient";
import { HeadCell, TableComponent } from "../../../components/TableComponent";
import { useNavigate } from "react-router-dom";
import { ComplaintContext } from "../../../context/complaintsExternContext/complaintContext";

const columns: HeadCell[] = [
   { field: "reportNumber", headerName: "No. de Reporte", type: "string" },
   { field: "company", headerName: "Empresa/Sucursal", type: "string" },
   { field: "complaintType", headerName: "Tipo", type: "string" },
   { field: "complainerType", headerName: "Denunciante", type: "string" },
   { field: "category", headerName: "Categoría", type: "string" },
   { field: "createdAt", headerName: "Recepción", type: "date" },
   { field: "status", headerName: "Estatus", type: "status" },
];

export const InternComplaintsInboxTable = () => {
   const { loadingBranches } = useContext(ComplaintContext);

   const { internComplaints, getInternComplaints, isLoading } = useContext(ComplaintInternContext);
   const navigate = useNavigate();
   const [branchesFilter, setBranchesFilter] = useState<string[]>([]);
   const [categoryFilter, setCategoryFilter] = useState<string[]>([]);

   useEffect(() => {
      if (!loadingBranches) {
         getInternComplaints();
         getFiltersAndCategories();
      }
      // eslint-disable-next-line
   }, [loadingBranches]);

   const getFiltersAndCategories = () => {
      const branchesArray = [];
      const categoryArray = [];
      !isLoading &&
         internComplaints?.forEach((c) => {
            if (!branchesArray.includes(c.company)) {
               branchesArray.push(c.company);
            }
            if (!categoryArray.includes(c.category)) {
               categoryArray.push(c.category);
            }
         });
      setBranchesFilter(branchesArray);
      setCategoryFilter(categoryArray);
   };

   const seenComplaint = async (reportNumber: string) => {
      await changeIncomingComplaint(reportNumber);
      await getInternComplaints();
   };

   return (
      <Box sx={{ width: "100%" }}>
         <TableComponent
            showBadges
            headerColor
            defaultColumnToOrder="createdAt"
            defaultOrder="asc"
            defaultRowsPerPage={10}
            rowsPerPageOptions={[5, 10, 25]}
            emptyDataText="No hay denuncias en este momento"
            headers={columns}
            loader={isLoading}
            onClick={async (e, row) => {
               await seenComplaint(row.reportNumber);
               return navigate(row.reportNumber);
            }}
            rows={internComplaints || []}
            filters={[
               {
                  allOption: "Todas",
                  data: branchesFilter,
                  id: "company",
                  label: "Empresas/Sucursales",
                  type: "select",
               },
               { allOption: "", data: [], id: "reportNumber", label: "No. de reporte", type: "number" },
               { allOption: "Todas", data: categoryFilter, id: "category", label: "Categoría", type: "select" },
            ]}
            filtersInitialState={{
               company: "Todas",
               reportNumber: "",
               category: "Todas",
            }}
         />
      </Box>
   );
};
