import { useContext, useEffect, useState } from "react";
import { Box, Container, CssBaseline, Typography, Divider, Fab, Tooltip } from "@mui/material";
import LecosyLogoWhite from "../assets/LecosyLogo.png";
import { LoginMasterUser } from "../lib/usersBEClient";
import { UserContext } from "../context/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { LoadingButton } from "../components/LoadingButton";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { InputBaseMod } from "../components/Inputs/InputBase";
import { Form, Formik } from "formik";
import { SnackBarContext } from "../context/snackBarContext";
import { userLoginSchema } from "../lib/Validations/inputSchemas";
import { SupportContext } from "../context/supportContext";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const LoginScreen = () => {
   const { loginUser, user } = useContext(UserContext);
   const { setSupportOpen } = useContext(SupportContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [showPassword, setShowPassword] = useState(false);
   const navigate = useNavigate();
   const { mutate, isLoading: loadingSession } = LoginMasterUser();

   useEffect(() => {
      const RedirectIfLoggedIn = () => {
         if (user.auth) {
            navigate("/inicio");
         }
      };
      RedirectIfLoggedIn();
      // eslint-disable-next-line
   }, [user]);

   const login = async (values) => {
      // ================== Use of Client SDK ===============================
      // const authResponse = await signInWithEmailAndPassword(auth, email, password);
      // const idToken = await authResponse.user.getIdToken();
      // const loginRes = await loginMU(idToken);
      // loginUser();
      // ================== Use of Admin SDK ===============================

      mutate(
         { email: values.email, password: values.password },
         {
            onError: (error: any) => {
               if (error.response.data.message === "Session in use") showSnackBar("Sesión en uso", true);
               else if (error.response.data.message === "email not verified")
                  showSnackBar("Correo electrónico no verificado", true);
               else if (error.response.data.message === "invalid credentials")
                  showSnackBar("Usuario o contraseña incorrectos", true);
               else showSnackBar("Error, inténtelo de nuevo más tarde", true);
            },
            onSuccess: () => {
               loginUser();
               navigate("/");
            },
         }
      );
   };

   return (
      <>
         <CssBaseline />
         <Box display={"flex"} justifyContent="center" height="100vh" bgcolor={"#F5F5F5"}>
            <Container
               maxWidth="xl"
               sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
               }}
            >
               <Box
                  flex={1}
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: { md: "center", xs: "flex-end" },
                     pb: { xs: 5, md: 0 },
                  }}
               >
                  <Box
                     component={"img"}
                     sx={{ maxHeight: { md: 300, xs: 120 }, maxWidth: { md: 400, xs: 280 } }}
                     alt="Logo Lecosy"
                     src={LecosyLogoWhite}
                  />
               </Box>
               <Box
                  flex={1}
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: { md: "center", xs: "none" },
                  }}
               >
                  <Box sx={{ bgcolor: "white", boxShadow: 1, borderRadius: 6, px: 4, py: 1, maxHeight: 380 }}>
                     <Typography variant="h6" sx={{ m: 2 }} textAlign="center" color={"#142c44"}>
                        Accede a tu cuenta
                     </Typography>
                     <Divider sx={{ bgcolor: "#162c44" }} />
                     <Formik
                        initialValues={{
                           email: "",
                           password: "",
                        }}
                        validationSchema={userLoginSchema}
                        onSubmit={login}
                     >
                        <Form>
                           <Box
                              sx={{
                                 display: "flex",
                                 flexDirection: "column",
                                 mx: 2,
                                 mt: 4,
                                 minWidth: { md: 350, xs: 190 },
                                 alignItems: "center",
                              }}
                           >
                              <Box sx={{ display: "flex", minHeight: 50, minWidth: "100%" }}>
                                 <InputBaseMod
                                    id="email"
                                    name="email"
                                    type="email"
                                    fullWidth
                                    placeholder="Usuario"
                                    sx={{
                                       bgcolor: "#64748BC2",
                                       color: "white",
                                       borderTopRightRadius: 5,
                                       borderBottomRightRadius: 5,
                                       px: 3,
                                       boxShadow: "0px 1.5px 2px grey",
                                       minHeight: "100%",
                                    }}
                                    icon={<PersonIcon sx={{ color: "white", fontSize: "30px" }} />}
                                 />
                              </Box>

                              <Box sx={{ display: "flex", minHeight: 60, mt: 3, minWidth: "100%" }}>
                                 <InputBaseMod
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    fullWidth
                                    placeholder="Contraseña"
                                    sx={{
                                       bgcolor: "#64748BC2",
                                       color: "white",
                                       borderTopRightRadius: 5,
                                       borderBottomRightRadius: 5,
                                       px: 3,
                                       boxShadow: "0px 1.5px 2px grey",
                                       minHeight: "100%",
                                    }}
                                    icon={<LockIcon sx={{ color: "white", fontSize: "30px" }} />}
                                    eyeIcon={
                                       showPassword ? (
                                          <Tooltip title={"Ocultar"} onClick={() => setShowPassword(false)}>
                                             <VisibilityOffIcon
                                                sx={{
                                                   fontSize: "27px",
                                                   color: "#CCD1D1",
                                                   alignSelf: "center",
                                                   ml: 1,
                                                   ":hover": { cursor: "pointer" },
                                                }}
                                             />
                                          </Tooltip>
                                       ) : (
                                          <Tooltip title={"Mostrar"} onClick={() => setShowPassword(true)}>
                                             <VisibilityIcon
                                                sx={{
                                                   fontSize: "27px",
                                                   color: "#CCD1D1",
                                                   alignSelf: "center",
                                                   ml: 1,
                                                   ":hover": { cursor: "pointer" },
                                                }}
                                             />
                                          </Tooltip>
                                       )
                                    }
                                 />
                              </Box>
                              <Box sx={{ display: "flex", justifyContent: "center", minWidth: "100%" }}>
                                 <Link
                                    to={"/recuperar-contrasena"}
                                    style={{
                                       textDecoration: "none",
                                       textAlign: "center",
                                       margin: 16,
                                       color: "#142c44",
                                       fontSize: 15,
                                    }}
                                 >
                                    ¿Olvidaste tu contraseña?
                                 </Link>
                              </Box>
                              <LoadingButton label={"Iniciar sesión"} isLoading={loadingSession} type="submit" />
                           </Box>
                        </Form>
                     </Formik>
                  </Box>
               </Box>
            </Container>
            <Box
               sx={{
                  position: "absolute",
                  right: 40,
                  bottom: 40,
               }}
            >
               <Fab
                  onClick={() => {
                     setSupportOpen(true);
                  }}
                  sx={{
                     bgcolor: "#162c44",
                     "&:hover": {
                        bgcolor: "#162c44",
                     },
                  }}
               >
                  <SupportAgentIcon sx={{ color: "white" }} />
               </Fab>
            </Box>
         </Box>
      </>
   );
};

export default LoginScreen;
