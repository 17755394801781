import { Box, Tab, Tabs } from "@mui/material";
import React, { useContext, useState } from "react";
import { TabPanel } from "../ClientInfoTabs";
import { GroupInformationComponent } from "../GroupInformationComponent";
import { InvoiceGroupInfoComponent } from "../InvoiceGroupInfoComponent";
import ClientStatusAccount from "../ClientStatusAccount";
import { ClientConfigContext } from "../../../context/clientConfigContext";

const GroupInformation = () => {
   const [value, setValue] = useState(0);
   const { group } = useContext(ClientConfigContext);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   return (
      <Box>
         <Box
            display="flex"
            sx={{
               borderBottom: 1,
               borderTopRightRadius: 5,
               borderTopLeftRadius: 5,
               borderColor: "#E0E0E0",
            }}
            bgcolor="white"
            boxShadow={2}
         >
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
               <Tab label="Información general" />
               <Tab label="Facturas" />
            </Tabs>
         </Box>
         <TabPanel value={value} index={0}>
            <GroupInformationComponent />
         </TabPanel>
         <TabPanel value={value} index={1}>
            <InvoiceGroupInfoComponent />
            {group.invoiceCompany ? <ClientStatusAccount /> : null}
         </TabPanel>
      </Box>
   );
};

export default GroupInformation;
