import { useState, useContext, useEffect } from "react";
import { Box, Typography, Divider, Drawer } from "@mui/material";
import { FilterComponent } from "../../components/FilterComponent";
import EditIcon from "@mui/icons-material/Edit";
import { HeadCell, TableComponent } from "../../components/TableComponent";
import { ClientConfigContext } from "../../context/clientConfigContext";
import { UseInvoicesByCompanyId, getUrlS3 } from "../../lib/usersBEClient";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ModifyInvoiceDrawer } from "./Drawer/ModifyInvoiceDrawer";
import { FilesModalDownload } from "./Modal/filesModalDownload";

interface InvoiceTableComponentProps {
   invoices: any[];
   refetch: boolean;
   setRefetch: Function;
}

export const InvoiceTableComponent = (props: InvoiceTableComponentProps) => {
   const { clientInfo, group } = useContext(ClientConfigContext);
   const [openEditInvoiceDrawer, setOpenEditInvoiceDrawer] = useState(false);
   const [branchSelected, setBranchSelected] = useState(null);
   const [invoiceId, setInvoiceId] = useState("");
   const [invoicesFiltered, setInvoicesFiltered] = useState(props.invoices);
   const [originalInvoice, setOriginalInvoice] = useState(null);
   const [open, setOpen] = useState<boolean>(false);
   const [file1, setFile1] = useState<string>("");
   const [file2, setFile2] = useState<string>("");
   const [fileName1, setFileName1] = useState(null);
   const [fileName2, setFileName2] = useState(null);
   const [idInvoice, setIdInvoice] = useState(null);
   const [refetchI, setRefetchI] = useState(null);
   const [nameInvoice, setNameInvoice] = useState(null);

   const getFile1 = async (clientId: string, year: string, month: string, file1: string, id: string, name: string) => {
      const fileData1: string = await getUrlS3(
         "files-lecosy",
         { folder: `invoices/${clientId}/${year}/${month}` },
         file1
      );
      setNameInvoice(name);
      setIdInvoice(id);
      setFile1(fileData1);
      setFileName1(file1);
   };

   const getFile2 = async (clientId: string, year: string, month: string, file2: string) => {
      const fileData2: string = await getUrlS3(
         "files-lecosy",
         { folder: `invoices/${clientId}/${year}/${month}` },
         file2
      );
      setFile2(fileData2);
      setFileName2(file2);
   };

   useEffect(() => {
      if (refetchI || props.refetch) {
         const refetch = async () => {
            const invoiceFetch = await UseInvoicesByCompanyId(clientInfo ? clientInfo._id : group.invoiceCompany._id);
            setInvoicesFiltered(invoiceFetch);
            const listInvoices = invoiceFetch.map((e) => ({
               _id: e._id,
               name: e.name,
               startPeriod: e.startPeriod,
               unformatedDate: new Date(e.startPeriod.split("T")[0]).getTime(),
               endPeriod: e.endPeriod,
               unformatedDate2: new Date(e.endPeriod.split("T")[0]).getTime(),
               file1: e.file1,
               file2: e.file2,
            }));
            setOriginalInvoice(listInvoices);
            props.setRefetch(false);
            setRefetchI(false);
         };
         refetch();
      }
      // eslint-disable-next-line
   }, [props.refetch, refetchI, clientInfo ? clientInfo._id : group.invoiceCompany._id]);

   const branchesColumns: HeadCell[] = [
      { field: "name", headerName: "Nombre", type: "string", width: 50 },
      { field: "startPeriod", headerName: "Inicio", type: "date", width: 50 },
      { field: "endPeriod", headerName: "Cierre", type: "date", width: 50 },
      {
         field: "files",
         headerName: "Ver",
         type: "button",
         width: 50,
         icon: <VisibilityIcon fontSize="small" />,
         onClick: async (e, row) => {
            setOpen(true);
            await getFile1(
               clientInfo ? clientInfo._id : group.invoiceCompany._id,
               row.startPeriod.split("-")[0],
               row.startPeriod.split("-")[1],
               row.file1,
               row._id,
               row.name
            );
            await getFile2(
               clientInfo ? clientInfo._id : group.invoiceCompany._id,
               row.startPeriod.split("-")[0],
               row.startPeriod.split("-")[1],
               row.file2
            );
         },
      },
      {
         field: "modify",
         headerName: "Modificar",
         type: "button",
         width: 50,
         icon: <EditIcon fontSize="small" />,
         onClick: async (e, row) => {
            await getFile1(
               clientInfo ? clientInfo._id : group.invoiceCompany._id,
               row.startPeriod.split("-")[0],
               row.startPeriod.split("-")[1],
               row.file1,
               row._id,
               row.name
            );
            await getFile2(
               clientInfo ? clientInfo._id : group.invoiceCompany._id,
               row.startPeriod.split("-")[0],
               row.startPeriod.split("-")[1],
               row.file2
            );
            setOpenEditInvoiceDrawer(true);
            setBranchSelected(row);
            setInvoiceId(row._id);
         },
      },
   ];

   return (
      <>
         <Box sx={{ bgcolor: "white", boxShadow: 2, borderRadius: 2, width: "100%" }}>
            <Box sx={{ p: 2 }}>
               <Typography fontWeight={600}>Facturas</Typography>
            </Box>
            <Box sx={{ width: "100%", px: 2 }}>
               <FilterComponent
                  originaltableOneRows={props.refetch || refetchI ? originalInvoice : props.invoices}
                  tableOneFilteredRows={invoicesFiltered}
                  setTableOneFilteredRows={setInvoicesFiltered}
                  period={true}
                  disableRefresh
                  filters={["daterange"]}
               />
            </Box>
            <Divider />
            <TableComponent
               defaultColumnToOrder="name"
               defaultOrder="asc"
               defaultRowsPerPage={5}
               emptyDataText="No hay facturas registradas por el momento"
               rowsPerPageOptions={[5, 10, 15, 20]}
               loader={false}
               headers={branchesColumns}
               rows={invoicesFiltered}
               disableBorders
               onClick={() => {}}
            />
         </Box>
         <Drawer
            anchor="right"
            open={openEditInvoiceDrawer}
            hideBackdrop
            onClose={() => {
               setOpenEditInvoiceDrawer(false);
            }}
            sx={{ maxWidth: 450 }}
         >
            {openEditInvoiceDrawer ? (
               <ModifyInvoiceDrawer
                  setRefetch={setRefetchI}
                  setOpen={setOpenEditInvoiceDrawer}
                  invoiceId={invoiceId}
                  invoiceData={branchSelected}
                  file1={file1}
                  file2={file2}
                  file1Name={fileName1}
                  file2Name={fileName2}
               />
            ) : (
               <></>
            )}
         </Drawer>
         <FilesModalDownload
            setOpen={setOpen}
            open={open}
            file1URL={file1}
            file2URL={file2}
            file1Name={fileName1}
            file2Name={fileName2}
            id={idInvoice}
            name={nameInvoice}
            invoice={true}
         />
      </>
   );
};
