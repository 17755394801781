import {
   Button,
   Box,
   CircularProgress,
   Container,
   Divider,
   Typography,
   IconButton,
   Stack,
   Fab,
   Tooltip,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { UserContext } from "../context/UserContext";
import { SnackBarContext } from "../context/snackBarContext";
import { updateMasterUserPassword } from "../lib/usersBEClient";
import { userChangePasswordSchema } from "../lib/Validations/inputSchemas";
import { InputTextField } from "../components/Inputs/InputTextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { SystemThemeConfig } from "../config/systemTheme.config";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { SupportContext } from "../context/supportContext";
import InfoIcon from "@mui/icons-material/Info";

export const ValidatePasswordScreen = () => {
   const { logoutUser } = useContext(UserContext);
   const { setSupportOpen } = useContext(SupportContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState(false);
   const [showPwd, setShowPwd] = useState(false);
   const [showNewPwd, setNewShowPwd] = useState(false);
   const [data, setData] = useState({
      passwordOriginal: "",
      passwordNew: "",
   });
   const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
   const [logoutModalOpen, setLogoutModalOpen] = useState(false);
   const navigate = useNavigate();
   const logo = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png";

   const handleOpenConfirmationModal = (values) => {
      setData({
         passwordNew: values.passwordNew,
         passwordOriginal: values.passwordOriginal,
      });
      setOpenConfirmationModal(true);
   };
   const handleLogOutModalOpen = () => setLogoutModalOpen(true);
   const onLogoutButtonPress = () => {
      logoutUser();
      navigate("/login");
   };
   const modifyUserPassword = async () => {
      try {
         setIsLoading(true);
         await updateMasterUserPassword(data.passwordOriginal, data.passwordNew);
         showSnackBar("Contraseña actualizada", false);
         setIsLoading(false);
         setTimeout(() => {
            logoutUser();
            navigate("/login");
         }, 2000);
      } catch (error: any) {
         if (error.response.status === 400) {
            showSnackBar("Contraseña incorrecta", true);
            setIsLoading(false);
            setOpenConfirmationModal(false);
            return null;
         }
      }
   };
   return (
      <Box sx={{ bgcolor: "whitesmoke" }}>
         <SystemThemeConfig>
            <Container
               maxWidth={"xl"}
               sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  flexWrap: "wrap",
               }}
            >
               <Box flex={1} sx={{ display: "flex", justifyContent: "center", px: 1 }}>
                  <Box component={"img"} sx={{ maxHeight: 250, maxWidth: 350 }} alt="Logo Lecosy" src={logo} />
               </Box>
               <Box
                  sx={{
                     maxWidth: "700px",
                     minWidth: "500px",
                     flex: 1,
                     boxShadow: 2,
                     borderRadius: 2,
                     bgcolor: "white",
                     px: 8,
                     py: 4,
                  }}
               >
                  <Typography variant="h5" textAlign={"center"} sx={{ fontWeight: "bold", color: "#162c44", py: 2 }}>
                     Crea una nueva contraseña
                  </Typography>
                  <Divider color="black" variant="middle" sx={{ my: 1 }} />
                  <Formik
                     initialValues={{
                        passwordOriginal: "",
                        passwordNew: "",
                        passwordNewConfirmation: "",
                     }}
                     onSubmit={(values) => {
                        handleOpenConfirmationModal(values);
                     }}
                     validationSchema={userChangePasswordSchema}
                  >
                     <Form>
                        <Stack spacing={2}>
                           <InputTextField
                              fullWidth
                              variant="standard"
                              size="small"
                              name="passwordOriginal"
                              id="passwordOriginal"
                              type={showPwd ? "text" : "password"}
                              label="Contraseña actual"
                              endAdornment={
                                 <IconButton onClick={() => setShowPwd(!showPwd)}>
                                    {showPwd ? <VisibilityOff /> : <Visibility />}
                                 </IconButton>
                              }
                           />
                           <InputTextField
                              fullWidth
                              variant="standard"
                              size="small"
                              name="passwordNew"
                              id="passwordNew"
                              type={showNewPwd ? "text" : "password"}
                              label="Nueva contraseña"
                              endAdornment={
                                 <>
                                    <IconButton onClick={() => setNewShowPwd(!showNewPwd)}>
                                       {showNewPwd ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    <Tooltip
                                       title={
                                          "Se requiere al menos una mayúscula, una minúscula, un dígito, un carácter especial y no espacios en blanco."
                                       }
                                    >
                                       <InfoIcon fontSize="small" color="action" />
                                    </Tooltip>
                                 </>
                              }
                           />
                           <InputTextField
                              fullWidth
                              variant="standard"
                              size="small"
                              name="passwordNewConfirmation"
                              id="passwordNewConfirmation"
                              type={showNewPwd ? "text" : "password"}
                              label="Confirmar nueva contraseña"
                           />
                        </Stack>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                           <Box sx={{ display: "flex", mt: 2 }}>
                              <Button variant="outlined" onClick={handleLogOutModalOpen}>
                                 Salir
                              </Button>
                           </Box>
                           <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                              <Button variant="contained" type="submit">
                                 {!isLoading ? "Confirmar" : <CircularProgress size={24} color="success" />}
                              </Button>
                           </Box>
                        </Box>
                     </Form>
                  </Formik>
               </Box>
               <ConfirmationModal
                  open={openConfirmationModal}
                  setOpen={setOpenConfirmationModal}
                  title="Nueva contraseña"
                  body={
                     <Box>
                        <Typography>Estás a punto de cambiar tu contraseña.</Typography>
                        <Typography>Será necesario iniciar sesión con tu nueva contraseña.</Typography>
                     </Box>
                  }
                  onConfirm={modifyUserPassword}
               />
               <ConfirmationModal
                  open={logoutModalOpen}
                  setOpen={setLogoutModalOpen}
                  onConfirm={onLogoutButtonPress}
                  title={"Cerrar sesión"}
                  body={"¿Estás seguro que deseas salir?"}
               />
            </Container>
            <Box
               sx={{
                  position: "absolute",
                  right: 40,
                  bottom: 40,
               }}
            >
               <Fab
                  onClick={() => {
                     setSupportOpen(true);
                  }}
                  sx={{
                     bgcolor: "#162c44",
                     "&:hover": {
                        bgcolor: "#162c44",
                     },
                  }}
               >
                  <SupportAgentIcon sx={{ color: "white" }} />
               </Fab>
            </Box>
         </SystemThemeConfig>
      </Box>
   );
};
