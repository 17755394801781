import React, { useState } from "react";
import { Alert, Box, Container, CssBaseline, Snackbar, Stack, TextField, Typography, Button } from "@mui/material";
import { emailRegex } from "../const/globalConst";
import { restoreMasterUserPassword } from "../lib/usersBEClient";
import { Link } from "react-router-dom";
import { SystemThemeConfig } from "../config/systemTheme.config";

export const ForgotPasswordScreen = () => {
   const [email, setEmail] = useState("");
   const [emailError, setEmailError] = useState(false);
   const [snackbarMessage, setSnackbarMessage] = useState("");
   const [snackbarisVisible, setSnackbarisVisible] = useState(false);
   const [snackbarIsError, setSnackbarIsError] = useState(true);

   const handleEmail = (event) => {
      setEmail(event.target.value);
   };

   const handleSendInstructions = async () => {
      if (!email) {
         setEmailError(true);
         setSnackbarIsError(true);
         setSnackbarMessage("Favor de ingresar un correo electrónico.");
         setSnackbarisVisible(true);
         return;
      }

      if (!emailRegex.test(email)) {
         setEmailError(true);
         setSnackbarIsError(true);
         setSnackbarMessage("Favor de ingresar un correo electrónico válido.");
         setSnackbarisVisible(true);
         return;
      }

      if (email) {
         await restoreMasterUserPassword(email);
         setEmailError(false);
         setSnackbarIsError(false);
         setSnackbarMessage("Instrucciones enviadas con éxito.");
         setSnackbarisVisible(true);
      }
   };

   const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") {
         return;
      }
      setSnackbarisVisible(false);
   };

   return (
      <SystemThemeConfig>
         <Box bgcolor="#12181B">
            <Container maxWidth="lg">
               <CssBaseline />
               <Box minHeight="100vh" display="flex" alignItems="center" justifyContent="center">
                  <Box
                     bgcolor="whitesmoke"
                     display="flex"
                     alignItems="center"
                     flexDirection="column"
                     borderRadius={2}
                     py={5}
                     sx={{ width: "90%" }}
                  >
                     <img
                        src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png"
                        alt="Logo Lecosy"
                        style={{ maxWidth: 250, marginBottom: 20 }}
                     />
                     <Stack spacing={2} minWidth="50%">
                        <Typography color="black" variant="h4" align="center" sx={{ my: 2 }}>
                           Restaurar Contraseña
                        </Typography>
                        <Typography color="black" sx={{ mr: 2 }}>
                           Ingrese el correo con el que se registró.
                        </Typography>
                        <TextField
                           id="email"
                           label="Correo electrónico"
                           type="email"
                           placeholder="Ingresa tu correo electrónico registrado"
                           variant="outlined"
                           value={email}
                           sx={{ input: { color: "#12181B" } }}
                           error={emailError}
                           onChange={handleEmail}
                        />
                        <Button variant="contained" onClick={handleSendInstructions} color="primary">
                           Recibir instrucciones
                        </Button>
                        <Link to={"/login"} style={{ textDecoration: "none", color: "#12181B", textAlign: "center" }}>
                           Regresar a inicio de sesión
                        </Link>
                     </Stack>
                  </Box>
               </Box>
               <Snackbar open={snackbarisVisible} autoHideDuration={5000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity={snackbarIsError ? "error" : "success"} sx={{ width: "100%" }}>
                     {snackbarMessage}
                  </Alert>
               </Snackbar>
            </Container>
         </Box>
      </SystemThemeConfig>
   );
};
