import { useContext, useEffect, useState } from "react";
import {
   Box,
   Button,
   Collapse,
   Stack,
   Typography,
   Toolbar,
   IconButton,
   CircularProgress,
   Divider,
   Autocomplete,
   TextField,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { InputTextField } from "../../Inputs/InputTextField";
import { userModifyeSchema } from "../../../lib/Validations/inputSchemas";
import { catchAxiosError } from "../../../lib/lecosyBackendClient";
import { SnackBarContext } from "../../../context/snackBarContext";
import { AxiosError } from "axios";
import { MoreVert, Close } from "@mui/icons-material";
import {
   updateCompanyAdminUser,
   useCreateUser,
   GetCompanyUsers,
   GetGroupUsers,
   updateClientUserRoles,
   UpdateGroupAdmin,
   updateUserEmail,
} from "../../../lib/usersBEClient";
import { ClientConfigContext } from "../../../context/clientConfigContext";
import { Users } from "../../../types/BaseTypes";
import { ConfirmationModal } from "../../ConfirmationModal";
import LoginModal from "../Modal/loginModal";

export interface UpdateCompanyAdminUserProps {
   isModify: boolean;
   setOpen: Function;
   adminUserData?: {
      createdAt: string;
      email: string;
      phoneNumber: string;
      firstName: string;
      lastName: string;
      role: RoleInterface[];
      uid: string;
      updatedAt: string;
   };
   updateAdminInfo?: Function;
   group: boolean;
}
interface RoleInterface {
   _id: string;
   name: string;
   description: string;
}

export const UpdateCompanyAdminUserDrawer = (props: UpdateCompanyAdminUserProps) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { clientInfo, refreshClientInfo, refreshGroupInfo, group } = useContext(ClientConfigContext);
   const [isLoading, setIsLoading] = useState(false);
   const [isLoadingEmail, setIsLoadingEmail] = useState(false);
   const [openUserDeleteCollapse, setOpenUserDeleteCollapse] = useState(false);
   const handleClose = () => props.setOpen(false);
   const { mutate } = useCreateUser();
   const [changeAdmin, setChangeAdmin] = useState(props.isModify);
   const [userSelected, setUserSelected] = useState<Users | null>(null);
   const [userSelectedFlag, setUserSelectedFlag] = useState(false);
   const [userfromGroup, setUserfromGroup] = useState(false);
   const { data } =
      clientInfo && !clientInfo.group && !props.group
         ? GetCompanyUsers(clientInfo)
         : clientInfo?.group && !props.group
         ? GetCompanyUsers(clientInfo, { groupId: clientInfo.group._id })
         : GetGroupUsers(group);

   const handleMasterUserDelete = (values) => {
      values.firstName = "";
      values.lastName = "";
      values.email = "";
      values.phoneNumber = "";
      setOpenUserDeleteCollapse(false);
      setChangeAdmin(false);
   };

   const usersGroupList = data ? data.map((user) => user) : [];

   const addNewMasterUser = async (values, actions) => {
      if (userfromGroup) {
         if (group && props.group) {
            await UpdateGroupAdmin(userSelected._id, group._id);
            showSnackBar("Usuario modificado exitosamente", false);
            props.setOpen(false);
            actions.resetForm();
            setIsLoading(false);
            await refreshGroupInfo();
         } else {
            const response = await updateClientUserRoles(
               userSelected._id,
               userfromGroup,
               true,
               clientInfo._id,
               userSelected.role
            );
            if (response.data) {
               refreshClientInfo();
               props.setOpen(false);
               actions.resetForm();
               setIsLoading(false);
            }
         }
      } else {
         try {
            setIsLoading(true);
            mutate(
               {
                  userData: {
                     firstName: values.firstName,
                     lastName: values.lastName,
                     email: values.email,
                     phoneNumber: values.phoneNumber,
                     role: null,
                  },
                  additionalData: {
                     admin: true,
                     company: !props.group ? clientInfo._id : null,
                     group: props.group && group ? group._id : clientInfo.group ? clientInfo.group : null,
                  },
               },
               {
                  onError: (error: any) => {
                     error.response.data.message === "email already in use"
                        ? showSnackBar("Ya existe un usuario con ese correo", true)
                        : showSnackBar("Error al agregar administrador.", true);
                  },
                  onSuccess: async () => {
                     setChangeAdmin(true);
                     if (group) {
                        refreshGroupInfo();
                     }
                     showSnackBar("El administrador fue agregado correctamente.", false);
                     if (clientInfo) refreshClientInfo();
                     props.setOpen(false);
                     actions.resetForm();
                     setIsLoading(false);
                  },
               }
            );
            return;
         } catch (error: any) {
            if (
               error instanceof AxiosError &&
               (error.response.data.message === "Email already in use." ||
                  error.response.data.message.includes("auth/email-already-exists"))
            ) {
               showSnackBar("Este correo electrónico ya existe", true);
            }
            if (error.response.status === 400) {
               showSnackBar("Error, intente de nuevo", true);
               return null;
            }
            props.setOpen(false);
            actions.resetForm();
            setIsLoading(false);
         }
      }
   };

   const modifyMasterUser = async (values) => {
      try {
         if (
            values.firstName === props.adminUserData.firstName &&
            values.lastName === props.adminUserData.lastName &&
            values.phoneNumber === props.adminUserData.phoneNumber &&
            values.email === props.adminUserData.email
         ) {
            showSnackBar("No hay cambios que efectuar", true);
            props.setOpen(false);
            setIsLoading(false);
            return;
         }
         const response = await updateCompanyAdminUser(
            props.adminUserData.uid,
            values.firstName,
            values.lastName,
            values.phoneNumber
         );
         if (response.data) {
            if (props.group) {
               refreshGroupInfo();
            } else {
               refreshClientInfo();
            }
            showSnackBar("Usuario modificado exitosamente", false);
            props.updateAdminInfo();
            props.setOpen(false);
            setIsLoading(false);
         }
      } catch (error) {
         const errorMessage = catchAxiosError(error);
         if (errorMessage.message.includes("no user found")) {
            showSnackBar("Error, intente de nuevo", true);
         }
         if (errorMessage.message.includes("email-already-exists")) {
            showSnackBar("Correo electrónico en uso", true);
         }
      }
      setIsLoading(false);
   };

   const modifyUserEmail = async (values) => {
      try {
         const response = await updateUserEmail(values.email, props.adminUserData.uid);
         if (response.status === 200) {
            showSnackBar("Correo electrónico modificado correctamente", false);
            setIsLoadingEmail(false);
         }
         if (
            !(
               values.firstName === props.adminUserData.firstName &&
               values.lastName === props.adminUserData.lastName &&
               values.phoneNumber === props.adminUserData.phoneNumber
            )
         ) {
            setIsLoading(true);
            //await modifyMasterUser(values);
         }
         setIsLoadingEmail(false);
      } catch (error: any) {
         showSnackBar("Este correo electrónico ya existe", true);
         setIsLoadingEmail(false);
      }
   };

   const FormObserver: React.FC = () => {
      const { setFieldValue } = useFormikContext();

      useEffect(() => {
         if (userSelected) {
            setUserSelectedFlag(true);
         } else {
            setUserSelectedFlag(false);
         }
         if (userSelected === null && userSelectedFlag) {
            setFieldValue("firstName", "");
            setFieldValue("lastName", "");
            setFieldValue("phoneNumber", "");
            setFieldValue("email", "");
            setUserfromGroup(false);
         } else if (userSelected) {
            setFieldValue("firstName", userSelected?.firstName);
            setFieldValue("lastName", userSelected?.lastName);
            setFieldValue("phoneNumber", userSelected?.phoneNumber);
            setFieldValue("email", userSelected?.email);
            setUserfromGroup(true);
         }
         // eslint-disable-next-line
      }, [userSelected]);

      return null;
   };
   return (
      <>
         <Toolbar />
         <Box display="flex" height={"100%"} width={400}>
            <Box
               height="100%"
               display="flex"
               alignItems="center"
               onClick={() => props.setOpen(false)}
               sx={{
                  "&:hover": {
                     bgcolor: "#2d435710",
                  },
               }}
            >
               <ChevronRightIcon fontSize="small" />
            </Box>
            <Box pr={1} py={1} pl={0.5} width={"100%"}>
               <Formik
                  initialValues={{
                     firstName: props.isModify ? props.adminUserData["firstName"] : "",
                     lastName: props.isModify ? props.adminUserData["lastName"] : "",
                     email: props.isModify ? props.adminUserData["email"] : "",
                     password: props.isModify ? null : "",
                     passwordConfirmation: props.isModify ? null : "",
                     role: props.isModify ? props.adminUserData["role"] : "",
                     phoneNumber: props.isModify ? props.adminUserData["phoneNumber"] : "",
                  }}
                  validationSchema={userModifyeSchema}
                  onSubmit={(values, actions) =>
                     changeAdmin && values.email !== props.adminUserData.email
                        ? setIsLoadingEmail(true)
                        : changeAdmin
                        ? setIsLoading(true)
                        : addNewMasterUser({ ...values }, actions)
                  }
               >
                  {(formProps: FormikProps<any>) => (
                     <Form>
                        <FormObserver />
                        <Box mt={2} mr={1}>
                           <Box display={"flex"} m={2}>
                              <Typography variant="h5">
                                 {!props.isModify ? "Agregar usuario interno" : "Modificar usuario interno"}
                              </Typography>
                              {!props.isModify ? (
                                 <></>
                              ) : (
                                 <Box
                                    sx={{
                                       display: "flex",
                                       flexDirection: "column",
                                       maxWidth: "50px",
                                       alignItems: "center",
                                    }}
                                 >
                                    <IconButton onClick={() => setOpenUserDeleteCollapse(!openUserDeleteCollapse)}>
                                       {!openUserDeleteCollapse ? <MoreVert /> : <Close />}
                                    </IconButton>
                                    <Collapse sx={{ width: 200 }} in={openUserDeleteCollapse}>
                                       <Button
                                          variant="outlined"
                                          color="error"
                                          onClick={() => {
                                             handleMasterUserDelete(formProps.values);
                                          }}
                                       >
                                          <Typography>Eliminar usuario</Typography>
                                       </Button>
                                    </Collapse>
                                 </Box>
                              )}
                           </Box>
                           <Autocomplete
                              hidden={changeAdmin}
                              limitTags={2}
                              id="role"
                              options={data ? usersGroupList : []}
                              getOptionLabel={(option) => `${(option as any).firstName} ${(option as any).lastName}`}
                              onChange={(event, newValue) => {
                                 setUserSelected(newValue as Users);
                                 //hacer cambio aqui
                              }}
                              renderInput={(params) => (
                                 <TextField
                                    {...params}
                                    label="Usuarios del grupo empresarial"
                                    placeholder="Selecciona un usuario"
                                    name="role"
                                 />
                              )}
                              sx={{ width: "auto" }}
                           />
                           <Divider sx={{ mr: 1, mb: 2 }} />
                           <Stack spacing={2} mx={2}>
                              <InputTextField
                                 variant="standard"
                                 label="Nombre(s)"
                                 id="firstName"
                                 name="firstName"
                                 type="text"
                                 value={formProps.values.firstName}
                                 fullWidth={true}
                                 disabled={userfromGroup}
                              />
                              <InputTextField
                                 variant="standard"
                                 label="Apellidos"
                                 id="lastName"
                                 name="lastName"
                                 type="text"
                                 value={formProps.values.lastName}
                                 fullWidth={true}
                                 disabled={userfromGroup}
                              />
                              <InputTextField
                                 variant="standard"
                                 label="Teléfono"
                                 id="phoneNumber"
                                 name="phoneNumber"
                                 type="text"
                                 value={formProps.values.phoneNumber}
                                 fullWidth={true}
                                 disabled={userfromGroup}
                              />
                              <InputTextField
                                 variant="standard"
                                 label="Correo electrónico"
                                 id="email"
                                 name="email"
                                 type="text"
                                 value={formProps.values.email}
                                 fullWidth={true}
                                 disabled={userfromGroup}
                              />
                              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                 <Button onClick={handleClose} sx={{ borderColor: "whitesmoke", border: 1 }}>
                                    Cancelar
                                 </Button>
                                 <Button variant="contained" color="primary" type="submit">
                                    {!isLoading ? "Confirmar" : <CircularProgress color="success" size={22} />}
                                 </Button>
                              </Box>
                           </Stack>
                        </Box>
                        <ConfirmationModal
                           open={isLoading}
                           setOpen={setIsLoading}
                           generic
                           onConfirm={() => modifyMasterUser(formProps.values)}
                        />
                        <LoginModal
                           open={isLoadingEmail}
                           setOpen={setIsLoadingEmail}
                           onConfirm={() => modifyUserEmail(formProps.values)}
                        />
                     </Form>
                  )}
               </Formik>
            </Box>
         </Box>
      </>
   );
};
