import { TextField, FormControl, InputLabel, FormHelperText, Input } from "@mui/material";
import { useField } from "formik";

interface TextFieldInterfaceProps {
   label?: string;
   fullWidth?: boolean;
   rows?: string | number;
   multiline?: boolean;
   variant?: any;
   size?: any;
   id: string;
   name: string;
   placeholder?: string;
   type: string;
   value?: any;
   endAdornment?: React.ReactNode;
   startAdornment?: React.ReactNode;
   disabled?: boolean;
   sx?: object;
   InputProps?: any;
   InputLabelProps?: any;
   maxLength?: number;
   mindate?: string;
   readOnly?: boolean;
   onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputTextField = (props: TextFieldInterfaceProps) => {
   const [field, meta] = useField(props);
   return (
      <>
         {props.endAdornment ? (
            <FormControl fullWidth={props.fullWidth} variant={props.variant} size={props.size}>
               <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
               <Input
                  {...field}
                  // {...props} // With this spread operator, input tag props become invalid.
                  id={props.id}
                  rows={props.rows}
                  type={props.type}
                  multiline={props.multiline}
                  endAdornment={props.endAdornment}
                  error={meta.error && meta.touched}
               />
               <FormHelperText error={meta.error && meta.touched}>
                  {meta.error && meta.touched ? meta.error : null}
               </FormHelperText>
            </FormControl>
         ) : props.startAdornment ? (
            <FormControl fullWidth={props.fullWidth} variant={props.variant} size={props.size}>
               <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
               <Input
                  {...field}
                  // {...props} // With this spread operator, input tag props become invalid.
                  id={props.id}
                  rows={props.rows}
                  type={props.type}
                  multiline={props.multiline}
                  startAdornment={props.startAdornment}
                  error={meta.error && meta.touched}
               />
               <FormHelperText error={meta.error && meta.touched}>
                  {meta.error && meta.touched ? meta.error : null}
               </FormHelperText>
            </FormControl>
         ) : (
            <TextField
               {...field}
               {...props}
               placeholder={props.placeholder}
               error={meta.error && meta.touched}
               helperText={meta.error && meta.touched ? meta.error : null}
               inputProps={{
                  maxLength: props.maxLength,
                  min: props.mindate,
                  readOnly: props.readOnly || false,
               }}
            />
         )}
      </>
   );
};
