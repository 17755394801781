import { Grid, Box, Typography, TextField, InputAdornment } from "@mui/material";
import { useState } from "react";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import RateReviewIcon from "@mui/icons-material/RateReview";

interface WitnessInputType {
   name: string;
   facts: string;
   proof: string;
}

interface WitnessInputComponentProps {
   e: any;
   data: WitnessInputType[];
   setData: Function;
   index: number;
}

export const WitnessInputComponent = (props: WitnessInputComponentProps) => {
   const [inputState, setInputState] = useState<WitnessInputType>({
      name: props.e.name + " " + props.e.lastName,
      facts: "",
      proof: "",
   });

   const [errors, setErrors] = useState({
      facts: {
         error: false,
         message: "",
      },
      proof: {
         error: false,
         message: "",
      },
   });

   let dat = { name: props.e.name, facts: "", proof: "" };

   const handleChange = (e) => {
      const { value, name } = e.target;

      if (value.length > 300 || value.length < 15) {
         setErrors({
            ...errors,
            [name]: { error: true, message: "EL texto debe contener mínimo 15 caracteres y maximo 300." },
         });
      } else {
         setErrors({
            ...errors,
            [name]: {
               message: "",
               error: false,
            },
         });
      }

      setInputState({
         ...inputState,
         [name]: value,
      });
      dat = { ...inputState };
      dat.name = props.e.name + " " + props.e.lastName;
      dat[name] = value;
      const nextList = [...props.data];
      nextList[props.index] = dat;
      props.setData(nextList);
   };

   return (
      <>
         <Grid item xs={12}>
            <Box display={"flex"} sx={{ px: 4 }}>
               <Typography fontWeight={500} variant="body1" sx={{ textAlign: "center" }}>
                  Nombre:&nbsp;
               </Typography>
               {props.e.name ? props.e.name + " " + props.e.lastName : "No aplica"}
            </Box>
         </Grid>
         <Grid item xs={6}>
            <Box display={"flex"} sx={dataFieldSX}>
               <TextField
                  variant="standard"
                  label="Hechos declarados"
                  fullWidth
                  multiline
                  error={errors.facts.error}
                  helperText={errors.facts.error ? errors.facts.message : ""}
                  name="facts"
                  value={inputState.facts}
                  onChange={handleChange}
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position="start">
                           <RateReviewIcon />
                        </InputAdornment>
                     ),
                  }}
               />
            </Box>
         </Grid>
         <Grid item xs={6}>
            <Box display={"flex"} sx={dataFieldSX}>
               <TextField
                  variant="standard"
                  label="Pruebas otorgadas"
                  fullWidth
                  multiline
                  error={errors.proof.error}
                  helperText={errors.proof.error ? errors.proof.message : ""}
                  name="proof"
                  value={inputState.proof}
                  onChange={handleChange}
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position="start">
                           <StickyNote2Icon />
                        </InputAdornment>
                     ),
                  }}
               />
            </Box>
         </Grid>
      </>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   py: 2,
   px: 4,
};
