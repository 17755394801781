import {
   imageEighteen,
   imageEigth,
   imageEleven,
   imageFive,
   imageFifteen,
   imageFour,
   imageFourteen,
   imageNine,
   imageOne,
   imageSeven,
   imageSeventeen,
   imageSix,
   imageSixteen,
   imageTen,
   imageThirteen,
   imageTree,
   imageTwelve,
   imageTwo,
   interviewCircle,
   imageNineteen,
   lecosyLogoBase64,
} from "../../const/globalConst";

const textDefinition = (
   text: string,
   margin: number[],
   bold = false,
   color?: string,
   fontSize?: number,
   width?: number
) => {
   return {
      text: `${text}`,
      fontSize: fontSize || 11,
      color: color || "#00000",
      bold: bold,
      alignment: "justify",
      margin: margin,
      lineHeight: 1.1,
      width: width,
   };
};

export const witnessInterview = (
   empty: boolean,
   answer1: string,
   answer2: string,
   answer3: string,
   answer4: string,
   body: string,
   answer5: string,
   answer6: string,
   answer7: string,
   answer8: string,
   answer9: string,
   answer10: string,
   answer11: string,
   answer12: string,
   answer13: string,
   answer14: string,
   answer15: string,
   answer16: string,
   answer17: string,
   answer18: string,
   answer19: string,
   evidence: string | ArrayBuffer
) => {
   const dd = {
      pageSize: "A4",
      pageMargins: [70, 90, 70, 180],
      footer:
         evidence === ""
            ? [
                 {
                    margin: [90, 50, 90, 10],
                    table: {
                       widths: [398],
                       heights: [2],
                       body: [
                          [
                             {
                                text: "(Nombre completo y firma)",
                                border: [false, true, false, false],
                                alignment: "center",
                                fontSize: 10.5,
                             },
                          ],
                          [
                             {
                                text: "Está entrevista se apega a la debida confidencialidad como evidencia de la denuncia.",
                                border: [],
                                alignment: "center",
                                fontSize: 10,
                                color: "#A0A0A0",
                                margin: [0, 10],
                             },
                          ],
                       ],
                    },
                 },
              ]
            : null,
      header: {
         columns: [
            {
               image: lecosyLogoBase64,
               width: 100,
               absolutePosition: { x: 50, y: 30 },
            },
            {
               canvas: [
                  {
                     type: "rect",
                     x: 100,
                     y: 55,
                     w: 450,
                     h: 4,
                     color: "#152c44",
                  },
               ],
            },
            {
               text: "FORMATO DE ENTREVISTA AL TESTIGO",
               bold: true,
               fontSize: 15,
               color: "#152c44",
               absolutePosition: { x: 220, y: 35 },
            },
         ],
      },
      content: [
         textDefinition(
            "La razón de esta entrevista es colaborar con la investigación de los hechos, conforme un acopio de información de cualquier denuncia existente en el canal de denuncias.",
            [25, 10, 25, 10],
            false
         ),
         textDefinition(
            "Consiste en una serie de preguntas efectuadas a las distintas personas que tienen conocimiento o pueden brindar antecedentes acerca de un hecho que se investiga, fundamentalmente con el objeto de obtener información que coadyuve en el esclarecimiento de los hechos.",
            [25, 10, 25, 10],
            false
         ),
         {
            columns: [
               {
                  image: imageOne,
                  width: 20,
               },
               textDefinition("¿Cuál es su nombre completo?", [4, 2, 0, 2], true, "#152c44", 12),
            ],
         },
         empty ? textDefinition("", [25, 10, 25, 10], false) : textDefinition(answer1, [25, 10, 25, 10], false),
         {
            columns: [
               {
                  image: imageTwo,
                  width: 20,
               },
               textDefinition("¿Usted labora en la organización?", [4, 2, 0, 2], true, "#152c44", 12),
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    textDefinition("Si", [0, 0, 0, 0], true, "#152c44", 12, 20),
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    textDefinition("No", [0, 0, 0, 0], true, "#152c44", 12, 20),
                 ],
                 columnGap: 5,
                 margin: [25, 10, 25, 10],
              }
            : textDefinition(answer2, [25, 10, 25, 10], false, "#00000", 11),
         textDefinition(
            "En caso de que la respuesta número 2 sea 'No', se omiten las preguntas 3 y 4.",
            [25, 10, 25, 10],
            false,
            "#646D80",
            10
         ),
         {
            columns: [
               {
                  image: imageTree,
                  width: 20,
               },
               textDefinition(
                  "En caso de que la respuesta anterior sea si ¿Cuál es la actividad que realiza en la organización?",
                  [4, 2, 0, 2],
                  true,
                  "#152c44",
                  12
               ),
            ],
         },
         empty
            ? textDefinition("", [0, 0, 0, 0], false, "#00000", 11)
            : textDefinition(answer3, [25, 10, 25, 10], false, "#00000", 11),
         {
            columns: [
               {
                  image: imageFour,
                  width: 20,
               },
               textDefinition(
                  "¿Cúanto tiempo tiene trabajando en esta organización?",
                  [4, 2, 0, 2],
                  true,
                  "#152c44",
                  12
               ),
            ],
         },
         empty
            ? textDefinition("", [0, 0, 0, 0], false, "#00000", 11)
            : textDefinition(answer4, [25, 10, 25, 10], false, "#00000", 11),
         {
            text: `Se le da lectura de los hechos denunciados y se cuestiona lo siguiente:`,
            fontSize: 10,
            color: "#646D80",
            alignment: "center",
            margin: [25, 10, 25, 10],
            lineHeight: 1.1,
         },
         textDefinition(body, [25, 10, 25, 10], false, "#46566a", 10),
         {
            columns: [
               {
                  image: imageFive,
                  width: 22,
               },
               textDefinition(
                  "¿Usted tiene conocimiento de los hechos anteriormente leídos?",
                  [4, 2, 0, 2],
                  true,
                  "#152c44",
                  12
               ),
            ],
         },
         empty
            ? textDefinition("", [0, 0, 0, 0], false, "#00000", 11)
            : textDefinition(answer5, [25, 10, 25, 10], false, "#00000", 11),
         {
            columns: [
               {
                  image: imageSix,
                  width: 20,
               },
               {
                  text: "¿Usted presenció la denuncia que se menciona?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer6}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageSeven,
                  width: 20,
               },
               {
                  text: "¿Reconoce la denuncia narrada como cierta?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer7}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageEigth,
                  width: 20,
               },
               {
                  text: "En caso de que la respuesta anterior sea afirmativa, ¿Tiene conocimiento del nombre de las personas implicadas en los hechos?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: answer8 !== "" ? `${answer8}` : "No aplica",
                 margin: [25, 10, 25, 10],
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageNine,
                  width: 20,
               },
               {
                  text: "En caso de que la respuesta anterior sea si. ¿Podría mencionarlos?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: `${answer9}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageTen,
                  width: 20,
               },
               {
                  text: "¿Los hechos narrados en la denuncia tienen alguna variación conforme a su percepción?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer10}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageEleven,
                  width: 20,
               },
               {
                  text: "En caso de que la respuesta anterior sea afirmativa, ¿puede narrar los hechos?",
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: answer11 !== "" ? `${answer11}` : "No aplica",
                 margin: [25, 10, 25, 10],
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageTwelve,
                  width: 20,
               },
               {
                  text: "¿Qué actividades realiza usted en la fecha que se estima esta denuncia?",
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: `${answer12}`,
                 margin: [25, 10, 25, 10],
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageThirteen,
                  width: 20,
               },
               {
                  text: "¿Tiene testigos de las actividades que realizaba ese día?",
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer13}`,
                 margin: [25, 10, 25, 10],
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageFourteen,
                  width: 20,
               },
               {
                  text: `En caso de que la respuesta anterior sea si. ¿Podría mencionarlos?`,
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: answer14 !== "" ? `${answer14}` : "No aplica",
                 margin: [25, 10, 25, 10],
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageFifteen,
                  width: 20,
               },
               {
                  text: `¿Cuenta con alguna evidencia que pruebe lo que menciona en esta entrevista?`,
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer15}`,
                 margin: [25, 10, 25, 10],
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageSixteen,
                  width: 20,
               },
               {
                  text: "En caso de ser afirmativa, su respuesta ¿puede proporcionar tales evidencias?",
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: `${answer16}`,
                 margin: [25, 10, 25, 10],
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageSeventeen,
                  width: 20,
               },
               {
                  text: "¿Tiene alguna información que crea pertinente adicionar a esta entrevista para la correcta investigación de los hechos?",
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: `${answer17}`,
                 margin: [25, 10, 25, 10],
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageEighteen,
                  width: 17,
               },
               {
                  text: "¿Qué acciones cree que pudieran modificarse para evitar la existencia de este tipo de denuncias?",
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: `${answer18}`,
                 margin: [25, 10, 25, 10],
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageNineteen,
                  width: 17,
               },
               {
                  text: "¿Ha respondido a todas las preguntas con veracidad?",
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
                 pageBreak: evidence !== "" ? "after" : "",
              }
            : {
                 text: `${answer19}`,
                 margin: [25, 10, 25, 10],
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
                 pageBreak: evidence !== "" ? "after" : "",
              },
         evidence !== ""
            ? [
                 {
                    text: "Evidencia de identidad",
                    fontSize: 12,
                    bold: true,
                    color: "#152c44",
                    width: 20,
                 },
                 {
                    image: evidence,
                    width: 400,
                 },
              ]
            : {},
      ],
   };
   return dd;
};

export const denouncedInterview = (
   empty: boolean,
   answer1: string,
   answer2: string,
   answer3: string,
   answer4: string,
   answer5: string,
   answer6: string,
   answer7: string,
   answer8: string,
   answer9: string,
   answer10: string,
   answer11: string,
   answer12: string,
   answer13: string,
   answer14: string,
   answer15: string,
   answer16: string,
   answer17: string,
   clasification: string,
   evidence: string | ArrayBuffer
) => {
   const dd = {
      pageSize: "A4",
      pageMargins: [70, 90, 70, 180],
      footer:
         evidence === ""
            ? [
                 {
                    margin: [90, 50, 90, 10],
                    table: {
                       widths: [398],
                       heights: [2],
                       body: [
                          [
                             {
                                text: "(Nombre completo y firma)",
                                border: [false, true, false, false],
                                alignment: "center",
                                fontSize: 10.5,
                             },
                          ],
                          [
                             {
                                text: "Está entrevista se apega a la debida confidencialidad como evidencia de la denuncia.",
                                border: [],
                                alignment: "center",
                                fontSize: 10,
                                color: "#A0A0A0",
                                margin: [0, 10],
                             },
                          ],
                       ],
                    },
                 },
              ]
            : null,
      header: {
         columns: [
            {
               image: lecosyLogoBase64,
               width: 100,
               absolutePosition: { x: 50, y: 30 },
            },
            {
               canvas: [
                  {
                     type: "rect",
                     x: 100,
                     y: 55,
                     w: 450,
                     h: 4,
                     color: "#152c44",
                  },
               ],
            },
            {
               text: "FORMATO DE ENTREVISTA AL DENUNCIADO",
               bold: true,
               fontSize: 15,
               color: "#152c44",
               absolutePosition: { x: 220, y: 35 },
            },
         ],
      },
      content: [
         {
            text: `La razón de esta entrevista es colaborar con la investigación de los hechos, conforme un acopio de información de cualquier denuncia existente en el canal de denuncias.`,
            fontSize: 11,
            alignment: "justify",
            margin: [25, 10, 25, 10],
            lineHeight: 1.1,
         },
         {
            columns: [
               {
                  image: imageOne,
                  width: 20,
               },
               {
                  text: "¿Cuál es su nombre completo?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: `${answer1}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageTwo,
                  width: 20,
               },
               {
                  text: "¿Cuál es la actividad que realiza en la organización?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: `${answer2}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },

         {
            columns: [
               {
                  image: imageTree,
                  width: 20,
               },
               {
                  text: "¿Cuánto tiempo tiene trabajando en esta organización?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: `${answer3}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },

         {
            columns: [
               {
                  image: imageFour,
                  width: 20,
               },
               {
                  text: `¿Personalmente reconoces alguna falta catalogada como ${clasification} que te indique como responsable de la misma?`,
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer4}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageFive,
                  width: 22,
               },
               {
                  text: "¿Tiene algún hecho que manifestar referente a la denuncia que fue instaurada en su contra?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer5}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageSix,
                  width: 20,
               },
               {
                  text: "En caso de que la respuesta anterior sea si ¿Puede mencionarlos?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: answer6 === "" ? "No aplica" : `${answer6}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageSeven,
                  width: 20,
               },
               {
                  text: "¿Niega que exista una denuncia de este tipo, que se le indique como responsable de alguna falta?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer7}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageEigth,
                  width: 20,
               },
               {
                  text: "Si la respuesta anterior fue si, ¿Tiene conocimiento del nombre de la persona que pudiera ser la que cometió dicha denuncia?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer8}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageNine,
                  width: 20,
               },
               {
                  text: "Si la respuesta anterior fue si, ¿Puede mencionarlo?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: answer9 === "" ? "No aplica" : `${answer9}`,

                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageTen,
                  width: 20,
               },
               {
                  text: "¿Qué actividades realizaba usted en la fecha que se estima la denuncia?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: `${answer10}`,

                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },

         {
            columns: [
               {
                  image: imageEleven,
                  width: 20,
               },
               {
                  text: "¿Tiene testigos de las actividades que realizaba ese día?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer11}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageTwelve,
                  width: 20,
               },
               {
                  text: "Si la respuesta anterior fue si, ¿Puede mencionarlos?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: answer12 === "" ? "No aplica" : `${answer12}`,

                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageThirteen,
                  width: 20,
               },
               {
                  text: "¿Tiene alguna prueba que fundamente lo que menciona en esta entrevista?",
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer13}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageFourteen,
                  width: 20,
               },
               {
                  text: "Si la respuesta anterior fue si, ¿Puede mencionarlos?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: answer14 === "" ? "No aplica" : `${answer14}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageFifteen,
                  width: 20,
               },
               {
                  text: "¿Conoce o fue protagonista de algún suceso de esta categoría recientemente?",
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer15}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageSixteen,
                  width: 20,
               },
               {
                  text: "Si la respuesta anterior es sí, ¿podría narrar los hechos?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: answer16 === "" ? "No aplica" : `${answer16}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageSeventeen,
                  width: 20,
               },
               {
                  text: `¿Ha respondido a todas las preguntas con la verdad?`,
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer17}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
                 pageBreak: evidence !== "" ? "after" : "",
              },
         evidence !== ""
            ? [
                 {
                    text: "Evidencia de identidad",
                    fontSize: 12,
                    bold: true,
                    color: "#152c44",
                    width: 20,
                    margin: [25, 10, 25, 10],
                 },
                 {
                    image: evidence,
                    width: 400,
                    margin: [25, 10, 25, 10],
                 },
              ]
            : {},
      ],
   };
   return dd;
};

export const complainantInterview = (
   empty: boolean,
   answer1: string,
   answer2: string,
   answer3: string,
   answer4: string,
   answer5: string,
   answer6: string,
   answer7: string,
   answer8: string,
   answer9: string,
   answer10: string,
   answer11: string,
   answer12: string,
   answer13: string,
   answer14: string,
   answer15: string,
   answer16: string,
   answer17: string,
   evidence: string | ArrayBuffer
) => {
   const dd = {
      pageSize: "A4",
      pageMargins: [70, 90, 70, 180],
      footer:
         evidence === ""
            ? [
                 {
                    margin: [90, 50, 90, 10],
                    table: {
                       widths: [398],
                       heights: [2],
                       body: [
                          [
                             {
                                text: "(Nombre completo y firma)",
                                border: [false, true, false, false],
                                alignment: "center",
                                fontSize: 10.5,
                             },
                          ],
                          [
                             {
                                text: "Está entrevista se apega a la debida confidencialidad como evidencia de la denuncia.",
                                border: [],
                                alignment: "center",
                                fontSize: 10,
                                color: "#A0A0A0",
                                margin: [0, 10],
                             },
                          ],
                       ],
                    },
                 },
              ]
            : null,
      header: {
         columns: [
            {
               image: lecosyLogoBase64,
               width: 100,
               absolutePosition: { x: 50, y: 30 },
            },
            {
               canvas: [
                  {
                     type: "rect",
                     x: 100,
                     y: 55,
                     w: 450,
                     h: 4,
                     color: "#152c44",
                  },
               ],
            },
            {
               text: "FORMATO DE ENTREVISTA AL DENUNCIANTE",
               bold: true,
               fontSize: 15,
               color: "#152c44",
               absolutePosition: { x: 220, y: 35 },
            },
         ],
      },
      content: [
         {
            text: `La razón de esta entrevista es colaborar con la investigación de los hechos, conforme un acopio de información de cualquier denuncia existente en el canal de denuncias.`,
            fontSize: 11,
            alignment: "justify",
            margin: [25, 10, 25, 10],
            lineHeight: 1.1,
         },
         {
            columns: [
               {
                  image: imageOne,
                  width: 20,
               },
               {
                  text: "¿Cuál es su nombre completo?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: `${answer1}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageTwo,
                  width: 20,
               },
               {
                  text: "¿Usted labora en la organización?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer2}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageTree,
                  width: 20,
               },
               {
                  text: "En caso de que la respuesta anterior sea si ¿Cuál es la actividad que realiza en la organización?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: answer3 === "" ? "No aplica" : `${answer3}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },

         {
            columns: [
               {
                  image: imageFour,
                  width: 20,
               },
               {
                  text: `¿Cuánto tiempo tiene trabajando en esta organización?`,
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: answer4 === "" ? "No aplica" : `${answer4}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageFive,
                  width: 22,
               },
               {
                  text: "En caso de que la respuesta número 2. Sea No ¿Por qué razón se encontraba en el lugar de los hechos, motivo de su denuncia?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: `${answer5}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageSix,
                  width: 20,
               },
               {
                  text: "¿Usted se declara como la persona denunciante en el canal de denuncias?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer6}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageSeven,
                  width: 20,
               },
               {
                  text: "¿Alguien más está involucrado o es consciente de la irregularidad denunciada?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer7}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageEigth,
                  width: 20,
               },
               {
                  text: "Si la respuesta anterior es sí, ¿Podría proceder a narrar tal afirmación?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: answer8 === "" ? "No aplica" : `${answer8}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageNine,
                  width: 20,
               },
               {
                  text: "¿Usted asegura que lo ocurrido sucedió de esa manera?",
                  fontSize: 12,
                  bold: true,
                  alignment: "justify",
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer9}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageTen,
                  width: 20,
               },
               {
                  text: "¿Cuenta con algún testigo que respalde su narración?",
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer10}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageEleven,
                  width: 20,
               },
               {
                  text: "En caso de que la respuesta anterior sea si. ¿Podría mencionarlos?",
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: `${answer11}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageTwelve,
                  width: 20,
               },
               {
                  text: "¿Se declara como víctima principal de lo ocurrido?",
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer12}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageThirteen,
                  width: 20,
               },
               {
                  text: "¿Cuenta con alguna evidencia para probar su dicho, que pueda aportar en el presente proceso de investigación?",
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",
                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer13}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageFourteen,
                  width: 20,
               },
               {
                  text: `En caso de ser afirmativa, su respuesta ¿puede proporcionar tales evidencias?`,
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",

                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",

                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
              }
            : {
                 text: `${answer14}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },

         {
            columns: [
               {
                  image: imageFifteen,
                  width: 20,
               },
               {
                  text: `¿Qué medidas serían las idóneas para usted para la erradicación de estos problemas dentro de la organización?`,
                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: `${answer15}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageSixteen,
                  width: 20,
               },
               {
                  text: "¿Cómo podríamos ayudar a resarcir el daño ocurrido?",

                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 text: ``,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 lineHeight: 1.1,
              }
            : {
                 text: `${answer16}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
              },
         {
            columns: [
               {
                  image: imageSeventeen,
                  width: 20,
               },
               {
                  text: "¿Ha respondido a todas las preguntas con la verdad?",

                  fontSize: 12,
                  bold: true,
                  color: "#152c44",
                  margin: [4, 2, 0, 2],
               },
            ],
         },
         empty
            ? {
                 columns: [
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "Si",

                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                    {
                       image: interviewCircle,
                       width: 15,
                    },
                    {
                       text: "No",

                       fontSize: 12,
                       bold: true,
                       color: "#152c44",
                       width: 20,
                    },
                 ],
                 columnGap: 5,
                 pageBreak: evidence !== "" ? "after" : "",
              }
            : {
                 text: `${answer17}`,
                 fontSize: 11,
                 color: "#00000",
                 alignment: "justify",
                 margin: [25, 10, 25, 10],
                 lineHeight: 1.1,
                 pageBreak: evidence !== "" ? "after" : "",
              },

         evidence !== ""
            ? [
                 {
                    text: "Evidencia de identidad",

                    fontSize: 12,
                    bold: true,
                    color: "#152c44",
                    width: 20,
                    margin: [25, 10, 25, 10],
                 },
                 {
                    image: evidence,
                    width: 400,
                    margin: [25, 10, 25, 10],
                 },
              ]
            : {},
      ],
   };
   return dd;
};
