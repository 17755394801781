import { useContext } from "react";
import { ClientConfigContext } from "../../../../context/clientConfigContext";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { getDateFromISODate } from "../../../../const/globalConst";

export const ConsultiveGroupInfo = () => {
   const { consultiveGroup, group } = useContext(ClientConfigContext);
   const consultiveGroupExists = consultiveGroup && consultiveGroup._id === group._id;

   return (
      <Stack>
         <Box sx={{ mx: 1, my: 2, display: "flex", justifyContent: "space-between" }}>
            <Typography fontWeight={600} alignSelf={"center"}>
               Información societaria
            </Typography>
         </Box>
         <Box sx={{ bgcolor: "#EFEFEF", borderRadius: 2.5, p: 1 }}>
            <Grid container sx={{ mx: 3, my: 2, rowGap: 1 }}>
               <Grid item xs={4}>
                  <Typography fontWeight={600} fontSize={14} my={1}>
                     Nombre del grupo:
                  </Typography>
                  <Typography fontSize={14}>{group.name}</Typography>
               </Grid>
               <Grid item xs={4}>
                  <Typography fontWeight={600} fontSize={14} my={1}>
                     Tipo de consejo:
                  </Typography>
                  <Typography fontSize={14}>{consultiveGroupExists ? "Consultivo" : "---"}</Typography>
               </Grid>
               <Grid item xs={4}>
                  <Typography fontWeight={600} fontSize={14} my={1}>
                     Tipo de sociedad:
                  </Typography>
                  <Typography fontSize={14}>{consultiveGroupExists ? "Sociedad mercantil" : "---"}</Typography>
               </Grid>
            </Grid>
         </Box>
         <Divider sx={{ my: 2 }} />
         <Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "center", mx: 1 }}>
            <Typography fontSize={14} alignSelf={"center"}>
               <b>Ultima modificación:</b>{" "}
               {consultiveGroupExists ? getDateFromISODate(consultiveGroup?.updatedAt) : "---"}
            </Typography>
         </Box>
      </Stack>
   );
};
