import { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ComplaintContext } from "../../../context/complaintsExternContext/complaintContext";
import { HeadCell, TableComponent } from "../../../components/TableComponent";
import { changeIncomingComplaint } from "../../../lib/lecosyBackendClient";
import { capitalizeFirstLetter } from "../../../const/globalConst";

const columns: HeadCell[] = [
   { field: "reportNumber", headerName: "No. de Reporte", type: "string" },
   { field: "company", headerName: "Empresa/Sucursal", type: "string" },
   { field: "complaintType", headerName: "Tipo", type: "string" },
   { field: "complainerType", headerName: "Denunciante", type: "string" },
   { field: "category", headerName: "Categoría", type: "string" },
   { field: "createdAt", headerName: "Recepción", type: "date" },
   { field: "status", headerName: "Estatus", type: "status" },
];

export const ComplaintsInboxTable = () => {
   const { complaints, loadingComplaints, refetch } = useContext(ComplaintContext);
   const navigate = useNavigate();
   const [branchesFilter, setBranchesFilter] = useState<any>([]);
   const [categoryFilter, setCategoryFilter] = useState<any>([]);

   useEffect(() => {
      getBranchAndCategoryFilters();
      // eslint-disable-next-line
   }, [complaints]);

   const getBranchAndCategoryFilters = () => {
      const branchesArray = [];
      const categoryArray = [];
      !loadingComplaints &&
         complaints &&
         complaints.forEach((c) => {
            if (!branchesArray.includes(c.branch?.name || c.foundCompany.person_details.comercialName)) {
               branchesArray.push(c.branch?.name || c.foundCompany.person_details.comercialName);
            }
            if (!categoryArray.includes(c.complaint.category)) {
               categoryArray.push(c.complaint.category);
            }
         });
      setCategoryFilter(categoryArray);
      setBranchesFilter(branchesArray);
   };

   const seenComplaint = async (reportNumber: string) => {
      await changeIncomingComplaint(reportNumber);
   };

   const rows =
      !loadingComplaints &&
      complaints.map((c) => ({
         reportNumber: c.complaint.reportNumber,
         company: c.branch?.name || c.foundCompany.person_details.comercialName,
         complaintType: capitalizeFirstLetter(c.complaint?.type || "denuncia"),
         complainerType: c.complaint.complainerType,
         category: c.complaint.category || "N/A",
         createdAt: c.complaint.createdAt,
         status: capitalizeFirstLetter(c.complaint.status),
         incoming: c.complaint.incoming,
      }));

   return (
      <Box sx={{ width: "100%" }}>
         <TableComponent
            headerColor
            showBadges
            defaultColumnToOrder="createdAt"
            defaultOrder="asc"
            defaultRowsPerPage={10}
            rowsPerPageOptions={[5, 10, 25]}
            emptyDataText="No hay denuncias en este momento"
            loader={loadingComplaints}
            onClick={async (e, row) => {
               await seenComplaint(row.reportNumber);
               await refetch();
               return navigate(row.reportNumber);
            }}
            headers={columns}
            rows={rows || []}
            filters={[
               {
                  allOption: "Todas",
                  data: branchesFilter,
                  id: "company",
                  label: "Empresas/Sucursales",
                  type: "select",
               },
               { allOption: "", data: [], id: "reportNumber", label: "No. de reporte", type: "number" },
               { allOption: "Todas", data: categoryFilter, id: "category", label: "Categoría", type: "select" },
            ]}
            filtersInitialState={{
               company: "Todas",
               reportNumber: "",
               category: "Todas",
            }}
         />
      </Box>
   );
};
