import React, { useState } from "react";
import { Box, Button, Collapse, Grid, Modal, Typography } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface ConfirmationModalProps {
   open: boolean;
   setOpen: Function;
   title?: string;
   body?: React.ReactNode;
   setOpenLogin?: Function;
   onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
   onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
   cancelButton?: boolean;
   documents?: string[];
   loader?: boolean;
   generic?: boolean;
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
   const [docsList, setDocsList] = useState<boolean>(false);

   const handleClose = () => {
      props.setOpen(false);
   };

   const handleOpenLoginModal = () => {
      props.setOpenLogin(true);
      props.setOpen(false);
   };

   return (
      <div>
         <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: "flex" }}
         >
            <Box sx={{ bgcolor: "white", m: "auto", maxWidth: 450, py: 1, px: 2, borderRadius: 1 }}>
               <Grid container>
                  <Grid item xs={12} sx={topRow}>
                     <label htmlFor="text" style={{ fontSize: "20px", fontWeight: "bold", padding: 5 }}>
                        {props.generic ? "Confirmar cambios" : props.title}
                     </label>
                  </Grid>
                  <Grid item xs={12} sx={middleRow}>
                     {/* <Box> */}
                     <label
                        htmlFor="text"
                        style={{
                           fontSize: "14px",
                           paddingLeft: 10,
                           paddingRight: 10,
                           textAlign: "center",
                           paddingTop: 20,
                           paddingBottom: 20,
                        }}
                     >
                        {props.generic ? (
                           <Typography>¿Está seguro que desea guardar los cambios realizados?</Typography>
                        ) : (
                           props.body
                        )}
                     </label>
                     {/* </Box> */}
                  </Grid>
                  {props.documents ? (
                     <Box>
                        <Grid item xs={12} sx={{ px: 1 }}>
                           <Button
                              size="small"
                              onClick={() => setDocsList(!docsList)}
                              sx={{ "&:hover": { bgcolor: "transparent" } }}
                           >
                              {docsList ? <KeyboardArrowDownIcon /> : <ArrowForwardIosIcon fontSize="inherit" />}
                              <Typography variant="caption" sx={{ ml: 1, py: 1 }}>
                                 Ver documentos de la sección
                              </Typography>
                           </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", px: 2 }}>
                           <Collapse in={docsList} sx={{ overflowY: "scroll", maxHeight: 150 }}>
                              {props.documents.map((doc, i) => {
                                 return (
                                    <Box
                                       key={`${doc}-${i}`}
                                       sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "initial",
                                          pb: 1.5,
                                       }}
                                    >
                                       <DescriptionIcon fontSize="small" color="action" sx={{ mr: 1 }} />
                                       <Typography variant="subtitle2">{doc}</Typography>
                                    </Box>
                                 );
                              })}
                           </Collapse>
                        </Grid>
                     </Box>
                  ) : (
                     <></>
                  )}
                  {!props.cancelButton ? (
                     <Box sx={{ display: "flex", justifyContent: "space-between", minWidth: "100%", p: 1 }}>
                        <Box>
                           <Button onClick={handleClose} sx={{ height: "100%" }} variant="outlined">
                              Cancelar
                           </Button>
                        </Box>
                        <Box>
                           <Button
                              sx={{ height: "100%" }}
                              variant="contained"
                              color="primary"
                              onClick={
                                 props.setOpenLogin
                                    ? handleOpenLoginModal
                                    : props.cancelButton
                                    ? handleClose
                                    : props.onConfirm
                              }
                           >
                              Confirmar
                           </Button>
                        </Box>
                     </Box>
                  ) : (
                     <Box sx={{ display: "flex", justifyContent: "center", minWidth: "100%", p: 1 }}>
                        <Button
                           sx={{ height: "100%" }}
                           variant="contained"
                           color="primary"
                           onClick={
                              props.setOpenLogin
                                 ? handleOpenLoginModal
                                 : props.cancelButton
                                 ? handleClose
                                 : props.onConfirm
                           }
                        >
                           Confirmar
                        </Button>
                     </Box>
                  )}
               </Grid>
            </Box>
         </Modal>
      </div>
   );
};

const topRow = {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
};

const middleRow = {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   border: "solid #EBEBEB",
   borderWidth: "thin 0px",
};
