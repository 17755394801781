import { Modal, Box, Typography, Divider, Button } from "@mui/material";
import { useContext } from "react";
import { ComplaintStepperContext } from "../context/complaintStepperContext";
import PersonIcon from "@mui/icons-material/Person";
import { styled } from "@mui/material/styles";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
   ({ theme }) => ({
      backgroundColor: "white",
      border: `1px solid ${theme.palette.divider}`,
      "&:not(:last-child)": {
         borderBottom: 0,
      },
      "&:before": {
         display: "none",
      },
      "&:hover": {
         cursor: "default",
      },
   })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
   backgroundColor: "#f9f9f9",
   borderTop: "1px solid rgba(0, 0, 0, .125)",
   "&:hover": {
      cursor: "default",
   },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
   <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
   borderRadius: 2,
   borderBottom: `1px solid ${theme.palette.divider}`,
   flexDirection: "row-reverse",
   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
   },
   "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
   },
   "&:hover": {
      cursor: "default",
   },
}));

interface ComplainerDetailsModalProps {
   open: boolean;
   setOpen: Function;
   onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ComplainerDetailsModal = (props: ComplainerDetailsModalProps) => {
   const { complaint } = useContext(ComplaintStepperContext);

   const handleClose = () => {
      props.setOpen(false);
   };

   return complaint ? (
      <Modal
         open={props.open}
         onClose={handleClose}
         sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
         <Box
            sx={{
               minWidth: 800,
               maxWidth: "90%",
               borderRadius: 1,
               bgcolor: "white",
               p: 2,
               minHeight: 200,
               height: "auto",
            }}
         >
            <Box sx={{ p: 2 }}>
               <Typography variant="h5">Información del denunciante</Typography>
            </Box>
            <Divider />
            <Divider sx={{ mx: 2 }} />
            <Box sx={{ maxHeight: "480px", overflowY: "scroll", m: 2 }}>
               <Accordion
                  expanded={true}
                  sx={{
                     borderRadius: 0,
                  }}
               >
                  <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                     <Box sx={{ display: "flex", alignItems: "center" }}>
                        <PersonIcon sx={{ mr: 1 }} />
                        <Typography fontWeight={600} fontSize={17}>
                           {complaint.complainerDetails.name}
                        </Typography>
                     </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                     <Box sx={{ px: 2, pt: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-around", pb: 1 }}>
                           <Box flex={1}>
                              <Typography fontWeight={600}>Nombre</Typography>
                              <Typography gutterBottom>{complaint.complainerDetails.name}</Typography>
                           </Box>
                           <Box flex={1}>
                              <Typography fontWeight={600}>Apellidos</Typography>
                              <Typography gutterBottom>{complaint.complainerDetails.lastName}</Typography>
                           </Box>
                           <Box flex={1}>
                              <Typography fontWeight={600}>Género</Typography>
                              <Typography gutterBottom>{complaint.complainerDetails.gender}</Typography>
                           </Box>
                           <Box flex={1}>
                              <Typography fontWeight={600}>Teléfono</Typography>
                              <Typography>{complaint.complainerDetails.phoneNumber}</Typography>
                           </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexWrap: "wrap", pb: 1 }}>
                           <Box flex={1} my={1}>
                              <Typography fontWeight={600}>Correo electrónico</Typography>
                              <Typography>{complaint.complainerDetails.email}</Typography>
                           </Box>
                           <Box flex={1} my={1}>
                              <Typography fontWeight={600}>Dirección</Typography>
                              <Typography>{complaint.complainerDetails.address}</Typography>
                           </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexWrap: "wrap", pb: 1 }}>
                           <Box flex={1} my={1}>
                              <Typography fontWeight={600}>País</Typography>
                              <Typography>{complaint.complainerDetails.country}</Typography>
                           </Box>
                           <Box flex={1} my={1}>
                              <Typography fontWeight={600}>Estado</Typography>
                              <Typography>{complaint.complainerDetails.state}</Typography>
                           </Box>
                           <Box flex={1} my={1}>
                              <Typography fontWeight={600}>Ciudad</Typography>
                              <Typography>{complaint.complainerDetails.city}</Typography>
                           </Box>
                           <Box flex={1} my={1}>
                              <Typography fontWeight={600}>C.P.</Typography>
                              <Typography>{complaint.complainerDetails.zipCode}</Typography>
                           </Box>
                        </Box>
                     </Box>
                  </AccordionDetails>
               </Accordion>
            </Box>
            <Box display="flex" justifyContent="flex-start" sx={{ mx: 2 }}>
               <Button variant="contained" onClick={handleClose}>
                  Cerrar
               </Button>
            </Box>
         </Box>
      </Modal>
   ) : (
      <></>
   );
};
