import { useContext } from "react";
import { GetActiveComplaints } from "../../../lib/lecosyBackendClient";
import { useNavigate } from "react-router-dom";
// @mui imports
import { Box } from "@mui/material";
import { HeadCell, TableComponent } from "../../../components/TableComponent";
import { ComplaintContext } from "../../../context/complaintsExternContext/complaintContext";
import GetComplaintTablesFilters from "../../../hooks/GetTablesFilters";
import { capitalizeFirstLetter } from "../../../const/globalConst";

const columns: HeadCell[] = [
   { field: "reportNumber", headerName: "No. de Reporte", type: "string" },
   { field: "company", headerName: "Empresa/Sucursal", type: "string" },
   { field: "complaintType", headerName: "Tipo", type: "string" },
   { field: "complainerType", headerName: "Denunciante", type: "string" },
   { field: "category", headerName: "Categoría", type: "string", align: "left" },
   { field: "date", headerName: "Recepción", type: "date" },
   { field: "status", headerName: "Estatus", type: "status" },
];

export const ActiveComplaintsTable = () => {
   const navigate = useNavigate();
   const { branches } = useContext(ComplaintContext);
   const { data, isLoading: loadingComplaints } = GetActiveComplaints(branches);

   const rows =
      !loadingComplaints &&
      data?.map((c) => ({
         reportNumber: c.complaint.reportNumber,
         company: c.branch?.name || c.foundCompany.person_details.comercialName,
         complaintType: capitalizeFirstLetter(c.complaint?.type || "denuncia"),
         complainerType: c.complaint.complainerType,
         date: c.complaint.date || c.complaint.createdAt,
         category: c.complaint.category,
         status: capitalizeFirstLetter(c.complaint.status),
      }));

   const branchesName = GetComplaintTablesFilters(data, "branches");

   return (
      <Box sx={{ width: "100%" }}>
         <TableComponent
            headerColor
            defaultColumnToOrder="date"
            defaultOrder="asc"
            defaultRowsPerPage={10}
            rowsPerPageOptions={[5, 10, 25]}
            emptyDataText="No hay denuncias en este momento"
            headers={columns}
            loader={loadingComplaints}
            rows={rows || []}
            onClick={(e, row) => {
               navigate(row.reportNumber);
            }}
            filters={[
               { allOption: "Todas", data: branchesName, id: "company", label: "Empresas/Sucursales", type: "select" },
               { allOption: "", data: [], id: "reportNumber", label: "No. de reporte", type: "number" },
               {
                  allOption: "Todos",
                  data: ["En proceso", "En valoración"],
                  id: "status",
                  label: "Estatus",
                  type: "select",
               },
            ]}
            filtersInitialState={{
               company: "Todas",
               reportNumber: "",
               status: "Todos",
            }}
         />
      </Box>
   );
};
