import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { handleInvalidRequest } from "./lecosyBackendClient";

const host =
   window.location.hostname === "test.admin.lecosy.com.mx" ||
   window.location.hostname === "www.test.admin.lecosy.com.mx"
      ? "https://test.server.lecosy.com.mx"
      : process.env.NODE_ENV === "production"
      ? "https://server.lecosy.com.mx"
      : "http://localhost:8002";

export const useGetObjectList = (objectId: string, folder: string, subfolder?: string) => {
   return useQuery({
      queryKey: ["ObjectList"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const url = subfolder
            ? `${host}/api/s3/get/${objectId}/${folder}/${subfolder}`
            : `${host}/api/s3/get/${objectId}/${folder}`;
         const { data } = await axios.get(url);
         return data;
      },
   });
};

export const useGetPresignedURL = (objectId: string, folder: string, subfolder?: string) => {
   return useQuery({
      queryKey: ["getObject"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const url = subfolder
            ? `${host}/api/s3/get/${objectId}/${folder}/${subfolder}`
            : `${host}/api/s3/get/${objectId}/${folder}`;
         const { data } = await axios.get(url);
         return data;
      },
   });
};

export const UseGetPresignedURLUpload = async (fileInfo: { bucket: string; folder: string; name: string }) => {
   axios.defaults.withCredentials = true;
   const data = await axios.post(`${host}/api/s3/upload`, fileInfo);
   return data;
};

export const useDeleteObject = (objectId: string) => {
   return useMutation({
      mutationFn: async (fileInfo: { name: string; folder: string }) => {
         axios.defaults.withCredentials = false;
         const url = fileInfo.name
            ? `${host}/api/s3/delete/${objectId}/${fileInfo.folder}/${fileInfo.name}`
            : `${host}/api/s3/delete/${objectId}/${fileInfo.folder}`;
         const { data } = await axios.put(url, fileInfo);
         return data;
      },
   });
};

export const uploadFileToS3 = async (signedURL, file) => {
   try {
      const config = {
         headers: {
            "Content-Type": `${file.type}`,
         },
      };
      axios.defaults.withCredentials = false;
      await axios.put(signedURL, file, config);
   } catch (error) {
      handleInvalidRequest(error);
   }
};
