import { Box } from "@mui/material";
import { InternComplainsQueryBanckTable } from "../../../containers/ComplaintsContainer/InternComplaints/InternComplainsQueryBanckTable";
import Container from "@mui/material/Container/Container";
import { TopHeader } from "../../../components/TopHeader";

export const ComplaintQueryBankScreen = () => {
   return (
      <Box sx={{ padding: 2, display: "flex", flexDirection: "column" }}>
         <TopHeader head="Banco de Consultas" content={<></>} />
         <Container maxWidth="xl">
            <Box sx={{ m: 2 }} />
            <InternComplainsQueryBanckTable />
         </Container>
      </Box>
   );
};
