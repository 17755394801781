import { useContext, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { ComplainantAndWitnessCards } from "../ComplainantAndWitnessCards";
import { DocumentsTableNew } from "../DocumentsTableNew";
import { ComplaintStepperContext } from "../../../context/complaintStepperContext";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { AddWitnessesModal } from "../AddWitnessesModal";

export const ReplicaStep = () => {
   const {
      complaint,
      dataState,
      filteredWitnesses,
      documentation,
      selectedData,
      setReplicaOpen,
      replicaSubStep,
      selectedWitness,
      setReplicaSubStep,
      setSelectedWitness,
   } = useContext(ComplaintStepperContext);
   const [openAddWitnesses, setOpenAddWitnesses] = useState(false);

   return (
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "50vh" }}>
         <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: 3 }}>
            <Typography fontWeight="400" variant="h6" textAlign={"center"}>
               Testigos en réplica de:{" "}
               {selectedData.e.lastName ? selectedData.e.name + " " + selectedData.e.lastName : selectedData.e.name}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
               <Typography fontWeight="400">Espacios para testigos disponibles:</Typography>
               <Typography fontWeight="400" color={"red"} ml={1}>
                  {2 - filteredWitnesses.length}
               </Typography>
               {2 - filteredWitnesses.length === 0 ? null : (
                  <Button
                     onClick={() => setOpenAddWitnesses(true)}
                     sx={{
                        bgcolor: "#162c44",
                        color: "white",
                        "&:hover": {
                           bgcolor: "#2d4357",
                        },
                        display: "flex",
                        alignItems: "center",
                        ml: 2,
                     }}
                  >
                     <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>Testigo</Typography> <AddIcon />
                     </Box>
                  </Button>
               )}
            </Box>
         </Box>
         <Divider sx={{ my: 1 }} />
         <Box sx={{ flex: 2 }}>
            {!replicaSubStep && filteredWitnesses.length > 0 ? (
               <Box sx={{ p: 2 }}>
                  <Box sx={{ height: "280px", overflowY: "scroll", borderRadius: 1 }}>
                     <ComplainantAndWitnessCards data={filteredWitnesses} isOffender={false} replica />
                  </Box>
               </Box>
            ) : (
               <>
                  {filteredWitnesses.length === 0 ? (
                     <Box sx={{ p: 2, textAlign: "center" }}>
                        <Typography fontWeight="400">Aún no existen testigos de réplica.</Typography>
                        <Typography fontWeight="400">
                           Para agregar testigos nuevos, dar click en el boton superior.
                        </Typography>
                     </Box>
                  ) : (
                     <>
                        <Box
                           sx={{
                              my: 1,
                              px: 2,
                              py: 1,
                              display: "flex",
                              justifyContent: "space-between",
                              bgcolor: "#f8f8f8",
                              borderRadius: 4,
                              alignItems: "center",
                           }}
                        >
                           <Typography fontWeight="400" variant="h5">
                              {selectedWitness.e.lastName
                                 ? selectedWitness.e.name + " " + selectedWitness.e.lastName
                                 : selectedWitness.e.name}
                           </Typography>
                           <Typography sx={{ display: "flex", alignItems: "center" }}>
                              {`Documentos obligatorios: ${
                                 documentation.find(
                                    (doc) =>
                                       doc.name ===
                                          `constancia-inexistencia-testigo-${complaint.reportNumber}-${selectedData.index}-${selectedWitness.index}.pdf` ||
                                       doc.name ===
                                          `constancia-incomparecencia-testigo-${complaint.reportNumber}-${selectedData.index}-${selectedWitness.index}.pdf` ||
                                       doc.name ===
                                          `entrevista-testigo-${complaint.reportNumber}-${selectedData.index}-${selectedWitness.index}.pdf`
                                 )
                                    ? 1
                                    : 0
                              }/${1}`}
                              {documentation.find(
                                 (doc) =>
                                    doc.name ===
                                       `constancia-inexistencia-testigo-${complaint.reportNumber}-${selectedData.index}-${selectedWitness.index}.pdf` ||
                                    doc.name ===
                                       `constancia-incomparecencia-testigo-${complaint.reportNumber}-${selectedData.index}-${selectedWitness.index}.pdf` ||
                                    doc.name ===
                                       `entrevista-testigo-${complaint.reportNumber}-${selectedData.index}-${selectedWitness.index}.pdf`
                              ) ? (
                                 <CheckCircleOutlineIcon sx={{ ml: 1, color: "green" }} />
                              ) : null}
                           </Typography>
                        </Box>
                        <DocumentsTableNew
                           replica={true}
                           index={selectedWitness.index}
                           offenderIndex={selectedData.index}
                           rows={[
                              {
                                 document: "Notificación de entrevista",
                                 templateLink: `notificacion/testigo/${selectedWitness.index}`,
                                 documentName: ["entrevista-testigo", "constancia-incomparecencia-testigo"],
                                 fileHeader: "Agregar documento de notificación al testigo",
                              },
                              {
                                 document: "Entrevista",
                                 templateLink: `${selectedWitness.index}/entrevista/1`,
                                 documentName: ["entrevista-testigo", "constancia-incomparecencia-testigo"],
                                 fileHeader: "Agregar documento de entrevista al testigo",
                              },
                              {
                                 document: "Constancia de incomparecencia",
                                 templateLink: `incomparecencia/1`,
                                 documentName: ["constancia-incomparecencia-testigo", "entrevista-testigo"],
                                 fileHeader: "Agregar documento de constancia de incomparecencia",
                              },
                           ]}
                        />
                     </>
                  )}{" "}
               </>
            )}
         </Box>
         <Box
            sx={{
               display: "flex",
               flexDirection: "row",
               flex: 1,
               mt: 2,
               mx: 2,
               alignItems: "center",
            }}
         >
            {dataState === 0 ? null : (
               <Box sx={{ maxHeight: 30 }}>
                  <Button
                     onClick={() => {
                        replicaSubStep ? setReplicaSubStep(false) : setReplicaOpen(false);
                        setSelectedWitness(null);
                     }}
                     variant="outlined"
                  >
                     {`Volver`}
                  </Button>
               </Box>
            )}
         </Box>
         <AddWitnessesModal open={openAddWitnesses} setOpen={setOpenAddWitnesses} />
      </Box>
   );
};
