import { Box, Stack, Typography } from "@mui/material";
import { HeadCell, TableComponent } from "../../../TableComponent";
import { useContext, useEffect, useState } from "react";
import { ClientConfigContext } from "../../../../context/clientConfigContext";
import rolesByService from "../../../../const/rolesByService.json";

export const DenounceChannelMembersTable = () => {
   const [userArray, setUserArray] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const { group } = useContext(ClientConfigContext);

   const usersColumns: HeadCell[] = [
      { field: "username", headerName: "Nombre", type: "string" },
      { field: "email", headerName: "Correo electrónico", type: "string" },
      { field: "phone", headerName: "Teléfono ", type: "string" },
      { field: "roles", headerName: "Rol", type: "popover" },
   ];

   const getGroupRoles = (roles: any[]) => {
      const rolesArray = roles.flatMap((role) =>
         role.roles.filter((r) => rolesByService.CD.includes(r.name)).map((r) => r.name)
      );
      return Array.from(new Set(rolesArray));
   };

   useEffect(() => {
      const fetchUserData = async () => {
         setIsLoading(true);
         const groupUsers: any = group.users;
         const filteredGroupUsers = groupUsers.filter((userG) => userG.role.some((role) => role.group === group._id));
         const usersData = filteredGroupUsers
            .map((user) => ({
               _id: user._id,
               username: user.firstName + " " + user.lastName,
               email: user.email,
               phone: user.phoneNumber,
               roles: getGroupRoles(user.role),
            }))
            .filter((v) => v.roles?.length > 0);
         setUserArray(usersData);
         setIsLoading(false);
      };
      if (group?._id && userArray.length === 0) fetchUserData();
   }, [group, userArray.length]);

   return (
      <Box
         sx={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            boxShadow: 2,
            bgcolor: "white",
         }}
      >
         <Stack direction={"row"} justifyContent={"space-between"} alignContent={"center"} sx={{ px: 4, pt: 3 }}>
            <Typography sx={{ fontWeight: 700 }}>Usuarios del módulo</Typography>
         </Stack>
         <TableComponent
            defaultColumnToOrder="firstName"
            defaultOrder="asc"
            defaultRowsPerPage={5}
            rowsPerPageOptions={[5, 10, 20, 50]}
            headers={usersColumns}
            rows={userArray}
            onClick={() => {}}
            loader={isLoading}
            emptyDataText="No existen usuarios registrados en este módulo"
            disableBorders
         />
      </Box>
   );
};
