import { ExpandMore, Info, InfoRounded, Percent, PictureAsPdf } from "@mui/icons-material";
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Button,
   CircularProgress,
   Popover,
   Stack,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../context/UserContext";
import {
   getAffairsByCompany,
   getChargeByGovernUsers,
   getGoberningBodiesByCompany,
   getGoberningBodiesByGroup,
} from "../../../../../lib/governanceClient";
import { getFileByGovernanceAndFoldername, getUrlS3, getUserDataGB } from "../../../../../lib/usersBEClient";
import { formatTextUpperLower, getDateAndTimeFromISODate } from "../../../../../const/globalConst";
import useGetMembersInfo from "../../../../../hooks/GC/useGetMembersInfo";
import { StructureDataDisplay } from "./StructureDataDisplay";
import { SnackBarContext } from "../../../../../context/snackBarContext";
import { ClientConfigContext } from "../../../../../context/clientConfigContext";

export interface AffairSeries {
   title: string;
   faculties: number;
}
export interface AffairsInterface {
   _id: string;
   title: string;
   series: AffairSeries[];
   classification: string;
   isNew?: boolean;
   companyId: string;
}
interface GoverningBodiesComponentProps {
   company?: any;
   group?: boolean;
}

export interface BodyUserFormat {
   _id: string;
   firstName: string;
   lastName: string;
   phoneNumber: string;
   email: string;
   charge: string;
   chargeName: string;
   characters: string[];
   user: string;
}

const useFilterGovernBodies = (companyId, group: boolean) => {
   const { user } = useContext(UserContext);
   const { group: groupContext } = useContext(ClientConfigContext);

   const [seed, setSeed] = useState(0);
   const [bodies, setBodies] = useState([]);
   const [loadingBodies, setLoadingBodies] = useState(true);

   useEffect(() => {
      const filterBodies = async () => {
         const data = await getGoberningBodiesByCompany(group ? groupContext._id : companyId);
         setBodies(data);
         setLoadingBodies(false);
      };
      filterBodies();
      // eslint-disable-next-line
   }, [user, seed]);

   return { bodies, loadingBodies, setSeed };
};

export const useProcessData = (companyId, group: boolean) => {
   const { bodies, loadingBodies, setSeed } = useFilterGovernBodies(companyId, group);
   const [members, setMembers] = useState([]);
   const [loadingFormat, setloadingFormat] = useState(false);
   const [affairs, setAffairs] = useState<AffairsInterface[]>([]);

   useEffect(() => {
      const processData = async () => {
         setloadingFormat(true);
         if (!group) {
            const response = await getAffairsByCompany(companyId);
            if (response) setAffairs(response.data);
         }

         const users = [];
         for (const body of bodies) {
            users.push(...body.users);
         }
         const ids = users.map((e) => e.user); //array con formato de govern bodies
         const chargesIds = users.map((e) => e.charge);
         const cleanArray = ids.filter((a, b) => ids.indexOf(a) === b); //string array sin duplicados para mandar al backend
         const chargeData = await getChargeByGovernUsers(chargesIds);
         const userData = await getUserDataGB(cleanArray);

         let completePorfile = [];
         for (let t = 0; t < users.length; t++) {
            completePorfile.push({
               ...chargeData.find((e) => e._id === users[t].charge),
               ...userData.find((e) => e._id === users[t].user),
               ...users[t],
            });
         }

         // ASIGNACION DE DATA A ORGANOS DE GOBIERNO
         let mergedFinalBodies = [];
         for (let i = 0; i < bodies.length; i++) {
            const element = bodies[i];
            let bodiesData = (({ _id, title, regulationFile, structure, functions }) => ({
               _id,
               title,
               regulationFile,
               structure,
               functions,
            }))(element);
            let usersData = [];
            for (let j = 0; j < element.users.length; j++) {
               const elementUser = element.users[j]._id;
               if (element.title === "Asamblea de accionistas") {
                  let userObject = {
                     ...completePorfile.find((e) => e._id === elementUser && e.chargeName === "Accionista"),
                  };
                  if (Object.entries(userObject).length !== 0) {
                     usersData.push({ ...userObject });
                  }
               } else {
                  usersData.push({
                     ...completePorfile.find((e) => e._id === elementUser),
                  });
               }
            }
            mergedFinalBodies.push({ bodiesData, usersData });
         }
         setMembers(mergedFinalBodies);
         setloadingFormat(false);
      };
      if (!loadingBodies && bodies) processData();
      // eslint-disable-next-line
   }, [bodies]);

   return { loadingFormat, members, affairs, setSeed };
};

export const GoverningBodiesComponent = (props: GoverningBodiesComponentProps) => {
   const { loadingFormat, members, affairs } = useProcessData(props.company?._id, props.group);
   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
   const { formatedMembers } = useGetMembersInfo(false);
   const [isLoadingFile, setIsLoadingFile] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);

   const chargesOrder = ["presidente", "vicepresidente", "secretario", "vocal", "consultor"];
   const getChargeKey = (chargeName) => {
      if (!chargeName) return;
      if (chargeName.toLowerCase().includes("vocal")) return "vocal";
      if (chargeName.toLowerCase().includes("presidente")) return "presidente";
      if (chargeName.toLowerCase().includes("secretario")) return "secretario";
      if (chargeName.toLowerCase().includes("vicepresidente")) return "vicepresidente";
      return "consultor";
   };

   const getFile = async (governingBodyId: string, title: string, fileName: string) => {
      setIsLoadingFile(true);
      let folderName = "";
      if (title === "Asamblea de accionistas") folderName = "Acta constitutiva";
      else if (title === "Consejo de administración") folderName = "Reglamento de consejo";
      else folderName = "Reglamento de comité";

      const file = await getFileByGovernanceAndFoldername(governingBodyId, folderName);
      if (file === undefined) return showSnackBar("Favor de actualizar documento", true);
      const fileDirection = file.fileDirection;
      const fileData1: string = await getUrlS3(
         "files-lecosy",
         {
            folder: fileDirection,
         },
         fileName
      );
      setIsLoadingFile(false);
      window.open(fileData1, "_blank", "noopener,noreferrer");
   };

   return (
      <>
         <Typography fontWeight={"bold"} p={2} pt={1}>
            Órganos de gobierno
         </Typography>
         {loadingFormat ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
               <CircularProgress />
            </Box>
         ) : members.length === 0 ? (
            <Box
               display={"flex"}
               flexDirection={"column"}
               justifyContent={"center"}
               alignItems={"center"}
               rowGap={1}
               sx={{ py: 2, opacity: 0.5 }}
            >
               <Info sx={{ fontSize: "50px" }} />
               <Typography textAlign={"center"}>No se han registrado órganos de gobierno</Typography>
            </Box>
         ) : (
            <TableContainer sx={{ display: "flex", width: "100%" }}>
               <Table>
                  <TableBody>
                     {loadingFormat || !members ? (
                        <CircularProgress />
                     ) : (
                        <>
                           {members.map((body, index) => {
                              const openPopover = Boolean(anchorEl);
                              const handlePopoverOpen = (event: any) => {
                                 setAnchorEl(event.currentTarget);
                              };
                              const handlePopoverClose = () => {
                                 setAnchorEl(null);
                              };
                              return (
                                 <Accordion
                                    key={index}
                                    disableGutters
                                    elevation={0}
                                    sx={{
                                       boxShadow: 0,
                                       "&:before": { backgroundColor: "transparent !important" },
                                       borderBottom: 0,
                                       my: 0.5,
                                    }}
                                 >
                                    <AccordionSummary
                                       expandIcon={
                                          <ExpandMore
                                             sx={{
                                                color: "white",
                                             }}
                                          />
                                       }
                                       sx={{ bgcolor: "#162c44", borderRadius: "20px !important" }}
                                    >
                                       <Box
                                          sx={{
                                             display: "flex",
                                             alignItems: "center",
                                             columnGap: 1,
                                          }}
                                       >
                                          <Typography sx={{ fontSize: 14, color: "white" }}>
                                             {body.bodiesData.title}
                                          </Typography>
                                          <InfoRounded
                                             id={body.bodiesData.title}
                                             onMouseEnter={handlePopoverOpen}
                                             sx={{ color: "#D4D4D4", fontSize: 18 }}
                                          />
                                          <Popover
                                             id={body.bodiesData.title}
                                             open={openPopover && anchorEl.id === body.bodiesData.title}
                                             anchorEl={anchorEl}
                                             onClose={handlePopoverClose}
                                             disableAutoFocus
                                          >
                                             <Stack
                                                sx={{
                                                   p: 2,
                                                   maxWidth: 700,
                                                   maxHeight: 400,
                                                   overflow: "auto",
                                                   gap: 2,
                                                }}
                                                onMouseLeave={handlePopoverClose}
                                             >
                                                {body.bodiesData.title === "Asamblea de accionistas" ? (
                                                   affairs.length > 0 ? (
                                                      affairs.map((a, i) => (
                                                         <Typography
                                                            key={`afairs-${i}`}
                                                            sx={{ fontSize: 14, textAlign: "justify" }}
                                                         >
                                                            {i + 1}. {a.title}
                                                         </Typography>
                                                      ))
                                                   ) : (
                                                      <Typography sx={{ fontSize: 14 }}>
                                                         No se han registrado asuntos
                                                      </Typography>
                                                   )
                                                ) : (
                                                   body.bodiesData.functions.map((o, i) => (
                                                      <Typography
                                                         key={`afairsGovernance-${i}`}
                                                         fontSize={14}
                                                         sx={{ textAlign: "justify" }}
                                                      >
                                                         {i + 1}. {o}
                                                      </Typography>
                                                   ))
                                                )}
                                             </Stack>
                                          </Popover>
                                       </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                       <TableRow sx={{ display: "flex" }}>
                                          <Table size="small" sx={{ display: "flex", flexDirection: "column" }}>
                                             <TableHead>
                                                <TableRow sx={{ display: "flex", mt: 1 }}>
                                                   {formatTextUpperLower(body.bodiesData.title) ===
                                                   "Asamblea de accionistas" ? (
                                                      <>
                                                         <TableCell sx={{ flex: 2, fontWeight: 600 }}>
                                                            Socio / Acionista
                                                         </TableCell>
                                                         <TableCell sx={{ flex: 3, fontWeight: 600 }}>
                                                            Correo electronico
                                                         </TableCell>
                                                         <TableCell sx={{ flex: 2, fontWeight: 600 }}>
                                                            Telefono
                                                         </TableCell>
                                                         <TableCell sx={{ flex: 2, fontWeight: 600 }}>
                                                            Fecha de creación
                                                         </TableCell>
                                                         <TableCell sx={{ flex: 2, fontWeight: 600 }}>
                                                            Porcentaje
                                                         </TableCell>
                                                      </>
                                                   ) : (
                                                      <>
                                                         <TableCell sx={{ flex: 2.5, fontWeight: 600 }}>
                                                            Cargo que desempeña
                                                         </TableCell>
                                                         <TableCell sx={{ flex: 2, fontWeight: 600 }}>
                                                            Nombre de Usuario
                                                         </TableCell>
                                                         <TableCell sx={{ flex: 3, fontWeight: 600 }}>
                                                            Correo electronico
                                                         </TableCell>
                                                         <TableCell sx={{ flex: 2, fontWeight: 600 }}>
                                                            Telefono
                                                         </TableCell>
                                                         <TableCell sx={{ flex: 2, fontWeight: 600 }}>
                                                            Fecha de creación
                                                         </TableCell>
                                                      </>
                                                   )}
                                                </TableRow>
                                             </TableHead>
                                             <TableBody>
                                                {body.usersData
                                                   .sort((a, b) => {
                                                      const aKey = getChargeKey(a.chargeName);
                                                      const bKey = getChargeKey(b.chargeName);
                                                      return chargesOrder.indexOf(aKey) - chargesOrder.indexOf(bKey);
                                                   })
                                                   .map((user, subIndex) => (
                                                      <TableRow
                                                         sx={{
                                                            display: "flex",
                                                            bgcolor: "#F7F9FB",
                                                            borderRadius: 2,
                                                            m: 1,
                                                         }}
                                                         key={subIndex}
                                                      >
                                                         {formatTextUpperLower(body.bodiesData.title) ===
                                                         "Asamblea de accionistas" ? (
                                                            <>
                                                               <TableCell
                                                                  sx={{
                                                                     flex: 2,
                                                                     borderBottom: 0,
                                                                     display: "flex",
                                                                     alignItems: "center",
                                                                  }}
                                                               >
                                                                  {user.firstName} {user.lastName}
                                                               </TableCell>
                                                               <TableCell
                                                                  sx={{
                                                                     flex: 3,
                                                                     borderBottom: 0,
                                                                     overflow: "hidden",
                                                                     display: "flex",
                                                                     alignItems: "center",
                                                                  }}
                                                               >
                                                                  {user.email}
                                                               </TableCell>
                                                               <TableCell
                                                                  sx={{
                                                                     flex: 2,
                                                                     borderBottom: 0,
                                                                     display: "flex",
                                                                     alignItems: "center",
                                                                  }}
                                                               >
                                                                  {user.phoneNumber}
                                                               </TableCell>
                                                               <TableCell
                                                                  sx={{
                                                                     flex: 2,
                                                                     borderBottom: 0,
                                                                     display: "flex",
                                                                     alignItems: "center",
                                                                  }}
                                                               >
                                                                  {getDateAndTimeFromISODate(user.createdAt)}
                                                               </TableCell>
                                                               <TableCell
                                                                  sx={{
                                                                     flex: 2,
                                                                     borderBottom: 0,
                                                                     display: "flex",
                                                                     justifyContent: "center",
                                                                     alignItems: "center",
                                                                  }}
                                                               >
                                                                  <Typography
                                                                     sx={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        px: 1,
                                                                        fontSize: 15,
                                                                        bgcolor: "#162C44",
                                                                        color: "white",
                                                                        borderRadius: 1,
                                                                        maxHeight: 25,
                                                                     }}
                                                                  >
                                                                     {formatedMembers?.length > 0 &&
                                                                        formatedMembers &&
                                                                        formatedMembers[subIndex]?.propertyPercentage}
                                                                     <Percent sx={{ pl: 1 }} />
                                                                  </Typography>
                                                               </TableCell>
                                                            </>
                                                         ) : (
                                                            <>
                                                               <TableCell
                                                                  sx={{
                                                                     flex: 2.5,
                                                                     borderBottom: 0,
                                                                     display: "flex",
                                                                     alignItems: "center",
                                                                  }}
                                                               >
                                                                  {user.chargeName}
                                                               </TableCell>
                                                               <TableCell
                                                                  sx={{
                                                                     flex: 2,
                                                                     borderBottom: 0,
                                                                     display: "flex",
                                                                     alignItems: "center",
                                                                  }}
                                                               >
                                                                  {user.firstName} {user.lastName}
                                                               </TableCell>
                                                               <TableCell
                                                                  sx={{
                                                                     flex: 3,
                                                                     borderBottom: 0,
                                                                     overflow: "hidden",
                                                                     display: "flex",
                                                                     alignItems: "center",
                                                                  }}
                                                               >
                                                                  {user.email}
                                                               </TableCell>
                                                               <TableCell
                                                                  sx={{
                                                                     flex: 2,
                                                                     borderBottom: 0,
                                                                     display: "flex",
                                                                     alignItems: "center",
                                                                  }}
                                                               >
                                                                  {user.phoneNumber}
                                                               </TableCell>
                                                               <TableCell
                                                                  sx={{
                                                                     flex: 2,
                                                                     borderBottom: 0,
                                                                     display: "flex",
                                                                     alignItems: "center",
                                                                  }}
                                                               >
                                                                  {getDateAndTimeFromISODate(user.createdAt)}
                                                               </TableCell>
                                                            </>
                                                         )}
                                                      </TableRow>
                                                   ))}
                                                <TableRow sx={{ display: "flex" }}>
                                                   <TableCell sx={{ flex: 1, borderBottom: 0, display: "flex" }}>
                                                      <Button
                                                         endIcon={<PictureAsPdf />}
                                                         variant="contained"
                                                         sx={{
                                                            flex: 1,
                                                            ml: 1,
                                                            height: 30,
                                                            alignSelf: "center",
                                                            bgcolor: "#E5E6EB",
                                                            color: "black",
                                                            borderRadius: 2,
                                                            boxShadow: 0,
                                                            ":hover": { bgcolor: "#E5E6EB" },
                                                         }}
                                                         disabled={isLoadingFile}
                                                         onClick={() => {
                                                            getFile(
                                                               body.bodiesData._id,
                                                               body.bodiesData.title,
                                                               body.bodiesData.regulationFile.name
                                                            );
                                                         }}
                                                      >
                                                         {formatTextUpperLower(body.bodiesData.title) ===
                                                         "Asamblea de accionistas"
                                                            ? "Acta constitutiva"
                                                            : "Reglamento"}
                                                      </Button>
                                                   </TableCell>
                                                </TableRow>

                                                <Box sx={{ bgcolor: "#F0F0F0", m: 2, borderRadius: 2.5, px: 4, py: 2 }}>
                                                   <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                      <Typography fontWeight={600}>Información del órgano</Typography>
                                                   </Box>
                                                   <StructureDataDisplay
                                                      structureData={body.bodiesData.structure}
                                                      bodyTitle={body.bodiesData.title}
                                                   />
                                                </Box>
                                             </TableBody>
                                          </Table>
                                       </TableRow>
                                    </AccordionDetails>
                                 </Accordion>
                              );
                           })}
                        </>
                     )}
                  </TableBody>
               </Table>
            </TableContainer>
         )}
      </>
   );
};
