import { useContext, useEffect, useState } from "react";
import { Box, Button, Divider, Typography, Toolbar, MenuItem, InputAdornment } from "@mui/material";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { InputTextField } from "../../Inputs/InputTextField";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircularProgress from "@mui/material/CircularProgress";
import { ClientConfigContext } from "../../../context/clientConfigContext";
import { SnackBarContext } from "../../../context/snackBarContext";
import { useUpdateInvoiceDetails, useUpdateInvoiceDetailsProvider } from "../../../lib/usersBEClient";
import { addInvoiceDetailsSchema } from "../../../lib/Validations/inputSchemas";
import { InputSelectField } from "../../Inputs/InputSelectField";
import { InvoiceDetails } from "../../../types/BaseTypes";

interface ModifyClientInvoiceInfoDrawerProps {
   open: boolean;
   setOpen: Function;
   clientInfo?: any;
   group?: boolean;
}

export const ModifyClientInvoiceInfoDrawer = (props: ModifyClientInvoiceInfoDrawerProps) => {
   const { refreshClientInfo, group, refreshGroupInfo } = useContext(ClientConfigContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [selectedCompany, setSelectedCompany] = useState<InvoiceDetails>();

   const { mutate, isLoading: isLoadingInvoice } = useUpdateInvoiceDetails(props.clientInfo._id);
   const { mutate: updateCompany, isLoading: isLoadingUpdate } = useUpdateInvoiceDetailsProvider(
      group ? group._id : ""
   );

   function handleOpenAddCompanyDrawer() {
      props.setOpen(!props.open);
   }

   async function handleSubmit(values) {
      mutate(
         {
            denomination: values.denomination,
            rfc: values.rfc,
            city: values.city,
            state: values.state,
            address: values.address,
            suburb: values.suburb,
            phone: values.phone,
            zipCode: values.zipCode,
            regime: values.regime,
            amount: values.amount,
            email: values.email,
         },
         {
            onSuccess: () => {
               showSnackBar("Los datos se han modificado", false);
               setTimeout(() => {
                  refreshClientInfo();
                  props.setOpen(false);
               }, 2000);
            },
            onError: (error) => {
               showSnackBar("Error al modificar los datos", true);
            },
         }
      );
   }

   async function handleUpdateCompany(values) {
      updateCompany(
         {
            company: values.company,
            amount: values.amount,
         },
         {
            onSuccess: () => {
               showSnackBar("Los datos se han modificado", false);
               setTimeout(() => {
                  refreshGroupInfo();
                  props.setOpen(false);
               }, 2000);
            },
            onError: (error) => {
               showSnackBar("Error al modificar los datos", true);
            },
         }
      );
   }

   const FormObserver: React.FC = () => {
      const { setFieldValue } = useFormikContext();

      useEffect(() => {
         if (selectedCompany) {
            setFieldValue("denomination", selectedCompany.denomination);
            setFieldValue("rfc", selectedCompany.rfc);
            setFieldValue("city", selectedCompany.city);
            setFieldValue("state", selectedCompany.state);
            setFieldValue("address", selectedCompany.address);
            setFieldValue("phone", selectedCompany.phone);
            setFieldValue("zipCode", selectedCompany.zipCode);
            setFieldValue("regime", selectedCompany.regime);
            setFieldValue("email", selectedCompany.email);
         }
         // eslint-disable-next-line
      }, [selectedCompany]);

      return null;
   };

   return (
      <Box display="flex" maxWidth={450} width={420}>
         <Box
            height="100%"
            position="fixed"
            display="flex"
            alignItems="center"
            onClick={handleOpenAddCompanyDrawer}
            sx={{
               "&:hover": {
                  bgcolor: "#2d435710",
               },
            }}
         >
            <ChevronRightIcon fontSize="small" />
         </Box>
         <Box flex={1} ml={2.5} mt={1}>
            <Toolbar />
            <Box px={2} py={3} bgcolor="white" width="100%" zIndex={1}>
               <Typography variant="h5">{"Modificar datos de factura"}</Typography>
            </Box>
            <Divider sx={{ mr: 1 }} />
            <Box pr={1} pl={0.5}>
               <Formik
                  initialValues={{
                     company: props.group ? group.invoiceCompany._id : "",
                     denomination: props.clientInfo.denomination,
                     rfc: props.clientInfo.rfc,
                     city: props.clientInfo.city,
                     state: props.clientInfo.state,
                     address: props.clientInfo.address,
                     suburb: props.clientInfo.suburb,
                     phone: props.clientInfo.phone,
                     zipCode: props.clientInfo.zipCode,
                     regime: props.clientInfo.regime,
                     amount: props.clientInfo.amount,
                     email: props.clientInfo.email,
                  }}
                  onSubmit={async (values) => {
                     try {
                        if (props.group) {
                           await handleUpdateCompany(values);
                        } else {
                           await handleSubmit(values);
                        }
                     } catch (error) {
                        console.log(error);
                     }
                  }}
                  validationSchema={addInvoiceDetailsSchema}
               >
                  {(formProps: FormikProps<any>) => (
                     <Form>
                        <FormObserver />
                        <Box mx={2} mt={1}>
                           {props.group ? (
                              <InputSelectField
                                 label="Seleccionar empresa"
                                 labelId="company"
                                 id="company"
                                 fullWidth
                                 name="company"
                                 variant={"outlined"}
                                 size={"small"}
                                 sx={{ my: "5px" }}
                                 value={formProps.values.company}
                              >
                                 {group.companies.length > 0 ? (
                                    group.companies.map((option, index) => {
                                       return option.invoice_details ? (
                                          <MenuItem
                                             key={`option-${index}`}
                                             value={option._id}
                                             onClick={() => setSelectedCompany(option.invoice_details)}
                                          >
                                             {option.person_details.comercialName}
                                          </MenuItem>
                                       ) : null;
                                    })
                                 ) : (
                                    <MenuItem key={"none"}>
                                       No se han registrado empresas con facturación completa
                                    </MenuItem>
                                 )}
                              </InputSelectField>
                           ) : null}
                           <InputTextField
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="rfc"
                              id="rfc"
                              type="text"
                              label="RFC"
                              fullWidth={true}
                              value={formProps.values.rfc}
                              disabled={props.group}
                           />
                        </Box>
                        <Box m={2}>
                           <InputTextField
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="denomination"
                              id="denomination"
                              type="text"
                              label="Denominación o razón social"
                              fullWidth={true}
                              value={formProps.values.denomination}
                              disabled={props.group}
                           />
                        </Box>
                        <Box m={2}>
                           <InputTextField
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="address"
                              id="address"
                              type="text"
                              label="Avenida o calle y número"
                              fullWidth={true}
                              value={formProps.values.address}
                              disabled={props.group}
                           />
                        </Box>
                        <Box m={2}>
                           <InputTextField
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="suburb"
                              id="suburb"
                              type="text"
                              label="Colonia"
                              fullWidth={true}
                              value={formProps.values.suburb}
                              disabled={props.group}
                           />
                        </Box>
                        <Box m={2}>
                           <InputTextField
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="email"
                              id="email"
                              type="text"
                              label="Correo electrónico"
                              fullWidth={true}
                              value={formProps.values.email}
                              disabled={props.group}
                           />
                        </Box>
                        <Box m={2}>
                           <InputTextField
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="phone"
                              id="phone"
                              type="text"
                              label="Teléfono"
                              fullWidth={true}
                              value={formProps.values.phone}
                              disabled={props.group}
                           />
                        </Box>
                        <Box m={2}>
                           <InputTextField
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="zipCode"
                              id="zipCode"
                              type="text"
                              label="Código postal"
                              fullWidth={true}
                              value={formProps.values.zipCode}
                              disabled={props.group}
                           />
                        </Box>
                        <Box m={2}>
                           <InputTextField
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="regime"
                              id="regime"
                              type="text"
                              label="Régimen fiscal"
                              fullWidth={true}
                              value={formProps.values.regime}
                              disabled={props.group}
                           />
                        </Box>
                        <Box m={2}>
                           <InputTextField
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="amount"
                              id="amount"
                              type="text"
                              label="Monto a facturar:"
                              fullWidth={true}
                              value={formProps.values.amount}
                              InputProps={{
                                 startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                           />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2, mt: 2 }}>
                           <Button variant="contained" type="submit">
                              {!isLoadingInvoice || !isLoadingUpdate ? (
                                 "Guardar cambios"
                              ) : (
                                 <CircularProgress size={24} color="success" />
                              )}
                           </Button>
                        </Box>
                     </Form>
                  )}
               </Formik>
            </Box>
         </Box>
      </Box>
   );
};
