import { Box, Button, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate, useRouteError } from "react-router-dom";
import { InputTextField } from "../components/Inputs/InputTextField";
import { Form, Formik } from "formik";
import { sendSupportEmail } from "../lib/usersBEClient";
import { useContext, useState } from "react";
import { CheckCircleRounded } from "@mui/icons-material";
import { LoadingButton } from "../components/LoadingButton";
import { UserContext } from "../context/UserContext";

export function RootBoundary() {
   const { user } = useContext(UserContext);
   const error: any = useRouteError();
   const location = useLocation();
   const navigate = useNavigate();
   const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
   const [reportSent, setReportSent] = useState(false);

   const submit = async (values) => {
      setIsLoadingSubmit(true);
      try {
         await sendSupportEmail(
            "Reporte de error",
            "Informa de un error",
            "",
            `${values.description} <br>  ${error.stack} <br> En la ubicación: ${location.pathname}`,
            user.email
         );
         setReportSent(true);
      } catch (error) {
         setReportSent(false);
      }
      setIsLoadingSubmit(false);
   };

   return (
      <Box
         display="flex"
         sx={{
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
            justifyContent: "center",
         }}
      >
         <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
            <Box
               component={"img"}
               src={"https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png"}
               width={200}
            />
            <Box display={"flex"} sx={{ width: "100%", height: "100%", justifyContent: "center" }}>
               <Box display={"flex"} sx={{ m: 4, flexDirection: "column", alignItems: "center" }}>
                  <Typography color="#2d4357" variant="h5" sx={{ textAlign: "center" }}>
                     Hubo un problema con el sistema.
                  </Typography>
                  <Typography color="#2d4357" variant="h6" sx={{ mt: 2, textAlign: "center" }}>
                     A continuación, por favor describa cómo surgió el problema y le brindaremos una solución lo más
                     rápido posible.
                  </Typography>
                  <Stack sx={{ display: "flex", justifyContent: "center" }}>
                     <Formik
                        initialValues={{
                           description: "",
                        }}
                        onSubmit={submit}
                     >
                        <Form>
                           <InputTextField
                              id="description"
                              name="description"
                              type="text"
                              rows={4}
                              multiline
                              fullWidth
                              sx={{ my: 1, minWidth: 500 }}
                           />
                           <Box
                              sx={{
                                 display: "flex",
                                 justifyContent: "center",
                              }}
                           >
                              {reportSent ? (
                                 <Stack sx={{ alignItems: "center", rowGap: 2 }}>
                                    <CheckCircleRounded color="success" sx={{ fontSize: 50 }} />
                                    <Typography sx={{ fontWeight: 600 }}>
                                       Su reporte fue enviado con éxito! Para volver al sistema favor de dar click a
                                       continuación.
                                    </Typography>
                                    <Button
                                       sx={{
                                          bgcolor: "#162c44",
                                          "&:hover": {
                                             bgcolor: "#162c44",
                                          },
                                          color: "white",
                                       }}
                                       onClick={() => navigate(-1)}
                                    >
                                       Volver a lecosy
                                    </Button>
                                 </Stack>
                              ) : (
                                 <LoadingButton
                                    label="Enviar retroalimentación"
                                    isLoading={isLoadingSubmit}
                                    sx={{
                                       bgcolor: "#162c44",
                                       "&:hover": {
                                          bgcolor: "#162c44",
                                       },
                                    }}
                                 />
                              )}
                           </Box>
                        </Form>
                     </Formik>
                  </Stack>
               </Box>
            </Box>
         </Stack>
      </Box>
   );
}
