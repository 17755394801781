import { useContext, useEffect, useState } from "react";
import { ClientConfigContext } from "../../../../context/clientConfigContext";
import { HeadCell, TableComponent } from "../../../TableComponent";
import { getMemberChargesByCompany } from "../../../../lib/governanceClient";
import { Box, Stack, Typography } from "@mui/material";
import rolesByService from "../../../../const/rolesByService.json";

export const ConsultiveGroupUsers = () => {
   const [userArray, setUserArray] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const { group } = useContext(ClientConfigContext);

   const usersColumns: HeadCell[] = [
      { field: "username", headerName: "Nombre", type: "string" },
      { field: "email", headerName: "Correo electrónico", type: "string" },
      { field: "phone", headerName: "Teléfono ", type: "string" },
      { field: "roles", headerName: "Rol", type: "popover" },
   ];

   function getGroupRoles(roles: any[]) {
      const companyRoles = roles.filter((r) => r.group === group._id);
      const rolesArray = companyRoles.flatMap((role) =>
         role.roles.filter((r) => rolesByService.GC.includes(r.name)).map((r) => r.name)
      );
      return Array.from(new Set(rolesArray));
   }

   useEffect(() => {
      const fetchUserData = async () => {
         setIsLoading(true);
         const groupUsers: any = group.users;
         const filteredGroupUsers = groupUsers.filter((userG) => userG.role.some((role) => role.group === group._id));
         const usersData = filteredGroupUsers.map((user) => ({
            _id: user._id,
            username: user.firstName + " " + user.lastName,
            email: user.email,
            phone: user.phoneNumber,
            roles: getGroupRoles(user.role),
         }));

         const gcUserIds = usersData
            .filter((user) => user.roles.some((r) => rolesByService.GC.includes(r)))
            .map((user) => user._id);

         if (gcUserIds.length > 0) {
            const chargesResponses = await getMemberChargesByCompany(gcUserIds, group._id);
            for (const user of usersData) {
               const index = chargesResponses.findIndex((c) => c.user === user._id);
               if (index >= 0) {
                  user.roles = user.roles.concat(chargesResponses[index].charges);
                  user.roles = user.roles.filter(
                     (r) => !rolesByService.GC.includes(r) || r === "Coordinador de gobierno corporativo"
                  );
               }
            }
         }
         const finalUserFilter = usersData.filter((a) => a.roles.length > 0);
         setUserArray(finalUserFilter);
         setIsLoading(false);
      };
      if (group?._id && userArray.length === 0) fetchUserData();
   }, [group, userArray.length]);

   return (
      <Box
         sx={{
            bgcolor: "white",
            boxShadow: 2,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            borderRadius: 1,
         }}
      >
         <Stack direction={"row"} justifyContent={"space-between"} alignContent={"center"} sx={{ px: 4, pt: 3 }}>
            <Typography sx={{ fontWeight: 700 }}>Usuarios del módulo</Typography>
         </Stack>
         <TableComponent
            defaultColumnToOrder="firstName"
            defaultOrder="asc"
            defaultRowsPerPage={5}
            rowsPerPageOptions={[5, 10, 20, 50]}
            headers={usersColumns}
            rows={userArray}
            onClick={() => {}}
            loader={isLoading}
            emptyDataText="No existen usuarios registrados en este módulo"
            disableBorders
         />
      </Box>
   );
};
