import React, { useState } from "react";
import { Box, Button, Divider, Typography, Toolbar } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Form, Formik, FormikProps } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import { InputTextField } from "../../Inputs/InputTextField";
import { addCompanyBranchSchema } from "../../../lib/Validations/inputSchemas";
import { AxiosError } from "axios";
import { ConfirmationModal } from "../../ConfirmationModal";
import { SnackBarContext } from "../../../context/snackBarContext";
import {
   useCreateClientCompanyBranch,
   useDisableCompanyBranch,
   useUpdateClientCompanyBranch,
} from "../../../lib/usersBEClient";
import LoginModal from "../Modal/loginModal";

interface AddModifyCompanyBranchDrawerProps {
   isModify: boolean;
   open: boolean;
   setOpen: Function;
   companyId: string;
   hasCDModule: boolean;
   branchData?: any;
   branchComplaints?: any;
   setRefetch?: Function;
}

export const AddModifyCompanyBranchDrawer = (props: AddModifyCompanyBranchDrawerProps) => {
   const { showSnackBar } = React.useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState(false);
   const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
   const [values, setValues] = useState({});
   const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
   const { mutate: updateMutate } = useUpdateClientCompanyBranch(props.branchData ? props.branchData._id : "");
   const { mutate } = useCreateClientCompanyBranch();
   const [openLoginModal, setOpenLoginModal] = useState(false);
   const { mutate: disableCompanyBranch } = useDisableCompanyBranch();

   function handleOpen() {
      props.setOpen(!props.open);
   }

   const disabledBranch = async () => {
      disableCompanyBranch(props.branchData._id, {
         onSuccess: () => {
            showSnackBar(`Sucursal ${!props.branchData.disabled ? "inhabilitada" : "habilitada"} con éxito`, false);
            props.setRefetch(true);
            setTimeout(() => {
               props.setOpen(false);
            }, 1000);
         },
         onError: () => {
            showSnackBar(`Error al ${!props.branchData.disabled ? "inhabilitar" : "habilitar"} la sucursal`, true);
         },
      });
   };

   async function handleSubmit(values) {
      try {
         setIsLoading(true);
         mutate(
            {
               company: props.companyId,
               name: values.name,
               address: values.phoneNumber,
               phoneNumber: values.phoneNumber,
               zipCode: values.zipCode,
               state: values.state,
               city: values.city,
            },
            {
               onSuccess: () => {
                  setIsLoading(false);
                  showSnackBar("Sucursal agregada con éxito", false);
                  props.setRefetch(true);
                  setTimeout(() => {
                     props.setOpen(false);
                  }, 1000);
               },
               onError: () => {
                  setIsLoading(false);
                  showSnackBar("Error al agregar la sucursal", true);
               },
            }
         );
      } catch (error) {
         const errorData: AxiosError = error as AxiosError;
         const errorResponse: any = errorData.response.data;
         if (errorResponse.message === "branch name already in use") {
            showSnackBar("Este nombre ya existe.", true);
            setIsLoading(false);
            return;
         }
         showSnackBar("Error, intente de nuevo.", true);
         setIsLoading(false);
      }
   }

   async function handleUpdate(values) {
      try {
         setIsLoading(true);

         updateMutate(
            {
               name: values.name,
               address: values.address,
               phoneNumber: values.phoneNumber,
               zipCode: values.zipCode,
               state: values.state,
               city: values.city,
            },
            {
               onSuccess: () => {
                  setIsLoading(false);
                  showSnackBar("Sucursal modificada con éxito", false);
                  props.setRefetch(true);
                  setTimeout(() => {
                     props.setOpen(false);
                  }, 2000);
               },
               onError: () => {
                  setIsLoading(false);
                  showSnackBar("Error al modificar la sucursal", true);
               },
            }
         );
      } catch (error) {
         console.log(error);
      }
   }

   return (
      <Box sx={{ width: 450, display: "flex", maxHeight: "100vh", height: "100%" }} role="presentation">
         <Box
            display="flex"
            alignItems="center"
            onClick={handleOpen}
            sx={{
               "&:hover": {
                  bgcolor: "#F7F7F7",
               },
            }}
         >
            <ChevronRightIcon fontSize="small" />
         </Box>
         <Box sx={{ display: "flex", flexDirection: "column", flex: 1, mb: 1, mt: 2 }}>
            <Toolbar />
            <Box p={1}>
               <Typography variant="h5">{!props.isModify ? "Agregar sucursal" : "Modificar sucursal"}</Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 1 }}>
               <Formik
                  initialValues={{
                     name: props.isModify ? props.branchData["name"] : "",
                     address: props.isModify ? props.branchData["address"] : "",
                     state: props.isModify ? props.branchData["state"] : "",
                     city: props.isModify ? props.branchData["city"] : "",
                     zipCode: props.isModify ? props.branchData["zipCode"] : "",
                     phoneNumber: props.isModify ? props.branchData["phoneNumber"] : "",
                  }}
                  onSubmit={(values) => {
                     try {
                        setValues(values);
                        setOpenConfirmationModal(true);
                     } catch (error) {
                        console.log(error);
                     }
                  }}
                  validationSchema={addCompanyBranchSchema}
               >
                  {(formProps: FormikProps<any>) => (
                     <Form>
                        <InputTextField
                           sx={{ m: 1 }}
                           variant="standard"
                           multiline={true}
                           size="small"
                           name="name"
                           id="name"
                           type="text"
                           label="Nombre de sucursal"
                           fullWidth={true}
                           value={formProps.values.name}
                        />
                        <InputTextField
                           sx={{ m: 1 }}
                           variant="standard"
                           multiline={true}
                           size="small"
                           name="address"
                           id="address"
                           type="text"
                           label="Dirección"
                           fullWidth={true}
                           value={formProps.values.address}
                        />
                        <InputTextField
                           sx={{ m: 1 }}
                           variant="standard"
                           multiline={true}
                           size="small"
                           name="state"
                           id="state"
                           type="text"
                           label="Estado"
                           fullWidth={true}
                           value={formProps.values.state}
                        />
                        <InputTextField
                           sx={{ m: 1 }}
                           variant="standard"
                           multiline={true}
                           size="small"
                           name="city"
                           id="city"
                           type="text"
                           label="Ciudad"
                           fullWidth={true}
                           value={formProps.values.city}
                        />
                        <InputTextField
                           sx={{ m: 1 }}
                           variant="standard"
                           multiline={true}
                           size="small"
                           name="zipCode"
                           id="zipCode"
                           type="text"
                           label="Código postal"
                           fullWidth={true}
                           value={formProps.values.zipCode}
                           maxLength={5}
                        />
                        <InputTextField
                           sx={{ m: 1 }}
                           variant="standard"
                           multiline={true}
                           size="small"
                           name="phoneNumber"
                           id="phoneNumber"
                           type="text"
                           label="Teléfono de sucursal"
                           fullWidth={true}
                           value={formProps.values.phoneNumber}
                           maxLength={10}
                        />
                        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                           <Button variant="outlined" color="error" onClick={() => props.setOpen(false)}>
                              Cancelar
                           </Button>
                           <Button variant="contained" type="submit">
                              {!isLoading ? "Guardar" : <CircularProgress size={24} color="success" />}
                           </Button>
                        </Box>
                     </Form>
                  )}
               </Formik>

               {props.isModify ? (
                  <>
                     <Divider sx={{ my: 1 }} />
                     <Typography color="red">Inhabilitar / habilitar sucursal</Typography>
                     <Box sx={{ border: 1, mt: 2, borderColor: "#DADADA", borderRadius: 1, p: 1 }}>
                        <Box sx={{ p: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                           <Typography fontSize={14}>
                              Para inhabilitar la sucursal se requiere permisos de administrador.
                           </Typography>
                           <Button
                              variant="outlined"
                              sx={{ color: "red", mt: 2 }}
                              onClick={() => {
                                 setOpenDeleteUserModal(true);
                              }}
                           >
                              {!props.branchData.disabled ? "Inhabilitar sucursal" : "Habilitar sucursal"}
                           </Button>
                        </Box>
                     </Box>
                  </>
               ) : null}
            </Box>
         </Box>
         {props.isModify ? (
            <ConfirmationModal
               open={openDeleteUserModal}
               setOpen={setOpenDeleteUserModal}
               title={props.branchData.disabled ? `Habilitar sucursal` : `Inhabilitar sucursal`}
               body={`¿Estás seguro de ${props.branchData.disabled ? "habilitar" : "inhabilitar"} la sucursal?`}
               setOpenLogin={setOpenLoginModal}
               onConfirm={() => {}}
            />
         ) : null}
         <ConfirmationModal
            open={openConfirmationModal}
            setOpen={setOpenConfirmationModal}
            body={
               props.isModify
                  ? "¿Estás seguro de cambiar los datos de la sucursal?"
                  : "¿Estás seguro de agregar una nueva sucursal?"
            }
            title={props.isModify ? "Modificar sucursal" : "Agregar sucursal"}
            onConfirm={props.isModify ? () => handleUpdate(values) : () => handleSubmit(values)}
         />
         <LoginModal open={openLoginModal} setOpen={setOpenLoginModal} onConfirm={() => disabledBranch()} />
      </Box>
   );
};
