import { Checkbox, FormControl, FormGroup, FormControlLabel, FormHelperText, Typography } from "@mui/material";
import { useField } from "formik";

interface TextFieldInterfaceProps {
   label: string;
   fullWidth?: boolean;
   variant?: any;
   id: string;
   name: string;
   sx?: object;
   size?: "small" | "medium";
   checked?: boolean;
   disabled?: boolean;
   fontSize?: number;
   isEditable?: boolean;
}

export const InputCheckBox = (props: TextFieldInterfaceProps) => {
   const [field, meta] = useField(props);
   return (
      <FormControl
         required
         error={meta.error && meta.touched}
         component="fieldset"
         {...props.sx}
         variant={props.variant}
      >
         {/* <FormLabel component="legend">Pick two</FormLabel> */}
         <FormGroup>
            <FormControlLabel
               control={
                  <Checkbox {...props} {...field} checked={props.checked} style={{ paddingTop: 0, paddingBottom: 0 }} />
               }
               label={
                  <Typography fontSize={props.fontSize} sx={{ color: !props.isEditable ? "gray" : "black" }}>
                     {props.label}
                  </Typography>
               }
            />
         </FormGroup>
         <FormHelperText>{meta.error && meta.touched ? meta.error : null}</FormHelperText>
      </FormControl>
   );
};
