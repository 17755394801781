import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getInterviewDate, getInterviewDateByDate } from "../../../../lib/lecosyBackendClient";
import {
   Box,
   Button,
   Grid,
   Typography,
   CircularProgress,
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   InputAdornment,
   Divider,
   Container,
} from "@mui/material";
import { TitleAppBar } from "../../../../components/TitleAppBar";
import { getDateAndTimeFromISODate, toISOString } from "../../../../const/globalConst";
import { constancyOfNonAppearance } from "../../../../lib/DocmunetsTemplates/ConstancyTemplates";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { InputTextField } from "../../../../components/Inputs/InputTextField";
import { Form, Formik } from "formik";
import { absenseConstancySchema } from "../../../../lib/Validations/inputSchemas";
import pdfMake from "../../../../const/pdfMakeFonts";
import { ComplaintStepperContext } from "../../../../context/complaintStepperContext";
import { UserContext } from "../../../../context/UserContext";

export const AbsenseConstancyScreen = () => {
   const { reportNumber, towards } = useParams();
   const { complaint } = useContext(ComplaintStepperContext);
   const [isLoading, setIsLoading] = useState<boolean>(true);
   const [showsDocument, setshowsDocument] = useState(false);
   const now = new Date();
   const [interview, setInterview] = useState<[{ date: Date }]>([
      {
         date: now,
      },
   ]);

   const date = new Date();
   const [lecosyInputs, setLecosyInputs] = useState({
      name: "denunciado",
      date: "",
      interviewDate: date,
   });
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Usuarios");
   const navigate = useNavigate();

   useEffect(() => {
      if (access) {
         navigate("/inicio");
      }
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      const fetchComplaintByReportNumber = async () => {
         const interviewResponse = await getInterviewDate(
            reportNumber,
            towards === "1" ? "testigo" : towards === "2" ? "denunciado" : "denunciante"
         );
         setInterview(interviewResponse.data);
         setIsLoading(false);
      };
      fetchComplaintByReportNumber();
      // eslint-disable-next-line
   }, [reportNumber]);

   const handleChangeInputs = async (event) => {
      let { name, value } = event.target;
      if (name === "date") {
         const interviewResponse = await getInterviewDateByDate(
            reportNumber,
            towards === "1" ? "testigo" : towards === "2" ? "denunciado" : "denunciante",
            value
         );
         value = interviewResponse.data.date;
         setshowsDocument(true);
      } else if (name === "name") {
         setshowsDocument(false);
         return setLecosyInputs({
            ...lecosyInputs,
            [name]: value,
            date: date.toDateString(),
         });
      }
      return setLecosyInputs({
         ...lecosyInputs,
         [name]: value,
      });
   };

   const handleSendForm = (values, actions) => {
      const dd = constancyOfNonAppearance(
         complaint.reportNumber,
         complaint.status,
         values.city,
         values.state,
         values.dateTime,
         lecosyInputs.date,
         values.organization,
         values.involvedName
      );
      pdfMake.createPdf(dd).open();
      actions.resetForm();
      setTimeout(() => navigate(-1), 1000);
   };

   return (
      <Box p={2}>
         <TitleAppBar head="Constancia de incomparecencia" isScheduleAppointment={true} />
         <Container maxWidth="xl">
            <>
               <Formik
                  initialValues={{
                     // name: "denunciado",
                     city: "",
                     state: "",
                     organization: "",
                     involvedName: "",
                     dateTime: toISOString(date),
                  }}
                  validationSchema={absenseConstancySchema}
                  onSubmit={handleSendForm}
               >
                  <Form>
                     <FormControl sx={{ width: "250px", ml: 4, mb: 2, mt: 2 }}>
                        <InputLabel id="demo-simple-select-label">Fecha de entrevista</InputLabel>
                        <Select
                           variant="standard"
                           labelId="demo-simple-select-label"
                           id="demo-simple-select"
                           name="date"
                           value={lecosyInputs.date}
                           onChange={handleChangeInputs}
                           label="Fecha de entrevista"
                        >
                           {interview.map((e, index) => {
                              return (
                                 <MenuItem key={index} value={String(e.date)}>
                                    {getDateAndTimeFromISODate(e.date)}
                                 </MenuItem>
                              );
                           })}
                        </Select>
                     </FormControl>
                     {showsDocument ? (
                        <>
                           <Box
                              display={"flex"}
                              sx={{
                                 px: 2,
                                 minWidth: 950,
                                 flexDirection: "column",
                                 alignItems: "center",
                                 m: 2,
                              }}
                           >
                              <Box
                                 sx={{
                                    width: "100%",
                                    bgcolor: "#ffffff",
                                    border: 1,
                                    borderRadius: 2,
                                    borderColor: "#8A95A0",
                                 }}
                              >
                                 <Grid container>
                                    <Grid item xs={3}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <InputTextField
                                             variant="standard"
                                             fullWidth
                                             name="dateTime"
                                             id="dateTime"
                                             label="Fecha y hora"
                                             type="datetime-local"
                                             InputLabelProps={{
                                                shrink: true,
                                             }}
                                          />
                                       </Box>
                                    </Grid>
                                    <Grid item xs={9} />
                                    <Grid item xs={4}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <InputTextField
                                             variant="standard"
                                             multiline={true}
                                             name="state"
                                             label="Estado"
                                             id="state"
                                             type="text"
                                             fullWidth={true}
                                             InputProps={{
                                                startAdornment: (
                                                   <InputAdornment position="start">
                                                      <LocationOnIcon />
                                                   </InputAdornment>
                                                ),
                                             }}
                                          />
                                       </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <InputTextField
                                             variant="standard"
                                             multiline={true}
                                             name="city"
                                             label="Ciudad"
                                             id="city"
                                             type="text"
                                             fullWidth={true}
                                             InputProps={{
                                                startAdornment: (
                                                   <InputAdornment position="start">
                                                      <LocationCityIcon />
                                                   </InputAdornment>
                                                ),
                                             }}
                                          />
                                       </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <InputTextField
                                             variant="standard"
                                             multiline={true}
                                             name="organization"
                                             label="Organización"
                                             id="organization"
                                             type="text"
                                             fullWidth={true}
                                             InputProps={{
                                                startAdornment: (
                                                   <InputAdornment position="start">
                                                      <PersonIcon />
                                                   </InputAdornment>
                                                ),
                                             }}
                                          />
                                       </Box>
                                    </Grid>
                                 </Grid>
                                 <Divider sx={{ mx: 2 }} />
                                 <Grid container>
                                    <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                       <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                          Detalles de entrevista
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <Typography fontWeight={500} variant="body1">
                                             Reporte de denuncia
                                          </Typography>
                                          <Typography variant="subtitle1">{reportNumber}</Typography>
                                       </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <Typography fontWeight={500} variant="body1">
                                             Fecha de entrevista
                                          </Typography>
                                          <Typography variant="subtitle1">
                                             {getDateAndTimeFromISODate(lecosyInputs.date)}
                                          </Typography>
                                       </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <InputTextField
                                             variant="standard"
                                             multiline={true}
                                             name="involvedName"
                                             label="Nombre del involucrado"
                                             id="involvedName"
                                             type="text"
                                             fullWidth={true}
                                             InputProps={{
                                                startAdornment: (
                                                   <InputAdornment position="start">
                                                      <PersonIcon />
                                                   </InputAdornment>
                                                ),
                                             }}
                                             mindate={toISOString(date)}
                                          />
                                       </Box>
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box display={"flex"} sx={{ justifyContent: "flex-end", width: "100%", py: 2 }}>
                                 <Button variant="contained" sx={{ mt: 2 }} type="submit">
                                    {isLoading ? <CircularProgress size={25} sx={{ color: "white" }} /> : "Enviar"}
                                 </Button>
                              </Box>
                           </Box>
                        </>
                     ) : (
                        <>
                           <Box sx={{ display: "flex", justifyContent: "center" }}>
                              {interview.length < 1 ? (
                                 <Typography>Este involucrado no cuenta con entrevistas</Typography>
                              ) : (
                                 <Typography>Seleccione una fecha de entrevista</Typography>
                              )}
                           </Box>
                        </>
                     )}
                  </Form>
               </Formik>
            </>
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   py: 2,
   px: 1,
};
