import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createInterview } from "../../../../lib/lecosyBackendClient";
import { Box, Grid, Typography, Divider, InputAdornment, Container, Skeleton } from "@mui/material";
import { TitleAppBar } from "../../../../components/TitleAppBar";
import {
   complainantNotification,
   witnessNotification,
   denouncedNotification,
} from "../../../../lib/DocmunetsTemplates/NotificationTemplates";
import StoreIcon from "@mui/icons-material/Store";
import MapIcon from "@mui/icons-material/Map";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PersonIcon from "@mui/icons-material/Person";
import { Form, Formik } from "formik";
import { InputTextField } from "../../../../components/Inputs/InputTextField";
import { notificationsSchema } from "../../../../lib/Validations/inputSchemas";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { createDocumentPdf } from "../../../../lib/uploadDocuments";
import { ComplaintStepperContext } from "../../../../context/complaintStepperContext";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { getMonthWord, toISOString } from "../../../../const/globalConst";
import pdfMake from "../../../../const/pdfMakeFonts";
import { LoadingButton } from "../../../../components/LoadingButton";
import { UserContext } from "../../../../context/UserContext";

export const InterviewNotificaionScreen = () => {
   const { complaint, complaintState, selectedData, isLoading, getComplaint } = useContext(ComplaintStepperContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { reportNumber, type, number } = useParams();
   const [isLoadig, setIsLoadig] = useState(false);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Usuarios");
   const navigate = useNavigate();

   useEffect(() => {
      const fetchComplaint = async () => {
         await getComplaint(reportNumber);
      };
      // eslint-disable-next-line
      if (isLoading) fetchComplaint();
      // eslint-disable-next-line
   }, [isLoading, reportNumber]);

   useEffect(() => {
      if (access) {
         navigate("/inicio");
      }
      // eslint-disable-next-line
   }, []);
   const date = new Date();

   const handleSendForm = async (values, actions) => {
      setIsLoadig(true);
      let dd;
      let docName;
      const date2 = values.dateTime.split(":");

      let mailSpeech;
      const date = values.date.split("-");
      const timeDate = date[2].split("T");

      if (type === "denunciado") {
         dd = denouncedNotification(
            complaint.reportNumber,
            complaint.clasification,
            values.city,
            values.state,
            values.date,
            values.organization,
            values.name,
            values.interviewPlace,
            values.dateTime,
            values.email,
            values.extract
         );
         docName = `notificacion-entrevista-denunciado-${number}-${complaint.reportNumber}-${date2[0].replace(
            "T",
            "-"
         )}-${date2[1]}`;

         mailSpeech = `En la ciudad de ${values.city}, ${values.state} siendo las ${timeDate[1]} horas del día ${
            timeDate[0]
         } del mes ${getMonthWord(date[1])} del año ${date[0]} se hace del conocimiento del C.${
            values.name
         } de la existencia de una denuncia interpuesta ante el canal de denuncias interno de la organización ${
            values.organization
         }, en el cual
         se mencionan posibles hechos contarios a la legislación de nuestro país y/o a las
         políticas internas de nuestra organización en la cual se le señala a usted como
         posible implicado de los hechos. `;
      } else if (type === "testigo") {
         dd = witnessNotification(
            complaint.reportNumber,
            complaint.clasification,
            values.city,
            values.state,
            values.date,
            values.organization,
            values.name,
            values.interviewPlace,
            values.dateTime,
            "",
            values.name,
            complaint.type
         );
         docName =
            complaintState.processState === 2
               ? `notificacion-entrevista-testigo-${number}-${selectedData.index}-${
                    complaint.reportNumber
                 }-${date2[0].replace("T", "-")}-${date2[1]}`
               : `notificacion-entrevista-testigo-${number}-${complaint.reportNumber}-${date2[0].replace("T", "-")}-${
                    date2[1]
                 }`;
         mailSpeech = `En la ciudad de ${values.city}, ${values.state} siendo las ${timeDate[1]} horas del día ${
            timeDate[0]
         } del mes ${getMonthWord(date[1])} del año ${date[0]} se hace del conocimiento del C.${
            values.name
         } de la existencia de una denuncia interpuesta ante el canal de denuncias interno de la organización ${
            values.organization
         }, en el cual se mencionan posibles hechos contrarios a la legislación de nuestro país y/o a las políticas internas de nuestra organización, 
         en la cual se le señala a usted como posible testigo de los hechos. `;
      } else {
         dd = complainantNotification(
            complaint.reportNumber,
            complaint.clasification,
            values.city,
            values.state,
            values.date,
            values.organization,
            values.name,
            values.interviewPlace,
            values.dateTime,
            "",
            complaint.complainerDetails
               ? complaint.complainerDetails.name + " " + complaint.complainerDetails.lastName
               : "DENUNCIANTE ANONIMO"
         );
         docName = `notificacion-entrevista-denunciante-${complaint.reportNumber}-${date2[0].replace("T", "-")}-${
            date2[1]
         }`;
         mailSpeech = `En la ciudad de ${values.city}, ${values.state} siendo las ${timeDate[1]} horas del día ${
            timeDate[0]
         } del mes ${getMonthWord(date[1])} del año ${date[0]} se hace del conocimiento del C.${
            values.name
         } de la existencia de una denuncia interpuesta ante el canal de denuncias interno de la organización ${
            values.organization
         }, en el cual se
         mencionan posibles hechos contarios a la legislación de nuestro país y/o a las
         políticas internas de nuestra organización en la cual usted funge como denunciante
         de la misma. `;
      }

      try {
         const response = await createInterview(
            reportNumber,
            type,
            values.dateTime,
            values.interviewPlace,
            values.emailTo,
            dd,
            mailSpeech
         );

         if (response.data.message === "interview created successfully") {
            const pdfDocGenerator = pdfMake.createPdf(dd);
            pdfDocGenerator.getBlob(async (blob) => {
               await createDocumentPdf(complaint.reportNumber, docName, blob);
               showSnackBar("Documento creado y subido correctamente.", false);
               setIsLoadig(false);
               setTimeout(() => navigate(-1), 1000);
            });
         }
      } catch (error) {
         showSnackBar("Error, intente de nuevo más tarde", true);
         setIsLoadig(false);
      }
   };

   return (
      <Box p={2}>
         <TitleAppBar
            head={
               type === "denunciado"
                  ? "Notificación de entrevista al denunciado"
                  : type === "denunciante"
                  ? "Notificación de entrevista al denunciante"
                  : "Notificación de entrevista al testigo"
            }
            isScheduleAppointment={true}
         />
         <Container maxWidth="xl">
            <Formik
               initialValues={{
                  name: "",
                  organization: "",
                  state: "",
                  city: "",
                  interviewPlace: "",
                  date: toISOString(date),
                  dateTime: "",
                  email: "",
                  extract: "",
                  emailTo:
                     type === "testigo"
                        ? complaint.witnesses[parseInt(number, 10) - 1].aditionalInformation
                           ? complaint.witnesses[parseInt(number, 10) - 1].aditionalInformation.email
                           : ""
                        : type === "denunciado"
                        ? complaint.offenderDetails[parseInt(number, 10) - 1].aditionalInformation
                           ? complaint.offenderDetails[parseInt(number, 10) - 1].aditionalInformation.email
                           : ""
                        : complaint.complainerDetails
                        ? complaint.complainerDetails.email
                        : "",
               }}
               validationSchema={notificationsSchema(type === "denunciado" ? true : false)}
               onSubmit={handleSendForm}
            >
               <Form>
                  <Box
                     display={"flex"}
                     sx={{
                        px: 4,
                        py: 2,
                        minWidth: 950,
                        flexDirection: "column",
                        alignItems: "center",
                        m: 2,
                        justifyContent: "center",
                     }}
                  >
                     <Box sx={{ width: "100%", maxWidth: 1400 }}>
                        <Box
                           display={"flex"}
                           sx={{
                              bgcolor: "#FFFFFF",
                              flexDirection: "column",
                              borderRadius: 2,
                              border: 1,
                              borderColor: "#8A95A0",
                              px: 3,
                           }}
                        >
                           <Box sx={{ py: 1 }}>
                              <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                 Denuncia
                              </Typography>
                           </Box>
                           <Box
                              sx={{
                                 display: "flex",
                                 flexDirection: "row",
                                 mb: 2,
                                 justifyContent: "space-between",
                                 px: 6,
                              }}
                           >
                              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", px: 11 }}>
                                 <Typography color={"#2d4357"} fontWeight={600} variant="h6">
                                    Reporte de denuncia
                                 </Typography>
                                 <Typography sx={{ fontSize: 16 }}>{reportNumber}</Typography>
                              </Box>
                              <Box sx={{ px: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                                 <Typography color={"#2d4357"} fontWeight={600} variant="h6">
                                    Clasificación de denuncia
                                 </Typography>
                                 {complaint.reportNumber !== "" ? (
                                    <Typography sx={{ fontSize: 16 }}>{complaint.clasification}</Typography>
                                 ) : (
                                    <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
                                 )}
                              </Box>
                           </Box>
                           <Divider />
                           <Box sx={{ py: 1 }}>
                              <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                 General
                              </Typography>
                           </Box>
                           <Grid container>
                              <Grid container item xs={12} sx={{ pb: 5 }}>
                                 <Grid
                                    item
                                    xs={6}
                                    sx={{
                                       display: "flex",
                                       flexDirection: "row",
                                       alignItems: "baseline",
                                       justifyContent: "space-between",
                                    }}
                                 >
                                    <InputTextField
                                       id="name"
                                       name="name"
                                       type="text"
                                       variant="standard"
                                       label="Nombre(s)"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <PersonIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                       sx={{ ml: 2, mr: 2 }}
                                    />
                                 </Grid>
                                 <Grid
                                    item
                                    xs={6}
                                    sx={{
                                       display: "flex",
                                       flexDirection: "row",
                                       alignItems: "baseline",
                                       justifyContent: "space-between",
                                    }}
                                 >
                                    <InputTextField
                                       id="organization"
                                       name="organization"
                                       type="text"
                                       variant="standard"
                                       label="Organización"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <StoreIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                       sx={{ mr: 2 }}
                                    />
                                 </Grid>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "baseline",
                                    justifyContent: "space-between",
                                 }}
                              >
                                 <InputTextField
                                    id="state"
                                    name="state"
                                    type="text"
                                    variant="standard"
                                    label="Estado"
                                    fullWidth={true}
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <MapIcon />
                                          </InputAdornment>
                                       ),
                                    }}
                                    sx={{ ml: 2 }}
                                 />
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "baseline",
                                    justifyContent: "space-between",
                                    pr: 2,
                                 }}
                              >
                                 <InputTextField
                                    id="city"
                                    name="city"
                                    type="text"
                                    variant="standard"
                                    label="Ciudad"
                                    fullWidth={true}
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <MapIcon />
                                          </InputAdornment>
                                       ),
                                    }}
                                    sx={{ ml: 2 }}
                                 />
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                    justifyContent: "space-between",
                                 }}
                              >
                                 <InputTextField
                                    variant="standard"
                                    name="date"
                                    id="date"
                                    fullWidth
                                    label="Fecha y hora actual"
                                    type="datetime-local"
                                    InputLabelProps={{
                                       shrink: true,
                                    }}
                                    sx={{ mr: 2 }}
                                 />
                              </Grid>
                           </Grid>
                           {type === "denunciado" ? (
                              <>
                                 <Box sx={{ py: 1, px: 0 }}>
                                    <Divider sx={{ my: 1 }} />
                                    <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                       Datos para réplica
                                    </Typography>
                                 </Box>
                                 <Grid container sx={{ display: "flex", justifyContent: "center", py: 2, px: 1 }}>
                                    <Grid item xs={12} sx={{ pr: 2 }}>
                                       <InputTextField
                                          id="email"
                                          name="email"
                                          type="text"
                                          variant="standard"
                                          label="Correo electrónico"
                                          fullWidth={true}
                                          InputProps={{
                                             startAdornment: (
                                                <InputAdornment position="start">
                                                   <ContactMailIcon />
                                                </InputAdornment>
                                             ),
                                          }}
                                          sx={{ ml: 1 }}
                                       />
                                    </Grid>
                                 </Grid>
                              </>
                           ) : null}
                           <Box sx={{ py: 1, px: 0 }}>
                              <Divider sx={{ my: 1 }} />
                              <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                 Cita
                              </Typography>
                           </Box>
                           <Grid container sx={{ display: "flex", justifyContent: "center", py: 2, px: 1 }}>
                              <Grid item xs={8} sx={{ pr: 3 }}>
                                 <InputTextField
                                    id="interviewPlace"
                                    name="interviewPlace"
                                    type="text"
                                    variant="standard"
                                    label="Medio y/o lugar de entrevista"
                                    fullWidth={true}
                                    multiline
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <QuestionAnswerIcon />
                                          </InputAdornment>
                                       ),
                                    }}
                                    sx={{ ml: 1 }}
                                 />
                              </Grid>
                              <Grid item xs={4} sx={{ alignSelf: "center" }}>
                                 <InputTextField
                                    variant="standard"
                                    fullWidth
                                    name="dateTime"
                                    id="dateTime"
                                    label="Fecha y hora de entrevista"
                                    type="datetime-local"
                                    InputLabelProps={{
                                       shrink: true,
                                    }}
                                    sx={{ pr: 1 }}
                                    mindate={toISOString(date)}
                                 />
                              </Grid>
                              {type === "denunciado" ? (
                                 <Grid item xs={12} sx={{ pr: 3, pt: 5 }}>
                                    <InputTextField
                                       id="extract"
                                       name="extract"
                                       type="text"
                                       variant="standard"
                                       label="Extracto de denuncia"
                                       fullWidth={true}
                                       multiline={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <TextSnippetIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                       sx={{ ml: 1 }}
                                    />
                                 </Grid>
                              ) : null}
                              <Grid item xs={12} sx={{ mx: 1, mt: 4 }}>
                                 <InputTextField
                                    id="emailTo"
                                    name="emailTo"
                                    type="text"
                                    variant="standard"
                                    label="Correo electrónico del destinatario:"
                                    fullWidth={true}
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <ContactMailIcon />
                                          </InputAdornment>
                                       ),
                                    }}
                                 />
                              </Grid>
                           </Grid>
                        </Box>
                        <Box display={"flex"} sx={{ justifyContent: "flex-end", pt: 2 }}>
                           <LoadingButton label="Enviar" type="submit" isLoading={isLoadig} />
                        </Box>
                     </Box>
                  </Box>
               </Form>
            </Formik>
         </Container>
      </Box>
   );
};
