import { Order } from "../../const/globalConst";
import { HeadCell, TableComponentFilter } from "../TableComponent";
import {
   ArrayTypeComponent,
   ButtonTypeComponent,
   CheckTypeComponent,
   CollapsibleTypeComponent,
   CompletedComponent,
   DateTypeComponent,
   DisabledComponent,
   FileTypeComponent,
   ImageTypeComponent,
   ListTypeComponent,
   StatusTypeComponent,
   StringNumberTypeComponent,
} from "./headerTypeComponent";

interface TableComponentContentProps {
   isUpRow: boolean;
   isItemSelected: boolean;
   labelId: string;
   header: any;
   collapsible?: boolean;
}

export interface DinamicTableComponentProps {
   headers: HeadCell[];
   rows?: any[];
   selectedRows?: readonly string[];
   setSelected?: Function;
   defaultColumnToOrder: string;
   defaultOrder: Order;
   defaultRowsPerPage?: number;
   rowsPerPageOptions?: number[];
   icon?: any;
   loader: boolean;
   emptyDataText: string;
   onClick: (e, row, group?) => void;
   disableBorders?: boolean;
   dense?: boolean;
   filters?: TableComponentFilter[];
   filtersInitialState?: any;
   showBadges?: boolean;
   checkboxSelection?: boolean;
   multiselect?: boolean;
   collapsible?: boolean;
   collapsiblesubActivity?: boolean;
   opened?: string;
   setOpened?: Function;
   upRows?: any[];
   withoutfilterText?: boolean;
   headerColor?: boolean;
}

const TableComponentContentMap = (row, props: DinamicTableComponentProps, mapProps: TableComponentContentProps) => {
   switch (mapProps.header.type) {
      case "date":
         return DateTypeComponent(row, mapProps);
      case "string":
         return StringNumberTypeComponent(row, mapProps);
      case "number":
         return StringNumberTypeComponent(row, mapProps);
      case "disabled":
         return DisabledComponent(row, mapProps);
      case "completed":
         return CompletedComponent(row, mapProps);
      case "collapsible":
         return CollapsibleTypeComponent(props, row, mapProps.collapsible);
      case "check":
         return CheckTypeComponent(mapProps);
      case "button":
         return ButtonTypeComponent(props, mapProps, row);
      case "file":
         return FileTypeComponent(props, mapProps, row);
      case "image":
         return ImageTypeComponent(row, mapProps, props);
      case "status":
         return StatusTypeComponent(row, mapProps);
      case "array":
         return ArrayTypeComponent(row, mapProps);
      case "list":
         return ListTypeComponent(row, mapProps);
      default:
         return <span>{row[mapProps.header.field]}</span>;
   }
};

export default TableComponentContentMap;
