import { Box, Button } from "@mui/material";
import { useContext } from "react";
import { ComplaintStepperContext } from "../../../context/complaintStepperContext";
import { DocumentsTable } from "../DocumentsTable";
import { UserContext } from "../../../context/UserContext";

export const ClosureStep = () => {
   const { isLastStepResolution, setOpenCompleteAllSteps, setOpenCompleteDialog, checkDocuments } =
      useContext(ComplaintStepperContext);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Denuncias internas");
   return (
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "50vh" }}>
         <Box sx={{ flex: 2 }}>
            <DocumentsTable
               rows={[
                  {
                     document: "Cierre de expediente",
                     documentName: "cierre",
                     templateLink: `acuse-cierre`,
                  },
               ]}
               disabled={access}
            />
         </Box>
         {!access ? (
            <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mr: 2 }}>
               <Box sx={{ flex: "1 1 auto" }} />
               {isLastStepResolution() ? (
                  <Button
                     disabled={!checkDocuments("cierre")}
                     onClick={() => setOpenCompleteAllSteps(true)}
                     variant="outlined"
                  >
                     Finalizar resolución y cierre
                  </Button>
               ) : (
                  <Button
                     disabled={!checkDocuments("cierre")}
                     onClick={() => setOpenCompleteDialog(true)}
                     variant="outlined"
                  >
                     {`Completar cierre de expediente`}
                  </Button>
               )}
            </Box>
         ) : null}
      </Box>
   );
};
