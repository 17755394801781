import React, { useState, useContext } from "react";
import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import { ConfirmationModal } from "./ConfirmationModal";
import { deleteSupportNotificationById } from "../lib/usersBEClient";
import { NotificationsContext } from "../context/notificationsContext/notificationsContext";

interface NotificationInfoModalProps {
   open: boolean;
   setOpen: Function;
   onConfirm?: (event: React.MouseEvent<HTMLButtonElement>) => void;
   onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const NotificationInfoModal = (props: NotificationInfoModalProps) => {
   const { selectedNotification, refetch } = useContext(NotificationsContext);
   const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
   const handleClose = () => {
      props.setOpen(false);
   };
   return (
      <div>
         {selectedNotification ? (
            <Modal
               open={props.open}
               onClose={handleClose}
               sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
               <Box
                  display="flex"
                  flexDirection="column"
                  maxWidth="50%"
                  sx={{ bgcolor: "white", p: 2, borderRadius: 2 }}
               >
                  <Typography variant="h6">Información de la duda</Typography>
                  <Divider sx={{ my: 1 }} />
                  <Box display="flex" justifyContent="space-between" sx={{ my: 1 }}>
                     <Typography variant="body1" sx={{ mr: 2 }}>
                        <strong style={{ fontWeight: 600 }}>Es referente a una denuncia existente: </strong>
                        {selectedNotification.isCR ? "Si" : "No"}
                     </Typography>
                     <Typography variant="body1">
                        <strong style={{ fontWeight: 600 }}>Fecha de recepción: </strong>
                        {`${selectedNotification.createdAt.slice(8, 10)}/${selectedNotification.createdAt.slice(
                           5,
                           7
                        )}/${selectedNotification.createdAt.slice(0, 4)}`}
                     </Typography>
                  </Box>
                  {selectedNotification.isCR ? (
                     <Typography sx={{ my: 1 }}>
                        <strong style={{ fontWeight: 600 }}>Denuncia relacionada: </strong>
                        {selectedNotification.reportNumber}
                     </Typography>
                  ) : (
                     <></>
                  )}
                  {selectedNotification.name ? (
                     <Typography sx={{ my: 1 }}>
                        <strong style={{ fontWeight: 600 }}>Nombre: </strong>
                        {selectedNotification.name}
                     </Typography>
                  ) : (
                     <></>
                  )}
                  <Typography sx={{ my: 1 }}>
                     <strong style={{ fontWeight: 600 }}>Correo electrónico: </strong>
                     {selectedNotification.email}
                  </Typography>
                  <Typography fontWeight={600}>Duda</Typography>
                  <Typography>{selectedNotification.body}</Typography>
                  <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
                     <Button variant="outlined" onClick={() => setOpenConfirmationModal(true)}>
                        Marcar como terminada
                     </Button>
                     <Button variant="contained" onClick={() => props.setOpen(false)}>
                        Cerrar
                     </Button>
                  </Box>
                  <ConfirmationModal
                     open={openConfirmationModal}
                     setOpen={setOpenConfirmationModal}
                     title={"¿Estás seguro de este movimiento?"}
                     body={<Box>Esta notificación sera eliminada permanentemente.</Box>}
                     onConfirm={async () => {
                        await deleteSupportNotificationById(selectedNotification._id);
                        setOpenConfirmationModal(false);
                        refetch();
                        props.setOpen(false);
                     }}
                  />
               </Box>
            </Modal>
         ) : (
            <></>
         )}
      </div>
   );
};
