import React from "react";
import { Box } from "@mui/material";
import { NotificationsTable } from "../containers/NotificationsContainer/NotificationsTable";
import Container from "@mui/material/Container/Container";
import { TopHeader } from "../components/TopHeader";

export const NotificationsScreen = () => {
   return (
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
         <TopHeader head="Notificaciones" content={<></>} />
         <Container maxWidth="xl">
            <Box sx={{ m: 2 }} />
            <NotificationsTable />
         </Container>
      </Box>
   );
};
