import {
   Box,
   Typography,
   Divider,
   Button,
   MenuItem,
   Dialog,
   DialogTitle,
   DialogContent,
   DialogContentText,
   DialogActions,
   CircularProgress,
   Stack,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useContext, useState, useEffect } from "react";
import { createRolSchema } from "../../lib/Validations/inputSchemas";
import { InputTextField } from "../../components/Inputs/InputTextField";
import { useNavigate } from "react-router-dom";
import { InputSelectField } from "../Inputs/InputSelectField";
import { CreateAccessModal } from "../ClientInformation/Modal/CreateAccessModal";
import { createMasterRole, getAllServices, getMasterAccessByServiceId } from "../../lib/usersBEClient";
import { SnackBarContext } from "../../context/snackBarContext";
import { RoleTransferListComponent, TransferListItem } from "../RoleTransferListComponent";
import { LoadingButton } from "../LoadingButton";

export const CreateRoleComponent = () => {
   const navigate = useNavigate();
   const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
   const [openCreateAccessModal, setOpenCreateAccessModal] = useState(false);
   const [leftlistState, setLeftlistState] = useState<TransferListItem[]>([]);
   const [rightListState, setRightListState] = useState<TransferListItem[]>([]);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState(false);
   const [services, setServices] = useState([]);
   const [lastSelectedService, setLastSelectedService] = useState(null);

   async function handleMenuItemClick(selectedService) {
      setIsLoading(true);
      if (selectedService === lastSelectedService) {
         setIsLoading(false);
         return null;
      }

      setLastSelectedService(selectedService);

      try {
         const masterAccessResponse = await getMasterAccessByServiceId(selectedService);
         const masterAccessData = masterAccessResponse.data.map((e) => ({
            id: e._id,
            name: e.name,
            description: e.description,
            resources: e.resources,
         }));
         const itemsToAddToLeftList = masterAccessData.filter((item) => {
            return !rightListState.some((rightItem) => rightItem.id === item.id);
         });
         setIsLoading(false);
         setLeftlistState(itemsToAddToLeftList);
      } catch (error) {
         setIsLoading(false);
         console.log(error);
      }
   }

   const fetchInfo = async () => {
      const servicesResponse = await getAllServices();
      const serviceData = servicesResponse.data;
      setServices(serviceData);
   };

   const servicesObtained = services.map((s) => ({
      id: s._id,
      name: s.service,
      resources: s.resources,
   }));

   useEffect(() => {
      fetchInfo();
   }, [lastSelectedService]);

   async function handleSubmit(values) {
      if (rightListState.length === 0) {
         return showSnackBar("Selecciona al menos un acceso", true);
      }
      setIsLoading(true);
      try {
         await createMasterRole(values.name, values.description, values.access);
         showSnackBar("Rol creado exitosamente", false);
         setIsLoading(false);
         setTimeout(() => {
            navigate(-1);
         }, 2000);
      } catch (error) {
         console.log(error);
         setIsLoading(false);
         return;
      }
      setIsLoading(false);
   }

   return (
      <>
         <Formik
            initialValues={{
               name: "",
               description: "",
               module: "",
               access: [],
            }}
            onSubmit={async (values) => {
               try {
                  await handleSubmit(values);
               } catch (error) {
                  console.log(error);
               }
            }}
            validationSchema={createRolSchema}
         >
            {(props) => (
               <Form>
                  <Box
                     bgcolor="white"
                     boxShadow={1}
                     borderRadius={1}
                     sx={{ display: "flex", rowGap: 2, flexDirection: "column" }}
                  >
                     <Box p={2}>
                        <Box display="flex" alignItems="center" px={5} py={1}>
                           <Typography variant="h5" fontWeight={500} style={{ flex: "0 0 auto" }}>
                              Datos del Rol
                           </Typography>
                           <Divider sx={{ my: 1, flexGrow: 1, ml: 2 }} />
                        </Box>

                        <Box sx={{ flexDirection: "row", alignItems: "center" }}>
                           <Box display={"flex"} justifyContent={"space-between"} px={5} py={1} columnGap={"30%"}>
                              <InputTextField
                                 variant="outlined"
                                 multiline
                                 size="small"
                                 name="name"
                                 id="name"
                                 type="text"
                                 label="Nombre del rol"
                                 sx={{ width: "40%" }}
                                 value={props.values.name}
                                 onChange={props.handleChange}
                              />

                              <InputTextField
                                 variant="outlined"
                                 multiline
                                 size="small"
                                 name="description"
                                 id="description"
                                 type="text"
                                 label="Descripción"
                                 sx={{ width: "60%" }}
                                 value={props.values.description}
                                 onChange={props.handleChange}
                              />
                           </Box>
                        </Box>
                        <Box mt={3}>
                           <Box display="flex" alignItems="center" px={5} py={1}>
                              <Typography variant="h5" fontWeight={500} style={{ flex: "0 0 auto" }}>
                                 Establecer acceso
                              </Typography>
                              <Divider sx={{ my: 1, flexGrow: 1, ml: 2 }} />
                           </Box>
                           <Box
                              display={"flex"}
                              flex={1}
                              sx={{
                                 justifyContent: "space-between",
                                 py: 2,
                                 flexDirection: "row",
                                 alignItems: "center",
                                 px: 5,
                              }}
                           >
                              <Box display={"flex"} columnGap={2} alignItems={"center"}>
                                 <Box>
                                    <Typography fontSize={"18px"} fontWeight={500}>
                                       Módulo al que tendrá acceso:
                                    </Typography>
                                 </Box>

                                 <Box mx={1} flex={1} width={"400px"}>
                                    <InputSelectField
                                       label="Seleccionar módulo"
                                       labelId="module"
                                       id="module"
                                       fullWidth
                                       name="module"
                                       variant={"outlined"}
                                       size="small"
                                       value={props.values.module}
                                       onChange={props.handleChange}
                                    >
                                       {servicesObtained.map((option, index) => {
                                          return (
                                             <MenuItem
                                                key={`option-${index}`}
                                                value={option.id}
                                                onClick={() => handleMenuItemClick(option.id)}
                                             >
                                                {option.name}
                                             </MenuItem>
                                          );
                                       })}
                                    </InputSelectField>
                                 </Box>
                              </Box>
                              <Box>
                                 <Button
                                    onClick={() => {
                                       setOpenCreateAccessModal(true);
                                    }}
                                    type="button"
                                    variant="contained"
                                    sx={{ width: "150px", backgroundColor: "#142c44" }}
                                 >
                                    Crear acceso
                                 </Button>
                                 <CreateAccessModal open={openCreateAccessModal} setOpen={setOpenCreateAccessModal} />
                              </Box>
                           </Box>
                           <Box my={5} justifyContent={"center"}>
                              {!isLoading ? (
                                 <RoleTransferListComponent
                                    title1="Accesos creados para el modulo seleccionado:"
                                    title2="Acceso asignado:"
                                    leftlist={leftlistState}
                                    rightlist={rightListState}
                                    setLeftlist={setLeftlistState}
                                    setRightlist={setRightListState}
                                    onSaveRightList={(newRightList) => props.setFieldValue("access", newRightList)}
                                    isLoading={isLoading}
                                 />
                              ) : (
                                 <Box
                                    sx={{
                                       justifyContent: "center",
                                       display: "flex",
                                       alignItems: "center",
                                    }}
                                 >
                                    <CircularProgress size={24} />
                                 </Box>
                              )}
                           </Box>
                        </Box>
                     </Box>
                  </Box>
                  <Box sx={{ display: "flex", rowGap: 2, flexDirection: "column", my: 2 }}>
                     <Stack direction="row" spacing={1} justifyContent="flex-end">
                        <Button
                           sx={{ mr: 4, width: "100px" }}
                           variant="outlined"
                           onClick={() => {
                              setIsCancelDialogOpen(true);
                           }}
                        >
                           Cancelar
                        </Button>
                        <LoadingButton label={"Guardar"} isLoading={isLoading} />
                     </Stack>
                  </Box>
               </Form>
            )}
         </Formik>

         <Dialog open={isCancelDialogOpen} onClose={() => setIsCancelDialogOpen(false)}>
            <DialogTitle>Cancelar creación de rol</DialogTitle>
            <DialogContent>
               <DialogContentText>¿Estás seguro de que deseas cancelar la creación del rol?</DialogContentText>
               <DialogContentText>Todos los cambios no guardados se perderán.</DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button sx={{ height: "100%" }} variant="outlined" onClick={() => setIsCancelDialogOpen(false)}>
                  Cancelar
               </Button>
               <Button
                  onClick={() => {
                     setIsCancelDialogOpen(false);
                     navigate(-1);
                  }}
                  color="primary"
                  autoFocus
                  variant="outlined"
                  sx={{ color: "white", backgroundColor: "#142c44" }}
               >
                  Aceptar
               </Button>
            </DialogActions>
         </Dialog>
      </>
   );
};
