import { complaintUploadPdfEvidence, getUrlForDocumentationUpload } from "./usersBEClient";

export const createDocumentPdf = async (reportNumber: string, documentName: string, blob: Blob) => {
   const updatedComplaint = await getUrlForDocumentationUpload(
      "files-lecosy",
      { folder: `docs/${reportNumber}` },
      documentName,
      reportNumber
   );
   await complaintUploadPdfEvidence(updatedComplaint, blob);
};
