import { useRef, useContext } from "react";
import { SnackBarContext } from "../../context/snackBarContext";
import { IconButton, Box, Button, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";

interface InputFileProps {
   setFile1: Function;
   file1: File;
   setFile2: Function;
   file2: File;
   onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CustomInputFiles = (props: InputFileProps) => {
   const inputRef: any = useRef<any>();
   const { showSnackBar } = useContext(SnackBarContext);

   const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files.length === 2) {
         if (
            (event.target.files[0].type === "application/pdf" || event.target.files[0].type === "text/xml") &&
            (event.target.files[1].type === "application/pdf" || event.target.files[1].type === "text/xml")
         ) {
            if (event.target.files[0].type === "application/pdf" && event.target.files[1].type === "application/pdf") {
               inputRef.current.value = null;
               return showSnackBar("Los archivos no pueden ser del mismo tipo (PDF)", true);
            }
            if (event.target.files[0].type === "text/xml" && event.target.files[1].type === "text/xml") {
               inputRef.current.value = null;
               return showSnackBar("Los archivos no pueden ser del mismo tipo (XML)", true);
            }
            if (event.target.files[0].size > 1000000 || event.target.files[1].size > 1000000) {
               inputRef.current.value = null;
               return showSnackBar("El archivo no puede pesar más de 1MB", true);
            }
            if (event.target.files[0].type === "application/pdf") {
               props.setFile1(event.target.files[0]);
               props.setFile2(event.target.files[1]);
            } else {
               props.setFile1(event.target.files[1]);
               props.setFile2(event.target.files[0]);
            }
         } else {
            return showSnackBar("El archivo tiene que ser PDF/XML", true);
         }
      } else {
         inputRef.current.value = null;
         return showSnackBar("Favor de adjuntar dos documentos PDF/XML", true);
      }
   };

   return (
      <Button
         variant="outlined"
         component="span"
         sx={{
            p: 1,
            minWidth: "83%",
            justifyContent: "center",
            minHeight: "80px",
            mb: 4,
            borderColor: "#C4C4C4",
         }}
      >
         {!props.file1 && !props.file2 ? (
            <input
               accept=".pdf,.xml"
               id="policies-button-file"
               multiple
               ref={inputRef}
               type="file"
               onChange={(e) => {
                  handleUploadFile(e);
               }}
            />
         ) : (
            <>
               <Box
                  sx={{ ml: 1 }}
                  onClick={() => {
                     window.open(URL.createObjectURL(props.file1), "_blank", "noopener,noreferrer");
                     window.open(URL.createObjectURL(props.file2), "_blank", "noopener,noreferrer");
                  }}
               >
                  <Typography fontSize={14} fontWeight={600}>
                     {props.file1.name.length > 30
                        ? `${props.file1.name.substring(0, 25)}...pdf - `
                        : `${props.file1.name} - `}
                     {props.file2.name.length > 30
                        ? `${props.file2.name.substring(0, 25)}...pdf`
                        : `${props.file2.name}`}
                  </Typography>
               </Box>
               <IconButton
                  size="medium"
                  sx={{ ml: 1 }}
                  onClick={() => {
                     props.setFile1(null);
                     props.setFile2(null);
                  }}
               >
                  <GridCloseIcon sx={{ color: "#162c44", fontSize: 15 }} />
               </IconButton>
            </>
         )}
      </Button>
   );
};
