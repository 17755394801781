import { Box, Button, Drawer, Grid, Typography, Container, Stack, IconButton } from "@mui/material";
import * as React from "react";
import { UserContext } from "../../context/UserContext";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import ReLogInModal from "../../components/ReLogInModal";
import { TopHeader } from "../../components/TopHeader";
import { ModifyUserInfoDrawer } from "../../components/Drawers/ModifyUserInfoDrawer";
import { ModifyPasswordDrawer } from "../../components/Drawers/ModifyPasswordDrawer";
import { getDateFromISODate } from "../../const/globalConst";
import { ProfilePictureComponent } from "./ProfilePictureComponent";

export const UserConfiguration = () => {
   const { user } = React.useContext(UserContext);
   const [openDrawer, setOpenDrawer] = React.useState(false);
   const [openPasswordDrawer, setOpenPasswordDrawer] = React.useState(false);
   const [openEmail, setOpenEmail] = React.useState(false);
   const [modifiedPassword, setModifiedPassword] = React.useState(false);

   const handleModifyUserInformation = () => {
      setOpenDrawer(true);
   };

   function getRoles(roles: any[]) {
      const roleNames = roles.map((role) => role.name);
      const rolesString = roleNames.join(", ");
      return rolesString;
   }

   return (
      <Box p={2}>
         <TopHeader head={"Configuración"} content={<></>} />
         <Container maxWidth="xl">
            <Box display={"flex"}>
               <Box bgcolor={"white"} pt={2} pb={2} boxShadow={2} borderRadius={1}>
                  <Box
                     borderBottom={"1px solid #D9D9D9"}
                     sx={{ display: "flex", justifyContent: "space-between", mb: 3, px: 5, pb: 2 }}
                  >
                     <Typography fontSize={"24px"} fontWeight={500}>
                        Información general
                     </Typography>
                     <Button
                        sx={{
                           bgcolor: "#2D4357",
                           "&:hover": {
                              bgcolor: "#2D435790",
                           },
                           color: "white",
                           px: 2,
                           boxShadow: 2,
                        }}
                        onClick={handleModifyUserInformation}
                     >
                        Modificar usuario
                     </Button>
                  </Box>
                  <Stack direction={"row"} justifyContent={"center"} sx={{ my: 4 }}>
                     <Box justifyContent={"center"} alignItems={"center"} display={"flex"} sx={{ mx: 2, flex: 1 }}>
                        <ProfilePictureComponent />
                     </Box>
                     <Grid container alignItems={"center"} sx={{ flex: 2, py: 3 }}>
                        <Grid display={"flex"} columnGap={2} item xs={7}>
                           <Typography fontWeight={600}>Nombre:</Typography>
                           <Typography>{user.masterUserName}</Typography>
                        </Grid>
                        <Grid display={"flex"} columnGap={2} item xs={5}>
                           <Typography fontWeight={600}>Apellido:</Typography>
                           <Typography>{user.masterUserLastName}</Typography>
                        </Grid>
                        <Grid display={"flex"} columnGap={2} item xs={7}>
                           <Typography fontWeight={600}>Correo electrónico:</Typography>
                           <Typography>{user.email}</Typography>
                        </Grid>

                        <Grid display={"flex"} alignItems={"center"} columnGap={2} item xs={5}>
                           <Typography fontWeight={600}>Contraseña:</Typography>
                           <Typography fontSize={20}>••••••••••</Typography>
                           <IconButton sx={{ color: "#2D4357" }} onClick={() => setOpenPasswordDrawer(true)}>
                              <ModeEditIcon />
                           </IconButton>
                        </Grid>
                        <Grid display={"flex"} columnGap={2} item xs={7}>
                           <Typography fontWeight={600}>Rol:</Typography>
                           <Typography>{getRoles(user.role)}</Typography>
                        </Grid>
                        <Grid display={"flex"} columnGap={2} item xs={5}>
                           <Typography fontWeight={600}>Fecha de creación:</Typography>
                           <Typography>{user.createdAt ? getDateFromISODate(user.createdAt) : ""}</Typography>
                        </Grid>
                     </Grid>
                  </Stack>
               </Box>
            </Box>
         </Container>
         <Drawer anchor="right" open={openDrawer || openPasswordDrawer} hideBackdrop sx={{ maxWidth: 450 }}>
            <>
               {openPasswordDrawer ? (
                  <ModifyPasswordDrawer
                     open={openPasswordDrawer}
                     setOpen={setOpenPasswordDrawer}
                     user={user}
                     setModifiedPassword={setModifiedPassword}
                  />
               ) : (
                  <ModifyUserInfoDrawer
                     open={openDrawer}
                     setOpen={setOpenDrawer}
                     setOpenEmail={setOpenEmail}
                     dataToModify={user}
                  />
               )}
            </>
         </Drawer>
         <ConfirmationModal
            open={openEmail}
            setOpen={setOpenEmail}
            title="Modificación de correo electrónico"
            body={
               <Typography>
                  Tu correo electrónico está en proceso de cambio. Por el momento no se verá reflejado.
                  <Typography>Es necesario verificar el nuevo correo para acceder a tu cuenta</Typography>
               </Typography>
            }
            onConfirm={() => {}}
            cancelButton={true}
         />
         <ReLogInModal open={modifiedPassword} setOpen={setModifiedPassword} />
      </Box>
   );
};
